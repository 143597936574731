import React from "react";
import { createTheme } from "@mui/material/styles";

const highlightColor = "#ff0";
const mainColor = "#1685d3";
const blockOrange = "#ff8521";
const navbarGreen = "#26a64a";

const Theme = createTheme({
  palette: {
    common: {
      highlight: highlightColor,
      orange: blockOrange,
      green: navbarGreen,
    },
    primary: {
      main: mainColor,
    },
    secondary: {
      main: "#808080",
    },
  },
  components: {
    MuiToolbar: {
      variants: [
        {
          props: { variant: "footer" },
          style: {
            backgroundColor: "#333",
            padding: "0.9em 8.2vw !important",
            color: "silver",
            fontSize: "0.8rem",
            fontFamily: "Roboto,sans-serif",
            textAlign: "left",
            lineHeight: "1.2rem",
            marginTop: "16px",
          },
        },
      ],
    },
    MuiLink: {
      variants: [
        {
          props: { variant: "email" },
          style: {
            textTransform: "lowercase",
            "&:focus": {
              color: "red",
            },
          },
        },
      ],
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: navbarGreen,
        },
      },
    },
  },
});
export default Theme;
