import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useForm } from "react-hook-form";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Tooltip,
  Typography,
  Box,
  RadioGroup,
  Radio,
  tooltipClasses,
} from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import StepComponent from "../components/StepComponent";

const YES = "YES";
const NO = "NO";
const theme = createTheme();
const useStyles = makeStyles(() => ({
  form: {
    marginTop: theme.spacing(9),
  },
  error: {
    color: "red !important",
    textAlign: "left !important",
  },
  listItem: {
    lineHeight: "1.2 !important",
    marginBottom: theme.spacing(1),
  },
  radioControl: {
    alignItems: "center",
  },
  labelText: {
    lineHeight: "1.2 !important",
  },
}));
const ToolTipContent = React.forwardRef(function ToolTipContent(props, ref) {
  return (
    <span {...props} ref={ref}>
      {" "}
      <u>{props.children}</u>
    </span>
  );
});

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[2],
    fontSize: 11,
    padding: theme.spacing(2),
  },
}));

const Disclosures = forwardRef((props, ref) => {
  const classes = useStyles();
  const { control, handleSubmit, setValue, errors, getValues, watch } = useForm(
    {}
  );
  const [values, setValues] = useState({
    conditions: NO,
    disclosures: {},
  });
  const { isPoliticallyExposed, isControlPerson, isAffiliatedExchangeOrFINRA } =
    watch();
  const getConditionValue = (disclosures) => {
    let value = YES;
    if (
      disclosures.isPoliticallyExposed === NO &&
      disclosures.isControlPerson === NO &&
      disclosures.isAffiliatedExchangeOrFINRA === NO
    ) {
      value = NO;
    }
    return value;
  };

  const handleGetDetails = (data) => {
    const res = data;
    const isDataPresent = !!(
      res.applicants &&
      res.applicants[0] &&
      res.applicants[0]?.disclosures &&
      Object.keys(res.applicants[0].disclosures).length
    );
    if (isDataPresent) {
      const { disclosures } = res.applicants[0];

      setTimeout(() => {
        setValue(
          "isPoliticallyExposed",
          disclosures.isPoliticallyExposed,
          true
        );
        setValue("isControlPerson", disclosures.isControlPerson, true);
        setValue(
          "isAffiliatedExchangeOrFINRA",
          disclosures.isAffiliatedExchangeOrFINRA,
          true
        );
      }, 10);
      setTimeout(() => {
        setValue(
          "companySymbols",
          disclosures?.companySymbols?.join(","),
          true
        );
        if (disclosures.politicalExposureDetail) {
          let { immediateFamily, politicalOrganization } =
            disclosures.politicalExposureDetail;
          immediateFamily = immediateFamily.join(",");
          politicalOrganization = politicalOrganization.join(",");
          setValue("politicalExposureDetail", {
            immediateFamily,
            politicalOrganization,
          });
        }
        setValue("firmName", disclosures.firmName, true);
      }, 100);
      setValues({
        ...values,
        applicants: res.applicants,
        conditions: getConditionValue(disclosures),
        disclosures,
      });
    } else {
      setValues({
        ...values,
        applicants: res.applicants,
        conditions: NO,
      });
    }
  };

  useEffect(() => {
    if (props.userInfoData) {
      handleGetDetails(props.userInfoData);
    }
  }, [props.userInfoData]); // eslint-disable-line react-hooks/exhaustive-deps

  const getApplicantsData = (data) => {
    const resApplicantData = values.applicants
      ? values.applicants[0]
      : { employment: {}, contact: {} };
    const applicantData = { ...resApplicantData };
    let { companySymbols, politicalExposureDetail } = data;
    if (politicalExposureDetail) {
      let { immediateFamily, politicalOrganization } = politicalExposureDetail;
      immediateFamily = immediateFamily.split(",");
      politicalOrganization = politicalOrganization.split(",");
      politicalExposureDetail = { immediateFamily, politicalOrganization };
    }
    if (companySymbols) {
      companySymbols = companySymbols.split(",");
    }
    applicantData.disclosures = Object.keys(data).length
      ? { ...data, companySymbols, politicalExposureDetail }
      : {
          isPoliticallyExposed: "NO",
          isControlPerson: "NO",
          isAffiliatedExchangeOrFINRA: "NO",
        };
    return [applicantData];
  };

  const yesNoOptions = [
    { value: YES, key: "Yes" },
    { value: NO, key: "No" },
  ];

  const seniorPoliticalFig =
    "A “senior political figure” means a current or former: senior official in the executive, legislative, administrative, military, or judicial branches of a foreign government (whether elected or not); senior official of a major foreign political party; or senior executive of a foreign government-owned commercial enterprise; (ii) a corporation, business, or other entity that has been formed by, or for the benefit of, any such individual; an immediate family member of any such individual; and (iv) a person who is widely and publicly known (or is actually known by the relevant covered financial institution) to be a close associate of such individual. For purposes of this definition: senior official or executive means an individual with substantial authority over policy, operations, or the use of government-owned resources; and immediate family member means spouses, parents, siblings, children and a spouse's parents and siblings.Public stocks (also known as equities) are securities that represent the ownership of a fraction of a corporation. Units of stock are called 'shares' that are bought and sold predominantly on stock exchanges.";

  const handleChange = (e, key) => {
    setValue(key, e.target.value);
    if (props.onEnableButtons) props.onEnableButtons();
  };
  const handleChangeCover = (condition) => {
    return (e) => {
      handleChange(e, condition);
    };
  };
  const handleRadioChange = (e) => {
    setValues({
      ...values,
      conditions: e.target.value,
    });
    if (props.onEnableButtons) props.onEnableButtons();
  };

  const onSubmit = (data) => {
    const applicationData = getApplicantsData(data);
    props.onSubmit(applicationData);
  };
  useImperativeHandle(ref, () => ({
    onBack() {
      const data = getValues();
      const applicationData = getApplicantsData(data);
      props.onBackSubmit(applicationData);
    },
  }));

  return (
    <div className="step1">
      <br />
      <Grid container spacing={6}>
        <Grid
          item
          xs={10}
          sm={8}
          lg={6}
          className="stepsize"
          style={{ paddingTop: "12px" }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Typography variant="subtitle1">
                Do any of the following conditions apply?
              </Typography>
              <ul>
                <li>
                  <Box>
                    <Typography
                      variant="subtitle1"
                      component="p"
                      align="left"
                      paragraph
                      className={classes.listItem}
                    >
                      You or any of your family members is a director, 10%
                      shareholder, or policy making officer of a publicly traded
                      company
                    </Typography>
                  </Box>
                </li>
                <li>
                  <Box>
                    <Typography
                      variant="subtitle1"
                      component="p"
                      align="left"
                      paragraph
                      className={classes.listItem}
                    >
                      You or any of your family members is affiliated with any
                      securities firm, bank, securities regulatory body,
                      insurance company or trust company
                    </Typography>
                  </Box>
                </li>
                <li>
                  <Box>
                    <Typography
                      variant="subtitle1"
                      component="p"
                      align="left"
                      paragraph
                      className={classes.listItem}
                    >
                      You or any member of your immediate family, or any of your
                      personal or business are associated with a <br />
                      <LightTooltip
                        title={<Typography>{seniorPoliticalFig}</Typography>}
                        id="seniorpolitical"
                      >
                        <ToolTipContent>senior political figure</ToolTipContent>
                      </LightTooltip>
                    </Typography>
                  </Box>
                </li>
              </ul>
            </Box>
            <FormControl component="fieldset" className={classes.radioControl}>
              <RadioGroup
                row={true}
                aria-label="conditions"
                name="conditions"
                value={values.conditions}
                onChange={(e) => handleRadioChange(e, "conditions")}
              >
                <FormControlLabel
                  value={YES}
                  control={<Radio color="primary" />}
                  label="Yes"
                />
                <FormControlLabel value={NO} control={<Radio />} label="No" />
              </RadioGroup>
              <FormHelperText className={classes.error}>
                {errors?.conditions?.message}
              </FormHelperText>
            </FormControl>
            {values.conditions === YES ? (
              <Box>
                <Typography
                  variant="subtitle1"
                  align="left"
                  paragraph={true}
                  className={classes.labelText}
                >
                  Are you or any member of your immediate family, or any of your
                  personal or business associates a senior political figure??
                </Typography>
                <Box mb={1}>
                  <FormControl variant="outlined">
                    <StepComponent
                      inputType="Select"
                      inputLabel="Politically exposed"
                      options={yesNoOptions}
                      componentProp={{
                        onChange: handleChangeCover("isPoliticallyExposed"),
                      }}
                      controllerProp={{
                        control,
                        name: "isPoliticallyExposed",
                        defaultValue: "",
                        rules: {
                          required: "This Field is Required",
                        },
                      }}
                    />
                    <FormHelperText className={classes.error}>
                      {errors?.isPoliticallyExposed?.message}
                    </FormHelperText>
                  </FormControl>
                </Box>
                {isPoliticallyExposed === YES ? (
                  <Box mt={1} mb={1}>
                    <FormControl variant="outlined">
                      <StepComponent
                        inputType="Input"
                        controllerProp={{
                          control,
                          name: "politicalExposureDetail.immediateFamily",
                          id: "politicalExposureDetail.immediateFamily",
                          defaultValue: "",
                          rules: {
                            validate: {
                              required: (value) => {
                                const {
                                  politicalExposureDetail: {
                                    politicalOrganization,
                                    immediateFamily,
                                  },
                                } = getValues();
                                return !(
                                  !politicalOrganization && !immediateFamily
                                );
                              },
                            },
                          },
                        }}
                        componentProp={{
                          variant: "outlined",
                          label: "Immediate Family, separated by commas",
                        }}
                      />
                      <FormHelperText className={classes.error}>
                        {errors.politicalExposureDetail?.politicalOrganization
                          ?.type === "required" &&
                          errors?.politicalExposureDetail?.immediateFamily
                            ?.type === "required" &&
                          "Any one of the two fields are required."}
                      </FormHelperText>
                    </FormControl>
                    <FormControl variant="outlined">
                      <StepComponent
                        inputType="Input"
                        controllerProp={{
                          control,
                          name: "politicalExposureDetail.politicalOrganization",
                          id: "politicalExposureDetail.politicalOrganization",
                          defaultValue: "",
                          rules: {
                            validate: {
                              required: (value) => {
                                const {
                                  politicalExposureDetail: {
                                    politicalOrganization,
                                    immediateFamily,
                                  },
                                } = getValues();
                                return !(
                                  !politicalOrganization && !immediateFamily
                                );
                              },
                            },
                          },
                        }}
                        componentProp={{
                          variant: "outlined",
                          label:
                            "Political people or organizations, separated by commas",
                        }}
                      />
                      <FormHelperText className={classes.error}>
                        {errors.politicalExposureDetail?.politicalOrganization
                          ?.type === "required" &&
                          errors?.politicalExposureDetail?.immediateFamily
                            ?.type === "required" &&
                          "Any one of the two fields are required."}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                ) : null}
                <Typography
                  variant="subtitle1"
                  align="left"
                  paragraph={true}
                  className={classes.labelText}
                >
                  Are you or any of your family members a director, 10%
                  shareholder or policy making officer of a publicly traded
                  company?
                </Typography>
                <Box mb={1}>
                  <FormControl variant="outlined">
                    <StepComponent
                      inputType="Select"
                      inputLabel="Control person"
                      options={yesNoOptions}
                      componentProp={{
                        onChange: handleChangeCover("isControlPerson"),
                      }}
                      controllerProp={{
                        control,
                        name: "isControlPerson",
                        defaultValue: "",
                        rules: {
                          required: "This Field is Required",
                        },
                      }}
                    />
                    <FormHelperText className={classes.error}>
                      {errors.isControlPerson && errors.isControlPerson.message}
                    </FormHelperText>
                  </FormControl>
                </Box>
                {isControlPerson === YES ? (
                  <Box mt={1} mb={1}>
                    <FormControl variant="outlined">
                      <StepComponent
                        inputType="Input"
                        controllerProp={{
                          control,
                          name: "companySymbols",
                          id: "companySymbols",
                          defaultValue: "",
                          rules: {
                            required: "This Field is Required",
                          },
                        }}
                        componentProp={{
                          variant: "outlined",
                          label: "Companies, separated by commas",
                        }}
                      />
                      <FormHelperText className={classes.error}>
                        {errors?.companySymbols?.message}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                ) : null}
                <Typography
                  variant="subtitle1"
                  align="left"
                  paragraph={true}
                  className={classes.labelText}
                >
                  Are you or any of your family members affiliated with any
                  securities firm, bank, securities regulatory body, insurance
                  company or trust company?
                </Typography>
                <Box mb={1}>
                  <FormControl variant="outlined">
                    <StepComponent
                      inputType="Select"
                      inputLabel="Affiliated exchange or FINRA"
                      options={yesNoOptions}
                      componentProp={{
                        onChange: handleChangeCover(
                          "isAffiliatedExchangeOrFINRA"
                        ),
                      }}
                      controllerProp={{
                        control,
                        name: "isAffiliatedExchangeOrFINRA",
                        defaultValue: "",
                        rules: {
                          required: "This Field is Required",
                        },
                      }}
                    />
                    <FormHelperText className={classes.error}>
                      {errors?.isAffiliatedExchangeOrFINRA?.message}
                    </FormHelperText>
                  </FormControl>
                </Box>
                {isAffiliatedExchangeOrFINRA === YES ? (
                  <Box mt={1} mb={1}>
                    <FormControl variant="outlined">
                      <StepComponent
                        inputType="Input"
                        controllerProp={{
                          control,
                          name: "firmName",
                          id: "firmName",
                          defaultValue: "",
                          rules: {
                            required: "This Field is Required",
                          },
                        }}
                        componentProp={{
                          variant: "outlined",
                          label: "Firm name",
                        }}
                      />
                      <FormHelperText className={classes.error}>
                        {errors?.firmName?.message}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                ) : null}
              </Box>
            ) : null}
            {props.StepButtons}
          </form>
        </Grid>
      </Grid>
    </div>
  );
});

export default Disclosures;
