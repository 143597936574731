import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Toolbar, Link, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { RoutePath } from "../onboarding";

const LegalLink = (props) => {
  const theme = useTheme();
  const link = `https://99rises-docs.s3-us-west-2.amazonaws.com/legal/${props.fname}`;
  return (
    <Link
      underline="none"
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      sx={{
        color: theme.palette.common.white,
        "&:hover": {
          color: theme.palette.common.highlight,
          background: "transparent",
        },
      }}
    >
      {props.children}
    </Link>
  );
};

export default function Footer() {
  const smallFooterPathList = Object.keys(RoutePath).map(
    (stepCount) => `/step/${stepCount}`
  );
  const { pathname } = useLocation();
  const [smallFooter, setSmallFooter] = useState(true);
  useEffect(() => {
    setSmallFooter(smallFooterPathList.includes(pathname));
  }, [pathname]);

  return (
    <>
      {!smallFooter && (
        <Toolbar variant="footer" className="copyrights">
          <div className="footer-head footer-options">
            <div className="footer-setup">
              <p>
                99Rises Inc (“99rises”) is an SEC-registered investment adviser.
                99rises offers a software-based financial advice platform and
                applications that deliver automated investment management to
                help users achieve their financial goals. 99rises services are
                available only to residents of the United States.
              </p>

              <p>
                By using this website, you understand the information being
                presented is provided for informational purposes only and agree
                to our{" "}
                <LegalLink fname="99rises+-+Terms+of+Use+v1.pdf">
                  Terms of Use
                </LegalLink>{" "}
                and{" "}
                <LegalLink fname="99rises+-+Consumer+Privacy+Policy+v1.pdf">
                  Privacy Policy
                </LegalLink>
                . 99rises relies on information from various sources believed to
                be reliable, including clients and third parties, but cannot
                guarantee the accuracy and completeness of that information.
                Nothing on this website should be construed as an offer,
                recommendation, or solicitation to buy or sell any security.
                Additionally, 99rises or its affiliates do not provide tax
                advice and investors are encouraged to consult with their
                personal tax advisors.
              </p>
              <p>
                Refer to 99rises's{" "}
                <LegalLink fname="99rises+-+Wrap+Fee+Brochure+v1.pdf">
                  Program Brochure
                </LegalLink>{" "}
                for more information. Certain investments are not suitable for
                all investors. Before investing, consider your investment
                objectives and the 99rises's fees. All investing involves risk,
                including the possible loss of money you invest, and past
                performance does not guarantee future performance. Historical
                returns, expected returns, and probability projections are
                provided for informational and illustrative purposes, and may
                not reflect actual future performance. Please see our{" "}
                <LegalLink fname="99rises+-+Disclosures+v1.pdf">
                  Full Disclosure
                </LegalLink>{" "}
                for important details.
              </p>
              <p>
                Brokerage services are provided to 99rises by Tradier Brokerage,
                an SEC registered broker-dealer and member FINRA/SIPC. For more
                information, see our{" "}
                <LegalLink fname="99rises+-+Disclosures+v1.pdf">
                  disclosures
                </LegalLink>
                . Contact: Tradier Brokerage, 11016 Rushmore Dr, Ste 350
                Charlotte, NC 28277
              </p>
              <Box
                className="notice"
                sx={{ fontSize: "0.9rem", textAlign: "center" }}
              >
                © {new Date().getFullYear()} 99rises. All rights reserved
              </Box>
            </div>
          </div>
        </Toolbar>
      )}
      {smallFooter && (
        <Toolbar
          variant="footer"
          style={{
            justifyContent: "center",
            top: 0,
          }}
        >
          <Box
            sx={{ fontSize: "0.9rem", textAlign: "center" }}
            className="notice"
          >
            © {new Date().getFullYear()} 99rises. All rights reserved
          </Box>
        </Toolbar>
      )}
    </>
  );
}
