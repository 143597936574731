import React, { useState, useEffect } from "react";
import { Grid, Chip } from "@mui/material";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import PropTypes from "prop-types";
import { numberFormat, number } from "../utils/numbers";
import AppBackService from "../services/app-back";
import StockMemo from "./StockMemo";

const Accountholdings = ({ longs, shorts, hedges }) => {
  const Appback = AppBackService();
  const [details, setDetails] = useState({
    longs: [],
    shorts: [],
    hedges: [],
  });
  const [starData, setStarData] = useState([]);
  const [openStockModel, setStockModel] = useState(false);
  const [tickerName, setTicker] = useState("");
  const [tickerType, setTickerType] = useState("");
  const [isStar, setStar] = useState(false);
  const getStockStartData = async () => {
    Appback.getBlockStarData("stocks").then((res) => {
      if (res && res.stocks) setStarData(res.stocks);
    });
  };
  const setStockStarData = (ticker) => {
    if (starData.includes(ticker)) {
      const array = [...starData];
      const index = array.indexOf(ticker);
      if (index !== -1) {
        array.splice(index, 1);
        setStarData(array);
      }
    } else {
      const array = [...starData];
      array.push(ticker);
      setStarData(array);
    }
  };
  const handleStockStarData = (ticker) => {
    setStockStarData(ticker);
    const data = {
      key: ticker,
      value: !starData.includes(ticker),
    };
    Appback.saveStarData(data, "stocks")
      .then(() => {})
      .catch(() => {
        setStockStarData(ticker);
      });
  };
  const handleStockMemo = (_ticker, _tickerType, _isStar) => {
    setTicker(_ticker);
    setTickerType(_tickerType);
    setStockModel(true);
    setStar(_isStar);
  };
  useEffect(() => {
    setDetails({ longs, shorts, hedges });
    if (starData.length === 0) getStockStartData();
  }, [longs, shorts, hedges]);

  return (
    <>
      <div className="account-holdings" style={{ overflow: "hidden" }}>
        <Grid container spacing={7}>
          {details.longs.length > 0 && (
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <h3 align="center"> Longs </h3>
              <table className="table" id="table_longs">
                <thead>
                  <tr>
                    <td align="center">SYMBOL</td>
                    <td align="right">NSHARES</td>
                    <td align="right">VALUE</td>
                    <td className="profit_loss" align="right">
                      CHANGE%
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <ReuseDetails
                    details={details.longs}
                    handleStockMemo={handleStockMemo}
                    tickerType="LONG"
                    handleStockStarData={handleStockStarData}
                    starData={starData}
                  />
                </tbody>
              </table>
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={6} lg={6}>
            {details.shorts.length > 0 && (
              <>
                <h3 align="center"> Shorts </h3>
                <table className="table" id="table_shorts">
                  <thead>
                    <tr>
                      <td align="center">SYMBOL</td>
                      <td align="right">NSHARES</td>
                      <td align="right">VALUE</td>
                      <td className="profit_loss" align="right">
                        CHANGE%
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <ReuseDetails
                      details={details.shorts}
                      handleStockMemo={handleStockMemo}
                      tickerType="SHORT"
                      handleStockStarData={handleStockStarData}
                      starData={starData}
                    />
                  </tbody>
                </table>
              </>
            )}
            {details.hedges.length > 0 && (
              <>
                <h3 align="center"> Hedges </h3>
                <table className="table" id="table_hedges">
                  <thead>
                    <tr>
                      <td align="center">SYMBOL</td>
                      <td align="right">NSHARES</td>
                      <td align="right">VALUE</td>
                      <td className="profit_loss" align="right">
                        CHANGE%
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <ReuseDetails
                      details={details.hedges}
                      handleStockMemo={handleStockMemo}
                      tickerType="HEDGE"
                      handleStockStarData={handleStockStarData}
                      starData={starData}
                    />
                  </tbody>
                </table>
              </>
            )}
          </Grid>
        </Grid>
      </div>
      <StockMemo
        open={openStockModel}
        close={() => setStockModel(false)}
        ticker={tickerName}
        tickerType={tickerType}
        dialogTitle="Stock Memo"
        isStar={isStar}
        handleStockStarData={handleStockStarData}
      />
    </>
  );
};
Accountholdings.propTypes = {
  longs: PropTypes.instanceOf(Array).isRequired,
  shorts: PropTypes.instanceOf(Array).isRequired,
  hedges: PropTypes.instanceOf(Array).isRequired,
};

function ReuseDetails({
  details,
  handleStockMemo,
  tickerType,
  handleStockStarData,
  starData,
}) {
  return details.map((result) => (
    <tr key={result.ticker}>
      <td
        align="center"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {starData.includes(result.ticker) ? (
          <StarIcon
            sx={{
              color: "#ffb800",
              fontSize: "1.8rem",
              marginLeft: "-10px",
              marginRight: "6px",
            }}
            onClick={(e) => handleStockStarData(result.ticker)}
          />
        ) : (
          <StarBorderIcon
            sx={{
              fontSize: "1.8rem",
              marginLeft: "-10px",
              marginRight: "6px",
            }}
            onClick={(e) => handleStockStarData(result.ticker)}
          />
        )}
        <Chip
          sx={{
            backgroundColor: "#fed9c6",
            "&:hover": {
              backgroundColor: "#fed9c6",
            },
            borderRadius: "7%",
            width: "65px",
          }}
          label={result.ticker}
          onClick={() =>
            handleStockMemo(
              result.ticker,
              tickerType,
              starData.includes(result.ticker)
            )
          }
        />
      </td>
      <td align="right">{parseFloat(number(result.nshares)).toFixed(3)}</td>
      <td align="right">{numberFormat(result.value)}</td>
      <td
        align="right"
        className={
          result["change%"] >= 0 ? "holding-positive" : "holding-negative"
        }
      >
        {result["change%"]}%
      </td>
    </tr>
  ));
}
ReuseDetails.propTypes = {
  details: PropTypes.instanceOf(Array).isRequired,
};

export default Accountholdings;
