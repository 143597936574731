const getAge = (age) => {
  switch (true) {
    case age >= 18 && age < 30:
      return "20-30 years of age";
    case age >= 30 && age < 40:
      return "30-40 years of age";
    case age >= 40 && age < 50:
      return "40-50 years of age";
    case age >= 50 && age < 60:
      return "50-60 years of age";
    case age >= 60 && age <= 70:
      return "60-70 years of age";
    case age > 70:
      return "70+ years of age";
    default:
      return "0 years of age";
  }
};
const getGender = (gender) => {
  switch (gender) {
    case "M":
      return "Male";
    case "F":
      return "Female";
    default:
      return "";
  }
};
const getAnnualIncome = (annualIncome) => {
  switch (true) {
    case annualIncome === "0-25000":
      return "$0-$25k";
    case annualIncome === "25001-50000":
      return "$25-$50k";
    case annualIncome === "50001-100000":
      return "$50-$100k";
    case annualIncome === "100001-200000":
      return "$100-$200k";
    case annualIncome === "200001-300000":
      return "$200-$300k";
    case annualIncome === "300001-500000":
      return "$300k+";
    default:
      return "0";
  }
};
const getNetWorth = (netWorth) => {
  switch (true) {
    case netWorth === "0 - 50000":
      return "$0-$50k";
    case netWorth === "50001-100000":
      return "$50-$100k";
    case netWorth === "100001-200000":
      return "$100-$200k";
    case netWorth === "200001-500000":
      return "$200-$500k";
    case netWorth === "500001-1000000":
      return "$500-$1000k";
    case netWorth === "1000001-5000000":
      return "$1000k+";
    default:
      return "0";
  }
};
const getLiquidAssets = (liquidAssets) => {
  switch (true) {
    case liquidAssets === "0 - 50000":
      return "$0-$50k";
    case liquidAssets === "50001-100000":
      return "$50-$100k";
    case liquidAssets === "100001-200000":
      return "$100-$200k";
    case liquidAssets === "200001-500000":
      return "$200-$500k";
    case liquidAssets === "500001-1000000":
      return "$500-$1000k";
    case liquidAssets === "1000001-5000000":
      return "$1000k+";
    default:
      return "0";
  }
};
const getInvestedAssets = (investedAssets) => {
  switch (true) {
    case investedAssets <= 25:
      return "< 25%";
    case investedAssets <= 50:
      return "25-50%";
    case investedAssets <= 75:
      return "50-75%";
    case investedAssets > 75:
      return "> 75%";
    default:
      return "0";
  }
};
const getInvestmentHorizon = (investmentHorizon) => {
  switch (true) {
    case investmentHorizon === 1:
      return "0-1 yr duration";
    case investmentHorizon === 2:
      return "1-2 yr duration";
    case investmentHorizon === 3:
      return "2-5 yr duration";
    case investmentHorizon === 4:
      return "5-10 yr duration";
    case investmentHorizon === 5:
      return "10+ yr duration";
    default:
      return "0";
  }
};
const getMaritalStatus = (maritalStatus) => {
  switch (maritalStatus) {
    case "single":
      return "Single";
    case "married":
      return "Married";
    case "divorced":
      return "Divorced";
    case "widowed":
      return "Widowed";
    default:
      return "";
  }
};
const getRiskTolerance = (riskTolerance) => {
  switch (riskTolerance) {
    case "HIGH":
      return "High";
    case "MEDIUM":
      return "Medium";
    case "LOW":
      return "Low";
    default:
      return "";
  }
};
const getDependents = (dependents) => {
  if (dependents >= 5) return "5+";
  if (dependents === 0) return "None";
  return dependents;
};

const MapRiskScoreFactors = ({
  age,
  gender,
  maritalStatus,
  dependents,
  annualIncome,
  netWorth,
  liquidAssets,
  investedAssets,
  investmentHorizon,
  riskTolerance,
}) => ({
  age: `${getAge(age)}`,
  gender: `${getGender(gender)}`,
  marital_status: `${getMaritalStatus(maritalStatus)}`,
  dependents: `${getDependents(dependents)}`,
  income: `${getAnnualIncome(annualIncome)}`,
  net_worth: `${getNetWorth(netWorth)}`,
  liquid_assets: `${getLiquidAssets(liquidAssets)}`,
  invested_assets: `${getInvestedAssets(investedAssets)}`,
  investment_horizon: `${getInvestmentHorizon(investmentHorizon)}`,
  risk_tolerance: `${getRiskTolerance(riskTolerance)}`,
});

export default MapRiskScoreFactors;
