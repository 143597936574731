import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  FormControl,
  FormHelperText,
  Grid,
  Box,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";
import StepComponent from "./StepComponent";
import AppBackService from "../services/app-back";
import getCountriesData from "../services/get-countries-data";

const YES = "YES";
const NO = "NO";

const Styles = {
  error: {
    color: "red !important",
    textAlign: "left !important",
  },
};

const ProfileInfo = ({ data, onChange, bacctId }) => {
  const Appback = AppBackService();
  const { control, errors, setValue } = useFormContext();
  const [nonCitizens, setNonCitizens] = useState(false);
  const [conditions, setConditions] = useState(YES);
  const [countryData, setCountryData] = useState([]);
  const handleCitizenship = () => (e) => {
    setConditions("YES");
    if (e.target.value !== "United States" && e.target.value !== "") {
      setNonCitizens(true);
    } else {
      setNonCitizens(false);
    }
  };
  const handleRadioChange = (e) => {
    setConditions(e.target.value);
    onChange(e.target.value);
  };

  useEffect(() => {
    const handleLoad = async () => {
      setCountryData(await getCountriesData(Appback));
      setValue("citizenship", data);
      if (data !== "United States") {
        setNonCitizens(true);
      } else {
        setNonCitizens(false);
      }
    };
    if (data) {
      handleLoad();
    }
  }, [data]);

  return (
    <>
      <Box mt={1} mb={1}>
        {" "}
        <FormControl variant="outlined">
          <StepComponent
            inputType="Input"
            controllerProp={{
              control,
              name: "accoutId",
              id: "accoutId",
              defaultValue: bacctId,
            }}
            componentProp={{
              variant: "outlined",
              label: "Account ID",
              inputProps: {
                readOnly: true,
              },
            }}
          />
        </FormControl>{" "}
      </Box>
      <Box mt={1} mb={1}>
        {" "}
        <FormControl variant="outlined">
          <StepComponent
            inputType="Input"
            controllerProp={{
              control,
              name: "accountType",
              id: "accountType",
              defaultValue: "",
            }}
            componentProp={{
              variant: "outlined",
              label: "Account type",
              inputProps: {
                readOnly: true,
              },
            }}
          />
        </FormControl>{" "}
      </Box>
      <Box mt={1} mb={1}>
        <FormControl variant="outlined">
          <StepComponent
            inputType="Select"
            inputLabel="citizenship"
            options={countryData}
            controllerProp={{
              control,
              name: `citizenship`,
              defaultValue: "",
              rules: {
                required: "This Field is Required",
              },
            }}
            componentProp={{
              onChange: handleCitizenship(),
              inputProps: {
                disabled: true,
              },
            }}
          />
          <FormHelperText sx={{ ...Styles.error }}>
            {errors?.citizenship?.message}
          </FormHelperText>
        </FormControl>
      </Box>
      {nonCitizens && (
        <Box mt={1} mb={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} container direction="row">
            <Grid
              item
              xs={7}
              sm={7}
              md={7}
              lg={7}
              container
              direction="row"
              className="non-citizen"
            >
              Are you a permanent US Resident?
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} container direction="row">
              <FormControl component="fieldset" sx={{ ...Styles.error }}>
                <RadioGroup
                  row
                  aria-label="conditions"
                  name="conditions"
                  value={conditions}
                  onChange={(e) => handleRadioChange(e)}
                  style={{ flexFlow: "row" }}
                >
                  <FormControlLabel
                    value={YES}
                    disabled
                    control={<Radio color="primary" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={NO}
                    disabled
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default ProfileInfo;
