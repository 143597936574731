import React, { useEffect } from "react";
import PropTypes from "prop-types";
import c3 from "c3";
import $ from "jquery";

export const DoughnutChart = ({ data, colors, id, ...rest }) => {
  const handleTitleChange = ({ name = "", value = "" }) => {
    $(`#${id} text.c3-chart-arcs-title`).html(
      `<tspan>${name}</tspan>
      <tspan x="0.2em" y="1.2em">${value ? "$ " + value : ""}</tspan>`
    );
  };
  useEffect(() => {
    c3.generate({
      bindto: `#${id}`,
      data: {
        columns: data.columns,
        colors,
        type: "donut",
        onmouseover: (d) => {
          handleTitleChange(d);
        },
        onmouseout: () => {
          handleTitleChange({});
        },
      },
      donut: {
        title: "",
      },
      tooltip: {
        show: false,
      },
      transition: {
        duration: 0,
      },
      ...rest,
    });
  }, [data]);
  return <div id={id} />;
};
DoughnutChart.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  colors: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default DoughnutChart;
