import React from "react";
import { Container, Grid } from "@mui/material";

export default function ZeroOutWithdrawStatus() {
  return (
    <div className="transfer-processing">
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={10} sm={8} md={8} lg={6} className="block-white">
            <br />
            <h2>Sad to see you go!</h2>
            <p>
              We thank you for your business! Please check back in 2-3 days to
              confirm fund withdrawal
            </p>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
