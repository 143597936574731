import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import StepTitle from "../components/StepTitle";
import StepData from "../services/step-data";
import StepButtons from "../components/StepButtons";
import { getMaxSteps } from ".";
import Disclosures from "../components/Disclosures";

const CURRENT_STEP = 8;
const NO = "NO";
const theme = createTheme();
const useStyles = makeStyles(() => ({
  form: {
    marginTop: theme.spacing(9),
  },
  error: {
    color: "red !important",
    textAlign: "left !important",
  },
  listItem: {
    lineHeight: "1.2 !important",
    marginBottom: theme.spacing(1),
  },
  radioControl: {
    alignItems: "center",
  },
  labelText: {
    lineHeight: "1.2 !important",
  },
}));

const Step8 = () => {
  const childRef = useRef();
  const classes = useStyles();
  const navigate = useNavigate();
  const [userInfoData, setUserInfoData] = useState(null);
  const [values, setValues] = useState({
    conditions: NO,
    disclosures: {},
    accountType: "",
    maxStep: 0,
  });

  useEffect(() => {
    const handleGetDetails = async () => {
      const result = await StepData.get();
      if (result.data && result.data.UserInformation_by_pk) {
        const res = result.data.UserInformation_by_pk;
        setUserInfoData(res);
        setValues({
          accountType: res.account_type,
          maxStep: getMaxSteps(res.account_type),
        });
      }
    };

    handleGetDetails();
  }, []);

  const handleUpdateDetails = async (
    data,
    stepRoute = `step/${CURRENT_STEP + 1}`,
    activeStep = CURRENT_STEP + 1
  ) => {
    if (values.accountType === "individual") {
      await StepData.update("applicants: $applicants", {
        applicants: data,
      });
      navigate(`/clientagreement`);
    } else {
      await StepData.update(
        `active_step: ${activeStep}, applicants: $applicants`,
        {
          applicants: data,
        }
      );
      navigate(`/${stepRoute}`);
    }
  };

  const onBackSubmit = (_data) => {
    navigate(`/step/${CURRENT_STEP - 1}`);
  };
  const onBack = () => {
    childRef.current.onBack();
  };

  return (
    <div className="step1">
      <br />
      <Container className={classes.form} style={{ marginTop: "0px" }}>
        <StepTitle
          currentStep={CURRENT_STEP}
          titleText="Disclosures"
          maxStep={values.maxStep}
        />
        <Disclosures
          ref={childRef}
          userInfoData={userInfoData}
          onSubmit={handleUpdateDetails}
          onBackSubmit={onBackSubmit}
          StepButtons={<StepButtons onBack={onBack} />}
        />
      </Container>
    </div>
  );
};

export default Step8;
