import React, { useEffect, useState } from "react";
import { Typography, Box } from "@mui/material";
import PropTypes from "prop-types";

const StepTitle = ({ titleText, currentStep, maxStep }) => {
  const [maxStepValue, setMaxStepValue] = useState(8);
  useEffect(() => {
    if (maxStep !== 0) setMaxStepValue(maxStep);
  }, [maxStep]);
  return (
    <Typography component="div">
      <br />
      <Box
        fontWeight="fontWeightBold"
        fontSize="h6.fontSize"
        pb={1}
        id="step_title"
        style={{ height: "30px" }}
      >
        {maxStepValue !== 0 ? `Step ${currentStep} of ${maxStepValue}` : " "}
      </Box>
      <Box fontSize="h7.fontSize" pb={1} sx={{ letterSpacing: "0rem" }}>
        {titleText}
      </Box>
    </Typography>
  );
};
StepTitle.propTypes = {
  titleText: PropTypes.string.isRequired,
  currentStep: PropTypes.string.isRequired,
  maxStep: PropTypes.string.isRequired,
};
export default StepTitle;
