import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Moment from "moment";
import { Button, Container, TextField, Tooltip, Modal } from "@mui/material";
import { Formik, Form } from "formik";
import * as yup from "yup";
import TrustedDevice from "../services/trusted-device";

const DEVICE_NAME_TEXT = "device_name";

const useStyles = makeStyles({
  form: {
    width: "100%",
  },
});

const TrustedDevices = ({ userData }) => {
  const classes = useStyles();
  const [deviceList, setDeviceList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [deviceName, setDeviceName] = useState("");
  const [deviceKey, setDeviceKey] = useState("");

  const getDevicesList = (user) => {
    user.listDevices(null, null, {
      onSuccess: async (result) => {
        const devices = [...result.Devices];
        const deviceListData = await Promise.all(
          devices.map(async (ele) => {
            const [deviceObj] = ele.DeviceAttributes.filter((data) => {
              if (data.Name === DEVICE_NAME_TEXT) {
                return data;
              }
            });
            ele.device_name = deviceObj.Value;
            const deviceRes = await TrustedDevice.getDevice(ele.DeviceKey);
            const deviceData = await deviceRes;
            return {
              ...ele,
              device_name:
                deviceData.data.TrustedDevices[0]?.device_name ||
                ele.device_name,
            };
          })
        );
        setDeviceList([...deviceListData]);
      },
      onFailure: (err) => {
        console.log(err);
      },
    });
  };

  const handleSave = async (values) => {
    try {
      const { data } = await TrustedDevice.getDevice(deviceKey);
      if (!data.TrustedDevices.length) {
        await TrustedDevice.insertDevice(deviceKey, values.device_name);
      } else {
        await TrustedDevice.updateDevice(deviceKey, values.device_name);
      }
      deviceList.map((dev) => {
        if (dev.DeviceKey === deviceKey) {
          dev.device_name = values.device_name;
        }
        return dev;
      });
    } catch (error) {
      console.log(error);
    } finally {
      setOpenModal(false);
    }
  };

  const deviceNameShort = (res) => {
    if (res !== undefined) {
      if (res.length > 11) {
        return `***${res.slice(-12)}`;
      }
      return res;
    }
  };
  const handleEdit = (key, name) => {
    setOpenModal(true);
    setDeviceName(name);
    setDeviceKey(key);
  };

  const handleRemove = (key) => {
    userData.forgetSpecificDevice(key, {
      onSuccess: async () => {
        await TrustedDevice.deleteDevice(key);
        getDevicesList(userData);
      },
      onFailure: (err) => {
        console.log(err);
      },
    });
  };
  const handleRemoveAll = () => {
    Promise.all(
      deviceList.map((ele) => {
        handleRemove(ele.DeviceKey);
      })
    )
      .then((data) => {
        console.log("remove all: ", data);
      })
      .catch((error) => {
        console.log("unable to remove all devices: ", error);
      });
  };
  useEffect(() => {
    if (userData) {
      getDevicesList(userData);
    }
  }, [userData]);

  return (
    <>
      <h3>Trusted Devices</h3>
      <Modal
        open={openModal}
        aria-labelledby="change-device-name"
        aria-describedby="Change your device name here."
      >
        <div
          style={{
            position: "relative",
            width: "46%",
            backgroundColor: "white",
            padding: "10px",
            top: "40%",
            left: "27%",
            borderStyle: "none",
            outline: "0",
          }}
        >
          <Container>
            <h2 id="simple-modal-title">Rename Device Name</h2>
            <Formik
              initialValues={{ device_name: deviceName || "" }}
              validationSchema={yup.object().shape({
                device_name: yup
                  .string("Enter the device name")
                  .required("Device name is required"),
              })}
              onSubmit={(values) => {
                handleSave(values);
              }}
            >
              {({ values, errors, touched, handleChange }) => (
                <Form className={`${classes.form}`}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="device_name"
                    name="device_name"
                    label="Device name"
                    value={values.device_name}
                    onChange={handleChange}
                    error={touched.device_name && Boolean(errors.device_name)}
                    helperText={touched.device_name && errors.device_name}
                  />
                  <Button
                    variant="contained"
                    onClick={() => setOpenModal(false)}
                    style={{ width: "49%", marginRight: "2%" }}
                    id="change_email_send_button"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    style={{ width: "49%" }}
                    id="submit"
                  >
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>
          </Container>
        </div>
      </Modal>
      <table
        className="table trustedDevicedTableFontSize"
        id="trust_device_tbl"
      >
        <thead>
          <tr>
            <td>Device&nbsp;Name</td>
            <td>Created</td>
            <td>Modified</td>
            <td style={{ width: "170px", padding: "2px" }}>
              <Button
                style={{
                  backgroundColor: "#fff",
                  textDecoration: "underline",
                  color: !deviceList.length ? "grey" : "blue",
                  textTransform: "none",
                }}
                onClick={() => handleRemoveAll()}
                disabled={!deviceList.length}
                id="remove_all_button"
              >
                Remove all
              </Button>
            </td>
          </tr>
        </thead>
        <tbody>
          {deviceList.map((result, i) => (
            <tr key={i}>
              <td>
                <Tooltip title={result.device_name}>
                  <div>{deviceNameShort(result.device_name)}</div>
                </Tooltip>
              </td>
              <td align="center">
                {Moment(result.DeviceCreateDate * 1000).format(
                  "MM/DD/YYYY HH:mm A"
                )}
              </td>
              <td align="center">
                {Moment(result.DeviceLastModifiedDate * 1000).format(
                  "MM/DD/YYYY HH:mm A"
                )}
              </td>
              <td style={{ padding: "2px" }}>
                <Button
                  style={{
                    backgroundColor: "#fff",
                    textDecoration: "underline",
                    color: "blue",
                    textTransform: "none",
                  }}
                  onClick={() =>
                    handleEdit(result.DeviceKey, result.device_name)
                  }
                  id="rename_button"
                >
                  Rename
                </Button>
                |{""}
                <Button
                  style={{
                    backgroundColor: "#fff",
                    textDecoration: "underline",
                    color: "blue",
                    textTransform: "none",
                  }}
                  onClick={() => handleRemove(result.DeviceKey)}
                  id="remove_button"
                >
                  Remove
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
export default TrustedDevices;
