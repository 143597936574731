import React, { useEffect, useState, useContext } from "react";
import { Grid, Container, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { RootContext } from "../context/root-provider";

const AddfundsStatus = (props) => {
  const { accountStatusName } = useContext(RootContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [amount, setAmount] = useState(0);
  const handleNavigation = () => {
    if (!props.isCashmanager) navigate("/deposit_ext");
    else props.handleStatus();
  };
  useEffect(() => {
    if (location && location.state && location.state.amount) {
      setAmount(location.state.amount);
    } else if (props.amount) {
      setAmount(props.amount);
    }
  }, []);

  return (
    <div className="transfer-processing">
      <Container>
        <Grid container spacing={6}>
          {/* <Grid item xs={1} sm={2} md={2} lg={3}> </Grid> */}
          <Grid
            item
            xs={props.isCashmanager ? 12 : 10}
            sm={props.isCashmanager ? 12 : 8}
            md={props.isCashmanager ? 12 : 8}
            lg={props.isCashmanager ? 12 : 6}
            className="block-white"
            style={{ marginTop: props.isCashmanager ? "-35px" : "" }}
          >
            <br />
            <h3 id="transfer_process_title">Your transfer is in process</h3>
            <div style={{ marginBottom: "30px" }}>
              <div>
                <br />
                <br />
                <img src="tick-mark.png" alt="tick-mark" width="180px" />
                <br />
                <br />
              </div>

              <p>
                A transfer of ${amount} from your bank account to your 99rises
                account has been initiated.
                {!["account_active", "account_paused"].includes(
                  accountStatusName
                ) && (
                  <>
                    Once the funds are cleared, we will invest your funds in a
                    first block on our next rebalance day.
                  </>
                )}
                {["account_active", "account_paused"].includes(
                  accountStatusName
                ) && (
                  <>
                    Once the funds are cleared, you will be able to add funds to
                    your blocks.
                  </>
                )}
              </p>
              <p>
                <em>
                  We are in process of making adding the block selector, so that
                  you can do this within the app.
                </em>
              </p>
              <Button
                variant="contained"
                className="confirm"
                id="Ok_button"
                style={{ width: "75%" }}
                onClick={handleNavigation}
              >
                OK
              </Button>
            </div>
          </Grid>
          {/* <Grid item xs={1} sm={2} md={2} lg={3}> </Grid> */}
        </Grid>
      </Container>
    </div>
  );
};

export default AddfundsStatus;
