import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { useIdleTimer } from "react-idle-timer";

export const SESSION_TIME_OUT = 60;
const COUNTER = 59;

const AutoLogout = ({ logout }) => {
  const [dailogOpen, setDailogOpen] = useState(false);
  const [count, setCount] = useState(COUNTER);
  const debounce = 1000 * 60;
  const onIdle = () => {
    setDailogOpen(false);
    logout();
  };

  const onPrompt = () => {
    setDailogOpen(true);
  };

  const onAction = () => {
    localStorage.setItem("activeTime", +new Date());
  };

  const timeout = SESSION_TIME_OUT * 30 * 1000;
  const promptTimeout = (COUNTER + 1) * 1000;
  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    timeout,
    promptTimeout,
    onPrompt,
    crossTab: true,
    debounce,
    onAction,
  });
  useEffect(() => {
    if (dailogOpen) {
      const interval = setInterval(() => {
        setCount(Math.floor(getRemainingTime() / 1000));
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [dailogOpen]);

  const handleDailogClose = () => {
    setDailogOpen(false);
    setCount(COUNTER);
    activate();
  };

  return (
    <>
      <Dialog open={dailogOpen} aria-describedby="alert-dialog-description">
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You will be automatically logged out in {count} seconds.
            <br /> <br />
          </DialogContentText>
          <DialogContentText
            id="alert-dialog-description"
            style={{ textAlign: "center" }}
          >
            <Button
              style={{ backgroundColor: "#1685d3", color: "white" }}
              onClick={handleDailogClose}
              id="stay_logged_button"
            >
              Stay Logged In
            </Button>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AutoLogout;
