import React, { useEffect } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Grid,
  CircularProgress,
  Modal,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import Avatar from "@mui/material/Avatar";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import { numberFormat } from "../utils/numbers";
import "./stock-memo.scss";
import AppBackService from "../services/app-back";

const StockMemo = ({
  open,
  close,
  ticker,
  tickerType,
  isStar,
  handleStockStarData,
}) => {
  const Appback = AppBackService();
  const [openmodel, setOpenmodel] = React.useState(false);
  const [stockMemoDetails, setStockMemo] = React.useState({});
  const [load, setLoad] = React.useState(false);
  const [star, setStar] = React.useState(false);
  const handleClose = () => {
    close();
    setOpenmodel(false);
  };
  const handleStockStar = () => {
    setStar(!star);
    handleStockStarData(ticker);
  };
  useEffect(() => {
    const getStockMemo = async () => {
      const stockMemo = await Appback.getStockMemo(ticker);
      setStockMemo(stockMemo);
      setLoad(false);
    };
    if (open === true) {
      setLoad(true);
      setStar(isStar);
      getStockMemo();
    }
    setOpenmodel(open);
  }, [open]);
  return (
    <div className="stock-memo-dialog">
      {load === true ? (
        <div className="progressbar">
          <Modal
            open
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div>
              <div id="circle-div">
                <CircularProgress className="circle" />
              </div>
            </div>
          </Modal>
        </div>
      ) : (
        <Dialog open={openmodel} onClose={handleClose} scroll="body">
          <DialogContent sx={{ padding: "0px !important" }}>
            <Card sx={{ m: 2 }} className="stock-memo">
              <CardHeader
                sx={{ background: "#000", color: "#fff" }}
                avatar={
                  <Avatar aria-label="recipe" sx={{ background: "#fff" }}>
                    {star ? (
                      <StarIcon
                        sx={{
                          color: "#ffb800",
                          fontSize: "1.8rem",
                        }}
                        onClick={(e) => handleStockStar()}
                      />
                    ) : (
                      <StarBorderIcon
                        sx={{
                          color: "#000",
                          fontSize: "1.8rem",
                        }}
                        onClick={(e) => handleStockStar()}
                      />
                    )}
                  </Avatar>
                }
                title={`${stockMemoDetails.companyName} (${stockMemoDetails.ticker})`}
                subheader={stockMemoDetails.oneliner}
              />
              <CardContent
                className={
                  stockMemoDetails.rating &&
                  stockMemoDetails.rating.includes("buy")
                    ? "buy"
                    : "sell"
                }
              >
                <Grid container>
                  <Grid item xs>
                    <img
                      src={`${stockMemoDetails.rating}.png`}
                      alt=""
                      style={{ width: "250px" }}
                    />
                  </Grid>
                  <Grid item xs sx={{ paddingTop: "25px" }}>
                    <Typography
                      variant="body2"
                      color="#fff"
                      component="p"
                      sx={{
                        marginTop: "5px",
                        flexDirection: "row",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {stockMemoDetails["%change"] &&
                        stockMemoDetails["%change"] < 0 && (
                          <SouthIcon fontSize="small" />
                        )}
                      {stockMemoDetails["%change"] &&
                        stockMemoDetails["%change"] > 0 && (
                          <NorthIcon fontSize="small" />
                        )}
                      <span> {stockMemoDetails["%change"]}%</span>
                    </Typography>
                    <div className="title">STOCK PRICE</div>
                    <div className="title_value">
                      {numberFormat(stockMemoDetails.price)}
                    </div>
                    <div className="title">MARKET CAPITALIZATION</div>
                    <div className="title_value">
                      {numberFormat(stockMemoDetails.marketcap)}
                    </div>
                    <div className="title">INDUSTRY</div>
                    <div className="title_value">
                      {stockMemoDetails.industry}
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
              <CardContent sx={{ background: "#808080", color: "#fff" }}>
                <Typography variant="body2" color="#fff" component="p">
                  {stockMemoDetails.desc}
                </Typography>
              </CardContent>
              <CardContent>
                <Typography
                  component="h2"
                  sx={{
                    fontWeight: "bold",
                    marginBottom: "5px",
                    marginTop: "15px",
                  }}
                >
                  WHY WE ARE{" "}
                  {tickerType === "HEDGE" ? "HEDGING WITH" : tickerType}{" "}
                  {stockMemoDetails.ticker}
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  sx={{ marginBottom: "20px" }}
                >
                  {stockMemoDetails &&
                    stockMemoDetails.ourWhy &&
                    stockMemoDetails.ourWhy.map((item) => (
                      <p key={item}>{item} </p>
                    ))}
                </Typography>
                <Typography
                  component="h2"
                  sx={{
                    fontWeight: "bold",
                    marginBottom: "5px",
                    marginTop: "15px",
                  }}
                >
                  WHAT COULD CHANGE OUR STANCE?
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  sx={{ marginBottom: "20px" }}
                >
                  {stockMemoDetails &&
                    stockMemoDetails.ourWatchPoints &&
                    stockMemoDetails.ourWatchPoints.map((item) => (
                      <p key={item}>{item} </p>
                    ))}
                </Typography>
                <Typography
                  component="h2"
                  sx={{
                    fontWeight: "bold",
                    marginBottom: "5px",
                    marginTop: "15px",
                  }}
                >
                  LAST WORD
                </Typography>
                <Typography variant="body2" component="p">
                  {stockMemoDetails &&
                    stockMemoDetails.lastWord &&
                    stockMemoDetails.lastWord.map((item) => (
                      <p key={item}>{item} </p>
                    ))}
                </Typography>
              </CardContent>
            </Card>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};
export default StockMemo;
