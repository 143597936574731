import graphql from "./graphql";

const TrustedDevice = {
  insertDevice: async (device_key, device_name) =>
    graphql.query(`mutation MyMutation {
        insert_TrustedDevices(objects: {device_key: "${device_key}", device_name: "${device_name}", user_id: "%(cognitoUserName)s"}) {
          returning {
            device_key
            device_name
            user_id
          }
        }
    }`),

  getDevice: async (dkey) =>
    graphql.query(
      `query MyQuery {
      TrustedDevices(where: {device_key: {_eq: "${dkey}"}}) {
        device_key
        device_name
      }
    }`
    ),

  updateDevice: async (device_key, device_name) =>
    graphql.query(
      `mutation MyMutation {
        update_TrustedDevices_by_pk(pk_columns: {device_key: "${device_key}"}, _set: {device_name: "${device_name}"}) {
          device_key
          device_name
        }
      }`
    ),

  deleteDevice: async (device_key) =>
    graphql.query(
      `mutation MyMutation {
      delete_TrustedDevices_by_pk(device_key: "${device_key}") {
        device_key
      }
    }`
    ),
};

export default TrustedDevice;
