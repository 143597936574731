import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { FormControl, FormHelperText, Typography, Box } from "@mui/material";
import StepComponent from "./StepComponent";

const Styles = {
  error: {
    color: "red !important",
    textAlign: "left !important",
  },
};

export const EmploymentDetails = ({ data, name, onChange, index }) => {
  const { control, errors, setValue } = useFormContext();
  const employmentOptions = [
    { value: "self_employed", key: "Self employed" },
    { value: "employed", key: "Employed" },
    { value: "unemployed", key: "Unemployed" },
  ];
  useEffect(() => {
    if (data && name) {
      setValue(`${name}.employmentStatus`, data?.status);
    }
  }, [data, name]);
  return (
    <>
      <Typography variant="subtitle1">Employment</Typography>
      <Box mt={2} mb={1}>
        <FormControl variant="outlined">
          <StepComponent
            inputType="Select"
            inputLabel="Employment status"
            options={employmentOptions}
            componentProp={{
              onChange: ({ target }) => {
                onChange(target.value);
              },
            }}
            controllerProp={{
              control,
              name: `${name}.employmentStatus`,
              defaultValue: "",
              rules: {
                required: "This Field is Required",
              },
            }}
          />
          <FormHelperText sx={{ ...Styles.error }}>
            {errors?.applicants && errors?.applicants[index]
              ? errors?.applicants[index]?.employment?.employmentStatus?.message
              : null}
          </FormHelperText>
        </FormControl>
      </Box>
    </>
  );
};

export const Employer = ({ data, name, index }) => {
  const { control, errors, setValue } = useFormContext();
  const { employer } = data;

  useEffect(() => {
    if (data && employer) {
      setValue(`${name}.employer`, employer);
    }
  }, [data]);
  return (
    <>
      <FormControl variant="outlined">
        <StepComponent
          inputType="Input"
          controllerProp={{
            control,
            name: `${name}.employer`,
            id: `${name}.employer`,
            defaultValue: "",
            rules: {
              required: "Employer name is Required",
            },
          }}
          componentProp={{
            variant: "outlined",
            label: "Employer",
          }}
        />
        <FormHelperText sx={{ ...Styles.error }}>
          {errors?.applicants && errors?.applicants[index]
            ? errors?.applicants[index]?.employment?.employer?.message
            : null}
        </FormHelperText>
      </FormControl>
    </>
  );
};
