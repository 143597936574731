import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const Modal = ({ open, setOpen, msg }) => (
  <>
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" />
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <p>Something went wrong. Please try again.</p>
          <p>{msg}</p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          id="close_button"
          onClick={() => {
            setOpen(false);
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  </>
);
export default Modal;
