import React, { useState } from "react";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import ApexChart, { Metric } from "./apex-chart";

const ChartBasicTV = ({ performance }) => {
  const [performanceData, setPerformance] = useState(performance);
  const handleTVChange = (data) => {
    setPerformance(data);
  };

  return (
    <div className="account-holdings" style={{ overflow: "hidden" }}>
      <Grid container spacing={7}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Metric handleBindData={handleTVChange} performance={performance} />
          <ApexChart
            performance={performanceData}
            activeMetric="tmv"
            chartType="dollor"
            chartTitle="Your Market Value"
          />
        </Grid>
      </Grid>
    </div>
  );
};
ChartBasicTV.propTypes = {
  performance: PropTypes.instanceOf(Array).isRequired,
};
export default ChartBasicTV;
