import React from "react";
import { Container, Grid } from "@mui/material";

export default function WithdrawStatus() {
  return (
    <div className="transfer-processing">
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={10} sm={8} md={8} lg={6} className="block-white">
            <br />
            <h3>Thank you</h3>
            <div>
              <div>
                <br />
                <br />
                <img src="tick-mark.png" alt="tick-mark" width="180px" />
                <br />
                <br />
              </div>
              <p style={{ padding: "0px 17px 0 17px" }}>
                <br />
                Please check back in 2-3 days to confirm fund withdrawal{" "}
              </p>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
