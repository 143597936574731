import React from "react";
import {
  FormLabel,
  OutlinedInput,
  InputLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  FormHelperText,
  Container,
  Grid,
  Button,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import publicIp from "public-ip";
import Service from "../services/hservice";
import Toastmessage from "../components/Toastmessage";
import AppBackService from "../services/app-back";

export default function Linkedaccounts(props) {
  const [values, setValues] = React.useState({
    linked_accounts: [],
    account_list: true,
    add_bank_account: false,
    thank_you: false,
    routing_number: "",
    owner_name: "",
    nickname: "",
    account_number: "",
    verifiAccountDetails: false,
    accountNumber: "",
    depositOne: "",
    depositTwo: "",
    depositOneEmptyError: false,
    depositTwoEmptyError: false,
    remove_account: false,
    successfully_remove: false,
    // successfully_verified: false,
    account_type: "",
    accountID: "",
    letters: "cancel.svg",
    account_type_required_error: "",
    depositOneText: "",
    depositTwoText: "",
    toast_message: "",
  });
  const Appback = AppBackService();

  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
      routing_number_empty_error: false,
      routing_number_required_error: "",
      owner_name_empty_error: false,
      owner_name_required_error: "",
      nickname_empty_error: false,
      nickname_required_error: "",
      account_number_empty_error: false,
      account_number_required_error: "",
      depositOneEmptyError: false,
      depositTwoEmptyError: false,
    });
    values.account_type_required_error = "";
    if (event.target.value.length === 9) {
      setValues({
        ...values,
        [prop]: event.target.value,
        letters: "checkmark.svg",
        routing_number_empty_error: false,
        routing_number_required_error: "",
      });
    } else {
      setValues({
        ...values,
        [prop]: event.target.value,
        letters: "cancel.svg",
      });
    }
  };
  const handleSubmit = () => {
    if (!values.account_type) {
      setValues({
        ...values,
        account_type_required_error: "Account type is required",
      });
    } else if (values.owner_name === "") {
      setValues({
        ...values,
        owner_name_empty_error: true,
        owner_name_required_error: "Owner name is required",
      });
    } else if (values.nickname === "") {
      setValues({
        ...values,
        owner_name_empty_error: false,
        owner_name_required_error: "",
        nickname_empty_error: true,
        nickname_required_error: "Name is required",
      });
    } else if (!values.routing_number) {
      setValues({
        ...values,
        owner_name_empty_error: false,
        owner_name_required_error: "",
        nickname_empty_error: false,
        nickname_required_error: "",
        routing_number_empty_error: true,
        routing_number_required_error: "Field is required",
      });
    } else if (!(values.routing_number.length === 9)) {
      setValues({
        ...values,
        routing_number_empty_error: true,
        routing_number_required_error: "Routing Number must be 9 digits",
      });
    } else if (!values.account_number) {
      setValues({
        ...values,
        account_number_empty_error: true,
        account_number_required_error: "Field is required",
      });
    } else {
      setValues({ ...values, thank_you: true, add_bank_account: false });
      addaccounts();
    }
  };
  const handleClick = () => {
    setValues({
      ...values,
      add_bank_account: true,
      account_list: false,
      routing_number: "",
      nickname: "",
      owner_name: "",
      account_number: "",
    });
  };
  const getaccounts = async () => {
    Service.getAccount().then((result) => {
      Service.getLinkedAccounts(result.data.Account[0].id).then((res) => {
        setValues({
          ...values,
          linked_accounts: res.data.ExtAcct,
        });
      });
    });
  };

  const addaccounts = async () => {
    const ipAA = await publicIp.v4();
    Service.getAccount().then((result) => {
      Service.insertLinkedAccounts(
        `account_num: "${values.account_number}",
         routing_num: "${values.routing_number}",
         status: "Verify",
         account_id: "${result.data.Account[0].id}",
         owner_name:"${values.owner_name}",
         account_type:"${values.account_type}",
         ip_address:"${ipAA}",
         nickname:"${values.nickname}"`
      ).then((res) => {});
    });
  };

  React.useState(() => {
    getaccounts();
  });
  const handleSet = () => {
    values.account_list = true;
    values.add_bank_account = false;
    values.thank_you = false;
    values.successfully_remove = false;
    getaccounts();
  };
  const verifyAccount = (e) => {
    values.linked_accounts
      .filter((x) => x.id === e)
      .map((x) => {
        if (x.status === "Verify") {
          const number =
            x.account_num.replace(/.(?=.{4})/g, "X") + x.routing_num;
          setValues({
            ...values,
            account_list: false,
            add_bank_account: false,
            thank_you: false,
            verifiAccountDetails: true,
            accountNumber: number,
            accountID: x.account_num,
            id: x.id,
          });
        }
        return x;
      });
  };
  const removeaccount = (e) => {
    values.linked_accounts
      .filter((x) => x.id === e)
      .map((x) => {
        setValues({
          ...values,
          account_list: false,
          add_bank_account: false,
          thank_you: false,
          remove_account: true,
          accountID: x.account_num,
          id: x.id,
        });
        return x;
      });
  };
  const verifyLater = () => {
    setValues({
      ...values,
      account_list: true,
      add_bank_account: false,
      thank_you: false,
      verifiAccountDetails: false,
      depositOne: "",
      depositTwo: "",
    });
  };
  const verify = (e) => {
    if (!values.depositOne) {
      setValues({ ...values, depositOneEmptyError: true });
    } else if (values.depositOne >= 1) {
      setValues({
        ...values,
        depositOneEmptyError: true,
        depositOneText:
          "Deposit amount should be under $1 and be entered like 0.12",
      });
    } else if (!values.depositTwo) {
      setValues({ ...values, depositTwoEmptyError: true });
    } else if (values.depositTwo >= 1) {
      setValues({
        ...values,
        depositTwoEmptyError: true,
        depositTwoText:
          "Deposit amount should be under $1 and be entered like 0.12",
      });
    } else {
      Appback.verifyDeposits(
        values.id,
        values.depositOne,
        values.depositTwo
      ).then((res) => {
        if (res.result !== "Failed") {
          Service.updateLinkedAccounts(
            `{id: "${values.id}"}`,
            `_set: {status: "Linked"}`
          ).then((res) => {
            const linked_accounts = values.linked_accounts;
            linked_accounts.map((item, index) => {
              if (
                item.account_num ===
                  res.data.update_ExtAcct_by_pk.account_num &&
                item.routing_num === res.data.update_ExtAcct_by_pk.routing_num
              ) {
                item.status = res.data.update_ExtAcct_by_pk.status;
              }
              return index;
            });
            setValues({
              ...values,
              account_list: false,
              linked_accounts: linked_accounts,
              verifiAccountDetails: false,
              toast_message: "Successfully linked your account",
            });
          });
        } else {
          setValues({
            ...values,
            account_list: false,
            toast_message:
              "The deposition amounts you entered do not match what Tradier Brokerage submitted to your Bank account.",
          });
        }
      });
    }
  };
  const deleteaccount = () => {
    Service.updateLinkedAccounts(
      `{id: "${values.id}"}`,
      '_set: {status: "Removed"}'
    ).then((res) => {
      getaccounts();
      values.remove_account = false;
      values.account_list = false;
      values.add_bank_account = false;
      values.thank_you = false;
      values.successfully_remove = true;
    });
  };
  const cancel_deleteaccount = () => {
    setValues({
      ...values,
      account_list: true,
      add_bank_account: false,
      thank_you: false,
      remove_account: false,
      successfully_remove: false,
    });
  };

  const toastMessageupdate = () => {
    setValues({
      ...values,
      toast_message: "",
      account_list: true,
      depositOne: "",
      depositTwo: "",
    });
  };

  return (
    <div className="link-accounts LinkedPageContentHeight">
      {values.account_list ? (
        <Container className="linked-accounts">
          <Grid container spacing={6}>
            <Grid item xs={10} sm={10} md={8} lg={6} className="block-white">
              <br />
              <h3>Bank accounts for transfer</h3>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th align="left">Nick Name</th>
                      <th align="left">Account Type</th>
                      <th align="left">Account</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {values.linked_accounts.map((result, j) => (
                      <tr key={j}>
                        <td align="left">{result.nickname}</td>
                        <td align="left">{result.account_type}</td>
                        <td align="left">
                          {result.account_num.replace(/.(?=.{4})/g, "X")}
                        </td>
                        <td className="nav-profile-name">
                          <Button
                            id={result.id}
                            onClick={() => verifyAccount(result.id)}
                            className={
                              result.status === "Verify" ? "positive" : "no"
                            }
                            style={{
                              fontWeight: "100",
                              marginTop: "-5px",
                              paddingTop: "5px",
                            }}
                          >
                            {result.status}
                          </Button>
                        </td>
                        <td className="nav-profile-name">
                          <Button
                            id={result.id}
                            onClick={() => removeaccount(result.id)}
                            className="positive"
                            style={{
                              fontWeight: "100",
                              marginTop: "-5px",
                              paddingTop: "5px",
                            }}
                          >
                            Remove
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <br />
              <div className="addicon">
                <span style={{ textAlign: "left", paddingLeft: "33px" }}>
                  <AddIcon />
                </span>
                <p onClick={handleClick}>&nbsp;Link a bank account</p>
              </div>
            </Grid>
          </Grid>
        </Container>
      ) : null}
      {values.add_bank_account ? (
        <Container className="add-bank-account">
          <Grid container spacing={6}>
            <Grid item xs={10} sm={10} md={8} lg={6} className="block-white">
              <br />
              <h3>Link new bank account</h3>
              <div>
                <form>
                  <FormControl component="fieldset">
                    <FormLabel component="legend" align="left">
                      Account type
                    </FormLabel>
                    <RadioGroup
                      aria-label="account_type"
                      name="account_type"
                      value={values.account_type}
                      onChange={handleChange("account_type")}
                    >
                      <FormControlLabel
                        value="Checking"
                        control={<Radio color="primary" />}
                        label="Checking"
                      />
                      <FormControlLabel
                        value="Savings"
                        control={<Radio color="primary" />}
                        label="Savings"
                      />
                    </RadioGroup>
                    <p
                      style={{
                        color: "red",
                        textAlign: "left",
                        marginTop: "-10px",
                      }}
                    >
                      {values.account_type_required_error}
                    </p>
                  </FormControl>
                  <FormControl variant="outlined">
                    <InputLabel
                      className="inputlabel"
                      error={values.owner_name_empty_error}
                    >
                      Account Owner
                    </InputLabel>
                    <OutlinedInput
                      className="outlinedinput-label"
                      id="owner_name"
                      type="text"
                      value={values.owner_name}
                      onChange={handleChange("owner_name")}
                      error={values.owner_name_empty_error}
                      labelWidth={140}
                      label="Account Owner"
                      // inputProps={{
                      //   min: "0",
                      //   maxLength: "9",
                      //   max: "999999999",
                      // }}
                      // onInput={(e) => {
                      //   e.target.value = Math.max(0, parseInt(e.target.value))
                      //     .toString()
                      //     .slice(0, 9);
                      // }}
                    />
                    <img
                      src="error.png"
                      alt="check"
                      width="18px"
                      hidden={!values.owner_name_empty_error}
                      style={{ marginTop: "5px" }}
                    />
                    <FormHelperText
                      style={{
                        color: "red",
                        marginTop: "-15px",
                        marginLeft: "20px",
                      }}
                    >
                      {values.owner_name_required_error}
                    </FormHelperText>
                  </FormControl>
                  <br />
                  <br />
                  <FormControl variant="outlined">
                    <InputLabel
                      className="inputlabel"
                      error={values.nickname_empty_error}
                    >
                      Account Nickname
                    </InputLabel>
                    <OutlinedInput
                      className="outlinedinput-label"
                      id="nickname"
                      type="text"
                      value={values.nickname}
                      onChange={handleChange("nickname")}
                      error={values.nickname_empty_error}
                      labelWidth={140}
                      label="Account Nickname"
                      // inputProps={{
                      //   min: "0",
                      //   maxLength: "9",
                      //   max: "999999999",
                      // }}
                      // onInput={(e) => {
                      //   e.target.value = Math.max(0, parseInt(e.target.value))
                      //     .toString()
                      //     .slice(0, 9);
                      // }}
                    />
                    <img
                      src="error.png"
                      alt="check"
                      width="18px"
                      hidden={!values.nickname_empty_error}
                      style={{ marginTop: "5px" }}
                    />
                    <FormHelperText
                      style={{
                        color: "red",
                        marginTop: "-15px",
                        marginLeft: "20px",
                      }}
                    >
                      {values.nickname_required_error}
                    </FormHelperText>
                  </FormControl>
                  <br />
                  <br />
                  <FormControl variant="outlined">
                    <InputLabel
                      className="inputlabel"
                      error={values.routing_number_empty_error}
                    >
                      Routing Number
                    </InputLabel>
                    <OutlinedInput
                      label="Routing Number"
                      className="outlinedinput-label"
                      id="routing_number"
                      type="number"
                      value={values.routing_number}
                      onChange={handleChange("routing_number")}
                      error={values.routing_number_empty_error}
                      labelWidth={140}
                      inputProps={{
                        min: "0",
                        maxLength: "9",
                        max: "999999999",
                      }}
                      onInput={(e) => {
                        e.target.value = e.target.value.toString().substr(0, 9);
                      }}
                    />
                    <img
                      src="error.png"
                      alt="check"
                      width="18px"
                      hidden={!values.routing_number_empty_error}
                      style={{ marginTop: "5px" }}
                    />
                    <FormHelperText
                      style={{
                        color: "red",
                        marginTop: "-15px",
                        marginLeft: "20px",
                      }}
                    >
                      {values.routing_number_required_error}
                    </FormHelperText>
                  </FormControl>
                  <br />
                  <br />

                  <FormControl variant="outlined">
                    <InputLabel
                      className="inputlabel"
                      error={values.account_number_empty_error}
                    >
                      Account Number
                    </InputLabel>
                    <OutlinedInput
                      label="Account Number"
                      className="outlinedinput-label"
                      id="account_number"
                      type="number"
                      value={values.account_number}
                      onChange={handleChange("account_number")}
                      error={values.account_number_empty_error}
                      labelWidth={140}
                    />
                    <img
                      src="error.png"
                      alt="check"
                      width="18px"
                      hidden={!values.account_number_empty_error}
                      style={{ marginTop: "5px" }}
                    />
                    <FormHelperText
                      style={{
                        color: "red",
                        marginTop: "-15px",
                        marginLeft: "20px",
                      }}
                    >
                      {values.account_number_required_error}
                    </FormHelperText>
                  </FormControl>
                  <br />
                  <br />

                  <p style={{ padding: "10px 15px", textAlign: "center" }}>
                    By continuing, you agree to let 99Rises <br />
                    send 2 small deposit amounts(less than $1.00) and
                    <br />
                    retrieve them in 1 withdrawal.
                  </p>
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    id="agree_button"
                  >
                    Agree and Link{" "}
                  </Button>
                </form>
              </div>
              <br />
            </Grid>
          </Grid>
        </Container>
      ) : null}
      {values.thank_you ? (
        <Container className="thank-you">
          <Grid container spacing={6}>
            <Grid item xs={10} sm={10} md={8} lg={6} className="block-white">
              <br />
              <h3>
                Thank you for setting <br />
                up your bank.
              </h3>
              <form>
                <div>
                  <img src="tick-mark.png" alt="tick-mark" width="180px" />
                  <p>
                    You should see two microdeposits in <br />
                    your bank account in 2-3 days.
                  </p>
                  <br />
                  <p>
                    {" "}
                    Then come back to Linked accounts in Settings <br />
                    and enter the two amounts to verify that the <br />
                    bank account is accessible by you.
                  </p>
                </div>
                <br />
                <br />
                <Button
                  className="continue"
                  variant="contained"
                  onClick={handleSet}
                  id="continue_button"
                >
                  Continue
                </Button>
              </form>
            </Grid>
          </Grid>
        </Container>
      ) : null}
      {values.remove_account ? (
        <Container className="remove-account">
          <Grid container spacing={6}>
            <Grid item xs={10} sm={10} md={8} lg={6} className="block-white">
              <br />
              <h3>Remove Bank account</h3>
              <p>Account to be removed</p>
              <div>
                <p>{values.accountNumber.replace(/.(?=.{4})/g, "X")}</p>
                <br />
                <br />
                <br />
                <p>
                  {" "}
                  Are you sure you want to delete <br />
                  this bank account
                </p>
              </div>
              <br />
              <br />
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Button
                    onClick={deleteaccount}
                    variant="contained"
                    style={{
                      width: "80%",
                      height: "50px",
                      backgroundColor: "#5bbaf9",
                      color: "white",
                      margin: "10px",
                    }}
                    id="yes_button"
                  >
                    Yes
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Button
                    onClick={cancel_deleteaccount}
                    variant="contained"
                    style={{
                      width: "80%",
                      height: "50px",
                      backgroundColor: "#5bbaf9",
                      color: "white",
                      margin: "10px",
                    }}
                    id="no_button"
                  >
                    No
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      ) : null}
      {values.successfully_remove ? (
        <Container className="successfully-remove">
          <Grid container spacing={6}>
            <Grid item xs={10} sm={10} md={8} lg={6} className="block-white">
              <br />
              <h3>
                Your bank account
                <br />
                has been deleted
              </h3>
              <form>
                <div>
                  <img src="tick-mark.png" alt="tick-mark" width="180px" />
                  <br />
                  <br />
                  <p>
                    To add another bank account <br />
                    Please click on the link below.
                  </p>
                  <br />
                </div>
                <br />
                <br />
                <Button
                  className="continue"
                  variant="contained"
                  onClick={handleSet}
                  id="continue_button"
                >
                  Continue
                </Button>
              </form>
            </Grid>
          </Grid>
        </Container>
      ) : null}
      {values.verifiAccountDetails ? (
        <Container className="verification">
          <Grid container spacing={6}>
            <Grid item xs={10} sm={10} md={8} lg={6} className="block-white">
              <br />
              <h3>Bank Account Verification</h3>
              <div>
                <p
                  style={{
                    paddingLeft: "15%",
                    paddingRight: "15%",
                    textAlign: "justify",
                    marginBottom: "20px",
                    marginTop: "-20px",
                  }}
                >
                  Please enter the small sub-dollar deposits that were made into
                  your Account {values.accountNumber} by Tradier Brokerage.
                </p>
                <TextField
                  className="textfield-label"
                  id="outlined-basic"
                  type="number"
                  label="Deposit 1"
                  variant="outlined"
                  style={{ width: "70%" }}
                  value={values.depositOne}
                  onChange={handleChange("depositOne")}
                  error={values.depositOneEmptyError}
                  helperText={values.depositOneText}
                />
                <br />
                <br />
                <TextField
                  className="textfield-label"
                  id="outlined-basic"
                  type="number"
                  label="Deposit 2"
                  variant="outlined"
                  style={{ width: "70%" }}
                  value={values.depositTwo}
                  onChange={handleChange("depositTwo")}
                  error={values.depositTwoEmptyError}
                  helperText={values.depositTwoText}
                />
                <br />
              </div>
              <br />
              <Grid
                container
                spacing={1}
                style={{
                  paddingLeft: "15%",
                  paddingRight: "15%",
                  textAlign: "justify",
                  marginBottom: "20px",
                  marginTop: "-20px",
                  justifyContent: "space-between",
                }}
              >
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Button
                    id={values.accountNumber}
                    onClick={verify}
                    variant="contained"
                  >
                    Verify
                  </Button>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Button
                    onClick={verifyLater}
                    variant="contained"
                    id="verify_later_button"
                  >
                    Verify Later
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      ) : null}
      {values.toast_message !== "" ? (
        <Toastmessage
          toast_message={values.toast_message}
          toastMessageupdate={() => toastMessageupdate()}
        />
      ) : null}
    </div>
  );
}
