import React, { useState } from "react";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import ApexChart, { Metric } from "./apex-chart";

const ChartAllTV = ({ performance }) => {
  const [performanceData, setPerformance] = useState(performance);
  const [metric, setMetric] = useState("td");
  const [chartTitle, setChartTitle] = useState("To Date");
  const handleChange = (data, actmetric) => {
    setMetric(actmetric);
    setPerformance(data);
    if (actmetric === "td") setChartTitle("To Date");
    if (actmetric === "ytd") setChartTitle("Year to Date");
  };
  return (
    <div className="account-holdings" style={{ overflow: "hidden" }}>
      <Grid container spacing={7}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Metric handleBindData={handleChange} performance={performance} />
          <ApexChart
            performance={performanceData}
            activeMetric={metric}
            chartType="percentage"
            chartTitle={chartTitle}
          />
        </Grid>
      </Grid>
    </div>
  );
};
ChartAllTV.propTypes = {
  performance: PropTypes.instanceOf(Array).isRequired,
};
export default ChartAllTV;
