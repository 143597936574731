import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import Service from "../services/hservice";
import publicIp from "public-ip";
import {
  FormControl,
  FormHelperText,
  TextField,
  Container,
  Grid,
  CircularProgress,
  Button,
  Typography,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Box,
} from "@mui/material";

const theme = createTheme();
const useStyles = makeStyles(() => ({
  form: {
    marginTop: theme.spacing(9),
  },
  actionButton: {
    width: "100% !important",
    height: "50px !important",
    color: "white !important",
  },
  error: {
    color: "red !important",
    textAlign: "left !important",
  },
  textFieldError: {
    padding: "0 14px 0 0px !important",
    color: "red !important",
  },
}));

const LinkaBankAcc = (props) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { control, handleSubmit, errors } = useForm({});
  const [values, setValues] = useState({
    account_type: "",
    owner_name: "",
    nickname: "",
    routing_number: "",
    account_number: "",
    accountadd: false,
    toast_message: "",
    account_details: {},
    loading: true,
  });
  useEffect(() => {
    Service.getAccount().then((result) => {
      Service.getLinkedAccounts(result.data.Account[0].id).then((res) => {
        if (res.data.ExtAcct.length > 0) {
          navigate("/deposit_ext");
        } else {
          setValues({ ...values, loading: false });
        }
      });
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = async (data) => {
    const ipAA = await publicIp.v4();
    Service.getAccount().then((result) => {
      Service.insertLinkedAccounts(
        `account_num: "${data.account_number}",
         routing_num: "${data.routing_number}",
         status: "Verify",
         account_id: "${result.data.Account[0].id}",
         owner_name:"${data.owner_name}",
         account_type:"${data.account_type}",
         ip_address:"${ipAA}",
         nickname:"${data.nickname}"`
      ).then((res) => {
        const updatedata = {
          accountadd: true,
          toast_message: "",
          account_details: {},
        };
        if (res.errors) {
          updatedata.toast_message = "Unable to add your account";
        } else {
          updatedata.account_details = res.data.insert_ExtAcct.returning[0];
          navigate("/verifyextacct", {
            state: { account_details: res.data.insert_ExtAcct.returning[0] },
          });
        }
        setValues({ ...values, ...updatedata });
      });
    });
  };
  const renderComponent = () => {
    if (values.loading === false) {
      return (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography component="div">
            <br />
            <Box
              fontWeight="fontWeightBold"
              fontSize="h6.fontSize"
              pb={1}
              id="addfunds_title"
            >
              Add funds
              <br />
            </Box>
            <Box fontSize="h7.fontSize" pb={1}>
              To transfer money directly from a bank account, you must first
              setup a link to the bank account.
            </Box>
            <Box fontSize="h7.fontSize" pb={1} id="link_bank_title">
              Link your bank account
            </Box>
          </Typography>
          <FormControl className="addfunds">
            <FormLabel component="legend" align="left">
              Account type
            </FormLabel>
            <Controller
              id="account_type"
              as={
                <RadioGroup
                  aria-label="account_type"
                  name="account_type"
                  value={values.account_type}
                  // onChange={handleChange("account_type")}
                >
                  <FormControlLabel
                    value="Checking"
                    control={<Radio color="primary" />}
                    label="Checking"
                  />
                  <FormControlLabel
                    value="Savings"
                    control={<Radio color="primary" />}
                    label="Savings"
                  />
                </RadioGroup>
              }
              control={control}
              name="account_type"
              defaultValue=""
              rules={{
                required: "Account type required",
              }}
            />
            <FormHelperText className={classes.textFieldError}>
              {errors.account_type && errors.account_type.message}
            </FormHelperText>
          </FormControl>
          <FormControl className="addfunds">
            <Controller
              id="owner_name"
              as={
                <TextField
                  type="text"
                  variant="outlined"
                  label="Account Owner"
                  fullWidth
                  autoFocus
                />
              }
              control={control}
              name="owner_name"
              defaultValue=""
              rules={{
                required: "Account owner required",
              }}
            />
            <FormHelperText className={classes.textFieldError}>
              {errors.owner_name && errors.owner_name.message}
            </FormHelperText>
          </FormControl>
          <FormControl className="addfunds">
            <Controller
              id="nickname"
              as={
                <TextField
                  type="text"
                  variant="outlined"
                  label="Account Nickname"
                  fullWidth
                  autoFocus
                />
              }
              control={control}
              name="nickname"
              defaultValue=""
              rules={{
                required: "Account nickname required",
              }}
            />
            <FormHelperText className={classes.textFieldError}>
              {errors.nickname && errors.nickname.message}
            </FormHelperText>
          </FormControl>
          <FormControl className="addfunds">
            <Controller
              id="routing_number"
              as={
                <TextField
                  type="number"
                  variant="outlined"
                  label="Routing Number"
                  inputProps={{
                    min: "000000000",
                    minLength: 9,
                    maxLength: "9",
                    max: "999999999",
                    size: 1,
                  }}
                  onInput={(e) => {
                    e.target.value = e.target.value.toString().substr(0, 9);
                  }}
                  fullWidth
                  autoFocus
                />
              }
              control={control}
              name="routing_number"
              defaultValue=""
              rules={{
                required: "Routing number required",
                minLength: {
                  value: 9,
                  message: "Routing Number must be 9 digits",
                },
              }}
            />
            <FormHelperText className={classes.textFieldError}>
              {errors.routing_number && errors.routing_number.message}
            </FormHelperText>
          </FormControl>
          <FormControl className="addfunds">
            <Controller
              id="account_number"
              as={
                <TextField
                  type="number"
                  variant="outlined"
                  label="Account Number"
                  inputProps={{ min: 0, size: 1 }}
                  fullWidth
                  autoFocus
                />
              }
              control={control}
              name="account_number"
              defaultValue=""
              rules={{
                required: "Account Number required",
              }}
            />
            <FormHelperText className={classes.textFieldError}>
              {errors.account_number && errors.account_number.message}
            </FormHelperText>
          </FormControl>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Button
                type="submit"
                className={classes.actionButton}
                variant="contained"
                id="link_button"
              >
                LINK
              </Button>
            </Grid>
          </Grid>
        </form>
      );
    } else {
      return <CircularProgress style={{ marginTop: "1em" }} />;
    }
  };
  return (
    <Container className={classes.form} style={{ marginTop: "0px" }}>
      <Grid container spacing={6}>
        <Grid item xs={10} sm={8} lg={6} className="stepsize">
          {renderComponent()}
        </Grid>
      </Grid>
    </Container>
  );
};
export default LinkaBankAcc;
