"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fadeAnimationHandler = exports.slideStopSwipingHandler = exports.slideSwipeAnimationHandler = exports.slideAnimationHandler = void 0;

var _react = require("react");

var _CSSTranslate = _interopRequireDefault(require("../../CSSTranslate"));

var _utils = require("./utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/**
 * Main animation handler for the default 'sliding' style animation
 * @param props
 * @param state
 */
var slideAnimationHandler = function slideAnimationHandler(props, state) {
  var returnStyles = {};
  var selectedItem = state.selectedItem;
  var previousItem = selectedItem;
  var lastPosition = _react.Children.count(props.children) - 1;
  var needClonedSlide = props.infiniteLoop && (selectedItem < 0 || selectedItem > lastPosition); // Handle list position if it needs a clone

  if (needClonedSlide) {
    if (previousItem < 0) {
      if (props.centerMode && props.centerSlidePercentage && props.axis === 'horizontal') {
        returnStyles.itemListStyle = (0, _utils.setPosition)(-(lastPosition + 2) * props.centerSlidePercentage - (100 - props.centerSlidePercentage) / 2, props.axis);
      } else {
        returnStyles.itemListStyle = (0, _utils.setPosition)(-(lastPosition + 2) * 100, props.axis);
      }
    } else if (previousItem > lastPosition) {
      returnStyles.itemListStyle = (0, _utils.setPosition)(0, props.axis);
    }

    return returnStyles;
  }

  var currentPosition = (0, _utils.getPosition)(selectedItem, props); // if 3d is available, let's take advantage of the performance of transform

  var transformProp = (0, _CSSTranslate.default)(currentPosition, '%', props.axis);
  var transitionTime = props.transitionTime + 'ms';
  returnStyles.itemListStyle = {
    WebkitTransform: transformProp,
    msTransform: transformProp,
    OTransform: transformProp,
    transform: transformProp
  };

  if (!state.swiping) {
    returnStyles.itemListStyle = _objectSpread(_objectSpread({}, returnStyles.itemListStyle), {}, {
      WebkitTransitionDuration: transitionTime,
      MozTransitionDuration: transitionTime,
      OTransitionDuration: transitionTime,
      transitionDuration: transitionTime,
      msTransitionDuration: transitionTime
    });
  }

  return returnStyles;
};
/**
 * Swiping animation handler for the default 'sliding' style animation
 * @param delta
 * @param props
 * @param state
 * @param setState
 */


exports.slideAnimationHandler = slideAnimationHandler;

var slideSwipeAnimationHandler = function slideSwipeAnimationHandler(delta, props, state, setState) {
  var returnStyles = {};
  var isHorizontal = props.axis === 'horizontal';

  var childrenLength = _react.Children.count(props.children);

  var initialBoundry = 0;
  var currentPosition = (0, _utils.getPosition)(state.selectedItem, props);
  var finalBoundry = props.infiniteLoop ? (0, _utils.getPosition)(childrenLength - 1, props) - 100 : (0, _utils.getPosition)(childrenLength - 1, props);
  var axisDelta = isHorizontal ? delta.x : delta.y;
  var handledDelta = axisDelta; // prevent user from swiping left out of boundaries

  if (currentPosition === initialBoundry && axisDelta > 0) {
    handledDelta = 0;
  } // prevent user from swiping right out of boundaries


  if (currentPosition === finalBoundry && axisDelta < 0) {
    handledDelta = 0;
  }

  var position = currentPosition + 100 / (state.itemSize / handledDelta);
  var hasMoved = Math.abs(axisDelta) > props.swipeScrollTolerance;

  if (props.infiniteLoop && hasMoved) {
    // When allowing infinite loop, if we slide left from position 0 we reveal the cloned last slide that appears before it
    // if we slide even further we need to jump to other side so it can continue - and vice versa for the last slide
    if (state.selectedItem === 0 && position > -100) {
      position -= childrenLength * 100;
    } else if (state.selectedItem === childrenLength - 1 && position < -childrenLength * 100) {
      position += childrenLength * 100;
    }
  }

  if (!props.preventMovementUntilSwipeScrollTolerance || hasMoved || state.swipeMovementStarted) {
    if (!state.swipeMovementStarted) {
      setState({
        swipeMovementStarted: true
      });
    }

    returnStyles.itemListStyle = (0, _utils.setPosition)(position, props.axis);
  } //allows scroll if the swipe was within the tolerance


  if (hasMoved && !state.cancelClick) {
    setState({
      cancelClick: true
    });
  }

  return returnStyles;
};
/**
 * Default 'sliding' style animination handler for when a swipe action stops.
 * @param props
 * @param state
 */


exports.slideSwipeAnimationHandler = slideSwipeAnimationHandler;

var slideStopSwipingHandler = function slideStopSwipingHandler(props, state) {
  var currentPosition = (0, _utils.getPosition)(state.selectedItem, props);
  var itemListStyle = (0, _utils.setPosition)(currentPosition, props.axis);
  return {
    itemListStyle: itemListStyle
  };
};
/**
 * Main animation handler for the default 'fade' style animation
 * @param props
 * @param state
 */


exports.slideStopSwipingHandler = slideStopSwipingHandler;

var fadeAnimationHandler = function fadeAnimationHandler(props, state) {
  var transitionTime = props.transitionTime + 'ms';
  var transitionTimingFunction = 'ease-in-out';
  var slideStyle = {
    position: 'absolute',
    display: 'block',
    zIndex: -2,
    minHeight: '100%',
    opacity: 0,
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    transitionTimingFunction: transitionTimingFunction,
    msTransitionTimingFunction: transitionTimingFunction,
    MozTransitionTimingFunction: transitionTimingFunction,
    WebkitTransitionTimingFunction: transitionTimingFunction,
    OTransitionTimingFunction: transitionTimingFunction
  };

  if (!state.swiping) {
    slideStyle = _objectSpread(_objectSpread({}, slideStyle), {}, {
      WebkitTransitionDuration: transitionTime,
      MozTransitionDuration: transitionTime,
      OTransitionDuration: transitionTime,
      transitionDuration: transitionTime,
      msTransitionDuration: transitionTime
    });
  }

  return {
    slideStyle: slideStyle,
    selectedStyle: _objectSpread(_objectSpread({}, slideStyle), {}, {
      opacity: 1,
      position: 'relative'
    }),
    prevStyle: _objectSpread({}, slideStyle)
  };
};

exports.fadeAnimationHandler = fadeAnimationHandler;