import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  InputLabel,
  FormControl,
  Select,
  TextField,
  FormHelperText,
  Container,
  Grid,
  Button,
  Modal,
  CircularProgress,
  InputAdornment,
  Box,
  MenuItem,
  ListSubheader,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import Service from "../services/hservice";
import { numberFormat } from "../utils/numbers";
import DialogComponent from "../components/DialogComponent";
import AppBackService from "../services/app-back";
import NumberFormatCustom from "../components/NumberFormat";

export default function Addinvests(props) {
  const Appback = AppBackService();
  const location = useLocation();
  const [values, setValues] = useState({
    select_account: "",
    select_block: "",
    amount: "",
    buttonText: props.isCashmanager
      ? "Invest Funds Into Block"
      : "Transfer Funds",
    buttonDisabled: false,
    AvailableBalance: 0,
    transfers_blocks: [],
    transfers_ext: [],
    pBlocklist: [],
    selBlocklist: [],
    avlBlocklist: [],
    minAmount: "",
  });
  const [dailogOpen, setDailogOpen] = useState(false);
  const [showLoad, setShowLoad] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [testAccount, setTestAccount] = useState(false);

  function isFloat(x) {
    return !!(x % 1);
  }
  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
      select_account_empty_error: false,
      select_account_required_error: "",
      select_block_empty_error: false,
      select_block_required_error: "",
      amount_empty_error: false,
      amount_required_error: "",
      buttonText: props.isCashmanager
        ? "Invest Funds Into Block"
        : "Transfer Funds",
      buttonDisabled: false,
    });
    if (event.target.name === "select_account") {
      if (event.target.value === "") {
        setValues({
          ...values,
          select_account: "",
        });
      }
    }
    if (event.target.name === "select_block") {
      if (event.target.value === "") {
        setValues({
          ...values,
          select_block: "",
        });
      } else {
        const blockObj = values.pBlocklist[event.target.value];
        setValues({
          ...values,
          [prop]: event.target.value,
          minAmount: blockObj ? blockObj.min : "",
          amount: "",
          select_block_empty_error: false,
          select_block_required_error: "",
          amount_empty_error: false,
          amount_required_error: "",
        });
      }
    }
    if (event.target.name === "amount") {
      if (event.target.value === "") {
        setValues({
          ...values,
          amount: "",
        });
      }
    }
  };

  const setAllValues = (transfers, cname) => {
    const selBlocks = [];
    const avlBlocks = [];
    Object.keys(transfers.pblocks).forEach((key) => {
      const obj = transfers.pblocks[key];
      if (obj.current) {
        selBlocks.push({ cname: key, name: obj.name });
      } else {
        avlBlocks.push({ cname: key, name: obj.name });
      }
    });
    const blockObj = transfers.pblocks[cname];
    setValues({
      ...values,
      buttonDisabled: false,
      AvailableBalance: transfers.avail_cash,
      transfers_ext: transfers.transfers_ext,
      transfers_blocks: transfers.transfers_blocks,
      amount: "",
      select_block: cname || "",
      pBlocklist: transfers.pblocks,
      selBlocklist: selBlocks,
      avlBlocklist: avlBlocks,
      minAmount: blockObj ? blockObj.min : "",
    });
  };

  const getaccounts = () => {
    Service.getAccount().then(async (result) => {
      setTestAccount(result.data.Account[0].test_acct);
      const transfers = await Appback.getFundtransfers(
        result.data.Account[0].id
      );
      const params = new URLSearchParams(location.search);
      const cname = params.get("cname");
      if (transfers) {
        if (transfers.transfers_ext) {
          Service.getLinkedAccounts(result.data.Account[0].id).then(
            async (res) => {
              if (res.data.ExtAcct.length > 0) {
                res.data.ExtAcct.map((j) => {
                  transfers.transfers_ext.map((i) => {
                    if (i.status === "pending") {
                      i.account_num = j.account_num;
                    }
                  });
                  return j;
                });
              }
              setAllValues(transfers, cname);
            }
          );
        } else {
          setAllValues(transfers, cname);
        }
      } else {
        setValues({
          ...values,
        });
      }
      setShowLoad(false);
    });
  };
  const handleDelete = (fundId) => {
    Service.getAccount().then((result) => {
      Service.deleteBlockInvest(result.data.Account[0].id, fundId).then(() => {
        getaccounts();
      });
    });
  };
  const handleSubmit = () => {
    if (!values.select_block) {
      setValues({
        ...values,
        select_block_empty_error: true,
        select_block_required_error: "Field is required",
      });
    } else if (!values.amount) {
      setValues({
        ...values,
        amount_empty_error: true,
        amount_required_error: "Field is required",
      });
    } else if (values.amount < values.minAmount) {
      setValues({
        ...values,
        amount_empty_error: true,
        amount_required_error: `${numberFormat(
          values.minAmount
        )} is the minimum initial investment.`,
      });
    } else if (values.amount && isFloat(values.amount)) {
      setValues({
        ...values,
        amount_empty_error: true,
        amount_required_error: `Must enter whole dollar amounts with no cents.`,
      });
    } else if (values.AvailableBalance < values.amount) {
      setValues({
        ...values,
        amount_empty_error: true,
        amount_required_error: `You can only invest a max of ${numberFormat(
          values.AvailableBalance
        )} at this time.`,
      });
    } else {
      Service.getAccount().then((result) => {
        const data = {
          type: "deposit",
          amount: parseFloat(values.amount.replace(/\$|,/g, "")),
          cname: values.select_block,
        };
        Appback.insertFundTransfers(result.data.Account[0].id, data).then(
          (tranRes) => {
            if (tranRes.error) {
              setDailogOpen(true);
              setErrorMessage(tranRes.msg);
            } else {
              setValues({
                ...values,
                amount: "",
                select_block: "",
              });
              getaccounts();
            }
          }
        );
      });
    }
  };
  const handleReleasePending = () => {
    Service.getAccount().then((result) => {
      Appback.updateReleasePending(
        result.data.Account[0].id,
        values.transfers_ext[0].id
      ).then(() => {
        getaccounts();
      });
    });
  };
  const handleOpen = () => {
    document.body.classList.add("disabled-scroll");
  };
  const handleClose = () => {
    document.body.classList.remove("disabled-scroll");
  };
  useEffect(() => {
    setShowLoad(true);
    getaccounts();
  }, [props.updatedReleases]);

  const renderBlockTransfers = () => (
    <div className="accountstatus-body accountstatus-text">
      <div>
        <div>
          <p>Pending deposits into blocks:</p>
          <table>
            <thead>
              <tr>
                <th>DATE</th>
                <th>BLOCK</th>
                <th>AMOUNT</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {values.transfers_blocks &&
                values.transfers_blocks.map((result, j) => (
                  <tr key={j.toString()}>
                    <td align="left">
                      {moment(result.requested).format("MM/DD/YYYY")}
                    </td>
                    <td align="left">{result.bname}</td>
                    <td align="left">{result.amount}</td>
                    <td>
                      <DeleteIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleDelete(result.id)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
  const renderExtTransfers = () => (
    <div
      className="accountstatus-body accountstatus-text"
      style={{ marginTop: "40px" }}
    >
      <div>
        <div>
          <p>Pending transfers from your linked account:</p>
          <p>
            A deposit from your bank account{" "}
            {values.transfers_ex && values.transfers_ex[0].account_num} to
            99rises is in process:
          </p>
          <table>
            <thead>
              <tr>
                <th>DATE</th>
                <th>TYPE</th>
                <th>AMOUNT</th>
              </tr>
            </thead>
            <tbody>
              {values.transfers_ext &&
                values.transfers_ext.map((result, j) => (
                  <tr key={j.toString()}>
                    <td align="left">
                      {moment(result.requested).format("MM/DD/YYYY")}
                    </td>
                    <td align="left">{result.type}</td>
                    <td align="left">{result.amount}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
  const renderAddInvest = () => (
    <div style={{ marginTop: "40px" }}>
      <form style={{ width: "90%" }}>
        <Box mt={1} mb={1}>
          <FormControl
            variant="outlined"
            sx={{
              paddingLeft: "5px",
              marginBottom: "15px !important",
            }}
          >
            Available Cash : {numberFormat(values.AvailableBalance)}
          </FormControl>
        </Box>
        <Box mt={1} mb={1} className="add-invest">
          <FormControl variant="outlined" sx={{ textAlign: "left" }}>
            <InputLabel
              className="inputlabel"
              htmlFor="outlined-adornment-password"
              error={values.select_block_empty_error}
            >
              Block
            </InputLabel>
            <Select
              label="Block"
              className="select-label select_block"
              value={values.select_block}
              onChange={handleChange("select_block")}
              error={values.select_block_empty_error}
              name="select_block"
              onOpen={handleOpen}
              onClose={handleClose}
            >
              {values.selBlocklist.length > 0 && (
                <ListSubheader>Current Blocks</ListSubheader>
              )}
              {values.selBlocklist.length > 0 &&
                values.selBlocklist.map((result) => (
                  <MenuItem value={result.cname}>{result.name}</MenuItem>
                ))}
              {values.avlBlocklist.length > 0 && (
                <ListSubheader>Available Blocks</ListSubheader>
              )}
              {values.avlBlocklist.length > 0 &&
                values.avlBlocklist.map((result) => (
                  <MenuItem value={result.cname}>{result.name}</MenuItem>
                ))}
            </Select>
            <img
              src="error.png"
              alt="check"
              hidden={!values.select_block_empty_error}
            />
            <FormHelperText className="formhelpertext">
              {values.select_block_required_error}
            </FormHelperText>
            <br />
          </FormControl>
        </Box>
        <Box mt={1} mb={1}>
          <FormControl variant="outlined">
            <TextField
              variant="outlined"
              error={values.amount_empty_error}
              value={parseFloat(values.amount)}
              onChange={handleChange("amount")}
              name="amount"
              label="Amount"
              id="formatted-numberformat-input"
              placeholder={
                values.minAmount
                  ? `Min=${numberFormat(values.minAmount)}`
                  : "Amount"
              }
              InputProps={{
                inputComponent: NumberFormatCustom,
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
            <img
              src="error.png"
              alt="check"
              hidden={!values.amount_empty_error}
            />
            <FormHelperText className="formhelpertext">
              {values.amount_required_error}
            </FormHelperText>
          </FormControl>
        </Box>
        <Button
          className="transfer_funds"
          onClick={handleSubmit}
          variant="contained"
          disabled={values.buttonDisabled}
          id="transfer_button"
        >
          {values.buttonText}
        </Button>
      </form>
    </div>
  );

  return (
    <div className="add-funds AddFundsPageContentHeight">
      <Container>
        {showLoad === true ? (
          <div className="progressbar">
            <Modal
              open
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <div>
                <div id="circle-div">
                  <CircularProgress className="circle" />
                </div>
              </div>
            </Modal>
          </div>
        ) : (
          <Grid container spacing={6}>
            <Grid
              item
              xs={props.isCashmanager ? 12 : 10}
              sm={props.isCashmanager ? 12 : 8}
              md={props.isCashmanager ? 12 : 8}
              lg={props.isCashmanager ? 12 : 6}
              className="block-white"
            >
              <div className="fundingbasic size accountstatus">
                <h3>
                  {props.isCashmanager
                    ? "Invest funds into Blocks"
                    : "Invest Available Funds into Blocks"}
                </h3>
                {renderAddInvest()}
                {values.transfers_blocks &&
                  values.transfers_blocks.length > 0 &&
                  renderBlockTransfers()}
                {!props.isCashmanager &&
                  values.transfers_ext &&
                  values.transfers_ext.length > 0 && (
                    <>
                      {renderExtTransfers()}
                      {testAccount && (
                        <div style={{ marginTop: "40px" }}>
                          <button
                            type="button"
                            className="delete_link_account"
                            onClick={handleReleasePending}
                            variant="contained"
                            id="delink_button"
                          >
                            Release Pending
                          </button>
                        </div>
                      )}
                    </>
                  )}
              </div>
              <DialogComponent
                dailogOpen={dailogOpen}
                setDailogOpen={setDailogOpen}
              >
                <p>{errorMessage}</p>
              </DialogComponent>
            </Grid>
          </Grid>
        )}
      </Container>
    </div>
  );
}
