import React from "react";
import {
  Container,
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import "./emailnotifications.scss";

const EmailNotifications = () => (
  <div className="email-notifications">
    <Container>
      <Grid container spacing={6}>
        <Grid item xs={10} sm={8} md={8} lg={6} className="block-white">
          <br />
          <h3>Email Notifications</h3>
          <div>
            <form>
              <p>Select the email you would like us to send you:</p>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <p component="legend">Product announcements and tips</p>
                    </td>
                    <td>
                      <RadioGroup
                        aria-label="announcements"
                        name="announcements"
                        className="radio-group"
                      >
                        <FormControlLabel
                          className="Form-control-label"
                          value="yes"
                          control={<Radio color="primary" />}
                          label="yes"
                        />
                        <FormControlLabel
                          className="Form-control-label"
                          value="no"
                          control={<Radio />}
                          label="no"
                        />
                      </RadioGroup>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p component="legend">Promotional offers from 99rises</p>
                    </td>
                    <td>
                      <RadioGroup
                        aria-label="offers"
                        name="offers"
                        className="radio-group"
                      >
                        <FormControlLabel
                          className="Form-control-label"
                          value="yes"
                          control={<Radio color="primary" />}
                          label="yes"
                        />
                        <FormControlLabel
                          className="Form-control-label"
                          value="no"
                          control={<Radio />}
                          label="no"
                        />
                      </RadioGroup>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p component="legend">Monthly wealth-building content </p>
                    </td>
                    <td>
                      <RadioGroup
                        aria-label="Monthly"
                        name="Monthly"
                        className="radio-group"
                      >
                        <FormControlLabel
                          className="Form-control-label"
                          value="yes"
                          control={<Radio color="primary" />}
                          label="yes"
                        />
                        <FormControlLabel
                          className="Form-control-label"
                          value="no"
                          control={<Radio />}
                          label="no"
                        />
                      </RadioGroup>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p component="legend">99rises company news</p>
                    </td>
                    <td>
                      <RadioGroup
                        aria-label="news"
                        name="news"
                        className="radio-group"
                      >
                        <FormControlLabel
                          className="Form-control-label"
                          value="yes"
                          control={<Radio color="primary" />}
                          label="yes"
                        />
                        <FormControlLabel
                          className="Form-control-label"
                          value="no"
                          control={<Radio />}
                          label="no"
                        />
                      </RadioGroup>
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </div>
        </Grid>
      </Grid>
    </Container>
  </div>
);

export default EmailNotifications;
