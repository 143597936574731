import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Grid, Button } from "@mui/material";
import jQuery from "jquery";
import publicIp from "public-ip";
import Service from "../services/hservice";
import StepData from "../services/step-data";
import { getMaxSteps } from ".";
import "./clientagreement.scss";
import { RootContext } from "../context/root-provider";

const __html = require("./99rises+-+Account+Management+Agreement.js");

const template = { __html };

const Clientagreement = () => {
  const { setAccountStatusName } = useContext(RootContext);
  const [accountType, setAccountType] = useState("");
  const navigate = useNavigate();

  const getDetails = async () => {
    const result = await StepData.get();
    if (result.data && result.data.UserInformation_by_pk) {
      const res = result.data.UserInformation_by_pk;
      setAccountType(res.account_type);
    }
  };

  useEffect(() => {
    getDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  jQuery(document).ready(($) => {
    $("#agree").prop("disabled", true);
    $("#agree").addClass("Mui-disabled Mui-disabled");
    $(".scroll-area").scroll(function () {
      if (
        $(this).scrollTop() + $(this).height() >
        $(this)[0].scrollHeight - 80
      ) {
        $("#agree").prop("disabled", false);
        $("#agree").removeClass("Mui-disabled Mui-disabled");
      }
    });
  });

  const updateDetails = async () => {
    const ipAA = await publicIp.v4();

    await StepData.updateN(`appl_ip: "${ipAA}"`, {});
    // FIXME: TimingBug
    await new Promise((r) => setTimeout(r, 500));
    navigate("/accountstatus");
  };

  const handleagree = () => {
    Service.getAccount()
      .then((result) => {
        if (result.data.Account[0].account_status === "interview_started") {
          setAccountStatusName("application_submitted");
          Service.updateAccount(
            `id: {_eq: "${result.data.Account[0].id}"}}, _set: {account_status: "application_submitted"}`
          )
            .then((result1) => {})
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    updateDetails();
  };

  const handleback = () => {
    const maxStep = getMaxSteps(accountType);
    navigate(`/step/${maxStep}`);
  };

  return (
    <div className="client-agreement">
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={10} sm={8} md={8} lg={6} className="block-white">
            <h3>99rises Client Agreement</h3>
            <br />
            <p>
              Last step before we submit your information to our broker partner
              Tradier. Please review our client agreement by scrolling through
              the page below.
            </p>
            <br />
            <div className="scroll-area">
              <span dangerouslySetInnerHTML={template} />
            </div>
            <br />
            <Grid container spacing={1} className="stepsize">
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Button
                  onClick={handleback}
                  variant="contained"
                  id="back_button"
                >
                  Back
                </Button>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Button
                  onClick={handleagree}
                  id="agree"
                  className="agree"
                  variant="contained"
                >
                  Agree
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Clientagreement;
