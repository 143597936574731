import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CardComponent from "./cardComponent";

const CardDetails = ({ open, close, card, viewType, imgheight }) => {
  const [openmodel, setOpenmodel] = React.useState(false);
  const handleClose = () => {
    close();
    setOpenmodel(false);
  };
  useEffect(() => {
    setOpenmodel(open);
  }, [open]);
  return (
    <div className="stock-memo-dialog">
      <Dialog
        open={openmodel}
        onClose={handleClose}
        scroll="body"
        maxWidth="xs"
      >
        <DialogTitle
          sx={{ position: "absolute", right: 0, top: 0, zIndex: 9999 }}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              color: "#fff",
            }}
          >
            <HighlightOffIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ padding: "0px !important" }}>
          <CardComponent
            Cards={card}
            viewType={viewType}
            imgheight={imgheight}
            isFrom="Details"
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default CardDetails;
