import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Button, TextField, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Formik, Form } from "formik";
import * as yup from "yup";
import PasswordComponent, {
  passwordValidation,
} from "../components/PasswordComponent";

const ChangePassword = ({ userData }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handlePasswordChange = async (
    model,
    { setErrors, resetForm, setStatus }
  ) => {
    try {
      await Auth.changePassword(
        userData,
        model.old_password,
        model.new_password
      );
      const status = { message: "successfully changed password" };
      setStatus(status);
      resetForm({ status });
    } catch (error) {
      setErrors({ api: error?.message });
      setStatus({});
    }
  };

  return (
    <>
      <h3>Change Password</h3>
      <>
        <Formik
          initialValues={{ old_password: "", new_password: "" }}
          validationSchema={yup.object().shape({
            old_password: yup
              .string("Enter your current password")
              .required("Current password is required"),
            new_password: passwordValidation().notOneOf(
              [yup.ref("old_password")],
              "New password same as currect password."
            ),
          })}
          onSubmit={(values, formikBag) => {
            handlePasswordChange(values, formikBag);
          }}
        >
          {({ values, errors, touched, status, handleChange }) => (
            <Form>
              {errors?.api && <p className="error">{errors?.api}</p>}
              {status?.message && <p className="success">{status?.message}</p>}
              <TextField
                fullWidth
                variant="outlined"
                id="oldpassword"
                name="old_password"
                label="Current Password"
                autoComplete="current-password"
                type={showPassword ? "text" : "password"}
                value={values.old_password}
                onChange={handleChange}
                error={touched.old_password && Boolean(errors.old_password)}
                helperText={touched.old_password && errors.old_password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                        onMouseDown={(event) => event.preventDefault()}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <PasswordComponent
                values={values}
                label="New Password"
                name="new_password"
                type={showConfirmPassword ? "text" : "password"}
                value={values.new_password}
                onChange={(e) => {
                  handleChange(e);
                }}
                error={touched.new_password && Boolean(touched.new_password)}
                helperText={touched.new_password && errors.new_password}
                onClick={() => {
                  setShowConfirmPassword(!showConfirmPassword);
                }}
                onMouseDown={(event) => event.preventDefault()}
              />
              <Button
                type="submit"
                variant="contained"
                id="change_password_submit_button"
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </>
    </>
  );
};
export default ChangePassword;
