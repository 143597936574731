import graphql from "./graphql";

const Service = {
  // Account Initialization
  getUserInfo: async () =>
    graphql.query(
      `query MyQuery {
      UserInformation_by_pk(username: "%(cognitoUserName)s") {
        email
       }
      }`
    ),

  insertUserInfo: async (object) =>
    graphql.query(
      `mutation MyMutation {
        insert_UserInformation(objects: {
          username: "%(cognitoUserName)s",
          first_name: "${object.firstName}",
          last_name: "${object.lastName}",
          phone_number: "${object.phoneNumber}",
          email: "%(cognitoUserEmail)s"
        }) {
          affected_rows
          returning {
              username
          }
        }
      }`
    ),

  insertAccountId: async (object) =>
    graphql.query(
      `mutation MyMutation {
      insert_Account(objects: {${object} }) {
        returning {
          id
        }
      }
    }`
    ),

  deleteUserInformation: async () =>
    graphql.query(
      `mutation MyMutation {
      delete_UserInformation_by_pk(username: "%(cognitoUserName)s") {
        }
    }`
    ),

  // STEP DETAILS

  updateUserInfo: async (updatefields) =>
    graphql.query(
      `mutation MyMutation(
          ) {
          update_UserInformation(where: {username: {_eq: "%(cognitoUserName)s"}},
          _set: {
          ${updatefields}
          }) {
              returning {
                username
              }
          }
        }`
    ),

  // Accounts
  getAccount: async () =>
    graphql.query(
      `query MyQuery {
      Account(where: {username: {_eq: "%(cognitoUserName)s"}}) {
        username
        account_status
        id
        test_acct
        bacct_id
        UserInformation {
          account_type
        }
      }
    }`
    ),

  updateAccountStatus: async (object) =>
    graphql.query(
      `mutation MyMutation {
        update_Account(where: {username: {_eq: "%(cognitoUserName)s"}}, _set: {account_status: "${object}"}) {
          returning {
            account_status
            id
          }
        }
      }`
    ),

  getAccountId: async () =>
    graphql.query(
      `query MyQuery {
      Account(where: {username: {_eq: "%(cognitoUserName)s"}}) {
        id
        username
        account_status
        bacct_id
      }
    }`
    ),

  updateAccount: async (object) =>
    graphql.query(
      `mutation MyMutation {
      update_Account(where: {${object}) {
        returning {
          id
        }
      }
    }`
    ),

  getAccountAndAccountHoldings: async () =>
    graphql.query(
      `query MyQuery {
      Account(where: {username: {_eq: "%(cognitoUserName)s"}}) {
        AccountBlocks{
          id,
          Portfolio{
            name
          }
        }
        id,
        test_acct
      }
    }`
    ),

  // BLOCKS nee Portfolio
  getPortfolios: async () =>
    graphql.query(
      `query MyQuery {
          Portfolio(where: {test: {_eq: false}, active: {_eq: true}}) {
            name
            cname
            portfolioID
            active
            description
            start_date
            risk_band
            cash_amt
            type
          }
        }`
    ),

  getPortfolioHoldings: async (object) =>
    graphql.query(
      `query MyQuery {
      PortfolioHoldings(where: {portfolioID: {_eq: "${object}"}}) {
        nShares
        price
        ticker
      }
    }`
    ),

  // ExtAccts & FundTransfers

  getLinkedAccounts: async (account_id) =>
    graphql.query(
      `query MyQuery {
        ExtAcct(where: {account_id: {_eq: "${account_id}"}, status: {_neq: "Removed"}}) {
          id
          account_num
          routing_num
          status
          nickname
          account_type
          FundTransfers { id }
        }
      }`
    ),

  insertLinkedAccounts: async (object) =>
    graphql.query(
      `mutation MyMutation {
      insert_ExtAcct(objects: {${object}}) {
        affected_rows
        returning {
          id
          account_num
          routing_num
          status
          nickname
          account_type
          account_id
          created_dt
        }
      }
    }`
    ),

  updateLinkedAccounts: async (id, object) =>
    graphql.query(
      `mutation MyMutation {
      update_ExtAcct_by_pk(pk_columns: ${id}, ${object}) {
        account_num
        status
        routing_num
      }
    }`
    ),

  getFundTransfersAmount: async (accountID) =>
    graphql.query(
      ` query MyQuery {
        FundTransfers(where: {account_id: {_eq: "${accountID}"}}, order_by: {submitted_at: asc}) {
        account_id
        amount
        ext_acct_id
        requested
        type
        status,
        id
      }
    }`
    ),

  getLinkedVerfiedAccounts: async (account_id) =>
    graphql.query(
      `query MyQuery {
      ExtAcct(where: {account_id: {_eq: "${account_id}"},
      status: {_eq: "Linked"}}) {
        id
        account_num
        routing_num
        status
      }
    }`
    ),

  insertFundTransfers: async (ext_acct_id, account_id, amount, type, block) =>
    graphql.query(
      `mutation MyMutation {
      insert_FundTransfers(objects: {account_id: "${account_id}", amount: "${amount}", ext_acct_id: "${ext_acct_id}", type: "${type}"}) {
        returning {
          amount
          ext_acct_id
          requested
          amount
          type
        }
      }
    }`
    ),

  updateFundTransfers: async (ext_acct_id, amount, type) =>
    graphql.query(
      `mutation MyMutation {
      update_FundTransfers(where: {ext_acct_id: {_eq: "${ext_acct_id}"}}, _set: {amount: "${amount}", type: "${type}"}) {
        returning {
          ext_acct_id
          amount
          requested
          amount
          type
        }
      }
    }`
    ),

  deleteFundtransfers: async (accountID) =>
    graphql.query(
      `mutation MyMutation {
      delete_FundTransfers(where: {account_id: {_eq: "${accountID}"}}) {
        returning {
          account_id
        }
      }
    }`
    ),

  getLinkedVerfiedSingleAccount: async (id) =>
    graphql.query(
      `query MyQuery {
      ExtAcct(where: {id: {_eq: "${id}"}}) {
        status
        account_num
        created_dt
        id
        routing_num
      }
    }`
    ),

  deleteLinkedAccounts: async () =>
    graphql.query(
      `mutation MyMutation {
      delete_ExtAcct(where: {username: {_eq: "%(cognitoUserName)s"}}) {
        affected_rows
        returning {
          account_num
          created_dt
          id
          routing_num
          status
        }
      }
    }`
    ),

  getDocuments: async (account_id) =>
    graphql.query(
      `query MyQuery {
        Document(where: {account_id: {_eq: "${account_id}"}}) {
        account_id
        date
        id
        type
        }
     }`
    ),
  insertBlockInvest: async (ext_acct_id, account_id, amount, type, block) =>
    graphql.query(
      `mutation MyMutation {
      insert_FundTransfers(objects: {account_id: "${account_id}", amount: "${amount}", ext_acct_id: "${ext_acct_id}", type: "${type}", block_cname: "${
        !block ? null : block
      }"}) {
        returning {
          amount
          ext_acct_id
          requested
          amount
          type
        }
      }
    }`
    ),
  deleteBlockInvest: async (accountID, fundId) =>
    graphql.query(
      `mutation MyMutation {
        delete_FundTransfers(
          where: {
          _and: [{ id: { _eq: "${fundId}" } }, { account_id: { _eq: "${accountID}" } }]
        }) {
          returning {
            account_id
          }
        }
      }`
    ),
};

export default Service;
