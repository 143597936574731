import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Grid, Link } from "@mui/material";
import PropTypes from "prop-types";
import { RootContext } from "../context/root-provider";
import Service from "../services/hservice";
import Transferprocessing from "../funding/Transferprocessing";
import "./accountstatus.scss";

const accountStatusList = [
  "interview_started",
  "application_submitted",
  "lta_sent",
  "appln_pending",
  "account_approved",
  "account_funded",
  "account_active",
  "account_paused",
];

export default function Accountstatus() {
  const [values, setValues] = useState({
    account_status: "",
    test_acct: false,
    pendingTransfers: null,
    status_name: "",
    account_type: "",
  });
  const { setAccountStatusName } = useContext(RootContext);
  const navigate = useNavigate();
  const [accountId, setAccountId] = useState(0);
  const [approvedText, setApprovedText] = useState(
    "Your brokerage account is approved"
  );
  const [extAcctStatus, setExtAcctStatus] = useState("Approved");
  const [nickname, setNickname] = useState("");

  const getFundTransfers1 = (res, type, status, testAcct) => {
    if (res) {
      setValues({
        ...values,
        account_type: type,
        account_status: status,
        test_acct: testAcct,
        pendingTransfers: res,
      });
    }
  };

  const getFundTransfers = (id) =>
    Service.getLinkedAccounts(id).then((res) =>
      Service.getFundTransfersAmount(id).then((res1) => {
        const pendingFundTransfers = [];
        if (res1.data.FundTransfers.length > 0) {
          res.data.ExtAcct.map((j) => {
            res1.data.FundTransfers.map((i) => {
              if (i.status === "pending" && i.ext_acct_id === j.id) {
                i.account_num = j.account_num;
                pendingFundTransfers.push(i);
              }
              return pendingFundTransfers;
            });
            return j;
          });
        }
        return pendingFundTransfers;
      })
    );
  const getExtAcctStatus = (id) =>
    Service.getLinkedAccounts(id).then((res) => {
      if (res.data.ExtAcct.length > 0) {
        setNickname(res.data.ExtAcct[0].nickname);
        if (["Verify", "Confirm"].includes(res.data.ExtAcct[0].status)) {
          setApprovedText("Bank account verification is pending");
          setExtAcctStatus("Verify");
        } else if (res.data.ExtAcct[0].FundTransfers.length === 0) {
          setApprovedText("Your 99rises account is not funded");
          setExtAcctStatus("PendingFund");
        } else if (res.data.ExtAcct[0].FundTransfers.length > 0) {
          setApprovedText("Your deposit is in process");
          setExtAcctStatus("PendingInProcess");
        } else {
          setApprovedText("Your brokerage account is approved");
        }
      }
    });

  const AccountDetails = async () => {
    const result = await Service.getAccount();
    if (
      result.data.Account.length !== 0 &&
      result.data.Account.length !== undefined
    ) {
      setValues({
        ...values,
        account_status: result.data.Account[0].account_status,
        test_acct: result.data.Account[0].test_acct,
        status_name: result.data.Account[0].account_status,
        account_type: result.data.Account[0].UserInformation.account_type,
      });
      setAccountId(result.data.Account[0].id);
      setAccountStatusName(result.data.Account[0].account_status);
      if (result.data.Account[0].account_status === "interview_started") {
        await Service.updateAccount(
          `id: {_eq: '${result.data.Account[0].id}'}}, _set: {account_status: 'application_submitted'}`
        );
        setValues({
          account_status: "application_submitted",
        });
      } else if (
        result.data.Account[0].UserInformation.account_type === "individual" &&
        result.data.Account[0].account_status === "account_approved"
      ) {
        getExtAcctStatus(result.data.Account[0].id);
        getFundTransfers(result.data.Account[0].id).then((res) => {
          getFundTransfers1(
            res,
            result.data.Account[0].UserInformation.account_type,
            "account_approved",
            result.data.Account[0].test_acct
          );
        });
      }
    }
  };

  useEffect(() => {
    AccountDetails();
  }, []);
  useEffect(() => {}, [values]);

  function TestAcctLinks() {
    const index = accountStatusList.findIndex(
      (obj) => obj === values.account_status
    );
    const backAcctStatus = accountStatusList[index - 1];
    const continueAcctStatus = accountStatusList[index + 1];

    const handleChangeStatus = () => {
      Service.updateAccountStatus(continueAcctStatus).then(() => {
        setAccountStatusName(continueAcctStatus);
        setValues({
          ...values,
          account_status: continueAcctStatus,
        });
      });
    };
    const handleBackStatus = () => {
      Service.updateAccountStatus(backAcctStatus).then(() => {
        setAccountStatusName(backAcctStatus);
        setValues({
          ...values,
          account_status: backAcctStatus,
        });
        if (backAcctStatus === "interview_started") navigate("/");
      });
    };

    return (
      <Grid
        container
        spacing={1}
        className="navigators"
        sx={{ justifyContent: "center" }}
      >
        <ReuseGrid3>
          <p
            onClick={() => handleBackStatus()}
            className="link_in_accountstatus navigators"
            aria-hidden="true"
            id={`${backAcctStatus}_back_button`}
          >
            <button className="btn-link" type="button">
              {" "}
              &#60;-{backAcctStatus}
            </button>
          </p>
        </ReuseGrid3>
        <ReuseGrid3>
          {" "}
          <p className="status">
            <button className="btn-link" type="button">
              [ {values.account_status} ]
            </button>
          </p>
        </ReuseGrid3>
        {!["account_paused"].includes(values.account_status) && (
          <ReuseGrid3>
            <p
              onClick={() => handleChangeStatus()}
              className="link_in_accountstatus navigators"
              aria-hidden="true"
              id={`${continueAcctStatus}_continue_button`}
            >
              <button className="btn-link" type="button">
                {" "}
                {continueAcctStatus}-&#62;
              </button>
            </p>
          </ReuseGrid3>
        )}
      </Grid>
    );
  }

  const textFlowChart = (
    <>
      <h3>{approvedText}</h3>
      <FlowChart account_status={values.account_status} />
    </>
  );

  const aStatus = values.account_status;
  const aType = values.account_type;
  return (
    <div className="accountstatus AccountStatusPageContentHeight">
      <br />
      <Container maxWidth="lg">
        {values.account_status === "application_submitted" ? (
          <ReuseGrid>
            <h3>Thank you for submitting your account application!</h3>
            <FlowChart account_status={values.account_status} />
            <div className="accountstatus-body">
              <p>
                You will receive an email from our brokerage partner Tradier
                that requests you to review and accept an agreement that would
                authorize us to trade your brokerage account. If you do not
                receive this, please email us.
              </p>
              <p>
                After you accept the agreement, it will take 1-2 business days
                for account approval. You will then be able to set up an ACH
                Bank link and transfer money in.
              </p>
              <SupportPara />
            </div>
            <br />
            {values.test_acct && (
              <div>
                <br />
                <TestAcctLinks />
              </div>
            )}
          </ReuseGrid>
        ) : null}
        {values.account_status === "lta_sent" ? (
          <ReuseGrid>
            <h3>Limited Trade Authorization agreement</h3>
            <FlowChart account_status={values.account_status} />
            <div className="accountstatus-body">
              <p>
                Please check for an email that was sent by our brokerage partner
                Tradier. The email will request that you review and accept an
                agreement that authorizes us to trade your brokerage account.{" "}
              </p>
              <p>
                If you did not receive the email, let us know at{" "}
                <SupportEmail />.
              </p>
            </div>
            {values.test_acct && <TestAcctLinks />}
            <br />
            <br />
          </ReuseGrid>
        ) : null}
        {["appln_pending", "lta_approved", "lta_accepted"].includes(
          values.account_status
        ) ? (
          <ReuseGrid>
            <h3>Application submitted to Tradier Brokerage</h3>
            <FlowChart account_status={values.account_status} />
            <div className="accountstatus-body">
              <p>
                Your application has been submitted to our brokerage partner
                Tradier. Most applications are approved in 1-2 business days.
                However, approval times can vary, given additional regulatory
                requirements that may apply.
              </p>
              <p>
                After approval, you will be able to set up an ACH Bank link or
                fund your account by sending a check or wire.
              </p>
              <p>
                If you have a credit freeze or lock on your credit report, you
                will have to upload proof of identity using{" "}
                <a href="https://tradierbrokerage.sendsafely.com/dropzone/service">
                  Tradier&apos;s secure upload page
                </a>
                . Acceptable forms of ID include a US driver&apos;s license,
                passport or a government-issued ID.
              </p>
              <SupportPara />
            </div>

            {values.test_acct && <TestAcctLinks />}

            <br />
            <br />
          </ReuseGrid> // eslint-disable-next-line indent
        ) : null}
        {aStatus === "account_approved" ? (
          <ReuseGrid>
            <div className="accountstatus-body accountstatus-text">
              {values.account_type !== "individual" && (
                <div>
                  {textFlowChart}{" "}
                  <p>
                    To transfers funds to your IRA account, you will need to
                    execute an ACAT transfer. <br />
                    You should recieve an email from us shortly with the full
                    instructions.
                  </p>
                  <p>
                    Please let us know if you don&apos;t or if you have other
                    questions at <SupportEmail />.
                  </p>
                  {values.test_acct && <TestAcctLinks />}
                </div>
              )}
              {aType === "individual" &&
                values.pendingTransfers &&
                values.pendingTransfers.length <= 0 && (
                  <div>
                    {textFlowChart}
                    {extAcctStatus === "Approved" && (
                      <>
                        <p>
                          Your account has been approved by our brokerage
                          partner Tradier.{" "}
                        </p>
                        <p>
                          You can now proceed to set up an ACH bank link and{" "}
                          <u>
                            <a
                              href="/deposit_ext"
                              id="fund_your_account_anchor"
                            >
                              fund your account.
                            </a>
                          </u>
                        </p>
                      </>
                    )}
                    {extAcctStatus === "Verify" && (
                      <p>
                        Two small deposits (under 1 dollar) will be made into
                        your account nicknamed {nickname} from Tradier
                        Brokerage. When you see those, please come back to enter
                        them on <Link href="/deposit_ext">this screen.</Link>
                      </p>
                    )}
                    {extAcctStatus === "PendingFund" && (
                      <p>
                        You can now proceed to{" "}
                        <Link href="/deposit_ext">fund your account.</Link>
                      </p>
                    )}
                    {extAcctStatus === "PendingInProcess" && (
                      <p>
                        You can now proceed to{" "}
                        <Link href="/deposit_ext">fund your account.</Link>
                      </p>
                    )}
                    {values.test_acct && <TestAcctLinks />}
                  </div>
                )}
              {aType === "individual" &&
                values.pendingTransfers &&
                values.pendingTransfers.length > 0 && (
                  <div>
                    {textFlowChart}
                    {values.pendingTransfers.length < 1 ? (
                      <div style={{ marginTop: "50px" }}>
                        {values.pendingTransfers[0] !== undefined && (
                          <Transferprocessing id={accountId} />
                        )}
                      </div>
                    ) : (
                      <div>
                        <p>The following deposit is in process:</p>
                        <p>
                          A deposit from your bank account{" "}
                          {values.pendingTransfers[0].account_num} to 99rises is
                          in process:
                        </p>
                        <table>
                          <thead>
                            <tr>
                              <th>Account</th>
                              <th>Amount</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {values.pendingTransfers.map((result, j) => (
                              <tr key={j.toString()}>
                                <td align="left">{result.account_num}</td>
                                <td align="left">{result.amount}</td>
                                <td align="left">
                                  {result.status.toUpperCase()}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                    {values.test_acct && <TestAcctLinks />}
                  </div>
                )}
            </div>
            <br />
            <br />
          </ReuseGrid>
        ) : null}
        {values.account_status === "account_funded" ? (
          <ReuseGrid>
            <h3>Your account is funded</h3>
            <FlowChart account_status={values.account_status} />
            <div className="accountstatus-body accountstatus-text">
              <p>
                Your funds have arrived in your account. <br />
                We will invest them into your first block shortly.
              </p>
              <SupportPara />
            </div>
            {values.test_acct && <TestAcctLinks />}

            <br />
            <br />
          </ReuseGrid>
        ) : null}
        {["account_active", "account_paused"].includes(
          values.account_status
        ) ? (
          <ReuseGrid>
            <h3>Your account is funded</h3>
            <FlowChart account_status={values.account_status} />
            <div className="accountstatus-body accountstatus-text">
              <p>
                Your account is active and your funds have arrived in your
                account. <br />
                We will invest them into your first block shortly.
              </p>
              <SupportPara />
            </div>

            {values.test_acct && <TestAcctLinks />}

            <br />
            <br />
          </ReuseGrid>
        ) : null}
      </Container>
    </div>
  );
}

function ReuseGrid({ children }) {
  return (
    <Grid container spacing={6} sx={{ justifyContent: "center" }}>
      <Grid item xs={10} sm={8} md={8} lg={6} className="block-white">
        {children}
      </Grid>
    </Grid>
  );
}
ReuseGrid.propTypes = {
  children: PropTypes.element.isRequired,
};

function ReuseGrid3({ children }) {
  return (
    <Grid item xs={12} sm={12} md={4} lg={4}>
      {children}
    </Grid>
  );
}
ReuseGrid3.propTypes = {
  children: PropTypes.element.isRequired,
};

function SupportPara() {
  return (
    <p>
      Please feel free to send us questions at <SupportEmail />.
    </p>
  );
}

function SupportEmail() {
  return (
    <Link href="mailto:support@99rises.com" variant="email">
      support@99rises.com
    </Link>
  );
}
function FlowChart(status) {
  const { account_status } = status;
  const images = ["application-grn.png", "fundding-gry.png", "invest-gry.png"];
  if (account_status === "account_approved") {
    images[1] = "fundding-blk.png";
  } else if (account_status === "account_funded") {
    images[1] = "fundding-grn.png";
    images[2] = "invest-blk.png";
  } else if (["account_active", "account_paused"].includes(account_status)) {
    images[1] = "fundding-grn.png";
    images[2] = "invest-grn.png";
  }
  return (
    <ul className="account-status-process">
      <li>
        <img src={images[0]} alt="Application" />
        <h3>Application</h3>
      </li>
      <li>
        <img src="right-arrow-blk.png" alt="arrow" className="right-arrow" />
      </li>
      <li>
        <img src={images[1]} alt="Funding" />
        <h3>Funding</h3>
      </li>
      <li>
        <img src="right-arrow-blk.png" alt="arrow" className="right-arrow" />
      </li>
      <li>
        <img className="security" src={images[2]} alt="Investing" />
        <h3>Investing</h3>
      </li>
    </ul>
  );
}
