import React, { useEffect, useState } from "react";
import { Container, CircularProgress, Modal } from "@mui/material";
import AppBackService from "../services/app-back";
import SearchBar from "./searchBar";
import CardGrid from "./cardGrid";
import "./card.scss";
import CardDetails from "./cardDetails";

const Cards = () => {
  const Appback = AppBackService();
  const [showLoading, setShowLoading] = useState(true);
  const [view, setView] = useState(1);
  const [cards, setCards] = useState([]);
  const [card, setCard] = useState({});
  const [openCardModel, setCardModel] = useState(false);

  const showCardDetails = (cardId) => {
    const tempCards = [...cards];
    const details = tempCards.filter((item) => item.card_id === cardId);
    setCard(details);
    setCardModel(true);
  };
  useEffect(() => {
    const getCards = async () => {
      const lstCards = await Appback.getCards();
      if (lstCards) {
        setCards(lstCards.cards);
      }
      setShowLoading(false);
    };
    getCards();
  }, []);

  return (
    <Container>
      <SearchBar setView={setView} view={view} />
      {showLoading === true ? (
        <div className="progressbar">
          <Modal
            open
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div>
              <div id="circle-div">
                <CircularProgress className="circle" />
              </div>
            </div>
          </Modal>
        </div>
      ) : (
        <>
          <CardGrid
            view={view}
            setView={setView}
            Cards={cards}
            showCardDetails={showCardDetails}
            openCardModel={openCardModel}
          />
          <CardDetails
            open={openCardModel}
            close={() => setCardModel(false)}
            card={card}
            viewType="card"
            imgheight="200px"
          />
        </>
      )}
    </Container>
  );
};
export default Cards;
