import React, { useEffect } from "react";
import moment from "moment";
import { useFormContext } from "react-hook-form";
import MaskedInput from "react-text-mask";
import { FormControl, FormHelperText, Box } from "@mui/material";
import StepComponent from "./StepComponent";

const maskObj = {
  dateofbirth: [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/],
  socialSecurityNumber: [
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
};
const validationConst = {
  socialSecurityNumber: 9,
  dateFormat: "MM/DD/YYYY",
  maxDob: moment().add(-18, "y"),
  minDob: moment().add(-120, "y"),
};

const DOBMaskComponent = (props) => {
  const mask = maskObj.dateofbirth;
  const onFocus = "dateofbirth";
  const { inputRef, ...other } = props;
  const { setValue, setError } = useFormContext();
  const handleFocus = () => {
    setValue(onFocus, "");
    setError(onFocus, {
      type: "manual",
      message: "Date of Birth is required.",
    });
  };
  return (
    <MaskedInput
      {...other}
      mask={mask}
      onFocus={(_e) => {
        onFocus && handleFocus();
      }}
    />
  );
};

const SSNMaskComponent = (props) => {
  const mask = maskObj.socialSecurityNumber;
  const onFocus = "socialSecurityNumber";
  const { inputRef, ...other } = props;
  const { setValue, setError } = useFormContext();
  const handleFocus = () => {
    setValue(onFocus, "");
    setError(onFocus, {
      type: "manual",
      message: "Social security number is required.",
    });
  };
  return (
    <MaskedInput
      {...other}
      mask={mask}
      onFocus={(_e) => {
        onFocus && handleFocus();
      }}
    />
  );
};

const Styles = {
  error: {
    color: "red !important",
    textAlign: "left !important",
  },
};

const PersonalDetails = (props) => {
  const { data } = props;
  const { control, errors, formState, setValue } = useFormContext();
  const { dirtyFields } = formState;

  useEffect(() => {
    if (data) {
      setValue("dateofbirth", data?.dateofbirth);
      setValue("socialSecurityNumber", data?.socialSecurityNumber);
    }
  }, [data]);

  return (
    <>
      <Box mt={1} mb={1}>
        <FormControl variant="outlined">
          <StepComponent
            inputType="Masked"
            inputLabel="Date of Birth"
            controllerProp={{
              control,
              name: "dateofbirth",
              defaultValue: "",
              maskPattern: maskObj.dateofbirth,
              rules: {
                validate: {
                  required: (value) => value !== null && value !== "",
                  validDate: (value) =>
                    dirtyFields.dateofbirth === undefined ||
                    moment(new Date(value)).isValid(),
                  range: (value) =>
                    dirtyFields.dateofbirth === undefined ||
                    moment(value, validationConst.dateFormat).isBetween(
                      validationConst.minDob,
                      validationConst.maxDob
                    ),
                },
              },
            }}
            componentProp={{
              inputComponent: DOBMaskComponent,
            }}
          />
          <FormHelperText sx={{ ...Styles.error }}>
            {errors.dateofbirth?.type === "required" &&
              "Date of Birth is required."}
            {errors.dateofbirth?.type === "validDate" &&
              "Date of Birth is invalid"}
            {errors.dateofbirth?.type === "range" &&
              `Date of Birth is should be in between ${validationConst.minDob.format(
                validationConst.dateFormat
              )} - ${validationConst.maxDob.format(
                validationConst.dateFormat
              )}. `}
          </FormHelperText>
        </FormControl>
      </Box>
      <Box mt={1} mb={1}>
        <FormControl variant="outlined">
          <StepComponent
            inputType="Masked"
            inputLabel="Social security number"
            controllerProp={{
              control,
              name: "socialSecurityNumber",
              defaultValue: "",
              maskPattern: maskObj.socialSecurityNumber,
              rules: {
                validate: {
                  required: (value) => value !== null && value !== "",
                  maxNumber: (value) =>
                    (dirtyFields.socialSecurityNumber === undefined &&
                      value !== undefined) ||
                    value?.split("_").join("").split("-").join("").length ===
                      validationConst.socialSecurityNumber,
                },
              },
            }}
            componentProp={{
              inputComponent: SSNMaskComponent,
            }}
          />
          <FormHelperText sx={{ ...Styles.error }}>
            {errors.socialSecurityNumber?.type === "required" &&
              "Social security number is required."}
            {errors.socialSecurityNumber?.type === "maxNumber" &&
              `Social security number should be of ${validationConst.socialSecurityNumber} digits`}
          </FormHelperText>
        </FormControl>
      </Box>
    </>
  );
};
export default PersonalDetails;
