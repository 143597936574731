import React from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import PropTypes from "prop-types";

function Performance(props) {
  const { perf } = props;
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      container
      direction="row"
      className="border-top"
    >
      <Card className="etd performance">
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            className="head"
          >
            PERFORMANCE
          </Typography>
          <Typography variant="body2" component="span">
            {perf && (
              <div
                className="MuiPaper-root MuiTableContainer-root MuiPaper-rounded"
                style={{ marginTop: "25px" }}
              >
                <table className="MuiTable-root block_performance">
                  <tbody className="MuiTableBody-root">
                    <tr className="MuiTableRow-root MuiTableRow-head">
                      <th
                        className="MuiTableCell-root MuiTableCell-head MuiTableCell-alignCenter"
                        scope="col"
                      >
                        1D
                      </th>
                      <th
                        className="MuiTableCell-root MuiTableCell-head MuiTableCell-alignCenter"
                        scope="col"
                      >
                        MTD
                      </th>
                      <th
                        className="MuiTableCell-root MuiTableCell-head MuiTableCell-alignCenter"
                        scope="col"
                      >
                        YTD
                      </th>
                      <th
                        className="MuiTableCell-root MuiTableCell-head MuiTableCell-alignCenter"
                        scope="col"
                      >
                        TD
                      </th>
                    </tr>
                    <tr className="MuiTableRow-root">
                      <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignCenter">
                        {`${perf.one_day === null ? "0" : perf.one_day}%`}
                      </td>
                      <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignCenter">
                        {`${perf.mtd === null ? "0" : perf.mtd}%`}
                      </td>
                      <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignCenter">
                        {`${perf.ytd === null ? "0" : perf.ytd}%`}
                      </td>
                      <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignCenter">
                        {`${perf.td === null ? "0" : perf.td}%`}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}
Performance.propTypes = {
  perf: PropTypes.instanceOf(Array).isRequired,
};
export default Performance;
