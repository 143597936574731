import React, { useState, useEffect, useContext } from "react";
import {
  useLocation,
  useNavigate,
  UNSAFE_NavigationContext,
  createSearchParams,
} from "react-router-dom";
import { Container, Grid, Button } from "@mui/material";
import Mousetrap from "mousetrap";
import BlockOverview from "./BlockOverview";
import BlockDetails from "./BlockDetails";
import BlocksStrategy from "./BlocksStrategy";
import Performance from "./Performance";
import Holdings from "./Holdings";
import Cumulative from "./Cumulative";
import HoldingsList from "./HoldingsList";
import BlockBanner from "./BlockBanner";
import "./blocks.scss";
import "./blocks-light.scss";
import Service from "../services/hservice";
import AppBackService from "../services/app-back";
import SwicherModal from "../components/BlockSwicherModal";
import DialogComponent from "../components/DialogComponent";

const Blocks = () => {
  const history = useContext(UNSAFE_NavigationContext).navigator;
  const location = useLocation();
  const navigate = useNavigate();
  const Appback = AppBackService();
  const [cName, setCName] = useState("");
  const [blockData, setBlockData] = useState([]);
  const [portfoliolist, setPortfoliolist] = useState([]);
  const [selIndex, setSelIndex] = useState(0);
  const [stylePath, setStylePath] = useState("blocks-light");
  const [dailogOpen, setDailogOpen] = useState(false);
  const [holdingData, setHoldingData] = useState({
    columns: [
      { name: "Cash", value: 25 },
      { name: "Longs", value: 25 },
      { name: "Shorts", value: 25 },
      { name: "Hedge", value: 25 },
    ],
    colors: {
      Cash: "#1685d3",
      Longs: "#26a64a",
      Shorts: "#ff8521",
      Hedge: "#ffd300",
    },
  });
  const [openmodel, setOpenmodel] = useState(false);
  const [locationState, setLocationState] = useState("");
  const HoldingData = (result) => {
    setHoldingData({
      data: [
        {
          name: "Cash",
          value: Number((result.holdings_chart.cash_pct * 100).toFixed(1)),
        },
        {
          name: "Longs",
          value: Number((result.holdings_chart.long_pct * 100).toFixed(1)),
        },
        {
          name: "Shorts",
          value: Number((result.holdings_chart.short_pct * 100).toFixed(1)),
        },
        {
          name: "Hedge",
          value: Number((result.holdings_chart.hedge_pct * 100).toFixed(1)),
        },
      ],
      colors: ["#1685d3", "#26a64a", "#ff8521", "#ffd300"],
    });
  };
  const getBlockData = async (block) => {
    let result = await Appback.getBlockData(block);
    setBlockData(result);
    HoldingData(result);
  };
  const handleClick = async (_index, item) => {
    const cname = item.props.block.toLowerCase().replace(/ /g, "");
    history.push(`/blocks?${createSearchParams({ cname }).toString()}`);
    getBlockData(cname);
    setCName(cname);
  };

  useEffect(() => {
    return () => {
      if (history.action === "POP" && locationState !== "") {
        if (locationState.from === "port") {
          navigate("/portfolio", {
            state: locationState,
          });
        } else {
          navigate("/blocklist", {
            state: locationState,
          });
        }
      }
    };
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const portlist = async () => {
      if (portfoliolist.length === 0) {
        const res = await Service.getPortfolios();
        setPortfoliolist(res.data.Portfolio);
        setLocationState(location.state);
        let cname = location.state && location.state.cname;
        const search = location.search;
        if (!cname && search) {
          const params = new URLSearchParams(search);
          cname = params.get("cname");
        } else if (!cname) cname = "balanced2";

        const index = res.data.Portfolio.findIndex(
          (x) => x.cname === cname.toLowerCase()
        );
        setSelIndex(index);

        if (blockData.length === 0 && index == 0) {
          history.push(`/blocks?${createSearchParams({ cname }).toString()}`);
          setCName(cname);
          getBlockData(cname);
        }
      }
    };
    portlist();
  }, [blockData]);

  Mousetrap.bind("t", async () => {
    setOpenmodel(true);
  });
  const closemodel = () => {
    setOpenmodel(false);
  };
  const handleChangeTheme = (value) => {
    setOpenmodel(false);
    if (value === "flat") {
      setStylePath("blocks-light");
    } else {
      setStylePath("blocks");
    }
  };
  const handleShare = () => {
    setDailogOpen(true);
  };
  const handleInvest = () => {
    navigate(`/deposit_blocks?cname=${cName}`);
  };
  return (
    <>
      {/* <div id="cover-spin" /> */}
      <BlockBanner
        handleClick={handleClick}
        Portfoliolist={portfoliolist}
        selIndex={selIndex}
        locationState={locationState}
      />
      <Container className={stylePath} style={{ marginTop: "-60px" }}>
        <div className="banner-button">
          <Button
            variant="contained"
            type="button"
            className="share"
            id="share_button"
            onClick={() => handleShare()}
          >
            Share
          </Button>
          <Button
            variant="contained"
            type="button"
            className="invest"
            id="invest_button"
            onClick={() => handleInvest()}
          >
            Invest
          </Button>
        </div>
        {blockData && (
          <Grid container spacing={1} className="pt-24">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              container
              direction="row"
              className="border-right"
            >
              <BlockOverview desc={blockData.desc} />
              <BlockDetails details={blockData.details} />
              <BlocksStrategy stratDesc={blockData.strat_desc} />
              <Performance perf={blockData.perf} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} container direction="row">
              <Holdings holdingData={holdingData} />
              <Cumulative perfChart={blockData.perf_chart} />
            </Grid>
            <HoldingsList holdings={blockData.holdings} />
          </Grid>
        )}
        <SwicherModal
          open={openmodel}
          close={closemodel}
          handleChangeTheme={handleChangeTheme}
        />
        <DialogComponent dailogOpen={dailogOpen} setDailogOpen={setDailogOpen}>
          <p>
            We are in the process of getting our multi-block experience fully
            ready including showing useful information about each block.
          </p>{" "}
          <p>
            We’d love to hear feedback at any time at{" "}
            <a href="mailto:feedback@99rises.com">feedback@99rises.com</a>.
          </p>
        </DialogComponent>
      </Container>
    </>
  );
};
export default Blocks;
