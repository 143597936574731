{
  "states": [
    {
      "value": "AL",
      "key": "Alabama"
    },
    {
      "value": "AK",
      "key": "Alaska"
    },
    {
      "value": "AZ",
      "key": "Arizona"
    },
    {
      "value": "AR",
      "key": "Arkansas"
    },
    {
      "value": "CA",
      "key": "California"
    },
    {
      "value": "CO",
      "key": "Colorado"
    },
    {
      "value": "CT",
      "key": "Connecticut"
    },
    {
      "value": "DE",
      "key": "Delaware"
    },
    {
      "value": "FL",
      "key": "Florida"
    },
    {
      "value": "GA",
      "key": "Georgia"
    },
    {
      "value": "HI",
      "key": "Hawaii"
    },
    {
      "value": "ID",
      "key": "Idaho"
    },
    {
      "value": "IL",
      "key": "Illinois"
    },
    {
      "value": "IN",
      "key": "Indiana"
    },
    {
      "value": "IA",
      "key": "Iowa"
    },
    {
      "value": "KS",
      "key": "Kansas"
    },
    {
      "value": "KY",
      "key": "Kentucky"
    },
    {
      "value": "LA",
      "key": "Louisiana"
    },
    {
      "value": "ME",
      "key": "Maine"
    },
    {
      "value": "MD",
      "key": "Maryland"
    },
    {
      "value": "MA",
      "key": "Massachusetts"
    },
    {
      "value": "MI",
      "key": "Michigan"
    },
    {
      "value": "MN",
      "key": "Minnesota"
    },
    {
      "value": "MS",
      "key": "Mississippi"
    },
    {
      "value": "MO",
      "key": "Missouri"
    },
    {
      "value": "MT",
      "key": "Montana"
    },
    {
      "value": "NE",
      "key": "Nebraska"
    },
    {
      "value": "NV",
      "key": "Nevada"
    },
    {
      "value": "NH",
      "key": "New Hampshire"
    },
    {
      "value": "NJ",
      "key": "New Jersey"
    },
    {
      "value": "NM",
      "key": "New Mexico"
    },
    {
      "value": "NY",
      "key": "New York"
    },
    {
      "value": "NC",
      "key": "North Carolina"
    },
    {
      "value": "ND",
      "key": "North Dakota"
    },
    {
      "value": "OH",
      "key": "Ohio"
    },
    {
      "value": "OK",
      "key": "Oklahoma"
    },
    {
      "value": "OR",
      "key": "Oregon"
    },
    {
      "value": "PA",
      "key": "Pennsylvania"
    },
    {
      "value": "RI",
      "key": "Rhode Island "
    },
    {
      "value": "SC",
      "key": "South Carolina"
    },
    {
      "value": "SD",
      "key": "South Dakota"
    },
    {
      "value": "TN",
      "key": "Tennessee"
    },
    {
      "value": "TX",
      "key": "Texas"
    },
    {
      "value": "UT",
      "key": "Utah"
    },
    {
      "value": "VT",
      "key": "Vermont"
    },
    {
      "value": "VA",
      "key": "Virginia"
    },
    {
      "value": "WA",
      "key": "Washington"
    },
    {
      "value": "WV",
      "key": "West Virginia"
    },
    {
      "value": "WL",
      "key": "Wisconsin"
    }
  ],
  "countries": [
    {
      "value": "United States",
      "key": "United States"
    },
    {
      "value": "Afghanistan",
      "key": "Afghanistan"
    },
    {
      "value": "Albania",
      "key": "Albania"
    },
    {
      "value": "Algeria",
      "key": "Algeria"
    },
    {
      "value": "American Samoa",
      "key": "American Samoa"
    },
    {
      "value": "Andorra",
      "key": "Andorra"
    },
    {
      "value": "Angola",
      "key": "Angola"
    },
    {
      "value": "Anguilla",
      "key": "Anguilla"
    },
    {
      "value": "Åland Islands",
      "key": "Åland Islands"
    },

    {
      "value": "Antarctica",
      "key": "Antarctica"
    },
    {
      "value": "Antigua and Barbuda",
      "key": "Antigua and Barbuda"
    },
    {
      "value": "Argentina",
      "key": "Argentina"
    },
    {
      "value": "Armenia",
      "key": "Armenia"
    },
    {
      "value": "Aruba",
      "key": "Aruba"
    },
    {
      "value": "Australia",
      "key": "Australia"
    },
    {
      "value": "Austria",
      "key": "Austria"
    },
    {
      "value": "Azerbaijan",
      "key": "Azerbaijan"
    },
    {
      "value": "Bahamas",
      "key": "Bahamas"
    },
    {
      "value": "Bahrain",
      "key": "Bahrain"
    },
    {
      "value": "Bangladesh",
      "key": "Bangladesh"
    },
    {
      "value": "Barbados",
      "key": "Barbados"
    },
    {
      "value": "Belarus",
      "key": "Belarus"
    },
    {
      "value": "Belgium",
      "key": "Belgium"
    },
    {
      "value": "Belize",
      "key": "Belize"
    },
    {
      "value": "Benin",
      "key": "Benin"
    },
    {
      "value": "Bermuda",
      "key": "Bermuda"
    },
    {
      "value": "Bhutan",
      "key": "Bhutan"
    },
    {
      "value": "Bolivia (Plurinational State of)",
      "key": "Bolivia (Plurinational State of)"
    },
    {
      "value": "Bonaire, Sint Eustatius and Saba",
      "key": "Bonaire, Sint Eustatius and Saba"
    },
    {
      "value": "Bosnia and Herzegovina",
      "key": "Bosnia and Herzegovina"
    },
    {
      "value": "Botswana",
      "key": "Botswana"
    },
    {
      "value": "Bouvet Island",
      "key": "Bouvet Island"
    },
    {
      "value": "Brazil",
      "key": "Brazil"
    },
    {
      "value": "British Indian Ocean Territory",
      "key": "British Indian Ocean Territory"
    },
    {
      "value": "Brunei Darussalam",
      "key": "Brunei Darussalam"
    },
    {
      "value": "Bulgaria",
      "key": "Bulgaria"
    },
    {
      "value": "Burkina Faso",
      "key": "Burkina Faso"
    },
    {
      "value": "Burundi",
      "key": "Burundi"
    },
    {
      "value": "Cabo Verde",
      "key": "Cabo Verde"
    },
    {
      "value": "Cambodia",
      "key": "Cambodia"
    },
    {
      "value": "Cameroon",
      "key": "Cameroon"
    },
    {
      "value": "Canada",
      "key": "Canada"
    },
    {
      "value": "Cayman Islands",
      "key": "Cayman Islands"
    },
    {
      "value": "Central African Republic",
      "key": "Central African Republic"
    },
    {
      "value": "Chad",
      "key": "Chad"
    },
    {
      "value": "Chile",
      "key": "Chile"
    },
    {
      "value": "China",
      "key": "China"
    },
    {
      "value": "Christmas Island",
      "key": "Christmas Island"
    },
    {
      "value": "Cocos (Keeling) Islands",
      "key": "Cocos (Keeling) Islands"
    },
    {
      "value": "Colombia",
      "key": "Colombia"
    },
    {
      "value": "Comoros",
      "key": "Comoros"
    },
    {
      "value": "Congo",
      "key": "Congo"
    },
    {
      "value": "Congo, Democratic Republic of the",
      "key": "Congo, Democratic Republic of the"
    },
    {
      "value": "Cook Islands",
      "key": "Cook Islands"
    },
    {
      "value": "Costa Rica",
      "key": "Costa Rica"
    },
    {
      "value": "Croatia",
      "key": "Croatia"
    },
    {
      "value": "Cuba",
      "key": "Cuba"
    },
    {
      "value": "Curaçao",
      "key": "Curaçao"
    },
    {
      "value": "Cyprus",
      "key": "Cyprus"
    },
    {
      "value": "Czechia",
      "key": "Czechia"
    },
    {
      "value": "Côte d'Ivoire",
      "key": "Côte d'Ivoire"
    },
    {
      "value": "Denmark",
      "key": "Denmark"
    },
    {
      "value": "Djibouti",
      "key": "Djibouti"
    },
    {
      "value": "Dominica",
      "key": "Dominica"
    },
    {
      "value": "Dominican Republic",
      "key": "Dominican Republic"
    },
    {
      "value": "Ecuador",
      "key": "Ecuador"
    },
    {
      "value": "Egypt",
      "key": "Egypt"
    },
    {
      "value": "El Salvador",
      "key": "El Salvador"
    },
    {
      "value": "Equatorial Guinea",
      "key": "Equatorial Guinea"
    },
    {
      "value": "Eritrea",
      "key": "Eritrea"
    },
    {
      "value": "Estonia",
      "key": "Estonia"
    },
    {
      "value": "Eswatini",
      "key": "Eswatini"
    },
    {
      "value": "Ethiopia",
      "key": "Ethiopia"
    },
    {
      "value": "Falkland Islands (Malvinas)",
      "key": "Falkland Islands (Malvinas)"
    },
    {
      "value": "Faroe Islands",
      "key": "Faroe Islands"
    },
    {
      "value": "Fiji",
      "key": "Fiji"
    },
    {
      "value": "Finland",
      "key": "Finland"
    },
    {
      "value": "France",
      "key": "France"
    },
    {
      "value": "French Guiana",
      "key": "French Guiana"
    },
    {
      "value": "French Polynesia",
      "key": "French Polynesia"
    },
    {
      "value": "French Southern Territories",
      "key": "French Southern Territories"
    },
    {
      "value": "Gabon",
      "key": "Gabon"
    },
    {
      "value": "Gambia",
      "key": "Gambia"
    },
    {
      "value": "Georgia",
      "key": "Georgia"
    },
    {
      "value": "Germany",
      "key": "Germany"
    },
    {
      "value": "Ghana",
      "key": "Ghana"
    },
    {
      "value": "Gibraltar",
      "key": "Gibraltar"
    },
    {
      "value": "Greece",
      "key": "Greece"
    },
    {
      "value": "Greenland",
      "key": "Greenland"
    },
    {
      "value": "Grenada",
      "key": "Grenada"
    },
    {
      "value": "Guadeloupe",
      "key": "Guadeloupe"
    },
    {
      "value": "Guam",
      "key": "Guam"
    },
    {
      "value": "Guatemala",
      "key": "Guatemala"
    },
    {
      "value": "Guernsey",
      "key": "Guernsey"
    },
    {
      "value": "Guinea",
      "key": "Guinea"
    },
    {
      "value": "Guinea-Bissau",
      "key": "Guinea-Bissau"
    },
    {
      "value": "Guyana",
      "key": "Guyana"
    },
    {
      "value": "Haiti",
      "key": "Haiti"
    },
    {
      "value": "Heard Island and McDonald Islands",
      "key": "Heard Island and McDonald Islands"
    },
    {
      "value": "Holy See",
      "key": "Holy See"
    },
    {
      "value": "Honduras",
      "key": "Honduras"
    },
    {
      "value": "Hong Kong",
      "key": "Hong Kong"
    },
    {
      "value": "Hungary",
      "key": "Hungary"
    },
    {
      "value": "Iceland",
      "key": "Iceland"
    },
    {
      "value": "India",
      "key": "India"
    },
    {
      "value": "Indonesia",
      "key": "Indonesia"
    },
    {
      "value": "Iran (Islamic Republic of)",
      "key": "Iran (Islamic Republic of)"
    },
    {
      "value": "Iraq",
      "key": "Iraq"
    },
    {
      "value": "Ireland",
      "key": "Ireland"
    },
    {
      "value": "Isle of Man",
      "key": "Isle of Man"
    },
    {
      "value": "Israel",
      "key": "Israel"
    },
    {
      "value": "Italy",
      "key": "Italy"
    },
    {
      "value": "Jamaica",
      "key": "Jamaica"
    },
    {
      "value": "Japan",
      "key": "Japan"
    },
    {
      "value": "Jersey",
      "key": "Jersey"
    },
    {
      "value": "Jordan",
      "key": "Jordan"
    },
    {
      "value": "Kazakhstan",
      "key": "Kazakhstan"
    },
    {
      "value": "Kenya",
      "key": "Kenya"
    },
    {
      "value": "Kiribati",
      "key": "Kiribati"
    },
    {
      "value": "Korea (Democratic People's Republic of)",
      "key": "Korea (Democratic People's Republic of)"
    },
    {
      "value": "Korea, Republic of",
      "key": "Korea, Republic of"
    },
    {
      "value": "Kuwait",
      "key": "Kuwait"
    },
    {
      "value": "Kyrgyzstan",
      "key": "Kyrgyzstan"
    },
    {
      "value": "Lao People's Democratic Republic",
      "key": "Lao People's Democratic Republic"
    },
    {
      "value": "Latvia",
      "key": "Latvia"
    },
    {
      "value": "Lebanon",
      "key": "Lebanon"
    },
    {
      "value": "Lesotho",
      "key": "Lesotho"
    },
    {
      "value": "Liberia",
      "key": "Liberia"
    },
    {
      "value": "Libya",
      "key": "Libya"
    },
    {
      "value": "Liechtenstein",
      "key": "Liechtenstein"
    },
    {
      "value": "Lithuania",
      "key": "Lithuania"
    },
    {
      "value": "Luxembourg",
      "key": "Luxembourg"
    },
    {
      "value": "Macao",
      "key": "Macao"
    },
    {
      "value": "Madagascar",
      "key": "Madagascar"
    },
    {
      "value": "Malawi",
      "key": "Malawi"
    },
    {
      "value": "Malaysia",
      "key": "Malaysia"
    },
    {
      "value": "Maldives",
      "key": "Maldives"
    },
    {
      "value": "Mali",
      "key": "Mali"
    },
    {
      "value": "Malta",
      "key": "Malta"
    },
    {
      "value": "Marshall Islands",
      "key": "Marshall Islands"
    },
    {
      "value": "Martinique",
      "key": "Martinique"
    },
    {
      "value": "Mauritania",
      "key": "Mauritania"
    },
    {
      "value": "Mauritius",
      "key": "Mauritius"
    },
    {
      "value": "Mayotte",
      "key": "Mayotte"
    },
    {
      "value": "Mexico",
      "key": "Mexico"
    },
    {
      "value": "Micronesia (Federated States of)",
      "key": "Micronesia (Federated States of)"
    },
    {
      "value": "Moldova, Republic of",
      "key": "Moldova, Republic of"
    },
    {
      "value": "Monaco",
      "key": "Monaco"
    },
    {
      "value": "Mongolia",
      "key": "Mongolia"
    },
    {
      "value": "Montenegro",
      "key": "Montenegro"
    },
    {
      "value": "Montserrat",
      "key": "Montserrat"
    },
    {
      "value": "Morocco",
      "key": "Morocco"
    },
    {
      "value": "Mozambique",
      "key": "Mozambique"
    },
    {
      "value": "Myanmar",
      "key": "Myanmar"
    },
    {
      "value": "Namibia",
      "key": "Namibia"
    },
    {
      "value": "Nauru",
      "key": "Nauru"
    },
    {
      "value": "Nepal",
      "key": "Nepal"
    },
    {
      "value": "Netherlands",
      "key": "Netherlands"
    },
    {
      "value": "New Caledonia",
      "key": "New Caledonia"
    },
    {
      "value": "New Zealand",
      "key": "New Zealand"
    },
    {
      "value": "Nicaragua",
      "key": "Nicaragua"
    },
    {
      "value": "Niger",
      "key": "Niger"
    },
    {
      "value": "Nigeria",
      "key": "Nigeria"
    },
    {
      "value": "Niue",
      "key": "Niue"
    },
    {
      "value": "Norfolk Island",
      "key": "Norfolk Island"
    },
    {
      "value": "North Macedonia",
      "key": "North Macedonia"
    },
    {
      "value": "Northern Mariana Islands",
      "key": "Northern Mariana Islands"
    },
    {
      "value": "Norway",
      "key": "Norway"
    },
    {
      "value": "Oman",
      "key": "Oman"
    },
    {
      "value": "Pakistan",
      "key": "Pakistan"
    },
    {
      "value": "Palau",
      "key": "Palau"
    },
    {
      "value": "Palestine, State of",
      "key": "Palestine, State of"
    },
    {
      "value": "Panama",
      "key": "Panama"
    },
    {
      "value": "Papua New Guinea",
      "key": "Papua New Guinea"
    },
    {
      "value": "Paraguay",
      "key": "Paraguay"
    },
    {
      "value": "Peru",
      "key": "Peru"
    },
    {
      "value": "Philippines",
      "key": "Philippines"
    },
    {
      "value": "Pitcairn",
      "key": "Pitcairn"
    },
    {
      "value": "Poland",
      "key": "Poland"
    },
    {
      "value": "Portugal",
      "key": "Portugal"
    },
    {
      "value": "Puerto Rico",
      "key": "Puerto Rico"
    },
    {
      "value": "Qatar",
      "key": "Qatar"
    },
    {
      "value": "Romania",
      "key": "Romania"
    },
    {
      "value": "Russian Federation",
      "key": "Russian Federation"
    },
    {
      "value": "Rwanda",
      "key": "Rwanda"
    },
    {
      "value": "Réunion",
      "key": "Réunion"
    },
    {
      "value": "Saint Barthélemy",
      "key": "Saint Barthélemy"
    },
    {
      "value": "Saint Helena, Ascension and Tristan da Cunha",
      "key": "Saint Helena, Ascension and Tristan da Cunha"
    },
    {
      "value": "Saint Kitts and Nevis",
      "key": "Saint Kitts and Nevis"
    },
    {
      "value": "Saint Lucia",
      "key": "Saint Lucia"
    },
    {
      "value": "Saint Martin (French part)",
      "key": "Saint Martin (French part)"
    },
    {
      "value": "Saint Pierre and Miquelon",
      "key": "Saint Pierre and Miquelon"
    },
    {
      "value": "Saint Vincent and the Grenadines",
      "key": "Saint Vincent and the Grenadines"
    },
    {
      "value": "Samoa",
      "key": "Samoa"
    },
    {
      "value": "San Marino",
      "key": "San Marino"
    },
    {
      "value": "Sao Tome and Principe",
      "key": "Sao Tome and Principe"
    },
    {
      "value": "Saudi Arabia",
      "key": "Saudi Arabia"
    },
    {
      "value": "Senegal",
      "key": "Senegal"
    },
    {
      "value": "Serbia",
      "key": "Serbia"
    },
    {
      "value": "Seychelles",
      "key": "Seychelles"
    },
    {
      "value": "Sierra Leone",
      "key": "Sierra Leone"
    },
    {
      "value": "Singapore",
      "key": "Singapore"
    },
    {
      "value": "Sint Maarten (Dutch part)",
      "key": "Sint Maarten (Dutch part)"
    },
    {
      "value": "Slovakia",
      "key": "Slovakia"
    },
    {
      "value": "Slovenia",
      "key": "Slovenia"
    },
    {
      "value": "Solomon Islands",
      "key": "Solomon Islands"
    },
    {
      "value": "Somalia",
      "key": "Somalia"
    },
    {
      "value": "South Africa",
      "key": "South Africa"
    },
    {
      "value": "South Georgia and the South Sandwich Islands",
      "key": "South Georgia and the South Sandwich Islands"
    },
    {
      "value": "South Sudan",
      "key": "South Sudan"
    },
    {
      "value": "Spain",
      "key": "Spain"
    },
    {
      "value": "Sri Lanka",
      "key": "Sri Lanka"
    },
    {
      "value": "Sudan",
      "key": "Sudan"
    },
    {
      "value": "Suriname",
      "key": "Suriname"
    },
    {
      "value": "Svalbard and Jan Mayen",
      "key": "Svalbard and Jan Mayen"
    },
    {
      "value": "Sweden",
      "key": "Sweden"
    },
    {
      "value": "Switzerland",
      "key": "Switzerland"
    },
    {
      "value": "Syrian Arab Republic",
      "key": "Syrian Arab Republic"
    },
    {
      "value": "Taiwan, Province of China",
      "key": "Taiwan, Province of China"
    },
    {
      "value": "Tajikistan",
      "key": "Tajikistan"
    },
    {
      "value": "Tanzania, United Republic of",
      "key": "Tanzania, United Republic of"
    },
    {
      "value": "Thailand",
      "key": "Thailand"
    },
    {
      "value": "Timor-Leste",
      "key": "Timor-Leste"
    },
    {
      "value": "Togo",
      "key": "Togo"
    },
    {
      "value": "Tokelau",
      "key": "Tokelau"
    },
    {
      "value": "Tonga",
      "key": "Tonga"
    },
    {
      "value": "Trinidad and Tobago",
      "key": "Trinidad and Tobago"
    },
    {
      "value": "Tunisia",
      "key": "Tunisia"
    },
    {
      "value": "Turkey",
      "key": "Turkey"
    },
    {
      "value": "Turkmenistan",
      "key": "Turkmenistan"
    },
    {
      "value": "Turks and Caicos Islands",
      "key": "Turks and Caicos Islands"
    },
    {
      "value": "Tuvalu",
      "key": "Tuvalu"
    },
    {
      "value": "Uganda",
      "key": "Uganda"
    },
    {
      "value": "Ukraine",
      "key": "Ukraine"
    },
    {
      "value": "United Arab Emirates",
      "key": "United Arab Emirates"
    },
    {
      "value": "United Kingdom of Great Britain and Northern Ireland",
      "key": "United Kingdom of Great Britain and Northern Ireland"
    },
    {
      "value": "United States Minor Outlying Islands",
      "key": "United States Minor Outlying Islands"
    },
    {
      "value": "United States of America",
      "key": "United States of America"
    },
    {
      "value": "Uruguay",
      "key": "Uruguay"
    },
    {
      "value": "US",
      "key": "US"
    },
    {
      "value": "Uzbekistan",
      "key": "Uzbekistan"
    },
    {
      "value": "Vanuatu",
      "key": "Vanuatu"
    },
    {
      "value": "Venezuela (Bolivarian Republic of)",
      "key": "Venezuela (Bolivarian Republic of)"
    },
    {
      "value": "Viet Nam",
      "key": "Viet Nam"
    },
    {
      "value": "Virgin Islands (British)",
      "key": "Virgin Islands (British)"
    },
    {
      "value": "Virgin Islands (U.S.)",
      "key": "Virgin Islands (U.S.)"
    },
    {
      "value": "Wallis and Futuna",
      "key": "Wallis and Futuna"
    },
    {
      "value": "Western Sahara",
      "key": "Western Sahara"
    },
    {
      "value": "Yemen",
      "key": "Yemen"
    },
    {
      "value": "Zambia",
      "key": "Zambia"
    },
    {
      "value": "Zimbabwe",
      "key": "Zimbabwe"
    }
  ]
}