import graphql from "./graphql";

const StepData = {
  // STEP DETAILS

  get: async () =>
    graphql.query(
      `query MyQuery {
      UserInformation_by_pk(username: "%(cognitoUserName)s") {
          age
          annual_income
          applicants
          asset_percentage
          dependents
          first_name
          invest_amount
          investmentProfile
          investment_duration
          account_type
          last_name
          liquid_net_worth
          marital_status
          risk_score
          total_net_worth
          username
          trustedContactForm
          appl_ip
          email
          social_security_num
          date_of_birth
          active_step
          citizenship
          phone_number
          forms
      }
    }`
    ),

  update: async (updatefields, object) =>
    graphql.queryN(
      `mutation MyMutation(
        $investmentProfile: jsonb,
        $applicants:jsonb,
        $trustedContactForm: jsonb
        ) {
          update_UserInformation(where: {username: {_eq: "%(cognitoUserName)s"}},
          _set: {
          ${updatefields}
          }) {
              returning {
                username
              }
          }
        }`,
      {
        investmentProfile: object.investmentProfile,
        applicants: object.applicants,
        trustedContactForm: object.trustedContactForm,
        forms: object.forms,
      }
    ),

  updateN: async (updatefields, object) => {
    const params =
      (object.investmentProfile ? "$investmentProfile: jsonb," : "") +
      (object.applicants ? "$applicants: jsonb," : "") +
      (object.trustedContactForm ? "$trustedContactForm: jsonb," : "") +
      (object.forms ? "$forms: jsonb," : "");
    await graphql.queryN(
      `mutation MyMutation ${params ? "(" + params + ")" : ""}
        {
          update_UserInformation(where: {username: {_eq: "%(cognitoUserName)s"}},
          _set: {
          ${updatefields}
          }) {
              returning {
                username
              }
          }
        }`,
      object
    );
  },
};

export default StepData;
