import React from "react";
import { useParams, Navigate } from "react-router-dom";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import Step7 from "./Step7";
import Step8 from "./Step8";
import Step9 from "./Step9";

export const RoutePath = {
  1: <Step1 />,
  2: <Step2 />,
  3: <Step3 />,
  4: <Step4 />,
  5: <Step5 />,
  6: <Step6 />,
  7: <Step7 />,
  8: <Step8 />,
};

const StepRouter = (props) => {
  const { id } = useParams();
  if (props.accountType !== "individual") {
    RoutePath[9] = <Step9 />;
  }
  return RoutePath[id] || <Navigate to="/portfolio" />;
};
export default StepRouter;

export const getMaxSteps = (accountType) => {
  if (accountType && accountType !== "individual") return 9;
  return 8;
};
