import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Grid } from "@mui/material";
import "./funding.scss";
import Addfund from "./Addfund";

const Addfunds = () => {
  const navigate = useNavigate();
  const handleSubmit = (id, amount) => {
    navigate("/confirm_deposit_ext", {
      state: { id, amount },
    });
  };
  const handleRelease = () => {
    navigate("/accountstatus");
  };

  return (
    <div className="add-funds AddFundsPageContentHeight">
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={10} sm={8} md={8} lg={6} className="block-white">
            <div className="fundingbasic size accountstatus">
              <h3 id="addfunds_title">Add funds</h3>
              <Addfund
                handleSubmit={handleSubmit}
                handleRelease={handleRelease}
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Addfunds;
