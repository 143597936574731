import React, { useState, useEffect } from "react";
import { Grid, CircularProgress } from "@mui/material";
import PropTypes from "prop-types";
import AppBackService from "../services/app-back";
import ChartAllTV from "../charts/chart-all-tv";
import ChartApexBasicTV from "../charts/chart-basic-tv";

const Performance = ({ blockId }) => {
  const Appback = AppBackService();
  const [performanceData, setPerformanceData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getBlockPerformanceData = async () => {
    const { returns } = await Appback.performanceBlockData(blockId);
    setPerformanceData(returns);
    setIsLoading(false);
  };

  useEffect(() => {
    if (blockId) {
      setIsLoading(true);
      getBlockPerformanceData();
    }
  }, [blockId]);

  return (
    <div className="account-holdings" style={{ overflow: "hidden" }}>
      <Grid container spacing={7}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {!isLoading ? (
            <>
              <ChartApexBasicTV performance={performanceData} />
              <ChartAllTV performance={performanceData} />
            </>
          ) : (
            <div
              style={{
                paddingTop: "30px",
                margin: "auto",
                width: "fit-content",
              }}
            >
              <CircularProgress />
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};
Performance.propTypes = {
  blockId: PropTypes.string.isRequired,
};

export default Performance;
