import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Container, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import StepTitle from "../components/StepTitle";
import BeneficiaryForm from "../components/BeneficiaryForm";
import StepData from "../services/step-data";
import StepButtons from "../components/StepButtons";
import { getMaxSteps } from ".";

const CURRENT_STEP = 9;
const theme = createTheme();
const useStyles = makeStyles(() => ({
  form: {
    marginTop: theme.spacing(9),
  },
  error: {
    color: "red !important",
    textAlign: "left !important",
  },
  labelText: {
    lineHeight: "1.2 !important",
  },
}));
const Step9 = () => {
  const classes = useStyles();
  const methods = useForm({
    defaultValues: {
      applicants: [
        {
          contact: {},
        },
      ],
    },
  });
  const { reset } = methods;
  const navigate = useNavigate();
  const [maxStep, setMaxStep] = useState(0);
  const [beneficiaryData, setBeneficiaryData] = useState([]);
  const handleGetDetails = async () => {
    const result = await StepData.get();
    if (result.data && result.data.UserInformation_by_pk) {
      const res = result.data.UserInformation_by_pk;
      if (
        res.forms &&
        res.forms.beneficiaries &&
        res.forms.beneficiaries.length > 0
      ) {
        setBeneficiaryData(res.forms.beneficiaries);
        setMaxStep(getMaxSteps(res.account_type));
      } else {
        setMaxStep(getMaxSteps(res.account_type));
      }
    }
  };

  useEffect(() => {
    handleGetDetails();
  }, []);
  const handleBenificiary = (BeneficiaryData) => {
    setBeneficiaryData(BeneficiaryData);
  };
  const handleUpdateDetails = async (
    beneficiaries,
    stepRoute = "clientagreement",
    activeStep = CURRENT_STEP
  ) => {
    const updatingValue = {
      forms: { beneficiaries },
    };

    await StepData.updateN(
      `forms: $forms,
        active_step: ${activeStep}`,
      updatingValue
    );
    navigate(`/${stepRoute}`);
    reset({ ...beneficiaries });
  };
  const onBack = () => {
    handleUpdateDetails(
      beneficiaryData,
      `step/${CURRENT_STEP - 1}`,
      CURRENT_STEP - 1
    );
  };
  return (
    <div className="step1">
      <br />

      <Container className={classes.form} style={{ marginTop: "0px" }}>
        <Grid container spacing={6}>
          <Grid item xs={10} sm={8} lg={6} className="stepsize">
            <StepTitle
              currentStep={CURRENT_STEP}
              titleText="Beneficiary"
              maxStep={maxStep}
            />
          </Grid>
        </Grid>
        <BeneficiaryForm
          onBenificiary={handleBenificiary}
          onSubmit={handleUpdateDetails}
          Beneficiaries={beneficiaryData}
          setting_button_flip=""
          StepButtons={<StepButtons onBack={onBack} />}
        />
      </Container>
    </div>
  );
};

export default Step9;
