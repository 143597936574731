import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import { Grid } from "@mui/material";
import PersonalDetails from "../components/PersonalDetails";
import ContactInfo from "../components/ContactInfo";
import { EmploymentDetails, Employer } from "../components/EmploymentInfo";
import ProfileInfo from "../components/ProfileInfo";
import DialogComponent from "../components/DialogComponent";

const YES = "YES";
const Profile = forwardRef((props, ref) => {
  const methods = useForm({
    defaultValues: {
      applicants: [
        {
          details: {},
          contact: {},
          employment: {},
          officeAddress: {},
        },
      ],
    },
  });
  const { control, handleSubmit, setValue, formState, reset } = methods;
  const { fields } = useFieldArray({
    control,
    name: "applicants",
  });
  const [values, setValues] = useState({
    applicants: [],
    conditions: YES,
  });
  const getMaskedValues = (maskedVal) => maskedVal.split("*-").join("");
  const [contactInfoData, setContactInfoData] = useState({});
  const [employementDetailsData, setEmployementDetailsData] = useState({});
  const [citizenship, setCitizenship] = useState("");
  const [dailogOpen, setDailogOpen] = useState(false);
  const [personalDetails, setPersonalDetails] = useState({});
  const [isfromSetting, setFromSetting] = useState(true);

  const handleGetDetails = (data) => {
    const res = data;
    setValues({
      ...values,
      applicants: res.applicants,
    });
    setPersonalDetails({
      dateofbirth: res.date_of_birth
        ? `__-__-${getMaskedValues(res.date_of_birth || "")}`
        : null,
      socialSecurityNumber: res.social_security_num
        ? `___-__-${getMaskedValues(res.social_security_num || "")}`
        : null,
    });

    setCitizenship(res.citizenship);
    setContactInfoData({
      phoneNumber: res.phoneNumber,
      address: res.applicants && res.applicants[0].contact?.homeAddress,
    });
    setEmployementDetailsData({
      status: res.applicants && res.applicants[0].employment?.employmentStatus,
      employer: res.applicants && res.applicants[0].employment?.employer,
    });
    const accountType = accountTypeOptions.filter(
      (x) => x.value === res.account_type
    );
    setValue("accountType", accountType[0].key);
    setFromSetting(res.isfromSetting);
  };
  const { isDirty } = formState;
  useEffect(() => {
    if (isDirty) {
      if (props.onEnableButtons) props.onEnableButtons(true);
    }
    if (!isDirty && props.userInfoData) {
      handleGetDetails(props.userInfoData);
    }
  }, [props.userInfoData, isDirty]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleEmpChange = (e) => {
    setEmployementDetailsData({
      ...employementDetailsData,
      status: e,
    });
  };

  const getEmployeeJsx = (index, name) => {
    let employeeJSX = null;
    if (employementDetailsData.status === "employed") {
      employeeJSX = (
        <Employer data={employementDetailsData} name={name} index={index} />
      );
    }
    return employeeJSX;
  };

  const getEmployementData = (data) => {
    const employementData = {};
    if (employementDetailsData.status === "employed") {
      employementData.employer = data.employment.employer;
    }
    employementData.employmentStatus = data.employment.employmentStatus;
    return employementData;
  };
  const getAddressData = ({ streetAddress, streetAddress1, ...rest }) => ({
    ...rest,
    country: "USA",
    streetAddress: [streetAddress, streetAddress1],
  });

  const getApplicantsData = (data) => {
    const resApplicantData = (values.applicants && values.applicants[0]) || {
      employment: {},
      contact: {},
    };
    const formApplicantData = (data.applicants && data.applicants[0]) || {};
    const applicantData = { ...resApplicantData };
    applicantData.employment = getEmployementData(formApplicantData);
    applicantData.contact.homeAddress = getAddressData(
      formApplicantData?.contact?.address
    );
    return [applicantData];
  };
  const onSubmit = (data) => {
    if (values.conditions === "YES") {
      let dob = "";
      let ssn = "";
      const applicationData = getApplicantsData(data);
      if (formState?.dirtyFields?.dateofbirth) {
        dob = ` date_of_birth: "${data.dateofbirth}"`;
      }
      if (formState?.dirtyFields?.socialSecurityNumber) {
        ssn = ` social_security_num: "${data.socialSecurityNumber}"`;
      }
      data.dob = dob;
      data.ssn = ssn;
      if (!data.citizenship) {
        data.citizenship = citizenship;
      }
      data.applicationData = applicationData;
      props.onSubmit(data);
    } else setDailogOpen(true);
  };
  const onBackSubmit = () => {
    /* TODO document why this arrow function is empty */
  };
  useImperativeHandle(ref, () => ({
    onBack(isfrom) {
      if (!isfrom) {
        handleSubmit(onBackSubmit)();
        if (
          formState.dirtyFields?.applicants ||
          formState.dirtyFields?.dateofbirth ||
          formState.dirtyFields?.socialSecurityNumber ||
          formState.errors?.dateofbirth ||
          formState.errors?.socialSecurityNumber
        ) {
          props.onBackConfirm(true);
        } else if (formState.submitCount === 0) {
          props.onBackConfirm(false);
        }
      } else {
        reset();
        props.cancelHandle();
      }
    },
  }));
  const accountTypeOptions = [
    { value: "individual", key: "Individual" },
    { value: "traditional_ira", key: "Traditional IRA" },
    { value: "roth_ira", key: "Roth IRA" },
    { value: "rollover_ira", key: "Rollover IRA" },
    // { value: "joint_account", key: "Joint account" },
  ];
  const handleRadioChange = (value) => {
    setValues({
      ...values,
      conditions: value,
    });
    if (value === "NO") setDailogOpen(true);
  };
  const handleKeyUp = () => {
    if (props.onEnableButtons) props.onEnableButtons(true);
  };
  return (
    <div>
      <br />
      <Grid container spacing={6}>
        <Grid item xs={10} sm={8} lg={6} className="stepsize">
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              onKeyUp={() => handleKeyUp()}
              onChange={() => handleKeyUp()}
            >
              {fields.map((item, index) => (
                <div key={item.id}>
                  {!isfromSetting && <PersonalDetails data={personalDetails} />}
                  {isfromSetting && (
                    <ProfileInfo
                      onChange={handleRadioChange}
                      data={citizenship}
                      bacctId={props.bacctId}
                    />
                  )}
                  <ContactInfo
                    data={contactInfoData}
                    name={`applicants[${index}].contact`}
                    index={index}
                  />
                  <EmploymentDetails
                    data={employementDetailsData}
                    name={`applicants[${index}].employment`}
                    index={index}
                    onChange={handleEmpChange}
                  />
                  {getEmployeeJsx(index, `applicants[${index}].employment`)}
                </div>
              ))}
              {props.StepButtons}
            </form>
          </FormProvider>
        </Grid>
      </Grid>
      <DialogComponent dailogOpen={dailogOpen} setDailogOpen={setDailogOpen}>
        <p>
          We&apos;re sorry but our brokerage partner can not create an account
          for you at this time, if you aren&apos;t a permanent US Resident or a
          US Citizen.
        </p>{" "}
        <p>
          Please send email to us at{" "}
          <a href="mailto:support@99rises.com">support@99rises.com</a> with any
          questions.
        </p>
      </DialogComponent>
    </div>
  );
});

export default Profile;
