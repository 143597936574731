import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import moment from "moment";
import {
  LineChart,
  Line,
  XAxis,
  Tooltip,
  CartesianGrid,
  YAxis,
} from "recharts";
import PropTypes from "prop-types";

function Cumulative(props) {
  const { perfChart } = props;
  const [filterValues, setFilterValues] = React.useState("all");
  const [chartData, setChartData] = useState([]);
  const [headerTitle, setHeaderTitle] = React.useState("PERFORMANCE");

  useEffect(() => {
    if (perfChart) {
      const lstData = [];
      perfChart.map((item) => {
        const chat = {
          date: moment(item.date).format("MMM YY"),
          value: Number(item.td.toFixed(1)),
        };
        lstData.push(chat);
      });
      setChartData(lstData);
    }
  }, [perfChart]);

  const ytd = () => {
    setHeaderTitle("YEAR TO DATE PERFORMANCE");
    const newDate = new Date();
    const date = moment(newDate).format("YYYY-MM-DD");
    const year = moment(newDate).format("YYYY");
    const filterData = perfChart.filter(
      (x) => x.date >= `${year}-01-01` && x.date <= date
    );
    chartData.splice(0, chartData.length);
    const lstData = [];
    filterData.map((item) => {
      const chat = {
        date: moment(item.date).format("MMM YY"),
        value: Number(item.td.toFixed(1)),
      };
      lstData.push(chat);
    });
    setChartData(lstData);
  };

  const handleFormat = (event, newValue) => {
    setFilterValues(newValue);
    if (event.target.value === "ytd") {
      ytd();
    } else {
      setHeaderTitle("ALL TIME PERFORMANCE");
      const lstData = [];
      perfChart.map((item) => {
        const chat = {
          date: moment(item.date).format("MMM YY"),
          value: Number(item.td.toFixed(1)),
        };
        lstData.push(chat);
      });
      setChartData(lstData);
    }
  };

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      container
      direction="row"
      className="border-top"
    >
      <Card className="cumulative">
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            className="head"
          >
            {headerTitle}
            <div id="block-wrap">
              <div className="legend">
                <ToggleButtonGroup
                  value={filterValues}
                  exclusive
                  onChange={handleFormat}
                >
                  <ToggleButton value="ytd">YTD</ToggleButton>
                  <ToggleButton value="all">ALL</ToggleButton>
                </ToggleButtonGroup>
              </div>
            </div>
          </Typography>
          <Typography variant="body2" component="span">
            <BindLineChart chartData={chartData} />
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}
Cumulative.propTypes = {
  perfChart: PropTypes.instanceOf(Array).isRequired,
};

const BindLineChart = ({ chartData }) => {
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p
            className="label"
            style={{ textAlign: "center" }}
          >{`${payload[0].value}%`}</p>
        </div>
      );
    }

    return null;
  };
  return (
    <>
      <LineChart
        width={600}
        height={400}
        data={chartData}
        margin={{ top: 40, right: 100, bottom: 20, left: 0 }}
      >
        <CartesianGrid vertical={false} />
        <XAxis dataKey="date" angle="330" />
        <YAxis type="number" unit="%" />
        <Tooltip content={<CustomTooltip />} />
        <defs>
          <linearGradient id="colorUv" x1="0%" y1="0" x2="100%" y2="0">
            <stop offset="0%" stopColor="#00A3FF" />
            <stop offset={`${100}%`} stopColor="#27E55D" />
          </linearGradient>
        </defs>
        <Line
          strokeLinecap="round"
          dataKey="value"
          stroke="url(#colorUv)"
          dot={false}
          strokeWidth="5"
        />
        <CartesianGrid fill="red" />
      </LineChart>
    </>
  );
};

export default Cumulative;
