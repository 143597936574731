import React from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import Login from "../identity/Login";
import SignUp from "../identity/SignUp";
import ForgotPassword from "../identity/ForgotPassword";
import Portfolio from "../portfolio/Portfolio";
import Settings from "../settings/Setting";
import StepRouter from "../onboarding";
import Accountstatus from "../onboarding/Accountstatus";
import Clientagreement from "../onboarding/Clientagreement";
import Stepsinfo from "../onboarding/Stepsinfo";
import Addfunds from "../funding/Addfunds";
import ConfirmAddfunds from "../funding/ConfirmAddfunds";
import AddfundsStatus from "../funding/AddfundsStatus";
import LinkaBankAcc from "../funding/LinkaBankAcc";
import VerifyExtAcct from "../funding/VerifyExtAcct";
import Wiretransfer from "../funding/Wiretransfer";
import EmailVerify from "../identity/EmailVerify";
import Documents from "../settings/Documents";
import WithdrawFunds from "../funding/WithdrawFunds";
import ConfirmWithdraw from "../funding/ConfirmWithdraw";
import WithdrawStatus from "../funding/WithdrawStatus";
import ZeroOutWithdrawStatus from "../funding/ZeroOutWithdrawStatus";
import LTAAccepted from "../onboarding/LTAAccepted";
import Blocks from "../blocks/blocks";
import Index from "../blocks/index";
import Cards from "../cards/cards";
import Addinvests from "../funding/Addinvests";
import Cashmanager from "../funding/Cashmanager";
import Ewa from "../ewa";
import Settlement from "../portfolio/Settlement";

function UnAuthRoutes() {
  const location = useLocation();
  return (
    <Routes>
      <Route
        exact
        path="/login"
        element={<Login state={{ prev: location }} />}
      />
      <Route exact path="/signup" element={<SignUp />} />
      <Route exact path="/forgot-password" element={<ForgotPassword />} />
      <Route exact path="/email_verify" element={<EmailVerify />} />
      <Route exact path="/ewatest" element={<Ewa />} />
      <Route exact path="/license_success" element={<LTAAccepted />} />
      <Route
        path="*"
        element={
          <Navigate to="/login" replace={true} state={{ prev: location }} />
        }
      />
    </Routes>
  );
}
export { UnAuthRoutes };

function InterviewRoutes(redirect, accountType) {
  return (
    <Routes onUpdate={window.scrollTo(0, 0)}>
      <Route
        exact
        path="/step/:id"
        element={<StepRouter accountType={accountType} />}
      />
      <Route exact path="/license_success" element={<LTAAccepted />} />
      <Route exact path="/clientagreement" element={<Clientagreement />} />
      <Route exact path="/stepsinfo" element={<Stepsinfo />} />
      <Route exact path="*" element={<Navigate to={`${redirect}`} />} />
    </Routes>
  );
}
export { InterviewRoutes };

function AccountActiveRoutes(redirect, accountType, accountStatus) {
  return (
    <Routes>
      <Route exact path="/settings" element={<Settings />} />

      <Route
        exact
        path="/portfolio"
        element={
          accountStatus === "account_paused" ? <Settlement /> : <Portfolio />
        }
      />

      <Route exact path="/documents" element={<Documents />} />
      <Route exact path="/license_success" element={<LTAAccepted />} />
      {accountType === "individual" && (
        <Route path="/deposit_ext" element={<Addfunds />} />
      )}
      <Route path="/verifyextacct" element={<VerifyExtAcct />} />
      <Route path="/linkextacct" element={<LinkaBankAcc />} />
      {accountType === "individual" && (
        <Route
          exact
          path="/confirm_deposit_ext"
          element={<ConfirmAddfunds />}
        />
      )}
      <Route exact path="/deposit_ext_status" element={<AddfundsStatus />} />
      <Route exact path="/wiretransfer" element={<Wiretransfer />} />
      {accountType === "individual" && (
        <Route exact path="/withdrawfunds" element={<WithdrawFunds />} />
      )}
      {accountType === "individual" && (
        <Route exact path="/confirmwithdraw" element={<ConfirmWithdraw />} />
      )}
      <Route exact path="/withdrawstatus" element={<WithdrawStatus />} />
      <Route
        exact
        path="/zerooutwithdrawstatus"
        element={<ZeroOutWithdrawStatus />}
      />
      <Route exact path="/accountstatus" element={<Accountstatus />} />
      <Route exact path="/blocks" element={<Blocks />} />
      <Route exact path="/blocklist" element={<Index />} />
      <Route exact path="/cards" element={<Cards />} />
      <Route path="/deposit_blocks" element={<Addinvests />} />
      <Route path="/deposit" element={<Cashmanager />} />
      <Route exact path="*" element={<Navigate to={`${redirect}`} />} />
    </Routes>
  );
}
export { AccountActiveRoutes };

function AccountSubmittedRoutes(redirect, accountType, isEWA) {
  return (
    <Routes>
      <Route exact path="/accountstatus" element={<Accountstatus />} />
      <Route exact path="/settings" element={<Settings />} />
      <Route exact path="/license_success" element={<LTAAccepted />} />
      {accountType === "individual" && (
        <Route path="/deposit_ext" element={<Addfunds />} />
      )}
      {accountType === "individual" && (
        <Route
          exact
          path="/confirm_deposit_ext"
          element={<ConfirmAddfunds />}
        />
      )}
      <Route exact path="/deposit_ext_status" element={<AddfundsStatus />} />
      <Route exact path="/wiretransfer" element={<Wiretransfer />} />
      <Route path="/verifyextacct" element={<VerifyExtAcct />} />
      <Route path="/linkextacct" element={<LinkaBankAcc />} />
      {isEWA && <Route path="/deposit" element={<Cashmanager />} />}
      <Route exact path="*" element={<Navigate to={`${redirect}`} />} />
    </Routes>
  );
}
export { AccountSubmittedRoutes };

function TestAccountRoutes(redirect, accountStatus, accountType) {
  return (
    <Routes onUpdate={window.scrollTo(0, 0)}>
      <Route
        exact
        path="/step/:id"
        element={<StepRouter accountType={accountType} />}
      />
      <Route exact path="/license_success" element={<LTAAccepted />} />
      <Route exact path="/accountstatus" element={<Accountstatus />} />
      <Route exact path="/clientagreement" element={<Clientagreement />} />
      <Route exact path="/stepsinfo" element={<Stepsinfo />} />

      <Route exact path="/settings" element={<Settings />} />

      {accountType === "individual" && (
        <Route path="/deposit_ext" element={<Addfunds />} />
      )}
      <Route path="/verifyextacct" element={<VerifyExtAcct />} />
      <Route path="/linkextacct" element={<LinkaBankAcc />} />
      {accountType === "individual" && (
        <Route
          exact
          path="/confirm_deposit_ext"
          element={<ConfirmAddfunds />}
        />
      )}
      <Route
        exact
        path="/portfolio"
        element={
          accountStatus === "account_paused" ? <Settlement /> : <Portfolio />
        }
      />
      <Route exact path="/documents" element={<Documents />} />
      <Route exact path="/deposit_ext_status" element={<AddfundsStatus />} />
      <Route exact path="/wiretransfer" element={<Wiretransfer />} />
      {accountType === "individual" && (
        <Route exact path="/withdrawfunds" element={<WithdrawFunds />} />
      )}
      {accountType === "individual" && (
        <Route exact path="/confirmwithdraw" element={<ConfirmWithdraw />} />
      )}
      <Route exact path="/withdrawstatus" element={<WithdrawStatus />} />
      <Route
        exact
        path="/zerooutwithdrawstatus"
        element={<ZeroOutWithdrawStatus />}
      />
      <Route exact path="/blocks" element={<Blocks />} />
      <Route exact path="/blocklist" element={<Index />} />
      <Route exact path="/cards" element={<Cards />} />
      <Route path="/deposit_blocks" element={<Addinvests />} />
      <Route path="/deposit" element={<Cashmanager />} />
      <Route exact path="/iostest" element={<Ewa />} />
      <Route exact path="*" element={<Navigate to={`${redirect}`} />} />
    </Routes>
  );
}
export { TestAccountRoutes };

function AccountFundedRoutes(redirect) {
  return (
    <Routes>
      <Route exact path="/accountstatus" element={<Accountstatus />} />
      <Route exact path="/settings" element={<Settings />} />
      <Route exact path="*" element={<Navigate to={`${redirect}`} />} />
    </Routes>
  );
}
export { AccountFundedRoutes };
