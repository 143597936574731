import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import AppBackService from "../services/app-back";
import Service from "../services/hservice";
import { numberFormat } from "../utils/numbers";

function Settlement() {
  const [acctBal, setAcctBal] = useState("");
  const Appback = AppBackService();
  const Styles = {
    textTable: {
      marginTop: "100px",
      backgroundColor: "#fff",
      border: "1px solid #ccc",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
      padding: "30px",
    },
    acctBalance: {
      fontWeight: "bold",
      display: "inline",
    },
  };

  const getAcctBal = async () => {
    const result = await Service.getAccountId();
    const acctId = result?.data?.Account[0].id;
    const acctBal = await Appback.getAcctBal(acctId);
    setAcctBal(acctBal.balance);
  };
  useEffect(() => {
    getAcctBal();
  }, []);

  return (
    <Grid container spacing={6} sx={{ justifyContent: "center" }}>
      <Grid item xs={10} sm={8} md={8} lg={6}>
        <div style={Styles.textTable}>
          <p>
            Your account is in the process of being closed as per communication
            sent to your email of record. After all positions are closed and
            settled, money will be returned via ACH to your linked bank account.
          </p>
          {acctBal && (
            <p>
              Your account balance is{" "}
              <p style={Styles.acctBalance}>{numberFormat(acctBal)}</p>
            </p>
          )}
        </div>

        <br />
        <br />
      </Grid>
    </Grid>
  );
}

export default Settlement;
