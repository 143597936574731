import React, { useState, useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import PropTypes from "prop-types";
import DoughnutChart from "../charts/doughnut-chart";

export const DataTable = ({ data, header }) => {
  const [tableid, setTableid] = useState("bysector_table");
  useEffect(() => {
    if (header !== undefined && header[0] === "Sector") {
      const table = document.getElementById("bysector_table");
      let switching = true;
      let shouldSwitch = false;
      let i = 1;
      while (switching) {
        switching = false;
        const { rows } = table;
        for (i = 1; i < rows.length - 1; i++) {
          shouldSwitch = false;
          const x = rows[i].getElementsByTagName("TD")[0];
          const y = rows[i + 1].getElementsByTagName("TD")[0];
          if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
            shouldSwitch = true;
            break;
          }
        }
        if (shouldSwitch) {
          rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
          switching = true;
        }
      }
    } else {
      setTableid("description");
    }
  }, []);
  return (
    <>
      <table className="aum-table" id={`${tableid}`}>
        <thead>
          <tr>{header && header.map((res) => <td key={res}>{res}</td>)}</tr>
        </thead>
        <tbody>
          {data.map((res) => {
            return res[0] === "Multiple Sector" ||
              res[0] === "Total Gross" ? null : (
              <tr key={res[0]}>
                {res.map((sector) => {
                  return <td key={sector}>{sector}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <table className="totalgross">
        <tbody>
          {data.map((res) => {
            return res[0] === "Multiple Sector" || res[0] === "Total Gross" ? (
              <tr key={res[0]}>
                {res.map((sector) => {
                  return sector === "Multiple Sector" ? (
                    <td key={sector}>Others</td>
                  ) : (
                    <td key={sector}>{sector}</td>
                  );
                })}
              </tr>
            ) : null;
          })}
        </tbody>
      </table>
    </>
  );
};

const SideColumn = ({
  accountSummaryData,
  sectionData = {
    columns: [
      ["Cash", `25`],
      ["Longs", `25`],
      ["Shorts", `25`],
      ["Hedge", `25`],
    ],
  },
  chartSummaryData,
  blockName,
  handleShowTray,
  showTray,
  acctBlocks,
}) => {
  const colors = {
    Cash: "#1685d3",
    Longs: "#26a64a",
    Shorts: "#ff8521",
    Hedge: "#ffd300",
  };
  const tooltip = {
    contents: (d) => `${d[0].id}: ${d[0].value}%`,
  };
  useEffect(() => {}, []);
  const size = { width: 240 };

  const handleTray = () => {
    if (acctBlocks.length > 1) handleShowTray(!showTray);
  };

  return (
    <div className="aum">
      <div>
        <span
          onClick={() => handleTray()}
          style={{ fontSize: "30px", marginRight: "10px", cursor: "pointer" }}
          role="button"
        >
          {blockName}
        </span>
        {acctBlocks.length > 1 && (
          <>
            {blockName && !showTray && (
              <ExpandMoreIcon
                sx={{ cursor: "pointer" }}
                onClick={() => handleShowTray(true)}
              />
            )}
            {blockName && showTray && (
              <ExpandLessIcon
                sx={{ cursor: "pointer" }}
                onClick={() => handleShowTray(false)}
              />
            )}
          </>
        )}
      </div>
      <div className="boldClass">
        <DataTable data={accountSummaryData} />
      </div>

      <hr />
      <div className="portfolio-cards">
        <div className="portfolio-card1">
          <div className="card1">
            <DoughnutChart
              data={sectionData}
              colors={colors}
              id="sectionChart"
              tooltip={tooltip}
              size={size}
            />
          </div>
          <div className="boldClass">
            <DataTable data={chartSummaryData} />
          </div>
          <hr />
        </div>
      </div>
    </div>
  );
};

DataTable.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  header: PropTypes.instanceOf(Array).isRequired,
};

SideColumn.propTypes = {
  accountSummaryData: PropTypes.instanceOf(Array).isRequired,
  sectionData: PropTypes.object.isRequired,
  chartSummaryData: PropTypes.instanceOf(Array).isRequired,
  blockName: PropTypes.string.isRequired,
  handleShowTray: PropTypes.func.isRequired,
  showTray: PropTypes.bool.isRequired,
  acctBlocks: PropTypes.instanceOf(Array).isRequired,
};

export default SideColumn;
