import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Mousetrap from "mousetrap";
import {
  Container,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  Grid,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import PropTypes from "prop-types";
import moment from "moment";
import { withStyles } from "@mui/styles";
import Accountholdings from "./Accountholdings";
import SideColumn from "./SideColumn";
import { numberFormat } from "../utils/numbers";
import SelectTheme from "../charts/SelectTheme";
import AddBlock from "./AddBlock";
import Service from "../services/hservice";
import Performance from "./Performance";
import AppBackService from "../services/app-back";
import BlockHeader from "./BlockHeader";
import Sector from "./Sector";
import "./portfolio.scss";
import Theme from "../app/theme";

const styles = () => ({
  indicator: {
    backgroundColor: `${Theme.palette.common.white} !important`,
  },
});

const themes = Object.entries(SelectTheme).map(([_k, v]) => v);

const Portfolio = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const Appback = AppBackService();
  const { classes } = props;

  const [openBlockModel, setBlockModel] = useState(false);
  const [accountSummaryData, setAccountSummaryData] = useState([
    ["Account Value", 0],
    ["Earned to Date", 0],
    ["Personal Rate of Return", `0%`],
    ["Return Multiple", 0],
    ["Inception", ""],
  ]);
  const [sectionData, setSectionData] = useState();
  const [chartSummaryData, setChartSummaryData] = useState([
    ["Cash", 0],
    ["Long Value", 0],
    ["Short Value", 0],
    ["Hedge", 0],
  ]);
  const [testAcct, setTestAcct] = useState(false);
  const [blockName, setBlockName] = useState("");
  const [cName, setCName] = useState("");
  const [acctHoldingData, setAcctHoldingData] = useState({
    longs: [],
    shorts: [],
    hedges: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = useState(0);
  const [selectedThemeIndex, setSelectedThemeIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [blockIdValue, setBlockIdValue] = useState("");
  const [isAddedBlock, setAddedBlock] = useState(false);
  const [showTray, setShowTray] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState(SelectTheme[0]);
  const [acctBlocks, setAcctBlocks] = useState([]);

  Mousetrap.bind("t", () => {
    let themeValue = selectedThemeIndex + 1;
    if (themeValue === themes.length) {
      themeValue = 0;
    }
    setSelectedThemeIndex(themeValue);
    setSelectedTheme(SelectTheme[themeValue]);
  });

  Mousetrap.bind("b", async () => {
    if (testAcct) {
      setBlockModel(true);
    }
  });

  const setBalanceData = (res) => {
    if (
      (value === 0 && !res.holdings) ||
      (res.holdings.longs.length === 0 &&
        res.holdings.shorts.length === 0 &&
        res.holdings.hedges.length === 0)
    ) {
      setOpen(true);
    }
    setAcctHoldingData(res.holdings);
    const tempAccSummaryData = [];
    if (res) {
      tempAccSummaryData.push(
        ["Block Market Value", numberFormat(res.total_mv)],
        ["Earned to Date", numberFormat(res.earned_to_date)],
        ["Personal Rate of Return", `${res.dw_ror}%`],
        ["Return Multiple", `${res.equity_multiple}`],
        ["Inception", moment(res.start_date).format("MMM YYYY")]
      );
      setSectionData({
        columns: [
          ["Cash", `${(res.sections.cash_pct * 100).toFixed(1)}`],
          ["Longs", `${(res.sections.long_pct * 100).toFixed(1)}`],
          ["Shorts", `${(res.sections.short_pct * 100).toFixed(1)}`],
          ["Hedge", `${(res.sections.hedge_pct * 100).toFixed(1)}`],
        ],
        colors: {
          Cash: "#1685d3",
          Longs: "#26a64a",
          Shorts: "#ff8521",
          Hedge: "#ffd300",
        },
      });
      setChartSummaryData([
        ["Cash", numberFormat(res.cash)],
        ["Long Value", numberFormat(res.long_mv)],
        ["Short Value", numberFormat(res.short_mv)],
        ["Hedge", numberFormat(res.hedge_mv)],
      ]);
    }
    setAccountSummaryData([...tempAccSummaryData]);
  };

  const setAllData = (ablockPfData) => {
    if (ablockPfData) {
      setBlockName(ablockPfData.strategy);
      setCName(ablockPfData.cname);
      setBalanceData(ablockPfData);
      setIsLoading(false);
    } else {
      setOpen(true);
      setIsLoading(false);
    }
  };

  const getABlockPfData = async (blockId) => {
    const ablockPfData = await Appback.getABlockPfData(blockId);
    setAllData(ablockPfData);
  };

  const getAcctBlocks = async () => {
    Service.getAccountAndAccountHoldings().then(async (res) => {
      setTestAcct(res.data.Account[0].test_acct);
      setAcctBlocks(
        res.data.Account[0].AccountBlocks.map((x) => x.Portfolio.name)
      );
      if (!location.state) {
        const blockId = res.data.Account[0].AccountBlocks[0].id;
        setBlockIdValue(blockId);
        getABlockPfData(blockId);
      }
    });
  };

  const handleChange = (_event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      getABlockPfData(blockIdValue);
    }
    if (newValue === 0) navigate("/portfolio#holdings");
    else if (newValue === 1) navigate("/portfolio#byindustry");
    else if (newValue === 2) navigate("/portfolio#performance");
  };

  const handleBlockChange = (
    blockId,
    addedBlock,
    selectedPortfolio,
    blockCName
  ) => {
    setIsLoading(true);
    setBlockIdValue(blockId);
    setAddedBlock(addedBlock);
    setBlockName(selectedPortfolio);
    setCName(blockCName);
    if (value === 0) getABlockPfData(blockId);
    if (addedBlock) {
      const acctBlock = [...acctBlocks];
      acctBlock.push(selectedPortfolio);
      setAcctBlocks(acctBlock);
      setTimeout(() => {
        setAddedBlock(false);
      }, 500);
    }
  };

  const handleShowTray = (isShow) => {
    setShowTray(isShow);
  };

  const handleDeleteBlock = (delBlock) => {
    const acctBlock = [...acctBlocks];
    const index = acctBlock.indexOf(delBlock);
    if (index !== -1) {
      acctBlock.splice(index, 1);
      setAcctBlocks(acctBlock);
      if (acctBlock.length === 1) setShowTray(false);
    }
  };

  const handleNavigation = () => {
    navigate(`/deposit_blocks?cname=${cName}`);
  };

  useEffect(() => {
    if (location.state) {
      if (location.state.locationHash === "#holdings") {
        setValue(0);
      } else if (location.state.locationHash === "#byindustry") {
        setValue(1);
      } else if (location.state.locationHash === "#performance") {
        setValue(2);
      }
      getAcctBlocks();
      setShowTray(true);
      if (location.state.blockId) {
        setBlockIdValue(location.state.blockId);
        getABlockPfData(location.state.blockId);
      }
      navigate("/portfolio", { replace: true });
    } else {
      if (location.hash === "#holdings") {
        setValue(0);
      } else if (location.hash === "#byindustry") {
        setValue(1);
      } else if (location.hash === "#performance") {
        setValue(2);
      }

      if (blockIdValue) getABlockPfData(blockIdValue);
      else if (blockIdValue === "") getAcctBlocks();
    }
    return () => {
      Mousetrap.unbind("t");
    };
  }, []);

  return (
    <>
      <BlockHeader
        blockName={blockName}
        handleBlockChange={handleBlockChange}
        setIsLoading={setIsLoading}
        AddedBlock={isAddedBlock}
        showTray={showTray}
        handleShowTray={handleShowTray}
        setBlockData={getABlockPfData}
        handleDeleteBlock={handleDeleteBlock}
        tabValue={value}
        testAcct={testAcct}
      />
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className="portfolio">
              <SideColumn
                accountSummaryData={accountSummaryData}
                sectionData={sectionData}
                chartSummaryData={chartSummaryData}
                isLoading={isLoading}
                blockName={blockName}
                handleShowTray={handleShowTray}
                showTray={showTray}
                acctBlocks={acctBlocks}
              />
              <div className="holding-values">
                <div
                  className="portfolio-card2"
                  style={{ position: "relative" }}
                >
                  <AppBar position="static" color="default">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      textColor="primary"
                      style={{ height: "50px" }}
                      classes={{ indicator: classes.indicator }}
                    >
                      <Tab label="Holdings" />
                      <Tab label="By sector" />
                      <Tab label="Performance" />
                    </Tabs>
                    <p className="tabs-space" />
                  </AppBar>
                  <div
                    style={{
                      position: "absolute",
                      right: "5px",
                      top: "-5px",
                      zIndex: "99",
                    }}
                    className="add-fund-mobile"
                  >
                    <Button
                      variant="contained"
                      className="button-height"
                      onClick={handleNavigation}
                    >
                      ADD FUNDS
                    </Button>
                  </div>
                  {/* <ButtonInTabs className={classes.addButton}>
                    New Tab
                  </ButtonInTabs> */}
                  <SwipeableViews
                    index={value}
                    onChangeIndex={(index) => setValue(index)}
                  >
                    <TabPanel className="by_holdings" value={value} index={0}>
                      {!isLoading ? (
                        <Accountholdings {...acctHoldingData} />
                      ) : (
                        <div
                          style={{
                            paddingTop: "30px",
                          }}
                        >
                          <CircularProgress />
                        </div>
                      )}
                    </TabPanel>
                    <TabPanel className="by_sector" value={value} index={1}>
                      <div>
                        <Sector
                          blockId={blockIdValue}
                          selectedTheme={selectedTheme}
                        />
                      </div>
                    </TabPanel>
                    <TabPanel
                      className="by_performance"
                      value={value}
                      index={2}
                    >
                      <div>
                        <Grid
                          container
                          spacing={1}
                          className="by_sector_tables"
                        >
                          <Grid item xs={12} sm={12} md={12}>
                            <Performance blockId={blockIdValue} />
                          </Grid>
                        </Grid>
                      </div>
                    </TabPanel>
                  </SwipeableViews>
                </div>
              </div>
              <AddBlock
                open={openBlockModel}
                close={() => setBlockModel(false)}
                blockName={blockName}
                dialogTitle="Add Block"
                handleBlockChange={handleBlockChange}
              />

              <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title" />
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Your account is ready but is not enrolled into your first
                    block. This means either money has not fully cleared into
                    your account or the investment team has not moved your funds
                    into the market yet. Sit tight and you will see your
                    portfolio in a few days.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setOpen(false)}
                    color="primary"
                    id="close_button"
                  >
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default withStyles(styles)(Portfolio);

function TabPanel({ children, value, index, ...other }) {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}
TabPanel.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};
