import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CssBaseline,
  Drawer,
  Hidden,
  List,
  ListItem,
  Toolbar,
  Typography,
  Grid,
  Button,
  Alert,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import CheckIcon from "@mui/icons-material/Check";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Auth } from "aws-amplify";
import { RootContext } from "../context/root-provider";
import Disclosures from "../components/Disclosures";
import LinkedAccounts from "./LinkedAccounts";
import Securitycenter from "./Securitycenter";
import EmailNotifications from "./EmailNotifications";
import Services from "../services/hservice";
import BeneficiaryForm from "../components/BeneficiaryForm";
import StepData from "../services/step-data";
import Profile from "./Profile";
import ConfirmDialogComponent from "../components/ConfirmDialogComponent";
import "./setting.scss";

const drawerWidth = 240;
const theme = createTheme();
const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    // height: "80vh",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  toolbar: theme.mixins.toolbar,
  mobileDrawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
      zIndex: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  drawerPaper: {
    width: drawerWidth,
    background: "white",
    zIndex: 0,
  },
  mobilerawerPaper: {
    width: drawerWidth,
    background: "white",
    zIndex: 0,
    height: "350px",
  },
  content: {
    flexGrow: 1,
  },
}));

const Styles = {
  profileStyles: {
    "& form": {
      width: "90%",
    },
  },
};
const Settings = (props) => {
  const childRef = useRef();
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { accountStatusName } = useContext(RootContext);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [accountType, setAccountType] = useState(false);
  const [beneficiaryData, setBeneficiaryData] = useState([]);
  const [userInfoData, setUserInfoData] = useState(null);
  const [enableButtons, setEnableButtons] = useState(false);
  const [success, setSuccess] = useState(false);
  const [dailogOpen, setDailogOpen] = useState(false);
  const [beneficiaryDataChanged, setBeneficiaryDataChanged] = useState(false);
  const [menuName, setMenuName] = useState("");
  const [values, setValues] = useState({
    security: true,
    investment: false,
    restrictions: false,
    linkedaccounts: false,
    notifications: false,
    test_acct: false,
    bacctId: null,
  });
  const PHONE_CODE = "+1";
  const getStepData = async () => {
    let phoneNumber = "";
    const userInfo = await Auth.currentAuthenticatedUser();
    if (userInfo) {
      phoneNumber = userInfo.attributes.phone_number.includes(PHONE_CODE)
        ? userInfo.attributes.phone_number.split(PHONE_CODE)[1]
        : userInfo.attributes.phone_number;
    }
    const result = await StepData.get();
    if (result) {
      const res = result.data.UserInformation_by_pk;
      if (!res.phone_number) res.phoneNumber = phoneNumber;
      else res.phoneNumber = res.phone_number;
      res.isfromSetting = true;
      setUserInfoData(res);
      if (
        res.forms &&
        res.forms.beneficiaries &&
        res.forms.beneficiaries.length > 0
      ) {
        setBeneficiaryData(res.forms.beneficiaries);
      }
      setAccountType(res.account_type);
    }
  };

  useEffect(() => {
    const run = async () => {
      const resultTest_acct = await Services.getAccount();
      const {
        test_acct,
        UserInformation: { account_type },
      } = resultTest_acct.data.Account[0];
      if (location.hash === "#linkedaccounts") {
        if (test_acct === false) {
          navigate("/settings#security");
        } else {
          setValues({
            ...values,
            linkedaccounts: true,
            security: false,
            test_acct: test_acct,
          });
        }
      } else if (location.hash === "#profile") {
        setValues({
          ...values,
          investment: true,
          security: false,
          test_acct: test_acct,
          bacctId: resultTest_acct.data.Account[0].bacct_id,
        });
      } else if (location.hash === "#disclosures") {
        setValues({
          ...values,
          restrictions: true,
          security: false,
          test_acct: test_acct,
        });
      } else if (location.hash === "#notifications") {
        setValues({
          ...values,
          notifications: true,
          security: false,
          test_acct: test_acct,
        });
      } else if (location.hash === "#benificiary") {
        setValues({
          ...values,
          benificiary: true,
          security: false,
          test_acct: test_acct,
        });
      } else {
        setValues({
          ...values,
          security: true,
          test_acct: test_acct,
          bacctId: resultTest_acct.data.Account[0].bacct_id,
        });
        navigate("/settings#security");
      }
      if (
        test_acct &&
        accountStatusName === "account_approved" &&
        account_type === "individual"
      ) {
        setValues({
          ...values,
          linkedaccounts: true,
          security: false,
          test_acct: test_acct,
        });
        navigate("/settings#linkedaccounts");
      }
      getStepData();
    };
    run();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const sideMenuHandle = (menuItem, isFromBenificiary = false) => {
    location.state = "";
    if (!isFromBenificiary && beneficiaryDataChanged) {
      setDailogOpen(!dailogOpen);
      setMenuName(menuItem);
    } else {
      getStepData();
      setEnableButtons(false);
      navigate(`/settings#${menuItem}`);
      setValues({
        ...values,
        investment: menuItem === "profile",
        restrictions: menuItem === "disclosures",
        linkedaccounts: menuItem === "linkedaccounts",
        notifications: menuItem === "notifications",
        security: menuItem === "security",
        benificiary: menuItem === "benificiary",
      });
    }
  };

  const drawer = (
    <div style={{ background: "none" }}>
      <div className={classes.toolbar} style={{ background: "none" }} />
      <List>
        <ListItem
          button
          onClick={() => {
            sideMenuHandle("security");
          }}
          className={values.security ? "setting_active" : "setting_deactive"}
          id="lst_item_security_center"
        >
          Security center
        </ListItem>
        <ListItem
          button
          onClick={() => {
            sideMenuHandle("profile");
          }}
          className={values.investment ? "setting_active" : "setting_deactive"}
          id="lst_item_profile"
        >
          Personal Details
        </ListItem>
        <ListItem
          button
          onClick={() => {
            sideMenuHandle("disclosures");
          }}
          className={
            values.restrictions ? "setting_active" : "setting_deactive"
          }
          id="lst_item_disclosures"
        >
          Disclosures
        </ListItem>
        {values.test_acct === true ? (
          <ListItem
            button
            onClick={() => {
              sideMenuHandle("linkedaccounts");
            }}
            className={
              values.linkedaccounts ? "setting_active" : "setting_test_user"
            }
            id="lst_item_linked_account"
          >
            Linked accounts
          </ListItem>
        ) : null}
        {values.test_acct === true ? (
          <ListItem
            button
            onClick={() => {
              sideMenuHandle("notifications");
            }}
            className={
              values.notifications ? "setting_active" : "setting_test_user"
            }
            id="lst_item_notification"
          >
            Notifications
          </ListItem>
        ) : null}
        {accountType !== "individual" && (
          <ListItem
            button
            onClick={() => {
              sideMenuHandle("benificiary");
            }}
            className={
              values.benificiary ? "setting_active" : "setting_deactive"
            }
            id="lst_item_notification"
          >
            Beneficiary Form
          </ListItem>
        )}
      </List>
    </div>
  );

  const mobileDrawer = (
    <div style={{ background: "white", height: "100%", top: "3" }}>
      <div className={classes.toolbar} />
      <List>
        <ListItem
          button
          onClick={() => {
            sideMenuHandle("security");
            handleDrawerToggle();
          }}
          className={values.security ? "setting_active" : "setting_deactive"}
          id="lst_item_mb_security_center"
        >
          Security center
        </ListItem>
        <ListItem
          button
          onClick={() => {
            sideMenuHandle("profile");
            handleDrawerToggle();
          }}
          className={values.investment ? "setting_active" : "setting_deactive"}
          id="lst_item_mb_profile"
        >
          Personal Details
        </ListItem>
        <ListItem
          button
          onClick={() => {
            sideMenuHandle("disclosures");
            handleDrawerToggle();
          }}
          className={
            values.restrictions ? "setting_active" : "setting_deactive"
          }
          id="lst_item_mb_restrictions"
        >
          Disclosures
        </ListItem>
        {values.test_acct === true ? (
          <ListItem
            button
            onClick={() => {
              sideMenuHandle("linkedaccounts");
              handleDrawerToggle();
            }}
            className={
              values.linkedaccounts ? "setting_active" : "setting_test_user"
            }
            id="lst_item_mb_linked_account"
          >
            Linked accounts
          </ListItem>
        ) : null}
        <ListItem
          button
          onClick={() => {
            sideMenuHandle("notifications");
            handleDrawerToggle();
          }}
          className={
            values.notifications ? "setting_active" : "setting_test_user"
          }
          id="lst_item_mb_notification"
        >
          Notifications
        </ListItem>
        {accountType !== "individual" && (
          <ListItem
            button
            onClick={() => {
              sideMenuHandle("benificiary");
              handleDrawerToggle();
            }}
            className={
              values.benificiary ? "setting_active" : "setting_deactive"
            }
            id="lst_item_mb_beneficiary"
          >
            Beneficiary Form
          </ListItem>
        )}
      </List>
    </div>
  );

  const handleBenificiary = (BeneficiaryData) => {
    setBeneficiaryData(BeneficiaryData);
    setEnableButtons(true);
    setBeneficiaryDataChanged(true);
  };
  const handleUpdateDetails = async (beneficiaries) => {
    const updatingValue = {
      forms: { beneficiaries },
    };

    await StepData.updateN(`forms: $forms`, updatingValue);
    setSuccess(true);
    setBeneficiaryDataChanged(false);
    setEnableButtons(false);
    setTimeout(() => {
      setSuccess(false);
    }, 2000);
  };
  const onBack = () => {
    childRef.current.onBack();
    setEnableButtons(false);
    setBeneficiaryDataChanged(false);
    getStepData();
  };
  const confirmToBack = () => {
    setEnableButtons(false);
    setDailogOpen(false);
    setBeneficiaryDataChanged(false);
    getStepData();
    if (menuName !== "") {
      sideMenuHandle(menuName, true);
      setMenuName("");
    }
  };
  const handleDisclosureUpdateDetails = async (data) => {
    await StepData.update("applicants: $applicants", {
      applicants: data,
    });
    setSuccess(true);
    setEnableButtons(false);
    setTimeout(() => {
      setSuccess(false);
    }, 2000);
  };
  const onDisclosureBack = () => {
    setEnableButtons(false);
    getStepData();
  };
  const onEnableButtons = () => {
    setEnableButtons(true);
  };
  const handleProfileUpdateDetails = async (data) => {
    await StepData.updateN(
      `citizenship: "${data.citizenship}", ${data.dob}, ${data.ssn}, applicants: $applicants`,
      { applicants: data.applicationData }
    );
    setSuccess(true);
    setEnableButtons(false);
    setTimeout(() => {
      setSuccess(false);
    }, 2000);
  };
  const onProfileBack = () => {
    childRef.current.onBack(true);
  };
  const cancelHandle = () => {
    getStepData();
    setEnableButtons(false);
  };
  return (
    <Box className="settings" sx={{ ...Styles.profileStyles }}>
      <Toolbar className={classes.appBar}>
        <Typography variant="h6" onClick={handleDrawerToggle}>
          Settings{" "}
          {values.test_acct &&
            (values.linkedaccounts || values.notifications) && (
              <span style={{ backgroundColor: "#ffff00" }}>
                (For TEST Accounts only)
              </span>
            )}
        </Typography>
        <ArrowDropDownIcon
          aria-controls={mobileOpen ? "fade-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={mobileOpen ? "true" : undefined}
          sx={{ marginTop: "3px", marginLeft: "0px" }}
          onClick={handleDrawerToggle}
          className="setting-mobile"
        />
      </Toolbar>
      <div className={classes.root}>
        <CssBaseline />
        <nav className={classes.mobileDrawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css" className="setting-mobile">
            <Drawer
              BackdropProps={{ invisible: true }}
              variant="temporary"
              anchor={"top"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.mobilerawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              style={{ zIndex: 3 }}
            >
              {mobileDrawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css" className="setting-notmobile">
            <Drawer
              classes={{ paper: classes.drawerPaper }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div hidden={!values.security}>
            <Securitycenter />
          </div>
          {values.investment && (
            <div hidden={!values.investment}>
              {success && (
                <Alert
                  icon={<CheckIcon fontSize="inherit" />}
                  severity="success"
                  className="sucess_message"
                >
                  Data saved successfully.
                </Alert>
              )}
              <h3>Personal Details</h3>
              <Grid container spacing={6}>
                <Grid
                  item
                  xs={10}
                  sm={12}
                  md={12}
                  lg={10}
                  className="person-details"
                >
                  <Profile
                    ref={childRef}
                    userInfoData={userInfoData}
                    onSubmit={handleProfileUpdateDetails}
                    onEnableButtons={onEnableButtons}
                    bacctId={values.bacctId}
                    cancelHandle={cancelHandle}
                    StepButtons={
                      <SettingButtons
                        onBack={onProfileBack}
                        enableButtons={enableButtons}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </div>
          )}
          {values.restrictions && (
            <div hidden={!values.restrictions}>
              {success && (
                <Alert
                  icon={<CheckIcon fontSize="inherit" />}
                  severity="success"
                  className="sucess_message"
                >
                  Data saved successfully.
                </Alert>
              )}
              <h3>Disclosures</h3>
              <br />
              <Grid container spacing={6}>
                <Grid item xs={10} sm={8} md={8} lg={8}>
                  <Disclosures
                    ref={childRef}
                    userInfoData={userInfoData}
                    onSubmit={handleDisclosureUpdateDetails}
                    onEnableButtons={onEnableButtons}
                    StepButtons={
                      <SettingButtons
                        onBack={onDisclosureBack}
                        enableButtons={enableButtons}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </div>
          )}
          {values.linkedaccounts && values.test_acct && <LinkedAccounts />}
          {values.notifications && <EmailNotifications />}
          {values.benificiary && (
            <>
              {success && (
                <Alert
                  icon={<CheckIcon fontSize="inherit" />}
                  severity="success"
                  className="sucess_message"
                >
                  Data saved successfully.
                </Alert>
              )}
              <h3 style={{ paddingBottom: "0px !important" }}>Beneficiary</h3>
              <Grid container spacing={6}>
                <Grid item xs={10} sm={8} md={8} lg={10}>
                  <BeneficiaryForm
                    ref={childRef}
                    onBenificiary={handleBenificiary}
                    onSubmit={handleUpdateDetails}
                    Beneficiaries={beneficiaryData}
                    setting_button_flip="setting_button_flip"
                    StepButtons={
                      <SettingButtons
                        onBack={onBack}
                        enableButtons={enableButtons}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </>
          )}
        </main>
      </div>
      <ConfirmDialogComponent
        dailogOpen={dailogOpen}
        setDailogOpen={setDailogOpen}
        confirmToBack={confirmToBack}
      >
        <p>
          If you navigate away from this form page, you will lose changes that
          are pending. Select OK to continue anyway or select CANCEL to resume
          editing and to submit when done.
        </p>
      </ConfirmDialogComponent>
    </Box>
  );
};

function SettingButtons({ onBack, enableButtons }) {
  return (
    <Grid container spacing={1}>
      <Grid item xs={6} md={6}>
        <Button
          type="submit"
          variant="contained"
          id="continue_button"
          disabled={!enableButtons}
        >
          Submit
        </Button>
      </Grid>
      <Grid item xs={6} md={6}>
        <Button
          onClick={onBack}
          variant="contained"
          id="back_button"
          disabled={!enableButtons}
        >
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
}

export default Settings;
