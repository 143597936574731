import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import MaskedInput from "react-text-mask";
import { FormControl, FormHelperText, Typography, Box } from "@mui/material";
import StepComponent from "./StepComponent";
import { states as USA_STATES } from "../onboarding/USA_STATES.json";

const maskObj = {
  zipCode: [/\d/, /\d/, /\d/, /\d/, /\d/],
};
const validationConst = {
  zipCode: 5,
};

const PostalCodeMaskComponent = (props) => {
  const mask = maskObj.zipCode;
  const { inputRef, ...other } = props;
  return <MaskedInput {...other} mask={mask} />;
};

const Styles = {
  error: {
    color: "red !important",
    textAlign: "left !important",
  },
};

const Address = ({ data, name, errorObj }) => {
  const { control, setValue } = useFormContext();
  useEffect(() => {
    if (data && name) {
      setValue(`${name}.streetAddress`, data?.streetAddress[0]);
      setValue(`${name}.streetAddress1`, data?.streetAddress[1]);
      setValue(`${name}.city`, data?.city);
      setValue(`${name}.state`, data?.state);
      setValue(`${name}.postalCode`, data?.postalCode);
    }
  }, [data, name]);
  return (
    <>
      <Box mt={1} mb={1}>
        <FormControl variant="outlined">
          <StepComponent
            inputType="Input"
            controllerProp={{
              control,
              name: `${name}.streetAddress`,
              id: "streetAddress",
              defaultValue: "",
              rules: {
                required: "Street Address Field is Required",
              },
            }}
            componentProp={{
              variant: "outlined",
              label: "Street Address",
              autoComplete: "address-level4",
            }}
          />
          <FormHelperText sx={{ ...Styles.error }}>
            {errorObj?.address?.streetAddress?.message}
          </FormHelperText>
        </FormControl>
      </Box>
      <Box mt={1} mb={1}>
        <FormControl variant="outlined">
          <StepComponent
            inputType="Input"
            controllerProp={{
              control,
              name: `${name}.streetAddress1`,
              id: "streetAddress1",
              defaultValue: "",
            }}
            componentProp={{
              variant: "outlined",
              label: "Street Address Line 2",
              autoComplete: "address-level3",
            }}
          />
        </FormControl>
      </Box>
      <Box mt={1} mb={1}>
        <FormControl variant="outlined">
          <StepComponent
            inputType="Input"
            controllerProp={{
              control,
              name: `${name}.city`,
              id: "city",
              defaultValue: "",
              rules: {
                required: "City Field is Required",
              },
            }}
            componentProp={{
              variant: "outlined",
              label: "City",
              autoComplete: "address-level2",
            }}
          />
          <FormHelperText sx={{ ...Styles.error }}>
            {errorObj?.address?.city?.message}
          </FormHelperText>
        </FormControl>
      </Box>
      <Box mt={1} mb={1}>
        <FormControl variant="outlined">
          <StepComponent
            inputType="Select"
            inputLabel="State"
            options={USA_STATES}
            controllerProp={{
              control,
              name: `${name}.state`,
              defaultValue: "",
              rules: {
                required: "This Field is Required",
              },
            }}
            componentProp={{
              autoComplete: "address-level1",
            }}
          />
          <FormHelperText sx={{ ...Styles.error }}>
            {errorObj?.address?.state?.message}
          </FormHelperText>
        </FormControl>
      </Box>
      <Box mt={1} mb={1}>
        <FormControl variant="outlined">
          <StepComponent
            inputType="Masked"
            inputLabel="Zip Code"
            controllerProp={{
              control,
              name: `${name}.postalCode`,
              defaultValue: "",
              maskPattern: maskObj.postalCode,
              rules: {
                validate: {
                  required: (value) => value !== null && value !== "",
                  maxNumber: (value) =>
                    `${value}`.split("_").join("").split("").length ===
                    validationConst.zipCode,
                },
              },
            }}
            componentProp={{
              inputComponent: PostalCodeMaskComponent,
              autoComplete: "postal-code",
            }}
          />
          <FormHelperText sx={{ ...Styles.error }}>
            {errorObj?.address?.postalCode?.type === "required" &&
              "Zipcode is required."}
            {errorObj?.address?.postalCode?.type === "maxNumber" &&
              `Zipcode should be of ${validationConst.zipCode} digits`}
          </FormHelperText>
        </FormControl>
      </Box>
    </>
  );
};
const ContactInfo = ({ data, name, index }) => {
  const { errors } = useFormContext();
  return (
    <>
      <Typography variant="subtitle1">Contact Address</Typography>
      <Address
        data={data?.address}
        name={`${name}.address`}
        index={index}
        errorObj={errors?.applicants ? errors.applicants[index]?.contact : {}}
      />
    </>
  );
};

export default ContactInfo;
