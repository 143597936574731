import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import { useForm, FormProvider } from "react-hook-form";
import { Button, Grid } from "@mui/material";
import ContactInfo from "./ContactInfo";
import BeneficiaryInfo from "./BeneficiaryInfo";
import Beneficiary from "./Beneficiary";

const theme = createTheme();
const useStyles = makeStyles(() => ({
  form: {
    marginTop: theme.spacing(9),
  },
  error: {
    color: "red !important",
    textAlign: "left !important",
  },
  labelText: {
    lineHeight: "1.2 !important",
  },
}));

const BeneficiaryForm = forwardRef((props, ref) => {
  const classes = useStyles();
  const methods = useForm({
    defaultValues: {
      applicants: [
        {
          contact: {},
        },
      ],
    },
  });
  const { handleSubmit } = methods;
  const { Beneficiaries, onBenificiary } = props;
  const [showMessage, setShowMessage] = useState(false);
  const [values, setValues] = useState({
    showForm: false,
    formData: {},
    editIndex: -1,
    PrimaryExist: false,
    currentPercentage: 0,
    PrimaryError: false,
    contingentError: false,
  });

  useEffect(() => {}, []);
  const getAddressData = ({ streetAddress, streetAddress1, ...rest }) => ({
    ...rest,
    country: "USA",
    streetAddress: [streetAddress, streetAddress1],
  });
  const getBeneficiaryData = (data) => {
    const { address } = data.applicants[0].contact;
    const beneficiary = {
      name: {
        givenName: data.givenName,
        familyName: data.familyName,
      },
      address: getAddressData(address),
      dateOfBirth: data.dateofbirth,
      socialSecurityNumber: data.socialSecurityNumber,
      sharePercent: data.sharePercentage,
      relationship: data.relationship,
      beneficiaryType: data.beneficiaryType,
    };
    return beneficiary;
  };
  const onformSubmit = (data) => {
    const beneficiaries = [...Beneficiaries];
    const beneficiary = getBeneficiaryData(data);
    if (values.editIndex !== -1) {
      beneficiaries[values.editIndex] = beneficiary;
    } else beneficiaries.push(beneficiary);
    onBenificiary(beneficiaries);
    setValues({
      ...values,
      showForm: false,
      editIndex: -1,
      formData: {},
    });
  };

  const onSubmit = () => {
    const beneficiaries = [...Beneficiaries];
    const checkPrimary = (obj) => obj.beneficiaryType === "PRIMARY";
    const checkContingent = (obj) => obj.beneficiaryType === "CONTINGENT";
    const primaryPercentage = beneficiaries
      .filter(checkPrimary)
      .reduce((n, { sharePercent }) => n + parseFloat(sharePercent, 10), 0);
    const contiPercentage = beneficiaries
      .filter(checkContingent)
      .reduce((n, { sharePercent }) => n + parseFloat(sharePercent, 10), 0);
    let contingent = true;
    if (beneficiaries.some(checkContingent)) {
      contingent = false;
      if (contiPercentage === 100) {
        contingent = true;
      }
    }
    if (
      primaryPercentage === 100 &&
      beneficiaries.some(checkPrimary) &&
      contingent
    ) {
      props.onSubmit(beneficiaries);
    } else {
      setValues({
        ...values,
        PrimaryExist: beneficiaries.some(checkPrimary),
        PrimaryError: primaryPercentage === 100,
        contingentError: contingent,
      });
      setShowMessage(true);
    }
  };
  const onCancel = () => {
    setValues({
      ...values,
      showForm: false,
      editIndex: -1,
      formData: {},
    });
    setShowMessage(false);
  };
  useImperativeHandle(ref, () => ({
    onBack() {
      setShowMessage(false);
    },
  }));

  return (
    <Grid container spacing={6}>
      <Grid item xs={10} sm={8} lg={6} className="stepsize">
        <Beneficiary
          Beneficiaries={Beneficiaries}
          onBenificiary={onBenificiary}
          setValues={setValues}
          setShowMessage={setShowMessage}
        />
        {showMessage && (
          <>
            {!values.PrimaryExist && (
              <p style={{ color: "red" }}>
                The primary beneficiary must exist.
              </p>
            )}
            {!values.PrimaryError && (
              <p style={{ color: "red" }}>
                The percentages for all primary beneficiaries should total to
                100%.
              </p>
            )}
            {!values.contingentError && (
              <p style={{ color: "red" }}>
                The percentages for all contingent beneficiaries should total to
                100%.
              </p>
            )}
          </>
        )}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "30px" }}>
            {values.showForm && (
              <>
                <p
                  style={{
                    width: "auto",
                    margin: "auto",
                    marginBottom: "10px",
                    marginTop: "-25px",
                  }}
                >
                  You must click SAVE or your pending changes to beneficiaries
                  will be cancelled and require re-entry.
                </p>
                <BeneficiaryInfo
                  data={values.formData?.forms}
                  PrimaryExist={values.PrimaryExist}
                  name={`applicants[${0}].beneficiary`}
                />
                <ContactInfo
                  data={values.formData?.forms}
                  name={`applicants[${0}].contact`}
                  index={0}
                />
                <Grid
                  container
                  spacing={1}
                  className={props.setting_button_flip}
                >
                  <Grid item xs={6} md={6}>
                    <Button
                      onClick={onCancel}
                      variant="contained"
                      className={classes.actionButton}
                      id="cancel_button"
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Button
                      variant="contained"
                      id="submit_button"
                      onClick={handleSubmit((d) => onformSubmit(d))}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
            {!values.showForm && props.StepButtons}
          </form>
        </FormProvider>
      </Grid>
    </Grid>
  );
});

export default BeneficiaryForm;
