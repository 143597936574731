import React, { useEffect } from "react";
import { Button, Tooltip } from "@mui/material";
import "./beneficiary.scss";

const Beneficiary = (props) => {
  const { Beneficiaries, onBenificiary, setValues, setShowMessage } = props;

  useEffect(() => {
    if (Beneficiaries.length === 0) {
      setValues({
        editIndex: -1,
        showForm: true,
      });
    } else {
      setValues({
        editIndex: -1,
        showForm: false,
      });
    }
  }, [Beneficiaries]);
  const onEdit = (index) => {
    const beneficiary = Beneficiaries[index];
    setValues({
      showForm: true,
      editIndex: index,
      formData: { forms: beneficiary },
    });
    setShowMessage(false);
  };
  const onDelete = (index) => {
    const beneficiaries = [...Beneficiaries];
    beneficiaries.splice(index, 1);
    onBenificiary(beneficiaries);
    if (beneficiaries.length === 0) {
      setValues({
        showForm: true,
      });
    }
    setShowMessage(false);
  };
  const benificiaryNameShort = (res) => {
    if (res !== undefined) {
      if (res.length > 10) {
        const deviceName = res.slice(0, 10);
        return `${deviceName}***`;
      }
      return res;
    }
  };
  const onAdd = () => {
    const beneficiaries = [...props.Beneficiaries];
    const checkPrimary = (obj) => obj.beneficiaryType === "PRIMARY";
    setValues({
      editIndex: -1,
      showForm: true,
      PrimaryExist: beneficiaries.some(checkPrimary),
    });
    setShowMessage(false);
  };

  return (
    <table style={{ width: "100%" }} id="tbl_beneficiary_list">
      <thead>
        <tr>
          <td>Beneficiary</td>
          <td>Type</td>
          <td>Relationship</td>
          <td>Percentage</td>
          <td />
        </tr>
      </thead>
      <tbody>
        {Beneficiaries.length > 0 &&
          Beneficiaries.map((result, j) => (
            <tr key={j}>
              <td>
                {result.name && (
                  <Tooltip
                    title={`${result.name.givenName} ${result.name.familyName}`}
                  >
                    <div>
                      {benificiaryNameShort(
                        `${result.name.givenName} ${result.name.familyName}`
                      )}
                    </div>
                  </Tooltip>
                )}
              </td>
              <td>{result.beneficiaryType}</td>
              <td>{result.relationship}</td>
              <td>{result.sharePercent}</td>
              <td style={{ padding: "2px", display: "flex" }}>
                <Button
                  className="benificiary_buttons"
                  onClick={() => onEdit(j)}
                  id="edit_button"
                >
                  Edit
                </Button>
                <span style={{ marginTop: "5px" }}>|</span>
                <Button
                  className="benificiary_buttons"
                  onClick={() => onDelete(j)}
                  id="delete_button"
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={5} className="benificiary">
            <Button
              className="benificiary_buttons"
              id="add_button"
              // disabled={values.showForm}
              onClick={() => onAdd()}
            >
              Add Beneficiary
            </Button>
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

export default Beneficiary;
