import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import {
  FormControl,
  FormHelperText,
  TextField,
  Container,
  Grid,
  Button,
  Typography,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import Service from "../services/hservice";
import AppBackService from "../services/app-back";
import ConfirmMessage from "./ConfirmMessage";

const theme = createTheme();
const useStyles = makeStyles(() => ({
  form: {
    marginTop: theme.spacing(9),
  },
  actionButton: {
    width: "100% !important",
    height: "50px !important",
    color: "white !important",
  },
  error: {
    color: "red !important",
    textAlign: "left !important",
  },
  textFieldError: {
    padding: "0 14px 0 14px !important",
    color: "red !important",
  },
}));

const VerifyExtAcct = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const Appback = AppBackService();
  const { control, handleSubmit, errors } = useForm({});
  const [values, setValues] = useState({
    depositOne: "",
    depositTwo: "",
    account_details:
      location.state !== null
        ? location.state.account_details
        : { account_num: undefined },
    toast_message: "",
    message: "",
    showLoading: false,
  });
  useEffect(() => {
    if (location.state === undefined) {
      navigate("/deposit_ext");
    }
  }, [location.state]); // eslint-disable-line react-hooks/exhaustive-deps
  const onSubmit = (data) => {
    setValues({
      ...values,
      showLoading: true,
    });
    Appback.verifyDeposits(
      values.account_details.id,
      data.depositOne,
      data.depositTwo
    ).then((res) => {
      if (res !== "ERROR" && res.result !== "Failed") {
        Service.updateLinkedAccounts(
          `{id: "${values.account_details.id}"}`,
          `_set: {status: "Linked"}`
        ).then((_res) => {
          setValues({
            ...values,
            toast_message:
              "You have successfully set up a link to your bank account. Please continue to deposit funds into your 99rises account.",
          });
        });
      } else {
        setValues({
          ...values,
          message:
            "The two small amounts entered are not the amount deposited in your account",
        });
      }
    });
  };
  const toastMessageupdate = () => {
    navigate("/deposit_ext");
  };
  return (
    <Container className={classes.form} style={{ marginTop: "0px" }}>
      {values.showLoading && <div id="cover-spin" />}
      <Grid container spacing={6}>
        <Grid item xs={10} sm={8} lg={6} className="stepsize">
          <Typography component="div">
            <br />
            <Box
              fontWeight="fontWeightBold"
              fontSize="h6.fontSize"
              pb={1}
              id="verify_addfunds_title"
            >
              Add funds
            </Box>
            {values.toast_message === "" ? (
              <Box fontSize="h7.fontSize" pb={1} id="verify_bank_title">
                Verify bank account
              </Box>
            ) : null}
          </Typography>
          {values.toast_message !==
          "You have successfully set up a link to your bank account. Please continue to deposit funds into your 99rises account." ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              {values.message !== "" && (
                <p style={{ color: "red" }}>{values.message}</p>
              )}
              <p style={{ textAlign: "left", paddingLeft: "10px" }}>
                Two small deposits (under 1 dollar) will be made into your
                account nicknamed&nbsp;
                {values.account_details.nickname !== undefined &&
                  values.account_details.nickname}
                &nbsp;from Tradier Brokerage. When you see those, please come back
                to 99rises and enter the amounts on this screen.
              </p>
              <p style={{ textAlign: "left", paddingLeft: "10px" }}>
                <b>NOTE:</b> If not verified within 10 days, the ACH information
                will be deleted and you will have to re-enter the ACH
                information{" "}
              </p>
              <FormControl>
                <Controller
                  id="depositOne"
                  as={
                    <TextField
                      type="number"
                      variant="outlined"
                      label="Deposit 1"
                      inputProps={{
                        min: "0.01",
                        max: "0.99",
                        maxLength: "3",
                        step: 0.01,
                      }}
                      fullWidth
                      autoFocus
                    />
                  }
                  control={control}
                  name="depositOne"
                  defaultValue=""
                  rules={{
                    required:
                      "Deposit amount should be under $1 and be entered like 0.12",
                  }}
                />
                <FormHelperText className={classes.textFieldError}>
                  {errors.depositOne && errors.depositOne.message}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <Controller
                  id="depositTwo"
                  as={
                    <TextField
                      type="number"
                      variant="outlined"
                      label="Deposit 2"
                      inputProps={{
                        min: "0.01",
                        max: "0.99",
                        maxLength: "3",
                        step: 0.01,
                      }}
                      fullWidth
                    />
                  }
                  control={control}
                  name="depositTwo"
                  defaultValue=""
                  rules={{
                    required:
                      "Deposit amount should be under $1 and be entered like 0.12",
                  }}
                />
                <FormHelperText className={classes.textFieldError}>
                  {errors.depositTwo && errors.depositTwo.message}
                </FormHelperText>
              </FormControl>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <Button
                    type="submit"
                    className={classes.actionButton}
                    variant="contained"
                    id="verify_button"
                  >
                    VERIFY
                  </Button>
                </Grid>
              </Grid>
            </form>
          ) : (
            <ConfirmMessage
              toast_message={values.toast_message}
              toastMessageupdate={() => toastMessageupdate()}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
export default VerifyExtAcct;
