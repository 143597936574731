import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Menu,
  Button,
  MenuItem,
  Box,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import Fade from "@mui/material/Fade";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { RootContext } from "../context/root-provider";

const getStyles = (theme) => {
  return {
    navbar: { position: "relative", display: "flex", margin: "auto" },
    appBar: {
      "& .app-bar": {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.common.green,
      },
    },
    hoverStyles: {
      "& .img-top": {
        display: "none",
      },
      "& .logo-small": {
        margin: "unset",
        marginRight: "auto",
        width: "auto !important",
      },
      "& .logo-container:hover": {
        "& .img-top": {
          display: "inline",
          cursor: "pointer",
        },
        "& .white-logo": {
          display: "none",
        },
      },
      "& .topbar_menu li": {
        fontWeight: 500,
        fontSize: 17,
      },
      "& .topbar_menu li:hover": {
        backgroundColor: "transparent",
      },
    },
  };
};
const NavBar = ({ userInfo, testAccount, accountType, logout }) => {
  const Styles = getStyles(useTheme());
  const { accountStatusName } = useContext(RootContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mAnchorEl, setMAnchorEl] = useState(null);
  const open = Boolean(mAnchorEl);
  const navigate = useNavigate();
  const handleLogOut = async (event) => {
    event.preventDefault();
    setAnchorEl(null);
    logout();
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleNavigation = (url) => {
    navigate(url);
    setAnchorEl(null);
    setMAnchorEl(null);
  };

  const handleMClick = (event) => {
    setMAnchorEl(event.currentTarget);
  };
  const handleMClose = () => {
    setMAnchorEl(null);
  };
  const logoutMenu = <MenuItem onClick={handleLogOut}>Logout</MenuItem>;
  const portfolioMenu = (
    <MenuItem onClick={() => handleNavigation("/portfolio")}>
      Portfolio
    </MenuItem>
  );
  const cardsMenu = (
    <MenuItem onClick={() => handleNavigation("/cards")}>Cards</MenuItem>
  );
  const blocksMenu = (
    <MenuItem onClick={() => handleNavigation("/blocks")}>Blocks</MenuItem>
  );
  const documentsMenu = (
    <MenuItem onClick={() => handleNavigation("/documents")}>
      Documents
    </MenuItem>
  );
  const settingsMenu = (
    <MenuItem onClick={() => handleNavigation("/settings")}>Settings</MenuItem>
  );
  const withdrawFunds = (
    <MenuItem
      style={{ backgroundColor: "yellow" }}
      onClick={() => handleNavigation("/withdrawfunds")}
    >
      Withdraw funds
    </MenuItem>
  );
  const AddInvestfunds = (
    <MenuItem onClick={() => handleNavigation("/deposit")}>
      Invest funds
    </MenuItem>
  );
  const activeStates = [
    "account_approved",
    "account_funded",
    "account_active",
    "account_paused",
  ];
  const getMenuList = function* menuListGenerator() {
    if (["account_active", "account_paused"].includes(accountStatusName)) {
      if (accountType === "individual") {
        yield [AddInvestfunds];
        if (testAccount) {
          yield [withdrawFunds];
        }
      }
      yield [documentsMenu];
    }
    if (activeStates.includes(accountStatusName)) {
      yield [settingsMenu];
    }
    yield [logoutMenu];
  };

  const menuList = getMenuList();

  const handleLogoClick = () => {
    if (activeStates.includes(accountStatusName)) {
      handleNavigation("portfolio");
    }
  };

  return (
    <Box sx={{ ...Styles.appBar }} className="menu-height">
      <AppBar elevation={0} className="app-bar">
        {!userInfo && (
          <Toolbar
            sx={{
              ...Styles.navbar,
              ...Styles.hoverStyles,
            }}
          >
            <div className="logo-container">
              <img
                src={`${window.location.origin}/99rises-wht.png`}
                alt="99rises"
                className="white-logo"
                height="40px"
              />
              <img
                src={`${window.location.origin}/99rises-blk.png`}
                className="img-top"
                alt="logo"
                height="40"
              />
            </div>
          </Toolbar>
        )}
        {userInfo && (
          <Toolbar sx={{ ...Styles.hoverStyles }}>
            <div className="logo-small" style={{ display: "flex" }}>
              <div
                className={
                  ["account_active", "account_paused"].includes(
                    accountStatusName
                  )
                    ? "logo-container"
                    : ""
                }
                onClick={handleLogoClick}
              >
                <img
                  src={`${window.location.origin}/logo.png`}
                  className="white-logo"
                  alt="logo"
                  height="40"
                />
                <img
                  src={`${window.location.origin}/logo-black.png`}
                  className="img-top"
                  alt="logo"
                  height="40"
                />
              </div>
              {["account_active", "account_paused"].includes(
                accountStatusName
              ) && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                      ml: 5,
                    }}
                    className="topbar_menu notmobile"
                  >
                    <Typography
                      sx={{
                        minWidth: 80,
                        "&:hover": {
                          color: "common.black",
                        },
                      }}
                    >
                      {portfolioMenu}
                    </Typography>
                    <Typography
                      sx={{
                        minWidth: 80,
                        "&:hover": {
                          color: "common.black",
                        },
                      }}
                    >
                      {cardsMenu}
                    </Typography>
                    <Typography
                      sx={{
                        minWidth: 80,
                        "&:hover": {
                          color: "common.black",
                        },
                      }}
                    >
                      {blocksMenu}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      alignItems: "center",
                      textAlign: "center",
                      ml: 2,
                      mt: 1,
                    }}
                    className="topbar_menu mobile"
                  >
                    <MenuIcon
                      id="fade-button"
                      aria-controls={open ? "fade-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleMClick}
                    />
                    <Menu
                      id="fade-menu"
                      MenuListProps={{
                        "aria-labelledby": "fade-button",
                      }}
                      anchorEl={mAnchorEl}
                      open={open}
                      onClose={handleMClose}
                      TransitionComponent={Fade}
                    >
                      {portfolioMenu}
                      {cardsMenu}
                      {blocksMenu}
                    </Menu>
                  </Box>
                </>
              )}
            </div>

            <div className="nav-profile-name">
              <Button
                variant="text"
                size="large"
                sx={{
                  color: "common.white",
                  fontSize: 17,
                  fontWeight: "bold",
                  textTransform: "none",
                  "&:hover": {
                    color: "common.black",
                  },
                }}
                onClick={handleClick}
                id="menu_button"
              >
                {userInfo?.signInUserSession?.idToken?.payload?.given_name}{" "}
                {userInfo?.signInUserSession?.idToken?.payload?.family_name}
                <ArrowDropDownIcon
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  sx={{ marginTop: "1px", marginLeft: "-2px" }}
                />
              </Button>
            </div>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                  "& li": {
                    justifyContent: "flex-start",
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              {[...menuList]}
            </Menu>
          </Toolbar>
        )}
      </AppBar>
    </Box>
  );
};

export default NavBar;
