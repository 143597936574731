import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  Chip,
  Box,
  Paper,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import MaterialTable from "@material-table/core";
import SearchIcon from "@mui/icons-material/Search";
import "./index.scss";
import { numberFormat, percentageFormat } from "../utils/numbers";
import AppBackService from "../services/app-back";

const Index = () => {
  const Appback = AppBackService();
  const tableEl = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [blockslist, setBlockslist] = useState([]);
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    const getBlockslist = async () => {
      if (blockslist.length === 0) {
        const res = await Appback.getBlocks();
        setBlockslist(res.blocks);
        setShowLoading(false);
      }
    };
    getBlockslist();
  }, []);

  const columns = [
    {
      field: "name",
      title: "Block",
      render: (rowData) => (
        <Grid container spacing={2}>
          <Grid item xs="auto" className="image-grid">
            <div className="image-div">
              <img
                src={`${window.location.origin}/${rowData.cname}.png`}
                className="img-block"
                alt="logo"
              />
            </div>
          </Grid>
          <Grid item lg={5} container alignItems="center" xs>
            <Typography variant="h6" component="h6">
              {rowData.name}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs="auto">
                <Chip
                  label={`${rowData.type}`}
                  size="small"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    {
      field: "risk_band",
      title: "Risk Band",
    },
    {
      field: "perf.ytd",
      title: "YTD",
      customSort: (a, b) => a.perf.ytd - b.perf.ytd,
      render: (rowData) => (
        <span>{percentageFormat(rowData.perf.ytd / 100)}</span>
      ),
    },
    {
      field: "perf.bals.total_mv",
      title: "Market Value",
      render: (rowData) => (
        <span>{numberFormat(rowData.perf.bals.total_mv)}</span>
      ),
    },
  ];

  return (
    <Container className="blocks-table">
      {showLoading && <div id="cover-spin" />}
      <Box
        sx={{
          display: "none",
          flexWrap: "wrap",
          "& > :not(style)": {
            marginTop: 2,
            marginBottom: 0.5,
            width: "100%",
            height: 100,
            borderRadius: 2,
          },
        }}
      >
        <Paper elevation={1}>
          <Grid container spacing={2} sx={{ padding: 2.5 }}>
            <Grid item xs>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Status"
                >
                  <MenuItem value={10}>Open</MenuItem>
                  <MenuItem value={20}>Guarded</MenuItem>
                  <MenuItem value={30}>Experimental</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Platform</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Status"
                >
                  <MenuItem value={1}>Secure 2</MenuItem>
                  <MenuItem value={2}>Long long</MenuItem>
                  <MenuItem value={3}>Ira</MenuItem>
                  <MenuItem value={4}>Alpha Bets</MenuItem>
                  <MenuItem value={5}>Balanced 2</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Rewards</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Status"
                >
                  <MenuItem value={1}>Helper</MenuItem>
                  <MenuItem value={2}>DCA</MenuItem>
                  <MenuItem value={3}>None</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={5}>
              <TextField
                variant="outlined"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs>
              <Button variant="text" color="primary">
                Clear All
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <MaterialTable
        title="Customer Portfolio"
        tableRef={tableEl}
        columns={columns}
        data={blockslist}
        options={{
          toolbar: false,
          search: false,
          paging: false,
          showTitle: false,
          sorting: true,
          emptyRowsWhenPaging: false,
          headerStyle: {
            backgroundColor: "#fff",
            color: "#000",
          },
        }}
        onRowClick={(_event, rowData) => {
          const res = rowData.cname;
          navigate("/blocks", {
            state: {
              cname: res,
              from: "list",
              locationHash: location.hash,
            },
          });
        }}
      />
    </Container>
  );
};
export default Index;
