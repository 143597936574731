import React from "react";
import {
  Select,
  InputLabel,
  TextField,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import { useController } from "react-hook-form";
import NumberFormat from "react-number-format";

const StepComponent = ({
  inputLabel,
  options,
  componentProp,
  controllerProp,
  inputType,
}) => {
  const { field } = useController(controllerProp);
  let component = null;
  const label = inputLabel ? { label: inputLabel } : {};
  if (inputType === "Input") {
    component = (
      <TextField fullWidth {...componentProp} {...field} {...label} />
    );
  } else if (inputType === "Select") {
    component = (
      <Select
        {...componentProp}
        native
        displayEmpty
        {...field}
        {...label}
        onChange={(e) => {
          if (componentProp?.onChange) {
            componentProp.onChange(e);
          }
          return field.onChange(e);
        }}
      >
        <option value=""></option>
        {options.map(({ value, key }, index) => (
          <option key={index} value={value}>
            {key}
          </option>
        ))}
      </Select>
    );
  } else if (inputType === "NumberFormat") {
    component = (
      <NumberFormat
        customInput={TextField}
        thousandSeparator={true}
        prefix={"$ "}
        allowEmptyFormatting={true}
        {...field}
        {...label}
        variant="outlined"
      />
    );
  } else if (inputType === "Masked") {
    component = (
      <OutlinedInput
        {...componentProp}
        {...field}
        {...label}
        startAdornment={<InputAdornment position="end" />}
      />
    );
  }
  return (
    <>
      {inputLabel && <InputLabel>{inputLabel}</InputLabel>}
      {component}
    </>
  );
};
export default StepComponent;
