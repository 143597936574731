import React, { useState, useEffect } from "react";
import { Grid, CircularProgress } from "@mui/material";
import PropTypes from "prop-types";
import AppBackService from "../services/app-back";
import { numberFormat, percentageFormat } from "../utils/numbers";
import { DataTable } from "./SideColumn";
import DoughnutChart from "../charts/doughnut-chart";

const Sector = ({ blockId, selectedTheme }) => {
  const Appback = AppBackService();
  const [sectorTableData, setSectorTableData] = useState([]);
  const [sectorDonutData, setSectorDonutData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const setSectorData = (tableData, donutData) => {
    setSectorTableData([
      ...tableData.sectors.map((data) => [
        data.name,
        numberFormat(data.subtotal),
        percentageFormat(data.percentage),
      ]),
      ["Total Gross", numberFormat(tableData.total), "100%"],
    ]);
    setSectorDonutData({ columns: donutData });
  };
  const getBlockSectorData = async () => {
    const tableData = await Appback.sectorBlockData(blockId);
    const donutData = [];
    tableData.sectors.map((i) => {
      donutData.push([i.name, parseFloat(i.subtotal)]);
      return i;
    });
    setSectorData(tableData, donutData);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    if (blockId) getBlockSectorData();
  }, [blockId]);

  return (
    <>
      {!isLoading ? (
        <>
          <Grid container spacing={1} className="by_sector_tables">
            <Grid item xs={12} sm={12} md={5}>
              <DataTable
                data={sectorTableData}
                header={["Sector", "$K", "%"]}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <DoughnutChart
                data={sectorDonutData}
                colors={selectedTheme}
                id="sectorChart"
                padding={{ bottom: 20 }}
                size={{ height: 400 }}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <div
          style={{
            paddingTop: "30px",
            margin: "auto",
            width: "fit-content",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </>
  );
};
Sector.propTypes = {
  blockId: PropTypes.string.isRequired,
  selectedTheme: PropTypes.string.isRequired,
};

export default Sector;
