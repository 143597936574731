import React from "react";
import { Container, Grid, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Service from "../services/hservice";

export default function ConfirmWithdraw(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const handleSubmit = () => {
    Service.getAccount().then((res1) => {
      if (JSON.parse(localStorage.getItem("transferProcessingText"))) {
        Service.insertFundTransfers(
          location.state?.id,
          res1.data.Account[0].id,
          parseFloat(localStorage.getItem("amount").replace(/\$|,/g, "")),
          "withdraw"
        ).then((res) => {
          navigate("/withdrawstatus");
        });
      } else {
        Service.deleteFundtransfers(res1.data.Account[0].id).then((res) => {
          navigate("/zerooutwithdrawstatus");
        });
      }
    });
  };
  return (
    <div className="confirm-money-transfer">
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={10} sm={8} md={8} lg={6} className="block-white">
            <br />
            <h3>Please confirm funds withdrawal</h3>
            <div>
              <table className="bank-details">
                <tbody>
                  <tr>
                    <td>From</td>
                    <td>:</td>
                    <td>XXXX-9999 (99rises)</td>
                  </tr>
                  <tr>
                    <td>To</td>
                    <td>:</td>
                    <td>
                      {localStorage
                        .getItem("account")
                        ?.replace(/.(?=.{4})/g, "X")}
                    </td>
                  </tr>

                  <tr>
                    <td>Amount</td>
                    <td>:</td>
                    <td>${localStorage.getItem("amount")}</td>
                  </tr>
                </tbody>
              </table>
              <p id="transfer_duration">
                Please allow 3-5 business days for processing. Your portfolio
                value will be adjusted accordingly
              </p>
              <Button
                onClick={handleSubmit}
                variant="contained"
                className="confirm"
                id="confirm_button"
              >
                Confirm
              </Button>
            </div>
            <br />
            <br />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
