import React from "react";
import { Grid, Button } from "@mui/material";
import { withStyles } from "@mui/styles";

const styles = {
  actionButton: {
    width: "100%",
    height: "50px",
    color: "white",
  },
};

const StepButtons = ({ onBack, classes }) => {
  return (
    <Grid container spacing={1} sx={{ justifyContent: "center" }}>
      <Grid item xs={6} md={6}>
        <Button
          onClick={onBack}
          variant="contained"
          className={classes.actionButton}
          id="back_button"
        >
          Back
        </Button>
      </Grid>
      <Grid item xs={6} md={6}>
        <Button
          type="submit"
          className={classes.actionButton}
          variant="contained"
          id="continue_button"
        >
          Continue
        </Button>
      </Grid>
    </Grid>
  );
};
export default withStyles(styles)(StepButtons);
