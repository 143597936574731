import React from "react";
import { Container, Grid, Button } from "@mui/material";
import "./toastmessage.scss";

const Toastmessage = (props) => {
  const handleContinue = () => {
    props.toastMessageupdate();
  };
  return (
    <div className="Toastmessge">
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={10} sm={8} md={8} lg={6} className="block-white">
            <p>{props.toast_message}</p>
            <br />
            <Button
              className="toast-message-button"
              onClick={() => handleContinue()}
              variant="contained"
              id="continue_button"
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
export default Toastmessage;
