module.exports = `<!DOCTYPE html>
<html xmlns="http://www.w3.org/1999/xhtml" xml:lang="en-us" lang="en-us">

<head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type">
    <meta name="author" content="mobius" />
    <title>99rises+-+Account+Management+Agreement</title>
    <style type="text/css">
        ol.lst-kix_list_1-3 {
            list-style-type: none
        }

        ol.lst-kix_list_1-4 {
            list-style-type: none
        }

        ol.lst-kix_list_1-5 {
            list-style-type: none
        }

        ol.lst-kix_list_1-6 {
            list-style-type: none
        }

        ol.lst-kix_list_1-0 {
            list-style-type: none
        }

        .lst-kix_list_1-4 > li {
            counter-increment: lst-ctn-kix_list_1-4
        }

        ol.lst-kix_list_1-1 {
            list-style-type: none
        }

        ol.lst-kix_list_1-2 {
            list-style-type: none
        }

        ol.lst-kix_list_1-6.start {
            counter-reset: lst-ctn-kix_list_1-6 0
        }

        .lst-kix_list_1-1 > li {
            counter-increment: lst-ctn-kix_list_1-1
        }

        ol.lst-kix_list_1-3.start {
            counter-reset: lst-ctn-kix_list_1-3 0
        }

        ol.lst-kix_list_1-2.start {
            counter-reset: lst-ctn-kix_list_1-2 0
        }

        ol.lst-kix_list_1-8.start {
            counter-reset: lst-ctn-kix_list_1-8 0
        }

        .lst-kix_list_1-0 > li:before {
            content: "" counter(lst-ctn-kix_list_1-0,decimal) ". "
        }

        ol.lst-kix_list_1-5.start {
            counter-reset: lst-ctn-kix_list_1-5 0
        }

        ol.lst-kix_list_1-7 {
            list-style-type: none
        }

        .lst-kix_list_1-1 > li:before {
            content: "(" counter(lst-ctn-kix_list_1-1,lower-latin) ") "
        }

        .lst-kix_list_1-2 > li:before {
            content: "(" counter(lst-ctn-kix_list_1-2,lower-roman) ") "
        }

        .lst-kix_list_1-7 > li {
            counter-increment: lst-ctn-kix_list_1-7
        }

        ol.lst-kix_list_1-8 {
            list-style-type: none
        }

        .lst-kix_list_1-3 > li:before {
            content: "(" counter(lst-ctn-kix_list_1-3,upper-latin) ") "
        }

        .lst-kix_list_1-4 > li:before {
            content: "(" counter(lst-ctn-kix_list_1-4,decimal) ") "
        }

        ol.lst-kix_list_1-0.start {
            counter-reset: lst-ctn-kix_list_1-0 0
        }

        .lst-kix_list_1-0 > li {
            counter-increment: lst-ctn-kix_list_1-0
        }

        .lst-kix_list_1-6 > li {
            counter-increment: lst-ctn-kix_list_1-6
        }

        .lst-kix_list_1-7 > li:before {
            content: "" counter(lst-ctn-kix_list_1-7,upper-latin) ". "
        }

        .lst-kix_list_1-3 > li {
            counter-increment: lst-ctn-kix_list_1-3
        }

        .lst-kix_list_1-5 > li:before {
            content: "" counter(lst-ctn-kix_list_1-5,lower-latin) ". "
        }

        .lst-kix_list_1-6 > li:before {
            content: "" counter(lst-ctn-kix_list_1-6,lower-roman) ". "
        }

        ol.lst-kix_list_1-7.start {
            counter-reset: lst-ctn-kix_list_1-7 0
        }

        .lst-kix_list_1-2 > li {
            counter-increment: lst-ctn-kix_list_1-2
        }

        .lst-kix_list_1-5 > li {
            counter-increment: lst-ctn-kix_list_1-5
        }

        .lst-kix_list_1-8 > li {
            counter-increment: lst-ctn-kix_list_1-8
        }

        ol.lst-kix_list_1-4.start {
            counter-reset: lst-ctn-kix_list_1-4 0
        }

        .lst-kix_list_1-8 > li:before {
            content: "" counter(lst-ctn-kix_list_1-8,decimal) ") "
        }

        ol.lst-kix_list_1-1.start {
            counter-reset: lst-ctn-kix_list_1-1 0
        }

        ol {
            margin: 0;
            padding: 0
        }

        table td, table th {
            padding: 0
        }

        .c5 {
            margin-left: 0pt;
            padding-top: 0pt;
            list-style-position: inside;
            text-indent: 45pt;
            padding-bottom: 12pt;
            line-height: 1.0;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left !important;
        }

        .c1 {
            -webkit-text-decoration-skip: none;
            color: #0000ff;
            font-weight: 400;
            text-decoration: underline;
            vertical-align: baseline;
            text-decoration-skip-ink: none;
            font-size: 12pt;
            font-family: "Arial";
            font-style: normal
        }

        .c20 {
            padding-top: 6pt;
            padding-bottom: 12pt;
            line-height: 1.0;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: center !important;
        }

        .c0 {
            color: #000000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 12pt;
            font-family: "Arial";
            font-style: normal
        }

        .c2 {
            color: #000000;
            font-weight: 700;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 12pt;
            font-family: "Arial";
            font-style: italic
        }

        .c15 {
            -webkit-text-decoration-skip: none;
            color: #0000ff;
            text-decoration: underline;
            vertical-align: baseline;
            text-decoration-skip-ink: none;
            font-size: 12pt
        }

        .c21 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.0;
            orphans: 2;
            widows: 2;
            text-align: center !important;
        }

        .c17 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.0;
            orphans: 2;
            widows: 2;
            text-align: right !important;
        }

        .c12 {
            padding-top: 0pt;
            padding-bottom: 12pt;
            line-height: 1.0;
            orphans: 2;
            widows: 2;
            text-align: center !important;
        }

        .c3 {
            padding-top: 0pt;
            padding-bottom: 12pt;
            line-height: 1.5;
            orphans: 2;
            widows: 2;
            text-align: justify !important;
        }

        .c13 {
            color: #000000;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 14pt;
            font-style: normal
        }

        .c19 {
            color: #000000;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 8pt;
            font-style: normal
        }

        .c7 {
            color: #000000;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 12pt;
            font-style: normal
        }

        .c22 {
            color: #000000;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 12pt
        }

        .c18 {
            background-color: #ffffff;
            max-width: 468pt;
            padding: 72pt 72pt 72pt 72pt
        }

        .c6 {
            font-weight: 700;
            font-family: "Arial"
        }

        .c11 {
            font-weight: 400;
            font-family: "Times New Roman"
        }

        .c8 {
            font-weight: 400;
            font-family: "Arial"
        }

        .c10 {
            color: inherit;
            text-decoration: inherit
        }

        .c4 {
            padding: 0;
            margin: 0
        }

        .c9 {
            margin-left: 36pt;
            text-indent: -36pt
        }

        .c14 {
            font-style: italic
        }

        .c16 {
            height: 12pt
        }

        .title {
            padding-top: 6pt;
            color: #000000;
            font-weight: 700;
            font-size: 12pt;
            padding-bottom: 12pt;
            font-family: "Times New Roman";
            line-height: 1.0;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: center !important;
        }

        .subtitle {
            padding-top: 0pt;
            -webkit-text-decoration-skip: none;
            color: #000000;
            font-weight: 700;
            text-decoration: underline;
            font-size: 12pt;
            padding-bottom: 12pt;
            line-height: 1.0;
            text-decoration-skip-ink: none;
            font-family: "Times New Roman";
            orphans: 2;
            widows: 2;
            text-align: center !important;
        }

        .scroll-area li {
            color: #000000;
            font-size: 12pt;
            font-family: "Times New Roman"
        }

        .scroll-area p {
            margin: 0;
            color: #000000;
            font-size: 12pt;
            font-family: "Times New Roman"
        }

        .scroll-area h1 {
            padding-top: 0pt;
            color: #000000;
            font-weight: 700;
            font-size: 12pt;
            padding-bottom: 12pt;
            font-family: "Times New Roman";
            line-height: 1.0;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .scroll-area h2 {
            padding-top: 0pt;
            -webkit-text-decoration-skip: none;
            color: #000000;
            text-decoration: underline;
            text-decoration-skip-ink: none;
            font-size: 12pt;
            padding-bottom: 12pt;
            font-family: "Times New Roman";
            line-height: 1.0;
            orphans: 2;
            widows: 2;
            text-align: left !important;
        }

        .scroll-area h3 {
            padding-top: 0pt;
            color: #000000;
            font-size: 12pt;
            padding-bottom: 12pt;
            font-family: "Times New Roman";
            line-height: 1.0;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .scroll-area h4 {
            padding-top: 0pt;
            color: #000000;
            font-size: 12pt;
            padding-bottom: 12pt;
            font-family: "Times New Roman";
            line-height: 1.0;
            orphans: 2;
            widows: 2;
            text-align: left !important;
        }

        .scroll-area h5 {
            padding-top: 0pt;
            color: #000000;
            font-size: 12pt;
            padding-bottom: 12pt;
            font-family: "Times New Roman";
            line-height: 1.0;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .scroll-area h6 {
            padding-top: 0pt;
            color: #000000;
            font-size: 12pt;
            padding-bottom: 12pt;
            font-family: "Times New Roman";
            line-height: 1.0;
            orphans: 2;
            widows: 2;
            text-align: left !important;
        }
    </style>
</head>
<body class="c18">
    <p class="c20 title">
        <span class="c6 c13">99rises Inc.</span>
    </p>
    <p class="c12 subtitle">
        <span class="c7 c6">Account Management Agreement</span>
    </p>
    <p class="c3">
        <span class="c0">This account management agreement (this &ldquo;</span>
        <span class="c2">Agreement</span>
        <span class="c0">&rdquo;) sets out the terms and conditions under which 99rises Inc. (&ldquo;</span>
        <span class="c2">99rises</span>
        <span class="c0">&rdquo;, &ldquo;</span>
        <span class="c2">we</span>
        <span class="c0">&rdquo;, or &ldquo;</span>
        <span class="c8 c14 c22">us</span>
        <span class="c0">&rdquo;), a registered investment adviser, will provide ongoing discretionary management services with respect to the assets in your 99rises account (the &ldquo;</span>
        <span class="c2">Account</span>
        <span class="c0">&rdquo;). &nbsp;This Agreement will become effective, and you will become our client (&ldquo;</span>
        <span class="c2">Client</span>
        <span class="c0">&rdquo; or &ldquo;</span>
        <span class="c2">you</span>
        <span class="c0">&rdquo;), on the date you sign it. &nbsp;You will sign this Agreement by checking and clicking the &ldquo;I Agree&rdquo; button or typing your name in the electronic signature field appearing after you have scrolled through this Agreement, either of which will constitute your electronic signature. &nbsp;Your electronic signature will be the same as if you signed a paper agreement by hand.</span>
    </p>
    <p class="c3">
        <span class="c0">In order to have 99rises manage your Account on an ongoing basis, you will need to open a securities brokerage and margin account, and complete a customer account agreement, with Tradier Brokerage Inc. (&ldquo;</span>
        <span class="c2">Tradier</span>
        <span class="c0">&rdquo;), an SEC registered broker-dealer. &nbsp;Accordingly, you will be subject to the terms of the Tradier customer account agreement (Brokerage services provided by Tradier Brokerage Inc. Refer to </span>
        <span class="c1"><a class="c10" href="https://brokerage.tradier.com/" target="_blank">brokerage.tradier.com</a></span>
        <span class="c0">&nbsp;for further information). Tradier will provide trade execution and, through Apex Clearing Corporation (&ldquo;</span>
        <span class="c2">Apex</span>
        <span class="c0">&rdquo;), an SEC registered broker-dealer custody, clearing, and settlement services, as well as recordkeeping and reporting services, to you. &nbsp;You can open your Account instantly on any mobile device by using the application program interface (&ldquo;</span>
        <span class="c2">API</span>
        <span class="c0">&rdquo;) within the 99rises App (defined below), after you electronically sign this Agreement. &nbsp;</span>
    </p>
    <p class="c3">
        <span class="c0">You and we agree:</span>
    </p>
    <ol class="c4 lst-kix_list_1-0 start" start="1">
        <li class="c5"><h1 style="display:inline"><span class="c2">ACCOUNT MANAGEMENT SERVICES</span></h1></li>
    </ol>
    <p class="c3">
        <span class="c0">The Client hereby appoints 99rises as its investment adviser and grants us limited power of attorney with discretionary trading authority to manage and trade the assets in your Account (the &ldquo;</span>
        <span class="c2">Services</span>
        <span class="c0">&rdquo;). &nbsp;99rises accepts that appointment under the terms and conditions of this Agreement. &nbsp;</span>
    </p>
    <p class="c3">
        <span class="c0">99rises will not provide investment advice other than the Services described in this Agreement. &nbsp;You acknowledge that: (i) 99rises will deliver the Services exclusively through the 99rises API on one or more mobile devices and/or </span><span class="c1"><a class="c10" href="http://www.99rises.com/" class="a" target="_blank">www.99rises.com</a></span><span class="c0">&nbsp;(the &ldquo;</span>
        <span class="c2">99rises Website</span>
        <span class="c0">&rdquo;) (collectively, including any updates and replacement or supplement to them, the &ldquo;</span>
        <span class="c2">99rises App</span>
        <span class="c0">&rdquo;); (ii) 99rises will not provide Services in person, over the phone, or through any other medium, other than through the 99rises App (see detailed information concerning the 99rises App on the 99rises Website); and (iii) you will not be entitled or able to hold securities in your Account other than the securities selected by 99rises. &nbsp;You may contact 99rises by phone or email during its business hours regarding technical questions about the 99rises App. &nbsp;However, to comply with our regulatory obligations, we will not elaborate or expand upon the investment advice provided through the 99rises App. &nbsp;You will have an opportunity to communicate through the 99rises App.</span>
    </p>
    <p class="c3">
        <span class="c0">Through the 99rises App, you will provide us information about yourself (including your employment and marital status, income and net assets, investment goals and reasons to invest, time horizon and risk attitudes), and, upon assessing your information, 99rises will determine your risk profile and propose a portfolio plan for you (your &ldquo;</span>
        <span class="c2">Portfolio</span>
        <span class="c0">&rdquo;). &nbsp;You authorize us, without prior consultation, consent or approval, to give instructions to Tradier to implement securities transactions for your Account. &nbsp;Our authority will include the ability to: (i) buy, sell and trade United States equity securities; (ii) invest in and trade ETFs; (iii) employ hedging strategies, including sell securities short; (iv) periodically rebalance your portfolio; and (v) implement all of the above based on 99rises&rsquo; systematic (algorithmic) portfolio model. &nbsp;</span>
    </p>
    <p class="c3">
        <span class="c0">You also authorize us to deliver to Tradier (or any other securities brokerage firm executing transactions on behalf of the Accounts) a copy of this Agreement as evidence of the authority of 99rises to act for and on behalf of your Account.</span>
    </p>
    <p class="c3">
        <span class="c0">You acknowledge that 99rises does </span>
        <span class="c2">not provide</span>
        <span class="c0">: (i) comprehensive financial planning services, and its Services are not a complete investment program; (ii) tax, accounting or legal advice; and (iii) custody of your Account assets. &nbsp;You are encouraged to consider additional asset classes, strategies and investments to supplement your overall plan; and to consult with your tax advisor regarding any tax consequences related to your Portfolio. </span>
    </p>
    <p class="c3">
        <span class="c0">99rises will be responsible for the review and evaluation of your current financial information and any adjustments to it that you provide to us, and will make its Portfolio recommendation based on that information. It is your responsibility to update your information if your situation changes. &nbsp;</span>
        <span class="c2">You are solely responsible for evaluating the merits and risks associated with any investments and the Portfolio recommended by 99rises</span>
        <span class="c0">.</span>
    </p>
    <ol class="c4 lst-kix_list_1-0" start="2">
        <li class="c5">
            <h1 style="display:inline">
                <span class="c2">ACCOUNT REQUIREMENTS.</span>
            </h1>
        </li>
    </ol>
    <p class="c3">
        <span class="c8">The minimum investment requirement in an Account is $5,000. &nbsp;A Client retains sole ownership of the Account (</span>
        <span class="c8 c14">i.e</span>
        <span class="c0">. the right to withdraw securities or cash, exercise or delegate proxy voting and receive transactions confirmations). &nbsp;You may make additions to and withdrawals from your Account at any time through the 99rises App, subject to 99rises&rsquo; right to terminate your account. &nbsp;Additions must be in cash. &nbsp;You may withdraw assets from your Account upon five (5) days&rsquo; notice to 99rises. &nbsp;Understand, however, that 99rises designs its portfolios as long-term investments; therefore, a withdrawal of assets may impair the achievement of your investment objectives. &nbsp;Further, when securities are withdrawn, you may be subject to transaction fees and/or tax consequences. </span>
    </p>
    <p class="c3">
        <span class="c0">You confirm that all of the information you have provided through the 99rises App is true, accurate and complete in all respects. &nbsp;You agree to notify us promptly of any change in your information that may be relevant to your financial circumstances. &nbsp;You understand that 99rises will contact you at least annually through the 99rises App, email or any other means deemed appropriate, to request that you review your Portfolio to determine whether it should be modified. &nbsp;You agree to review your Portfolio for accuracy and provide material updates in a timely manner.</span>
    </p>
    <ol class="c4 lst-kix_list_1-0" start="3">
        <li class="c5">
            <h1 style="display:inline">
                <span class="c2">FUNDING THE ACCOUNT</span>
            </h1>
        </li>
    </ol>
    <p class="c3">
        <span class="c0">You will be required to connect your Account to your U.S. bank or other U.S. financial institution account from which you intend to transfer moneys to fund the Account (the &ldquo;</span><span class="c2">Funding Account</span><span class="c0">&rdquo;) by entering into the 99rises App, true, accurate, current, and complete information about your Funding Account, including the American Bankers Association routing number and account number for the Funding Account. &nbsp;You acknowledge that your Account will not be connected to your Funding Account unless and until you receive a confirmation through the 99rises App indicating you have successfully connected the Account and Funding Account. &nbsp;You further agree that, if your Funding Account is closed or restricted after you connect it to the Account, you will have no right to make additions to or withdrawals from your Account unless and until: (i)&nbsp;the Funding Account is reopened or unrestricted; or (ii)&nbsp;you successfully connect a different and adequately funded Funding Account to the Account.</span>
    </p>
    <ol class="c4 lst-kix_list_1-0" start="4">
        <li class="c5"><h1 style="display:inline"><span class="c2">FEES &amp; EXPENSES.</span></h1></li>
    </ol>
    <p class="c3">
        <span class="c2">Wrap Program Fee. &nbsp;</span>
        <span class="c0">99rises offers the Services through the 99rises Wrap Fee Program (the &ldquo;</span>
        <span class="c2">Program</span><span class="c0">&rdquo;). &nbsp;Under the Program, you will pay a single asset-based fee for the combined investment advisory, brokerage, custodial, and other related services (&ldquo;</span>
        <span class="c2">Wrap Program Fee</span>
        <span class="c0">&rdquo;). &nbsp;The Wrap Program Fee does not include the Other Fees described below. &nbsp;All assets held in your Account, including the cash you invest, the value of the securities and appreciation thereon, and assets acquired through dividend reinvestments, are subject to the Wrap Program Fee. &nbsp;</span>
    </p>
    <p class="c3">
        <span class="c0">The Wrap Program Fee is 100 basis points (1.00%) per year. &nbsp;The Wrap Program fee will be prorated and charged monthly in arrears, based upon the market value of the average daily balance of the securities portfolio in your Account over the preceding month. &nbsp;If assets are deposited into or withdrawn from a Account after the inception of a month, the Wrap Program Fee payable with respect to such assets is adjusted accordingly. &nbsp;This also includes the initial period of an engagement after the Account is opened and the final period before the Account Management Agreement is terminated: the Wrap Program Fee for those periods, if they are partial months, will be prorated based on the number of days during the month that the Account is open. &nbsp;</span>
    </p>
    <p class="c3">
        <span class="c2">Other Fees. &nbsp;</span>
        <span class="c0">You understand that, in addition to equity securities of individual companies, 99rises will invest in shares of ETFs for your Account in accordance with your Portfolio. &nbsp;As a shareholder in these ETFs (and in any money market funds), you will bear a share of the management and other expenses of such ETFs as disclosed in each ETF&rsquo;s prospectus. &nbsp;These expenses are charged by the ETFs&rsquo; managers and service providers in the normal course of business and are reflected in the share value of the ETFs. &nbsp;These expenses are separate and apart from your Wrap Program Fee. &nbsp;You will receive from us (posted on the 99rises Website) a prospectus or prospectus summary when ETFs (or money market funds) are purchased for your Account. &nbsp;You agree that it is your responsibility to read the applicable prospectuses in connection with your decision to invest.</span>
    </p>
    <p class="c3">
        <span class="c0">Additionally, you may incur certain other charges imposed by third party financial institutions. &nbsp;These additional costs may include but are not limited to: wire transfer fees, paper statement fees, and bounced check fees, transfer taxes, wire transfer and electronic fund fees. &nbsp;Those additional charges and expenses are listed at </span>
        <span class="c1"><a class="c10" href="https://99rises-docs.s3-us-west-2.amazonaws.com/legal/99rises+-+Fee+Schedule+v1.pdf" target="_blank">fees chedule</a></span>
        <span class="c0">.</span>
    </p>
    <p class="c3">
        <span class="c2">Performance-based Fees. &nbsp;</span>
        <span class="c0">99rises does not charge any performance-based fees (that is, fees based upon a share of capital gains on or capital appreciation of the assets in your Account).</span>
    </p>
    <p class="c3">
        <span class="c2">Fee Payment</span>
        <span class="c0">. By signing this Agreement, you authorize 99rises to direct Tradier to deduct the Wrap Program Fee and any other applicable fees, directly from your Account, in accordance with applicable custody rules. &nbsp;It is your responsibility to verify the accuracy of the calculation of the Wrap Program Fee. &nbsp;In the event your Account does not include a cash balance adequate to pay the Wrap Program Fee, you authorize 99rises to determine which assets in your Account will be sold, and to liquidate these assets, without notice to you, to pay the Wrap Program Fee, and any other fees due under this Agreement.</span>
    </p>
    <p class="c3">
        <span class="c0">Payment of fees generally will be made first from Account assets in cash, next from the liquidation of cash equivalents, including shares of money market funds (if any), and finally from the liquidation of ETFs and other securities in the Account.</span>
    </p>
    <p class="c3">
        <span class="c2">Discretion. </span>
        <span class="c0">99rises&rsquo; fees are not negotiable. &nbsp;99rises reserves the right to waive all or any portion of the Wrap Program Fee for any period for any Client or groups of Clients, at its sole discretion. &nbsp;99rises has sole discretion in determining whether or not any Client or potential client may receive lower fees, and 99rises shall not be liable to you or any other party in connection with any such decision. &nbsp;If 99rises lowers any fees for any Client, it has no obligation to continue to do so, and may cancel such benefit at any time. &nbsp;</span>
    </p>
    <ol class="c4 lst-kix_list_1-0" start="5">
        <li class="c5">
            <h1 style="display:inline">
                <span class="c2">CUSTODY OF ASSETS AND ACCOUNT STATEMENTS</span>
            </h1>
        </li>
    </ol>
    <p class="c3">
        <span class="c0">Apex, a &ldquo;qualified custodian&rdquo; under Rule 206(4)-2 of the Investment Advisers Act of 1940, as amended (the &ldquo;</span>
        <span class="c2">Advisers Act</span>
        <span class="c0">&rdquo;), will have possession and provide safekeeping of your assets in the Account (the &ldquo;</span>
        <span class="c2">Custodian</span>
        <span class="c0">&rdquo;). &nbsp;99rises will not act as the custodian for the assets in your Account, though our right to instruct Tradier to deduct fees from your Account deems us to have limited &ldquo;custody&rdquo; of your assets. &nbsp;You will complete the necessary application materials to open an account with the Custodian after agreeing to this Agreement. All Account transactions will be effected by a payment to, or delivery by, Tradier of all cash and/or securities due to or from the Account. &nbsp;99rises is not permitted to instruct the Custodian to deliver funds or securities to ourselves, except for payment of our fees as explained above. &nbsp; &nbsp;</span>
    </p>
    <p class="c3">
        <span class="c0">Tradier will send you electronic notice of all confirmations of securities transactions and provide account statements to you at least quarterly, identifying the amount of funds and of each security in the Account at the end of the period and setting forth all transactions during that period. &nbsp;You are responsible for reviewing the confirmations and statements provided by Tradier and reporting any discrepancies in the information directly to Tradier. &nbsp;You authorize us to instruct Tradier to send us trade confirms and monthly statements showing all transactions occurring in your Account during the period covered. &nbsp;We will not be responsible for any loss incurred by reason of any act or omission by Tradier or the Custodian, whether in its capacity as a custodian or a broker/dealer; provided, however, that we will make reasonable efforts to require that it perform its obligations with respect to the Account. &nbsp;All transactions concerning the Account shall be binding upon the Client at the Client&rsquo;s sole risk.</span>
    </p>
    <ol class="c4 lst-kix_list_1-0" start="6">
        <li class="c5">
            <h1 style="display:inline">
                <span class="c2">BROKERAGE AND TRANSACTION EXECUTION</span>
            </h1>
        </li>
    </ol>
    <p class="c3">
        <span class="c0">Upon your agreement to this Agreement and the custody and other agreements with Tradier and Apex, Tradier will act as the broker/dealer which will execute all orders on behalf of the Account. &nbsp;99rises anticipates (but is not obligated to) that it will combine or aggregate orders for your Account with other 99rises Client accounts to obtain &ldquo;best execution,&rdquo; to negotiate more favorable commission rates or to allocate equitably among the 99rises Client accounts differences in prices and commissions or other transaction costs that might have been obtained had each such order been placed independently. &nbsp;Under this procedure, transactions will be averaged as to price and transaction costs, and will be allocated among your Account and other Client accounts in proportion to the purchase and sale orders placed for each Client account on any given day. &nbsp;If 99rises cannot obtain execution of all the combined orders at prices or for transactions costs that 99rises believes are desirable, 99rises will allocate the securities 99rises does buy or sell as part of the combined orders by following 99rises&rsquo; order allocation policies and procedures. &nbsp;</span>
    </p>
    <p class="c3">
        <span class="c0">To participate in the 99rises Wrap Fee Program, you must direct all brokerage transactions for your Account to Tradier. &nbsp;You cannot designate or select a different broker for trade execution. &nbsp;99rises anticipates that Tradier will provide &ldquo;best execution&rdquo; for the Client. &nbsp;However, in the event 99rises determines that Tradier does not at any time provide &ldquo;best execution&rdquo; in its capacity as a broker/dealer, 99rises reserves the right to select a different broker/dealer which will provide &ldquo;best execution&rdquo; with respect to such transaction. &ldquo;Best execution&rdquo; includes the amount of broker/dealer fees or transaction costs that will be charged to the Account in connection with any trade, but it may also include other criteria and benefits. &nbsp;As such, 99rises may retain Tradier as the broker/dealer with respect to the Account even if its broker/dealer fees are not the lowest fees which could be charged for such transaction, in consideration for other benefits that the broker/dealer offers.</span>
    </p>
    <ol class="c4 lst-kix_list_1-0" start="7">
        <li class="c5">
            <h1 style="display:inline">
                <span class="c2">PROXIES AND CORPORATE LEGAL ACTIONS.</span>
            </h1>
        </li>
    </ol>
    <p class="c3">
        <span class="c0">You understand that 99rises will not vote or advise you about the voting of proxies for the securities held in your Account, or about any legal proceeding, including bankruptcies or class actions, or corporate actions involving securities in your Account. &nbsp;It is your responsibility to make decisions about proxy voting and corporate actions. &nbsp;We will instruct Apex to forward copies of all proxies and shareholder communications directly to you, and you may exercise those rights in your discretion. &nbsp;</span>
    </p>
    <ol class="c4 lst-kix_list_1-0" start="8">
        <li class="c5">
            <h1 style="display:inline">
                <span class="c2">RECEIPT OF DISCLOSURES AND PRIVACY POLICY.</span>
            </h1>
        </li>
    </ol>
    <p class="c3">
        <span class="c0">You acknowledge that you have received a copy of 99rises&rsquo;: (i) Wrap Fee Program Brochure (</span><span class="c1"><a class="c10" href="https://www.99rises.com/legal" target="_blank">https://www.99rises.com/legal</a></span><span class="c0">) and Brochure Supplement (</span><span class="c1"><a class="c10" href="https://www.99rises.com/legal" target="_blank">https://www.99rises.com/legal</a></span><span class="c0">) and (ii) Privacy Policy (</span><span class="c1"><a class="c10" href="https://www.99rises.com/legal" target="_blank">https://www.99rises.com/legal</a></span><span class="c0">).</span>
    </p>
    <ol class="c4 lst-kix_list_1-0" start="9">
        <li class="c5">
            <h1 style="display:inline">
                <span class="c8 c14">ACKNOWLEDGEMENTS, REPRESENTATIONS AND COVENANTS.</span>
            </h1>
        </li>
    </ol>
    <p class="c3">
        <span class="c0">You understand, agree and confirm to 99rises that:</span>
    </p>
    <p class="c3">
        <span class="c2">Authority. &nbsp;</span>
        <span class="c0">You have the full legal power, authority and capacity to enter into this Account Management Agreement. &nbsp;This Account Management Agreement constitutes a legal, valid and binding obligation on you with respect to your Account.</span>
    </p>
    <p class="c3">
        <span class="c2">The Program is appropriate. &nbsp;</span>
        <span class="c0">You have evaluated the Program and determined that it is appropriate for you, taking into account all relevant factors, including, your need for investment advice, the amount of trading you expect to make in the account compared to accounts where fees are transaction based, your risk tolerance and investment experience, your particular financial needs and circumstances, and the fees charged for the account versus other types of investment accounts. &nbsp;You understand that, depending on the circumstances, the brokerage and execution services offered through this program may be available for less money (but without the ongoing advisory services) through a commission based brokerage account. &nbsp;You acknowledge that your Account is not intended solely as a cash management vehicle.</span>
    </p>
    <p class="c3">
        <span class="c2">Investment Risks. &nbsp;</span>
        <span class="c0">You are aware of and willing to assume the risks involved with investing your Account in the Portfolio. &nbsp;You understand that 99rises does not guarantee the future performance of your assets in the Account or any specific level of performance, the success of any investment recommendation or the success of our overall management of your Account. &nbsp;Our investment recommendations are subject to various market, currency, economic, political and business risks. &nbsp;Investment decisions will not always result in profitable outcomes. &nbsp;</span>
        <span class="c7 c6">You may experience losses, including potentially a complete loss of your investment. &nbsp;You acknowledge that you have read the additional risk disclosures in 99rises&rsquo; Wrap Fee Program Brochure</span>
        <span class="c2">&nbsp;(</span>
        <span class="c6 c14 c15"><a class="c10" href="https://www.99rises.com/legal" target="_blank">https://www.99rises.com/legal</a></span>
        <span class="c2">).</span>
    </p>
    <p class="c3">
        <span class="c2">United States Resident. &nbsp;</span>
        <span class="c0">You are a &ldquo;US Person,&rdquo; which includes a US resident alien, have a valid US residential mailing address and have a valid US taxpayer identification number (or social security number).</span>
    </p>
    <p class="c3">
        <span class="c2">Indemnification. &nbsp;</span>
        <span class="c0">You will defend, indemnify and hold all of the </span>
        <span class="c6">&ldquo;</span><span class="c7 c6">Indemnified Persons</span>
        <span class="c6">&rdquo;</span><span class="c8">&nbsp;or</span>
        <span class="c6">&nbsp;&ldquo;Indemnified Party&rdquo; </span>
        <span class="c8">(any of our agents, including any service providers, together with our Affiliates)</span>
        <span class="c0">&nbsp;harmless from any and all </span>
        <span class="c6">&ldquo;</span>
        <span class="c7 c6">Losses</span>
        <span class="c6">&rdquo; (any obligations, costs, fees, losses, liabilities, claims, judgments, actions, damages and expenses, including but not limited to attorneys&rsquo; fees, expenses and court costs) </span><span class="c0">&nbsp;sustained by any Indemnified Party arising out of or in connection with any misrepresentations or omissions made by you in this Account Management Agreement, any inaccuracies in the information that you provide to us or any other Indemnified Party, or any instructions that you provide to us or any other Indemnified Party in connection with your Account.</span>
    </p>
    <p class="c3">
        <span class="c2">Non-Exclusivity. &nbsp;</span>

        <span class="c0">You acknowledge that we may, and will, render investment advice to others. &nbsp;We and our Affiliates may take the same, similar, or different positions in specific investments for our other clients&rsquo; and our own accounts, as we do for you. &nbsp;We have no obligation to purchase or sell, or to recommend for purchase or sale, any security which we or our Affiliates may purchase or sell for our other clients&rsquo; and our own accounts.</span>
    </p>
    <ol class="c4 lst-kix_list_1-0" start="10">
        <li class="c5">
            <h1 style="display:inline">
                <span class="c2">Termination</span>
            </h1>
        </li>
    </ol>
    <p class="c3">
        <span class="c0">You may terminate this Account Management Agreement at any time and the termination will be effective upon receipt of notification of the termination. &nbsp;You understand that 99rises may also elect to terminate your Account at any time, for any reason or no reason, in its sole discretion, upon notice to you. &nbsp;To the extent that the advisory relationship is terminated, you understand that all features and privileges associated with the Account will be cancelled and cease. &nbsp;You will still maintain an Tradier account, but you will no longer receive on-going monitoring, rebalancing and investment advice from 99rises. Further, Tradier may terminate your account separately.</span>
    </p>
    <p class="c3">
        <span class="c0">Termination of this Agreement will not affect (a) the validity of any action previously taken under this Agreement; (b) liabilities or obligations from transactions initiated before termination of this Agreement; or (c) your obligation to pay us fees that have already been earned under this Agreement. &nbsp;If you terminate this Agreement, you will promptly pay us any unpaid but earned Wrap Program Fee or Subscription Fee, as appropriate.</span>
    </p>
    <p class="c3">
        <span class="c0">A Client&rsquo;s death, disability or incompetence will not automatically terminate or change the terms of this Agreement. &nbsp;However, your executor, guardian, attorney-in-fact or other authorized representative may terminate this Account Management Agreement by giving us proper written notice. &nbsp;We will not be responsible for any transfers, payments or other transactions in the Account made at the direction of a former account holder or incapacitated account holder before we actually received and had a reasonable amount of time to act on such official written notice. &nbsp;Following receipt of an official written notice, we may require additional documents and reserve the right to retain the assets in and/or restrict transactions in the Account in our sole discretion. &nbsp;Any former account holder and the estate of any deceased or incapacitated account holder will remain jointly and severally liable for any losses in the Account arising out of or relating to transactions initiated before we actually received and had a reasonable amount of time to act on such official written notice.</span>
    </p>
    <ol class="c4 lst-kix_list_1-0" start="11">
        <li class="c5">
            <h1 style="display:inline">
                <span class="c2">Legal Capacity</span>
            </h1>
        </li>
    </ol>
    <p class="c3">
        <span class="c0">If this Agreement is established by the undersigned Client, or the Client&rsquo;s authorized representative in a fiduciary capacity, the Client hereby certifies that he/she is legally empowered to enter into or perform this Agreement in such a capacity. &nbsp;The Client represents that he or she is over 18 years of age and no person shall use or attempt to use the Services unless he or she is over 18.</span>
    </p>
    <ol class="c4 lst-kix_list_1-0" start="12">
        <li class="c5">
            <h1 style="display:inline">
                <span class="c2">AMENDMENTS.</span>
            </h1>
        </li>
    </ol>
    <p class="c3">
        <span class="c0">99rises may amend this Account Management Agreement at any time; provided, that we will give you 30 calendar days&rsquo; prior notice. &nbsp;You acknowledge that 99rises will generally not be required to obtain your consent for any amendments to the Account Management Agreement or changes to the Program.</span>
    </p>
    <ol class="c4 lst-kix_list_1-0" start="13">
        <li class="c5">
            <h1 id="h.gjdgxs" style="display:inline">
                <span class="c2">NOTICES AND COMMUNICATIONS.</span>
            </h1>
        </li>
    </ol>
    <p class="c3">
        <span class="c0">Our opening and maintaining your Account is conditioned on your agreement to receive all notices, documents, and other information related to your account and investments </span>
        <span class="c2">electronically</span>
        <span class="c0">. &nbsp;You agree that when we send these email notices to you that they constitute delivery to you of the information or documents referred to in the email you provided, even if you do not actually access the information or documents on our website.</span>
    </p>
    <p class="c3">
        <span class="c0">This consent will be effective immediately and will remain in effect unless revoked by you, as described in Section 18 below.</span>
    </p>
    <p class="c3">
        <span class="c0">You agree to keep a working email address and will update your account information immediately if your email address or other contact information changes. &nbsp;If you do not maintain an email address that is working and accessible to us, and we believe we are required to provide you with paper notice or documents of particular matters or actions, and we do so, we may charge you the cost of such delivery. &nbsp;You acknowledge that you may incur costs (such as online service provider charges or printing costs) associated with the electronic delivery of information to you. &nbsp;To view PDF files, you will need to download the Adobe Acrobat Reader, which is provided for free from Adobe.</span>
    </p>
    <p class="c3">
        <span class="c0">Any notice given to you in connection with this Account Management Agreement will be deemed delivered if personally delivered or sent by (i) U.S. mail, certified or registered, or overnight courier, postage prepaid with return receipt request, and addressed, if to you, at the address indicated in your Account information, or (ii) electronically to the address to which your Account communications are sent, and if to 99rises: 4300 Black Ave, #1572, Pleasanton, CA 94566 or (iii) posted to </span>
        <span class="c1"><a class="c10" href="https://www.99rises.com" target="_blank"> www.99rises.com</a></span>
        <span class="c0">.</span>
    </p>
    <ol class="c4 lst-kix_list_1-0" start="14">
        <li class="c5">
            <h1 style="display:inline">
                <span class="c2">MISCELLANEOUS.</span>
            </h1>
        </li>
    </ol>
    <p class="c3">
        <span class="c0">Neither you nor 99rises may assign (within the meaning of the Advisers Act) this Agreement without the consent of the other party. &nbsp;This Account Management Agreement and the 99rises App Terms of Use (</span>
        <span class="c1"><a class="c10" href="https://www.99rises.com/legal" target="_blank">https://www.99rises.com/legal</a></span>
        <span class="c0">), incorporated by reference, constitute the entire agreement between you and us.</span>
    </p>
    <p class="c3">
        <span class="c0">If any provision of this Account Management Agreement is deemed to be invalid or unenforceable or is prohibited by the laws of the state or jurisdiction where it is to be performed, this Agreement will be considered divisible as to such provision and such provision will be inoperative in such state or jurisdiction. &nbsp;The remaining provisions of this Agreement will be valid and binding and of full force and effect as though such provision was not included. &nbsp;</span>
    </p>
    <ol class="c4 lst-kix_list_1-0" start="15">
        <li class="c5">
            <h1 style="display:inline">
                <span class="c2">GOVERNING LAW.</span>
            </h1>
        </li>
    </ol>
    <p class="c3">
        <span class="c0">This Account Management Agreement will be governed by and construed in accordance with the laws of Delaware. &nbsp;Any action, suit or proceeding arising out of, under or in connection with this Agreement seeking an injunction or not otherwise submitted to arbitration pursuant to this Agreement will be brought and determined in the appropriate federal or state court in the State of California and in no other forum. &nbsp;The parties hereby irrevocably and unconditionally submit to the personal jurisdiction of such courts and agree to take any and all action necessary to submit to the jurisdiction of such courts in any such suit, action or proceeding arising out of or relating to this Agreement. &nbsp;No failure by us to exercise any right, power, or privilege will operate as a waiver thereof. &nbsp;No waiver of any breach of this Agreement by you will be deemed to be a waiver of any subsequent breach.</span>
    </p>
    <ol class="c4 lst-kix_list_1-0" start="16">
        <li class="c5">
            <h1 style="display:inline">
                <span class="c2">EFFECTIVE DATE.</span>
            </h1>
        </li>
    </ol>
    <p class="c3">
        <span class="c0">This Account Management Agreement will be effective on your acceptance of its terms electronically by clicking the &ldquo;I Agree&rdquo; button.</span>
    </p>
    <ol class="c4 lst-kix_list_1-0" start="17">
        <li class="c5">
            <h1 style="display:inline">
                <span class="c2">ARBITRATION.</span>
            </h1>
        </li>
    </ol>
    <p class="c3">
        <span class="c0">To the extent permitted by law, any controversy, dispute or claim arising out of or relating to this Agreement will be submitted to arbitration before a single arbitrator in accordance with the Commercial Rules of the American Arbitration Association. &nbsp;The prevailing party will be entitled to reasonable attorneys&rsquo; fees, costs and expenses. &nbsp;</span><span class="c2">This agreement to arbitrate does not constitute a waiver of your right to seek a judicial forum where such waiver would be void under federal or applicable state securities laws.</span>
    </p>
    <ol class="c4 lst-kix_list_1-0" start="18">
        <li class="c5">
            <h1 id="h.30j0zll" style="display:inline">
                <span class="c2">ELECTRONIC DELIVERY OF DOCUMENTS.</span>
            </h1>
        </li>
    </ol>
    <p class="c3">
        <span class="c0">The Program is an electronically based service. &nbsp;By accepting this Account Management Agreement when you click &ldquo;</span>
        <span class="c7 c6">I Agree</span>
        <span class="c0">,&rdquo; we are obtaining your informed consent to electronic delivery of all notices and communications relating to your Account to an e-mail address that you provided. &nbsp;This includes all notices, disclosures, regulatory communications (including privacy notices) and other information, documents, data and records regarding your Account (&ldquo;</span>
        <span class="c2">Account Communications</span>
        <span class="c0">&rdquo;).</span>
    </p>
    <p class="c3">
        <span class="c0">You may revoke your consent to electronic delivery of Account Communications by providing notice in accordance with Section 13 of this Account Management Agreement, and such revocation will be effective upon successful completion of its processing by 99rises. &nbsp;You understand that if you revoke or restrict your consent to electronic delivery of Account Communications or request paper delivery, 99rises, at its discretion, may (i) terminate this agreement and/or close your Account or (ii) charge you for the cost of the delivery of Account Communications that would otherwise be delivered to you electronically. &nbsp;Neither your revocation of consent to electronic delivery, your request for paper delivery, nor 99rises&rsquo; delivery of paper copies of Account Communications will affect the legal effectiveness or validity of any electronic communication provided while your consent was in effect. &nbsp;All e-mail notifications of Account Communications will be sent to your e-mail address of record. &nbsp;You acknowledge that the Internet is not a secure network and that communications transmitted over the Internet may be accessed by unauthorized or unintended third parties. &nbsp;Due to security risks, you will not send any sensitive information, such as account numbers or passwords, in an unencrypted email.</span>
    </p>
    <p class="c3">
        <span class="c0">E-mails on rare occasions may fail to transmit properly. &nbsp;You agree that, solely for your records, you can download and save or print the Account Communications you receive via electronic delivery.</span>
    </p>
    <p class="c3">
        <span class="c0">This consent is effective upon the execution of this Agreement and will remain in effect unless and until you revoke your consent to electronic delivery. &nbsp;You understand that it may take up to three (3) business days to process a revocation of consent to electronic delivery, and you may receive electronic notifications in the interim.</span>
    </p>
    <p class="c3">
        <span class="c0">Potential costs associated with electronic delivery of Account Communications include charges from Internet access providers and telephone companies, and such charges are borne solely by you. &nbsp;99rises does not charge additional online access fees for receiving electronic delivery of Account Communications. &nbsp;You understand that to receive electronic deliveries, you must have Internet access, a valid e-mail address with sufficient storage space, the ability to download documents as 99rises may specify and to which you have access and a printer or other device to download and print or save any information you may wish to retain.</span>
    </p>
    <p class="c3">
        <span class="c0">You acknowledge that you have, and will continue to have and maintain, Internet access and a valid e-mail address with characteristics as described in this paragraph, and you are able to receive electronic deliveries as set forth herein. &nbsp;99rises will notify you of any changes in the hardware and software requirements needed to access electronic records covered by this consent.</span>
    </p>
    <p class="c3">
        <span class="c0">You hereby agree that you have carefully read the above information regarding informed consent and fully understand the implications thereof. &nbsp;You hereby agree to the conditions outlined above concerning electronic delivery of Account Communications. &nbsp;If your e-mail address changes, you agree to notify 99rises of your new e-mail address immediately in writing in accordance with the notice provisions of this Account Management Agreement.</span>
    </p>
    <ol class="c4 lst-kix_list_1-0" start="19">
        <li class="c5">
            <h1 style="display:inline">
                <span class="c2">ELECTRONIC SIGNATURES.</span>
            </h1>
        </li>
    </ol>
    <p class="c3">
        <span class="c0">Your intentional action in electronically signing this Account Management Agreement is valid evidence of your consent to be legally bound by this Account Management Agreement. &nbsp;The use of an electronic version of any documents fully satisfies any requirement that they be provided to you in writing.</span>
    </p>
    <p class="c3">
        <span class="c0">You are solely responsible for reviewing and understanding all of the terms and conditions of these documents. &nbsp;You accept as reasonable and proper notice, for the purpose of any and all laws, rules and regulations, notice by electronic means, including, the posting of modifications to this Account Management Agreement on the 99rises App or 99rises Website. &nbsp;The electronically stored copy of this Account Management Agreement is considered to be the true, complete, valid, authentic and enforceable record of the Account Management Agreement, admissible in judicial or administrative proceedings to the same extent as if the documents and records were originally generated and maintained in printed form.</span>
    </p>
    <p class="c3">
        <span class="c0">You agree to not contest the admissibility or enforceability of 99rises&rsquo; electronically stored copy of the Agreement in any proceeding arising out of the terms and conditions of the Account Management Agreement. &nbsp;If more than one individual has electronically signed this Account Management Agreement, your obligations under this Account Management Agreement will be joint and several.</span>
    </p>
    <p class="c3">
        <span class="c6 c7">By clicking the &ldquo;</span>
        <span class="c2">I Agree</span>
        <span class="c7 c6">&rdquo; button, you acknowledge that you (on your own behalf and any joint accountholder&rsquo;s behalf):</span>
    </p>
    <p class="c3 c9">
        <span class="c7 c6">i.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;agree to arbitrate any controversies that may arise under this Account Management Agreement;</span>
    </p>
    <p class="c3 c9">
        <span class="c7 c6">ii.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;have reviewed the Portfolio designation relating to your Account and agree to, and accept, the Portfolio selection and it is incorporated herein by reference;</span>
    </p>
    <p class="c3 c9">
        <span class="c7 c6">iii.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;have read, understand and agree with all of the terms and conditions set forth in this Account Management Agreement;</span>
    </p>
    <p class="c3 c9">
        <span class="c7 c6">iv.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;have (i) received and read 99rises&rsquo; Wrap Fee Program Brochure and Brochure Supplement and (ii) received a copy of 99rises&rsquo; Privacy Policy; and </span>
    </p>
    <p class="c3 c9">
        <span class="c7 c6">v.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;understand that clicking &ldquo;</span>
        <span class="c2">I Agree</span>
        <span class="c7 c6">&rdquo; is the legal equivalent of manually signing this Agreement, and you will be legally bound by its terms and conditions.</span>
    </p>
    <p class="c3">
        <span class="c0">_______________________________</span>
    </p>
    <p class="c3 c16">
        <span class="c2"></span>
    </p>
    <div>
        <p class="c16 c21"><span class="c7 c11"></span></p>
        <p class="c16 c17"><span class="c11 c19"></span></p>
    </div>
</body>
</html>`;
