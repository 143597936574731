const SelectTheme = {
  Hues: {
    'Basic Materials': '#93BB24',
    'Consumer Cyclical': '#69C724',
    'Financial Services': '#22d646',
    'Real Estate': '#34c79b',
    'ConsumerDefensive': '#c170a7',
    Healthcare: '#fc3f7e',
    Utilities: '#fd6e21',
    'Communication Services': '#4d73fb',
    Energy: '#1789fb',
    Industrials: '#5fa8e8',
    Technology: '#24acce',
  },
  Tones: {
    'Basic Materials': '#8aa06e',
    'Consumer Cyclical': '#82b145',
    'Financial Services': '#69c929',
    'Real Estate': '#34c774',
    'ConsumerDefensive': '#a77971',
    Healthcare: '#d76072',
    Utilities: '#e9933d',
    'Communication Services': '#709aaa',
    Energy: '#24acce',
    Industrials: '#5fa8e8',
    Technology: '#839bd9',
  },
  Shades: {
    'Basic Materials': '#26743c',
    'Consumer Cyclical': '#269854',
    'Financial Services': '#30bc68',
    'Real Estate': '#4adc67',
    'ConsumerDefensive': '#ca0812',
    Healthcare: '#fb0d1d',
    Utilities: '#fc4e51',
    'Communication Services': '#2e6292',
    Energy: '#1f6ebe',
    Industrials: '#2882d7',
    Technology: '#3596db',
  },
  '1': {
    'Basic Materials': '#5e6cbe',
    'Consumer Cyclical': '#544fab',
    'Financial Services': '#472f9d',
    'Real Estate': '#674cc0',
    'ConsumerDefensive': '#865ed6',
    Healthcare: '#5234a4',
    Utilities: '#5d3bae',
    'Communication Services': '#7e5bbf',
    Energy: '#4d318f',
    Industrials: '#624b97',
    Technology: '#12085c',
  },
  '2': {
    'Basic Materials': '#5e6cbe',
    'Consumer Cyclical': '#544fab',
    'Financial Services': '#147bbf',
    'Real Estate': '#0d5b9a',
    'ConsumerDefensive': '#154aa0',
    Healthcare: '#042470',
    Utilities: '#074d41',
    'Communication Services': '#0b5962',
    Energy: '#10766d',
    Industrials: '#117c8f',
    Technology: '#157e2a',
  },
  '3': {
    'Basic Materials': '#5e6cbe',
    'Consumer Cyclical': '#544fab',
    'Financial Services': '#aa4dba',
    'Real Estate': '#674cc0',
    'ConsumerDefensive': '#865ed6',
    Healthcare: '#9b53c8',
    Utilities: '#6a2498',
    'Communication Services': '#6a0f7e',
    Energy: '#4d318f',
    Industrials: '#644d9b',
    Technology: '#863d95',
  },
};
export default SelectTheme;
