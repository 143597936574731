import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Button,
  Checkbox,
  TextField,
  Link,
  Box,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Auth } from "aws-amplify";
import { useFormik } from "formik";
import * as yup from "yup";
import { RootContext } from "../context/root-provider";
import AppBackService from "../services/app-back";

const validationSchema = yup.object({
  email_address: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string("Enter your password").required("Password is required"),
});
const authValidationSchema = yup.object({
  auth_code: yup
    .string("Enter verification code")
    .required("A verification code is required"),
});

const LoginWithAuth = () => {
  const Appback = AppBackService();
  const { state } = useLocation();
  const Styles = {
    ".belowLogin": {
      margin: "0 auto",
    },
  };
  const [values, setValues] = useState({
    showPassword: false,
    user: {},
    error: "",
    auth_code: "",
    hideAuthCode: true,
    phone_number: "",
    checkedB: false,
    email_address: "",
    password: "",
    requestedOTP: false,
  });
  const { setAuthStatus, setRedirectUrl } = useContext(RootContext);
  const [resendCount, setResendCount] = useState(0);

  const formik = useFormik({
    initialValues: {
      email_address: "",
      password: "",
      requestedOTP: false,
    },
    validationSchema,
    onSubmit: (login) => {
      handleLogin(login);
    },
  });
  const authFormik = useFormik({
    initialValues: {
      auth_code: "",
    },
    validationSchema: authValidationSchema,
    onSubmit: (login) => {
      handleAuthCode(login);
    },
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onKeyPress = (e) => {
    if (e.which === 13) {
      // handleSubmit();
    }
  };

  async function handleLogin(model) {
    setValues({ ...values });
    try {
      const email = model.email_address.toLowerCase();
      const user = await Auth.signIn(email, model.password);

      // if (email.match(/testuser\+ios/)) {
      // Special Handler for IOS App Store reviewer account
      const runMFA = false;
      const vc = runMFA && (await Appback.getVerifCode(user.username));

      if (runMFA && vc.code !== null) {
        model.auth_code = vc.code;
        confirmCode(user, model.auth_code);
      } else if (
        runMFA &&
        user.preferredMFA !== "NOMFA" &&
        user.challengeParam !== undefined
      ) {
        setValues({
          user,
          hideAuthCode: false,
          phone_number: user.challengeParam.CODE_DELIVERY_DESTINATION,
          email_address: model.email_address,
          password: model.password,
          requestedOTP: model.requestedOTP,
        });
      } else {
        setValues({ ...values, user });
        onLoginSuccess();
      }
    } catch (error) {
      setValues({ ...values, error: error.message });
    }
  }

  function onLoginSuccess() {
    setRedirectUrl(state);
    setAuthStatus(true);
    localStorage.setItem("activeTime", +new Date());
    if (localStorage.getItem("user") !== null) {
      localStorage.removeItem("user");
    }
    if (localStorage.getItem("password") !== null) {
      localStorage.removeItem("password");
    }
  }

  const handleCheck = (event) => {
    setValues({ ...values, [event.target.name]: event.target.checked });
  };

  function handleAuthCode(model) {
    confirmCode(values.user, model.auth_code);
  }

  function confirmCode(user, code) {
    Auth.confirmSignIn(user, code, user.challengeName)
      .then((user) => {
        if (values.checkedB || localStorage.getItem("mode") === "embedded") {
          user.setDeviceStatusRemembered({
            onSuccess() {
              onLoginSuccess();
            },

            onFailure(err) {
              alert(err);
            },
          });
        } else {
          user.setDeviceStatusNotRemembered({
            onSuccess() {
              onLoginSuccess();
            },

            onFailure(err) {
              alert(err);
            },
          });
        }
      })
      .catch((err) => {
        setValues({ ...values, error: err.message });
      });
  }

  const handleCancel = () => {
    setValues({ ...values, hideAuthCode: true });
  };
  const newAccessCode = () => {
    const model = {
      email_address: values.email_address,
      password: values.password,
      requestedOTP: true,
    };
    handleLogin(model);
    setResendCount(resendCount + 1);
  };
  useEffect(() => {
    if (localStorage.getItem("mode") == "embedded") {
      setValues({ ...values, checkedB: true });
      const model = {
        email_address: localStorage.getItem("user"),
        password: localStorage.getItem("password"),
        requestedOTP: false,
      };
      handleLogin(model);
    }
  }, [localStorage.getItem("mode")]);
  return (
    <Box sx={{ ...Styles }} className="login LoginPageContentHeight">
      <Container>
        <Grid container spacing={0} sx={{ textAlign: "center" }}>
          <Grid item xs={1} sm={1} md={3} lg={3} />
          <Grid item xs={10} sm={10} md={6} lg={6}>
            {values.hideAuthCode && (
              <div
                style={{
                  borderRadius: "10px",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                }}
              >
                <br />
                <br />
                <p style={{ fontSize: "1.25rem", fontWeight: "700" }}>
                  Welcome to 99rises
                </p>
                <br />
                <p className="error">{values.error}</p>
                <div>
                  <form
                    noValidate
                    style={{ width: "100%" }}
                    onSubmit={formik.handleSubmit}
                  >
                    <Box mt={1} mb={2}>
                      <TextField
                        variant="outlined"
                        sx={{
                          width: "72% !important",
                          marginBottom: "0px !important",
                        }}
                        className="formcontrol-email"
                        id="email_address"
                        name="email_address"
                        label="Email Address"
                        value={formik.values.email_address}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.email_address &&
                          Boolean(formik.errors.email_address)
                        }
                        helperText={
                          formik.touched.email_address &&
                          formik.errors.email_address
                        }
                        type="email"
                      />
                    </Box>
                    <Box mt={1} mb={-1}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        style={{
                          width: "72%",
                        }}
                        className="formcontrol-password"
                        id="password"
                        name="password"
                        label="Password"
                        type={values.showPassword ? "text" : "password"}
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.password &&
                          Boolean(formik.errors.password)
                        }
                        helperText={
                          formik.touched.password && formik.errors.password
                        }
                        onKeyPress={(e) => onKeyPress(e)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              className="MuiIconButton-edgeEnd"
                            >
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => handleClickShowPassword()}
                                onMouseDown={(e) => handleMouseDownPassword(e)}
                              >
                                {values.showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                    <Button
                      className="login_button formcontrol-button"
                      variant="contained"
                      style={{ width: "72%" }}
                      id="login_button"
                      type="submit"
                    >
                      LOGIN
                    </Button>
                  </form>
                </div>
                <br />
                <br />
                <Link href="/forgot-password">Forgot Password?</Link>
                <br />
                <br />
                <Grid
                  container
                  spacing={1}
                  style={{ textAlign: "center", marginBottom: "10px" }}
                >
                  {" "}
                  <Box className="belowLogin">
                    <p>
                      Don't have an account? &nbsp;
                      <Link
                        component="a"
                        className="signup_button"
                        href="/signup"
                      >
                        Sign up
                      </Link>
                    </p>
                  </Box>
                </Grid>
              </div>
            )}

            {!values.hideAuthCode && (
              <div>
                <form onSubmit={authFormik.handleSubmit}>
                  {values.requestedOTP && (
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        color: "green",
                      }}
                    >
                      New verification code has been sent to{" "}
                      {values.phone_number}
                    </p>
                  )}
                  {!values.requestedOTP && (
                    <p style={{ textAlign: "center", fontSize: "14px" }}>
                      A verification code has been sent to {values.phone_number}
                    </p>
                  )}
                  <Grid style={{ textAlign: "center", fontSize: "14px" }}>
                    If you have not received a code, you can{" "}
                    <Link onClick={() => newAccessCode()}>
                      request a new code
                    </Link>
                  </Grid>
                  <Grid>
                    {resendCount >= 2 && (
                      <p
                        style={{
                          color: "red",
                          marginBottom: "18px",
                          fontSize: "14px",
                          fontStyle: "italic",
                        }}
                      >
                        If you still aren’t receiving the code, please contact
                        us at{" "}
                        <Link href="mailto:support@99rises.com">
                          support@99rises.com
                        </Link>
                      </p>
                    )}
                  </Grid>
                  <Grid
                    style={{
                      borderRadius: "10px",
                    }}
                  >
                    <p className="error">{values.error}</p>
                    <TextField
                      variant="outlined"
                      style={{ width: "80%" }}
                      id="auth_code"
                      name="auth_code"
                      label="Enter Verification Code"
                      value={authFormik.values.auth_code}
                      onChange={authFormik.handleChange}
                      error={
                        authFormik.touched.auth_code &&
                        Boolean(authFormik.errors.auth_code)
                      }
                      helperText={
                        authFormik.touched.auth_code &&
                        authFormik.errors.auth_code
                      }
                    />
                    <br />
                    <br />
                    <Checkbox
                      checked={
                        values.checkedB ||
                        localStorage.getItem("mode") === "embedded" ||
                        false
                      }
                      onChange={handleCheck}
                      name="checkedB"
                      color="primary"
                      id="trust_device_chk"
                    />
                    <b>Trust this device and skip this step in the future</b>.
                    <Grid
                      container
                      spacing={1}
                      style={{ marginBottom: "10px", marginTop: "10px" }}
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <Grid>
                          <Button
                            className="signup_button"
                            style={{
                              color: "black",
                              fontWeight: "bold",
                              marginLeft: "10px",
                            }}
                            onClick={() => handleCancel()}
                            id="concel_button"
                          >
                            Cancel
                          </Button>
                          <Button
                            className="signup_button"
                            style={{
                              color: "white",
                              border: "none",
                              backgroundColor: "black",
                              fontWeight: "bold",
                              marginLeft: "10px",
                            }}
                            type="submit"
                            id="continue_button"
                          >
                            Continue
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </div>
            )}
          </Grid>
          <Grid item xs={1} sm={1} md={3} lg={3} />
        </Grid>
      </Container>
    </Box>
  );
};

export default LoginWithAuth;
