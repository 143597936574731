import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { Container, Grid } from "@mui/material";
import ChangePassword from "./ChangePassword";
import ChangeEmail from "./ChangeEmail";
import ChangePhoneNumber from "./ChangePhoneNumber";
import TrustedDevices from "./TrustedDevices";

const Securitycenter = () => {
  const [userData, setUserData] = useState(null);

  const getData = async () => {
    const data = await Auth.currentAuthenticatedUser();
    setUserData(data);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="security-center">
      <Container>
        <ReuseGrid>
          <ChangePassword userData={userData} />
        </ReuseGrid>
        <ReuseGrid>
          <ChangeEmail userData={userData} />
        </ReuseGrid>
        <ReuseGrid>
          <ChangePhoneNumber userData={userData} />
        </ReuseGrid>
        <ReuseGrid>
          <TrustedDevices userData={userData} />
        </ReuseGrid>
      </Container>
    </div>
  );
};

const ReuseGrid = ({ children }) => {
  return (
    <Grid container spacing={6}>
      <Grid item xs={10} sm={8} md={8} lg={6} className="block-white">
        {children}
      </Grid>
    </Grid>
  );
};

export default Securitycenter;
