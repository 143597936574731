import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Chart from "react-apexcharts";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { numberFormatNoDecimal } from "../utils/numbers";
import "./apex-chart.scss";

const useStyles = makeStyles(() => ({
  root: {
    "& .apexcharts-canvas": {
      margin: "auto",
      marginTop: "20px",
    },
  },
}));

const ApexChart = ({ performance, activeMetric, chartType, chartTitle }) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    chart: {
      height: 350,
      type: "area",
      zoom: {
        enabled: false,
      },
    },
    colors: ["#00BAEC"],
    stroke: {
      curve: "smooth",
      width: 3,
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        gradientToColors: ["#FDD835"],
        shadeIntensity: 1,
        type: "horizontal",
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100, 100, 100],
      },
    },
    title: {
      text: "",
      align: "left",
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    series: [
      {
        data: [],
      },
    ],
    xaxis: {
      type: "datetime",
    },
    tooltip: {
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        const label =
          chartType === "percentage"
            ? `${parseFloat(series[seriesIndex][dataPointIndex]).toFixed(2)}%`
            : numberFormatNoDecimal(series[seriesIndex][dataPointIndex]);
        return (
          `${
            '<div className="apexcharts-tooltip apexcharts-theme-light">' +
            '<div class="apexcharts-tooltip-title">'
          }${moment(
            w.config.series[seriesIndex].data[dataPointIndex][0]
          ).format("MMM DD, YYYY")}</div>` +
          `<div className="apexcharts-tooltip-series-group apexcharts-active">` +
          `<div className="apexcharts-tooltip-text">` +
          `<div class="apexcharts-tooltip-y-group">` +
          `<span class="apexcharts-tooltip-text-y-value">${label}</span>` +
          `</div></div></div></div>`
        );
      },
    },
    yaxis: {
      labels: {
        formatter: (value) => {
          return chartType === "percentage"
            ? `${parseFloat(value).toFixed(2)}%`
            : numberFormatNoDecimal(value);
        },
      },
    },
  });

  const generateDayWiseTimeSeries = (data, metric) => {
    const series = [];
    data.forEach((item) => {
      if (item[metric] !== "NA") {
        const x = item.date;
        const y = item[metric];
        series.push([x, y]);
      }
    });
    return series;
  };
  const bindChart = (data, metric) => {
    if (data.length > 0) {
      const chartData = generateDayWiseTimeSeries(data, metric);
      setValues({
        ...values,
        title: {
          text: chartTitle,
          align: "left",
        },
        series: [
          {
            data: chartData,
          },
        ],
      });
    }
  };
  useEffect(() => {
    if (performance.length > 0) {
      bindChart(performance, activeMetric);
    }
  }, [performance, activeMetric]);

  return (
    <Chart
      id=""
      options={values}
      series={values.series}
      type="line"
      height={350}
      width={800}
      className={classes.root}
    />
  );
};
ApexChart.propTypes = {
  performance: PropTypes.instanceOf(Array).isRequired,
  activeMetric: PropTypes.string.isRequired,
  chartType: PropTypes.string.isRequired,
  chartTitle: PropTypes.string.isRequired,
};

export const Metric = ({ handleBindData, performance }) => {
  const [activeMetric, setActiveMetric] = useState("td");
  const handleChange = (_event, newAlignment) => {
    if (newAlignment) {
      setActiveMetric(newAlignment);
      if (newAlignment === "ytd") {
        const date = new Date();
        const data = performance.filter(
          (elem) =>
            elem.date > moment(date).format("YYYY-01-01") &&
            elem.date < moment(date).format("YYYY-MM-DD")
        );
        handleBindData(data, newAlignment);
      } else handleBindData(performance, newAlignment);
    }
  };
  return (
    <div id="chart-wrap">
      <div className="legend">
        <ToggleButtonGroup
          value={activeMetric}
          exclusive
          onChange={handleChange}
        >
          <ToggleButton value="td">TD</ToggleButton>
          <ToggleButton value="ytd">YTD</ToggleButton>
        </ToggleButtonGroup>
      </div>
    </div>
  );
};
Metric.propTypes = {
  performance: PropTypes.instanceOf(Array).isRequired,
  handleBindData: PropTypes.func.isRequired,
};
export default ApexChart;
