import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  InputLabel,
  FormControl,
  Select,
  TextField,
  FormHelperText,
  Container,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  // IconButton,
  InputAdornment,
  Box,
} from "@mui/material";
import PropTypes from "prop-types";
import moment from "moment";
import Service from "../services/hservice";
import { numberFormat } from "../utils/numbers";
import DialogComponent from "../components/DialogComponent";
import NumberFormatCustom from "../components/NumberFormat";

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function WithdrawFunds() {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    select_account: "",
    amount: "",
    setUpLinkedAccountHide: true,
    linked_accounts: [],
    buttonText: "Transfer Funds",
    currentBalance: 0,
    dailogMessage: "",
    zeroOut: false,
    zeroOutNoButton: false,
    zeroOutYesButton: "ok",
    buttonDisabled: false,
    withdrawsAvailable: true,
    max_withdrawal: 1200,
    AvailableBalance: 0,
    fund_transfer: [],
  });
  const [dailogOpen, setDailogOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
      select_account_empty_error: false,
      select_account_required_error: "",
      amount_empty_error: false,
      amount_required_error: "",
    });
    if (event.target.name === "select_account") {
      if (values.withdrawsAvailable) {
        if (event.target.value === "Link a bank account") {
          setValues({
            ...values,
            [prop]: event.target.value,
            setUpLinkedAccountHide: true,
            select_account_empty_error: false,
            select_account_required_error: "",
            buttonText: "Link a bank account",
            buttonDisabled: false,
          });
        } else if (event.target.value === "Verify a bank account") {
          setValues({
            ...values,
            [prop]: event.target.value,
            setUpLinkedAccountHide: true,
            select_account_empty_error: false,
            select_account_required_error: "",
            buttonDisabled: false,
            buttonText: "Verify a bank account",
          });
        } else if (event.target.value === "") {
          setValues({
            ...values,
            select_account: "",
            buttonDisabled: true,
          });
        } else {
          setValues({
            ...values,
            [prop]: event.target.value,
            setUpLinkedAccountHide: false,
            select_account_empty_error: false,
            select_account_required_error: "",
            buttonText: "Transfer Funds",
            buttonDisabled: false,
          });
        }
      } else if (event.target.value === "Link a bank account") {
        setValues({
          ...values,
          [prop]: event.target.value,
          setUpLinkedAccountHide: true,
          select_account_empty_error: false,
          select_account_required_error: "",
          buttonText: "Link a bank account",
          buttonDisabled: false,
        });
      } else {
        setValues({
          ...values,
          [prop]: event.target.value,
          setUpLinkedAccountHide: false,
          buttonText: "Transfer Funds",
          select_account_empty_error: false,
          select_account_required_error: "",
          amount_empty_error: false,
          amount_required_error: "",
        });
      }
    } else if (event.target.name === "amount") {
      if (values.zeroOut) {
        if (parseInt(event.target.value) === parseInt(values.amount)) {
          setValues({
            ...values,
            zeroOut: true,
          });
        } else {
          setValues({
            ...values,
            zeroOut: false,
          });
        }
      }
    } else {
      setValues({
        ...values,
        [prop]: event.target.value,
        setUpLinkedAccountHide: false,
        buttonText: "Transfer Funds",
        select_account_empty_error: false,
        select_account_required_error: "",
        amount_empty_error: false,
        amount_required_error: "",
      });
    }
  };

  useEffect(() => {
    const getaccounts = () => {
      Service.getAccount().then((result) => {
        Service.getLinkedAccounts(result.data.Account[0].id).then((res) => {
          if (res.data.ExtAcct.length > 0) {
            Service.getFundTransfersAmount(result.data.Account[0].id).then(
              (res1) => {
                if (res1.data.FundTransfers.length > 0) {
                  const pendingFundTransfers = [];
                  res.data.ExtAcct.map((j) => {
                    res1.data.FundTransfers.map((i) => {
                      if (i.status === "pending" && i.type === "withdraw") {
                        i.account_num = j.account_num;
                        pendingFundTransfers.push(i);
                      }
                      return pendingFundTransfers;
                    });
                    return j;
                  });
                  let totalBalance = 0;
                  let deposits = 0;
                  let withdraws = 0;
                  res1.data.FundTransfers.map((i) => {
                    if (i.type === "deposit") {
                      deposits += parseFloat(
                        i.amount.toString().replace(/\$|,/g, "")
                      );
                      return deposits;
                    }
                    withdraws += parseFloat(
                      i.amount.toString().replace(/\$|,/g, "")
                    );
                    return withdraws;
                  });
                  totalBalance = deposits - withdraws;
                  setValues({
                    ...values,
                    linked_accounts: res.data.ExtAcct,
                    setUpLinkedAccountHide: false,
                    buttonDisabled: false,
                    withdrawsAvailable: false,
                    select_account: res.data.ExtAcct[0].id,
                    AvailableBalance: totalBalance,
                    fund_transfer: pendingFundTransfers,
                    max_withdrawal: totalBalance,
                  });
                } else {
                  setValues({
                    ...values,
                    linked_accounts: res.data.ExtAcct,
                    setUpLinkedAccountHide: false,
                    buttonDisabled: true,
                    select_account: res.data.ExtAcct[0].id,
                  });
                }
              }
            );
          } else {
            setValues({
              ...values,
              linked_accounts: res.data.ExtAcct,
              setUpLinkedAccountHide: true,
            });
          }
        });
      });
    };

    getaccounts();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    if (!values.select_account) {
      setValues({
        ...values,
        select_account_empty_error: true,
        select_account_required_error: "Field is required",
      });
    } else if (values.buttonText === "Link a bank account") {
      handleLinkedAccounts();
    } else if (values.buttonText === "Verify a bank account") {
      handleLinkedAccounts();
    } else if (!values.amount) {
      setValues({
        ...values,
        amount_empty_error: true,
        amount_required_error: "Field is required",
      });
    } else if (values.zeroOut) {
      setValues({
        ...values,
        dailogMessage: "Are you sure you want to liquidate your account?",
        zeroOutYesButton: "yes",
        zeroOutNoButton: true,
      });
      handleClickOpen();
    } else {
      const pendingTransferCount = values.fund_transfer.length;
      if (pendingTransferCount < 1) {
        Service.getLinkedVerfiedSingleAccount(values.select_account).then(
          (res1) => {
            Service.getAccount().then((result) => {
              Service.getFundTransfersAmount(result.data.Account[0].id).then(
                (res) => {
                  if (res.data.FundTransfers.length > 0) {
                    if (values.max_withdrawal >= parseFloat(values.amount)) {
                      localStorage.setItem(
                        "account",
                        res1.data.ExtAcct[0].account_num
                      );
                      localStorage.setItem(
                        "amount",
                        values.amount
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      );
                      localStorage.setItem(
                        "transferProcessingText",
                        JSON.stringify(true)
                      );
                      navigate("/confirmwithdraw", {
                        state: { id: res.data.FundTransfers[0].ext_acct_id },
                      });
                    } else {
                      setValues({
                        ...values,
                        dailogMessage: `You can only withdraw a max of ${numberFormat(
                          values.max_withdrawal
                        )} at this time`,
                      });
                      handleClickOpen();
                    }
                  }
                }
              );
            });
          }
        );
      } else {
        setDailogOpen(true);
      }
    }
  };

  const handleLinkedAccounts = () => {
    navigate("/settings#linkedaccounts");
  };

  const zeroOutWithdrawTransfer = () => {
    if (values.amount && values.amount > 0) {
      if (values.zeroOut) {
        Service.getLinkedVerfiedSingleAccount(values.select_account).then(
          (res) => {
            localStorage.setItem("account", res.data.ExtAcct[0].account_num);
            localStorage.setItem(
              "amount",
              values.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            );
            localStorage.setItem(
              "transferProcessingText",
              JSON.stringify(false)
            );
            navigate("/confirmwithdraw", {
              state: { id: values.select_account },
            });
          }
        );
        handleClose();
      } else {
        handleClose();
      }
    } else {
      setValues({
        ...values,
        dailogMessage:
          "The current value of your account is $0. You cannot withdraw any funds from your account",
      });
      handleClose();
    }
  };

  const zeroOutNoWithdrawTransfer = () => {
    setValues({
      ...values,
      amount: "",
      zeroOut: false,
      zeroOutNoButton: false,
    });
    handleClose();
  };

  return (
    <div className="add-funds AddFundsPageContentHeight">
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={10} sm={8} md={8} lg={6} className="block-white">
            <div className="fundingbasic size accountstatus">
              <h3>Withdraw funds</h3>
              <div className="accountstatus-body accountstatus-text">
                <div>
                  <div>
                    {values.fund_transfer.length > 0 && (
                      <p>
                        Only one pending fund transfer is supported at a time.
                        <br /> Please wait for the one below to complete.
                      </p>
                    )}
                    <p>The following withdrawals are in process:</p>
                    <table>
                      <thead>
                        <tr>
                          <th>DATE</th>
                          <th>TYPE</th>
                          <th>AMOUNT</th>
                          <th>FROM</th>
                          <th>TO</th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.fund_transfer.map((result, j) => (
                          <tr key={j.toString()}>
                            <td align="left">
                              {moment(result.requested).format("MM/DD/YYYY")}
                            </td>
                            <td align="left">Withdraw</td>
                            <td align="left">{result.amount}</td>
                            <td align="left">99rises</td>
                            <td align="left">{result.account_num}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <p className="error">{values.error}</p>
              {values.linked_accounts.length > 0 ? (
                <p />
              ) : (
                <p>Setup a Linked Account.</p>
              )}

              <div style={{ marginTop: "40px" }}>
                <form>
                  <Box mt={1} mb={1}>
                    <FormControl variant="outlined">
                      <InputLabel
                        className="inputlabel"
                        htmlFor="outlined-adornment-password"
                        error={values.select_account_empty_error}
                      >
                        Linked Bank Account
                      </InputLabel>
                      <Select
                        label="Linked Bank Account"
                        className="select-label select_account"
                        native
                        disabled
                        value={values.select_account}
                        onChange={handleChange("select_account")}
                        labelWidth={180}
                        error={values.select_account_empty_error}
                        name="select_account"
                      >
                        <option value="" />
                        <option value="Link a bank account">
                          Link a bank account
                        </option>
                        <option value="Verify a bank account">
                          Verify a Bank Account
                        </option>
                        {values.linked_accounts.map((result, j) => {
                          return result.status !== "Verify" ? (
                            <option key={j} value={result.id}>
                              {result.nickname}&nbsp;-&nbsp;
                              {result.account_num.replace(/.(?=.{4})/g, "X")}
                              &nbsp;(Avl.Bal={" "}
                              {numberFormat(values.AvailableBalance)})
                            </option>
                          ) : null;
                        })}
                      </Select>
                      <img
                        src="error.png"
                        alt="check"
                        hidden={!values.select_account_empty_error}
                      />
                      <FormHelperText className="formhelpertext">
                        {values.select_account_required_error}
                      </FormHelperText>
                      <br />
                    </FormControl>
                  </Box>
                  {!values.setUpLinkedAccountHide && (
                    <div>
                      <Box mt={1} mb={1}>
                        <FormControl variant="outlined">
                          <TextField
                            variant="outlined"
                            error={values.amount_empty_error}
                            value={parseInt(values.amount)}
                            onChange={handleChange("amount")}
                            name="amount"
                            label="Amount"
                            id="formatted-numberformat-input"
                            InputProps={{
                              inputComponent: NumberFormatCustom,
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                            autoFocus
                            disabled={values.fund_transfer.length > 0}
                          />
                          <img
                            src="error.png"
                            alt="check"
                            hidden={!values.amount_empty_error}
                          />
                          <FormHelperText className="formhelpertext">
                            {values.amount_required_error}
                          </FormHelperText>
                        </FormControl>
                      </Box>
                      <p id="transfer_duration">
                        Transfer to your bank account may take 3-5 business days
                        depending on the institution
                      </p>
                    </div>
                  )}
                  <Button
                    className="transfer_funds"
                    onClick={handleSubmit}
                    variant="contained"
                    disabled={
                      values.buttonDisabled || values.fund_transfer.length > 0
                    }
                    id="transfer_button"
                  >
                    {values.buttonText}
                  </Button>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title" />
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {values.dailogMessage}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      {values.zeroOutNoButton && (
                        <Button
                          onClick={zeroOutNoWithdrawTransfer}
                          color="primary"
                          id="no_button"
                        >
                          No
                        </Button>
                      )}
                      <Button
                        onClick={zeroOutWithdrawTransfer}
                        color="primary"
                        id="yes_button"
                      >
                        {values.zeroOutYesButton}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </form>
              </div>
            </div>
            <DialogComponent
              dailogOpen={dailogOpen}
              setDailogOpen={setDailogOpen}
            >
              <p>
                Only one fund transfer, whether into or out of your 99rises
                account, is supported at one time. Please wait for the pending
                transfer to complete.
              </p>
            </DialogComponent>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
