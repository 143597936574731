import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import InputMask from "react-input-mask";
import PropTypes from "prop-types";
import Mousetrap from "mousetrap";

const PhoneNumberInput = ({
  handlePhoneChange,
  error,
  helperText,
  width,
  inputId,
  phoneNumber,
  readOnly = false,
}) => {
  const PHONE_CODE = "+1";
  const backspace = "Backspace";
  const maskValue = "+1 (___) ___-____";
  const maskedValue = "+1 (999) 999-9999";
  const [othercountry, setOthercountry] = useState(false);
  const [phoneValue, setPhoneValue] = useState(false);
  Mousetrap.bind(["/"], async () => {
    setOthercountry(!othercountry);
  });
  const handleBlur = (event) => {
    if (
      othercountry &&
      (event.target.value === "" || event.target.value === maskValue)
    ) {
      setOthercountry(!othercountry);
    }
  };
  const handleKeyUp = (event) => {
    if (event.target.value === "+1") {
      setOthercountry(!othercountry);
    } else if (event.key === "Unidentified") {
      setPhoneValue("");
    } else if (
      event.key === backspace &&
      (event.target.value === "" || event.target.value === maskValue)
    ) {
      setOthercountry(!othercountry);
      setPhoneValue("");
    }
  };
  const handleChange = (event) => {
    if (event.target.value === maskValue && phoneNumber !== "") {
      setPhoneValue(null);
    } else {
      setPhoneValue(event.target.value);
      handlePhoneChange(event);
    }
  };
  useEffect(() => {
    if (phoneNumber?.includes(PHONE_CODE)) {
      setOthercountry(false);
    } else if (phoneNumber) {
      setOthercountry(true);
    }
    if (inputId === "new_phone_number") {
      setPhoneValue("");
      setOthercountry(false);
    } else setPhoneValue(phoneNumber);
  }, [phoneNumber]);
  return (
    <>
      <InputMask
        mask={!othercountry ? maskedValue : null}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        onBlur={handleBlur}
        id={inputId}
        name={inputId}
        label="Phone Number"
        value={phoneValue}
        InputProps={{ readOnly }}
        autoComplete="off"
        type="tel"
      >
        {() => (
          <TextField
            variant="outlined"
            label="Phone Number"
            id={inputId}
            name={inputId}
            style={{ width }}
            error={error}
            helperText={helperText}
            onChange={handleChange}
            onKeyUp={handleKeyUp}
            onBlur={handleBlur}
            value={phoneValue}
            InputProps={{ readOnly }}
            autoComplete="off"
            type="tel"
          />
        )}
      </InputMask>
    </>
  );
};
PhoneNumberInput.propTypes = {
  error: PropTypes.bool.isRequired,
  handlePhoneChange: PropTypes.func.isRequired,
  helperText: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  inputId: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
};
export default PhoneNumberInput;
