import React, { useState } from "react";
import { Button, Grid, TextField, Container } from "@mui/material";
import { Auth } from "aws-amplify";
import { useFormik } from "formik";
import * as yup from "yup";

const SignUpConfirmation = ({ email }) => {
  const [values, setValues] = useState({
    emailConfirmed: false,
    alreadyConfirmed: false,
    error: "",
  });

  const authValidationSchema = yup.object({
    auth_code: yup
      .string("Enter verification code")
      .required("A verification code is required"),
  });

  const authFormik = useFormik({
    initialValues: {
      user: email,
      auth_code: "",
    },
    validationSchema: authValidationSchema,
    onSubmit: (login) => {
      handleAuthCode(login);
    },
  });
  const handleAuthCode = (model) => {
    confirmCode(model.user, model.auth_code);
  };

  const handleLogin = () => {
    window.location.href = "/";
  };

  const confirmCode = (user, code) => {
    Auth.confirmSignUp(user, code)
      .then(() => {
        setValues({
          ...values,
          emailConfirmed: true,
          error: "",
        });
      })
      .catch((e) => {
        let message =
          "Your account not verified. Please check email address and verification code.";
        if (e.message.includes("Current status is CONFIRMED")) {
          message = "";
          setValues({ ...values, alreadyConfirmed: true });
        } else if (e.code === "LimitExceededException") {
          message = e.message;
        }
        if (message !== "") {
          setValues({ ...values, emailConfirmed: false });
          setValues({
            ...values,
            error: e.message,
          });
        }
      });
  };
  return (
    <div>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={1} sm={2} md={2} lg={3}>
            {" "}
          </Grid>
          <Grid
            item
            xs={10}
            sm={8}
            md={8}
            lg={6}
            style={{
              marginTop: "5%",
              borderRadius: "10px",
              paddingLeft: "0px",
              paddingRight: "0px",
            }}
          >
            {!values.emailConfirmed && !values.alreadyConfirmed && (
              <form onSubmit={authFormik.handleSubmit}>
                <p align="center">
                  We have sent an email verification link to you at {email}.
                  <br />
                  <br />
                  You can either click on the link in your email or enter the
                  verification code here to verify the email address.
                </p>
                <Grid item xs={1} sm={2} md={3}>
                  {" "}
                </Grid>

                <div>
                  <Grid
                    container
                    spacing={1}
                    style={{ marginBottom: "10px", marginTop: "8px" }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <Grid>
                        <div>
                          <p className="error">{values.error}</p>
                          <TextField
                            variant="outlined"
                            style={{ width: "80%" }}
                            id="auth_code"
                            name="auth_code"
                            label="Enter Verification Code"
                            value={authFormik.values.auth_code}
                            onChange={authFormik.handleChange}
                            error={
                              authFormik.touched.auth_code &&
                              Boolean(authFormik.errors.auth_code)
                            }
                            helperText={
                              authFormik.touched.auth_code &&
                              authFormik.errors.auth_code
                            }
                          />
                          <br />
                          <Button
                            className="confirm_button"
                            style={{
                              color: "white",
                              border: "none",
                              backgroundColor: "black",
                              fontWeight: "bold",
                              marginLeft: "10px",
                            }}
                            type="submit"
                            id="confirm_button"
                          >
                            Confirm
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </form>
            )}
            <div>
              {values.emailConfirmed && (
                <p style={{ color: "red" }}>{values.error}</p>
              )}
              {values.emailConfirmed && (
                <p align="center">
                  {" "}
                  Your account is verified. You can{" "}
                  <span
                    style={{ textDecoration: "underline", color: "blue" }}
                    onClick={handleLogin}
                  >
                    click here to login
                  </span>{" "}
                </p>
              )}
            </div>
            <div>
              {values.alreadyConfirmed && (
                <p align="center">
                  {" "}
                  Your account has already been verified.{" "}
                  <span
                    style={{ textDecoration: "underline", color: "blue" }}
                    onClick={handleLogin}
                  >
                    To continue login.
                  </span>{" "}
                </p>
              )}
            </div>
          </Grid>
          <Grid item xs={1} sm={2} md={2} lg={3}>
            {" "}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default SignUpConfirmation;
