import React, { useContext, useState, useEffect } from "react";
import moment from "moment";
import { ErrorBoundary } from "react-error-boundary";
import { Auth } from "aws-amplify";
import { RootContext } from "../context/root-provider";
import NavBar from "./navbar";
import Footer from "./footer";
import { FallbackComponents } from "./error-handling";
import StepData from "../services/step-data";
import Service from "../services/hservice";
import "../99app.scss";
import "../app.scss";
import AutoLogout, { SESSION_TIME_OUT } from "./auto-logout";
import {
  UnAuthRoutes,
  InterviewRoutes,
  AccountActiveRoutes,
  AccountSubmittedRoutes,
  TestAccountRoutes,
  AccountFundedRoutes,
} from "./routes";
import useMagicKeys from "./magic-keys";

const App = () => {
  const [redirect, setRedirect] = useState();
  const [accountStatus, setAccountStatus] = useState();
  const [accountType, setAccountType] = useState();
  const [testAccount, setTestAccount] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [accountId, setAccountId] = useState(null);
  const [unsetMagicKeys, setMagicKeys] = useMagicKeys();

  const {
    authStatus,
    setAuthStatus,
    setUserAttributes,
    setAccountStatusName,
    accountStatusName,
    redirectUrl,
    setRedirectUrl,
    isLoggedOut,
    setIsLoggedOut,
  } = useContext(RootContext);

  const getUserAttributes = async (authUserData) => {
    const attributesArr = await Auth.userAttributes(authUserData);
    const attributesObj = Auth.attributesToObject(attributesArr);
    setUserAttributes(attributesObj);
  };

  const getActiveStep = async () => {
    try {
      const result = await StepData.get();
      const activeStep = result?.data?.UserInformation_by_pk?.active_step;
      if (activeStep) {
        return `/step/${activeStep}`;
      }
      return "/stepsinfo";
    } catch (e) {
      console.log("unable to fetch step data");
    }
  };

  const setAccStatusValue = (accStatus) => {
    if (
      [
        "interview_started",
        "account_funded",
        "account_active",
        "account_paused",
      ].includes(accStatus)
    ) {
      setAccountStatus(accStatus);
    } else {
      setAccountStatus("account_submitted");
      setAccRedirectValue("account_submitted");
    }
  };

  const setAccRedirectValue = async (accStatus) => {
    let redirectValue = "";
    if (accStatus === "interview_started") {
      const stepRoute = await getActiveStep();
      redirectValue = stepRoute;
    } else if (["account_active", "account_paused"].includes(accStatus)) {
      if (localStorage.getItem("mode") == "embedded") {
        if (localStorage.getItem("ewa-url") !== null) {
          redirectValue = localStorage.getItem("ewa-url");
        } else redirectValue = "/deposit_ext";
      } else {
        redirectValue = "/portfolio";
      }
    } else {
      redirectValue = "/accountstatus";
    }
    if (redirectUrl && accStatus !== "interview_started" && !isLoggedOut) {
      redirectValue = redirectUrl.prev.pathname + redirectUrl.prev.search;
    }
    setRedirectUrl(null);
    setRedirect(redirectValue);
  };

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      setRedirectUrl(null);
      setAuthStatus(false);
      setUserInfo(null);
      setRedirect(null);
      setIsLoggedOut(true);
      localStorage.removeItem("activeTime");
      localStorage.removeItem("mode");
      unsetMagicKeys();
    } catch (e) {
      console.log("Error in logout: ", e);
    }
  };

  const hasUserTimeout = () => {
    const activeTime = localStorage.getItem("activeTime");
    if (
      activeTime !== null &&
      moment().diff(moment(new Date(+activeTime)), "m") > SESSION_TIME_OUT
    ) {
      return true;
    }
    return false;
  };

  const getAccountData = async () => {
    try {
      if (hasUserTimeout()) {
        handleLogout();
      } else {
        const user = await Auth.currentAuthenticatedUser();
        setUserInfo(user);
        getUserAttributes(user);
        Service.getAccount().then((result) => {
          if (result.errors === null || result.errors === undefined) {
            const res = result.data.Account;
            if (res.length === 0) {
              setAccRedirectValue("interview_started");
              setAccountStatus("interview_started");
            } else if (res.length !== 0 && res.length !== undefined) {
              const { id } = res[0];
              setAccountId(id);
              setTestAccount(res[0].test_acct);
              setAccountType(res[0].UserInformation.account_type);
              setAccStatusValue(res[0].account_status);
              setAccRedirectValue(res[0].account_status);
              setAccountStatusName(res[0].account_status);
            }
          }
        });
      }
    } catch (error) {
      console.log("No user logged in. error: ", error);
    }
  };

  useEffect(() => {
    getAccountData();
  }, []);

  useEffect(() => {
    if (authStatus) {
      getAccountData();
    }
    if (accountStatusName) {
      setAccStatusValue(accountStatusName);
    }
  }, [authStatus, accountStatusName]);
  useEffect(() => {
    if (testAccount) {
      setMagicKeys({ accountId, username: userInfo?.username }, testAccount);
    }
  }, [testAccount, accountStatusName]);

  return (
    <div className="App" id="main-content">
      <div className="body-content">
        <NavBar
          userInfo={userInfo}
          accountType={accountType}
          testAccount={testAccount}
          logout={handleLogout}
        />
        <ErrorBoundary FallbackComponent={FallbackComponents}>
          {authStatus === false && UnAuthRoutes()}
          {authStatus === true && redirect && (
            <>
              <AutoLogout logout={handleLogout} />
              {localStorage.getItem("mode") === "embedded" && (
                <>
                  {accountStatus === "interview_started" &&
                    authStatus &&
                    InterviewRoutes(redirect, accountType)}
                  {accountStatus === "account_funded" &&
                    authStatus &&
                    AccountFundedRoutes(redirect)}
                  {[
                    "account_submitted",
                    "account_active",
                    "account_paused",
                  ].includes(accountStatus) &&
                    authStatus &&
                    AccountSubmittedRoutes(redirect, accountType, true)}
                </>
              )}
              {localStorage.getItem("mode") !== "embedded" && !testAccount && (
                <>
                  {accountStatus === "interview_started" &&
                    authStatus &&
                    InterviewRoutes(redirect, accountType)}
                  {accountStatus === "account_submitted" &&
                    authStatus &&
                    AccountSubmittedRoutes(redirect, accountType, false)}
                  {accountStatus === "account_funded" &&
                    authStatus &&
                    AccountFundedRoutes(redirect)}
                  {["account_active", "account_paused"].includes(
                    accountStatus
                  ) &&
                    authStatus &&
                    AccountActiveRoutes(redirect, accountType, accountStatus)}
                </>
              )}
              {localStorage.getItem("mode") !== "embedded" &&
                testAccount &&
                TestAccountRoutes(redirect, accountStatus, accountType)}
            </>
          )}
        </ErrorBoundary>
      </div>
      <div className="footer-header">
        <Footer />
      </div>
    </div>
  );
};
export default App;
