import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  InputLabel,
  FormControl,
  Select,
  TextField,
  FormHelperText,
  Container,
  Grid,
  Button,
  InputAdornment,
  CircularProgress,
  Box,
  Link,
} from "@mui/material";

import moment from "moment";
import Service from "../services/hservice";
import { numberFormat } from "../utils/numbers";
import DialogComponent from "../components/DialogComponent";
import "./funding.scss";
import AppBackService from "../services/app-back";
import NumberFormatCustom from "../components/NumberFormat";

const Addfund = (props) => {
  const Appback = AppBackService();
  const [values, setValues] = useState({
    select_account: "",
    amount: "",
    setUpLinkedAccountHide: false,
    linked_accounts: [],
    buttonText: props.isCashmanager ? "Add Funds to 99rises" : "Transfer Funds",
    verified_accounts: false,
    account_details: {},
    loading_completed: false,
    amount_value: 5000,
    test_acct: false,
    pending_fund_transfers: [],
  });
  const [accountId, setAccountId] = useState("");
  const [dailogOpen, setDailogOpen] = useState(false);
  const navigate = useNavigate();

  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
      select_account_empty_error: false,
      select_account_required_error: "",
      amount_empty_error: false,
      amount_required_error: "",
    });
    if (event.target.value === "Link a bank account") {
      setValues({
        ...values,
        [prop]: event.target.value,
        setUpLinkedAccountHide: true,
        select_account_empty_error: false,
        select_account_required_error: "",
        buttonText: "Link a bank account",
      });
      // handleLinkedAccounts()
    } else if (event.target.value === "Verify a bank account") {
      setValues({
        ...values,
        [prop]: event.target.value,
        setUpLinkedAccountHide: true,
        select_account_empty_error: false,
        select_account_required_error: "",
        buttonText: "Verify a bank account",
      });
      // handleLinkedAccounts()
    } else {
      setValues({
        ...values,
        [prop]: event.target.value,
        setUpLinkedAccountHide: false,
        buttonText: props.isCashmanager
          ? "Add Funds to 99rises"
          : "Transfer Funds",
        select_account_empty_error: false,
        select_account_required_error: "",
        amount_empty_error: false,
        amount_required_error: "",
      });
    }
  };

  const updateLinkedAccounts = (updatingvalues, res) => {
    if (res.data.ExtAcct.length === 0) {
      updatingvalues.select_account = "Link a bank account";
      updatingvalues.buttonText = "Link a bank account";
    } else if (["Verify", "Confirm"].includes(res.data.ExtAcct[0].status)) {
      updatingvalues.select_account = "Verify a bank account";
      updatingvalues.buttonText = "Verify a bank account";
      navigate("/verifyextacct", {
        state: { account_details: res.data.ExtAcct[0] },
      });
      updatingvalues.account_details = res.data.ExtAcct[0];
    } else {
      updatingvalues.select_account = res.data.ExtAcct[0].id;
      updatingvalues.account_details = res.data.ExtAcct[0];
      updatingvalues.linked_accounts = res.data.ExtAcct;
      updatingvalues.setUpLinkedAccountHide = false;
      updatingvalues.buttonText = props.isCashmanager
        ? "Add Funds to 99rises"
        : "Transfer Funds";
    }
  };
  const getPendingTransfer = (updatingvalues, res, acctId) => {
    Service.getFundTransfersAmount(acctId).then((res1) => {
      if (res1.data.FundTransfers.length > 0) {
        const pendingFundTransfers = [];
        res.data.ExtAcct.map((j) => {
          res1.data.FundTransfers.map((i) => {
            if (i.status === "pending" && i.type === "deposit") {
              i.account_num = j.account_num;
              pendingFundTransfers.push(i);
            }
            return pendingFundTransfers;
          });
          return j;
        });
        updatingvalues.pending_fund_transfers = pendingFundTransfers;
        setValues({ ...values, ...updatingvalues });
      } else {
        setValues({ ...values, ...updatingvalues });
      }
    });
  };
  const getaccounts = (isReleasePending) => {
    Service.getAccount().then((result) => {
      setAccountId(result.data.Account[0].id);
      Service.getLinkedAccounts(result.data.Account[0].id).then((res) => {
        const updatingvalues = {
          select_account: "",
          setUpLinkedAccountHide: true,
          select_account_empty_error: false,
          select_account_required_error: "",
          buttonText: "",
          linked_accounts: [],
          account_details: {},
          loading_completed: true,
          amount_value: 0,
          test_acct: result.data.Account[0].test_acct,
          pending_fund_transfers: [],
        };
        updateLinkedAccounts(updatingvalues, res);

        if (
          result.data.Account.length !== 0 &&
          ["account_active", "account_paused"].includes(
            result.data.Account[0].account_status
          )
        ) {
          updatingvalues.amount_value = 100;
        } else {
          updatingvalues.amount_value = 5000;
        }
        if (res.data.ExtAcct.length > 0 && !isReleasePending) {
          getPendingTransfer(updatingvalues, res, result.data.Account[0].id);
        } else {
          setValues({ ...values, ...updatingvalues });
        }
        if (isReleasePending) {
          props.handleRelease();
        }
      });
    });
  };

  useState(() => {
    getaccounts(false);
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!values.select_account) {
      setValues({
        ...values,
        select_account_empty_error: true,
        select_account_required_error: "Field is required",
      });
    } else if (values.buttonText === "Link a bank account") {
      navigate("/linkextacct");
    } else if (values.buttonText === "Verify a bank account") {
      navigate("/verifyextacct", {
        state: { account_details: values.account_details },
      });
    } else if (!values.amount) {
      setValues({
        ...values,
        amount_empty_error: true,
        amount_required_error: "Field is required",
      });
    } else {
      const pendingTransferCount = values.pending_fund_transfers.length;
      if (pendingTransferCount < 1) {
        Service.getLinkedVerfiedSingleAccount(values.select_account).then(
          (res1) => {
            Service.getFundTransfersAmount(values.select_account).then(
              (res) => {
                if (res.data.FundTransfers.length > 0) {
                  if (values.amount >= values.amount_value) {
                    localStorage.setItem(
                      "account",
                      res1.data.ExtAcct[0].account_num
                    );
                    navigate("/confirm_deposit_ext", {
                      state: {
                        id: res.data.FundTransfers[0].ext_acct_id,
                        amount: values.amount.toString(),
                      },
                    });
                  } else {
                    setValues({
                      ...values,
                      amount_empty_error: true,
                      amount_required_error: `Incremental fund adds should be atleast ${values.amount_value}`,
                    });
                  }
                } else if (values.amount >= values.amount_value) {
                  localStorage.setItem(
                    "account",
                    res1.data.ExtAcct[0].account_num
                  );
                  props.handleSubmit(
                    res1.data.ExtAcct[0].id,
                    values.amount.toString()
                  );
                } else {
                  setValues({
                    ...values,
                    amount_empty_error: true,
                    amount_required_error: `${numberFormat(
                      values.amount_value
                    )} is the minimum initial investment`,
                  });
                }
              }
            );
          }
        );
      } else {
        setDailogOpen(true);
      }
    }
  };
  const handleDeleteAcc = () => {
    Service.updateLinkedAccounts(
      `{id: "${values.account_details.id}"}`,
      '_set: {status: "Removed"}'
    ).then(() => {
      getaccounts(false);
    });
  };
  const handleReleasePending = () => {
    Appback.updateReleasePending(
      accountId,
      values.pending_fund_transfers[0].id
    ).then(() => {
      getaccounts(true);
    });
  };
  const getGuidince = () => (
    <p id="transfer_duration">
      Transfer from your bank account may take 3-5 <br />
      business days depending on the institution
    </p>
  );
  const renderAddFund = () => (
    <div style={{ marginTop: "40px" }}>
      {props.isCashmanager && (
        <p style={{ marginTop: "-30px" }}>{getGuidince()}</p>
      )}
      <form style={{ width: "90%" }} onSubmit={handleSubmit}>
        {values.select_account !== "Link a bank account" ? (
          <Box mt={1} mb={1}>
            <FormControl>
              <InputLabel
                className="inputlabel"
                htmlFor="outlined-adornment-password"
                error={values.select_account_empty_error}
              >
                Linked Bank Account
              </InputLabel>
              <Select
                label="Linked Bank Account"
                className="select-label select_account"
                native
                disabled
                value={values.select_account}
                onChange={handleChange("select_account")}
                labelWidth={180}
                error={values.select_account_empty_error}
              >
                <option value="" />
                {values.linked_accounts.map(
                  (result, j) =>
                    result.status !== "Verify" && (
                      <option
                        key={values.account_details.id}
                        value={values.account_details.id}
                      >
                        {values.account_details.nickname}&nbsp;-&nbsp;
                        {values.account_details.account_num.replace(
                          /.(?=.{4})/g,
                          "X"
                        )}
                      </option>
                    )
                )}
                <option value="Link a bank account">Link a Bank Account</option>
                <option value="Verify a bank account">
                  Verify a Bank Account
                </option>
              </Select>
              <img
                src="/error.png"
                alt="check"
                hidden={!values.select_account_empty_error}
              />
              <FormHelperText className="formhelpertext">
                {values.select_account_required_error}
              </FormHelperText>
              <br />
            </FormControl>
          </Box>
        ) : null}

        {!values.setUpLinkedAccountHide && (
          <>
            <Box mt={1} mb={1}>
              <FormControl variant="outlined">
                <TextField
                  variant="outlined"
                  error={values.amount_empty_error}
                  value={parseInt(values.amount)}
                  onChange={handleChange("amount")}
                  name="numberformat"
                  id="formatted-numberformat-input"
                  placeholder="Amount"
                  label="Amount"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  autoFocus
                  disabled={values.pending_fund_transfers.length > 0}
                />
                <img
                  src="/error.png"
                  alt="check"
                  hidden={!values.amount_empty_error}
                />
                <FormHelperText className="formhelpertext">
                  {values.amount_required_error}
                </FormHelperText>
              </FormControl>
            </Box>
            {!props.isCashmanager && getGuidince()}
          </>
        )}
        <Button
          className="transfer_funds"
          type="submit"
          variant="contained"
          id="transfer_button"
          disabled={values.pending_fund_transfers.length > 0}
        >
          {values.buttonText}
        </Button>
        <p className="separator"> or </p>
        <Link href="/wiretransfer" underline="always" color="secondary">
          Via wire transfer or check
        </Link>
        {values.account_details.id !== undefined &&
        values.test_acct === true ? (
          <div>
            <Box sx={{ marginTop: 6, marginBottom: 3 }}>
              <button
                type="button"
                className="delete_link_account"
                onClick={handleDeleteAcc}
                variant="contained"
                id="delink_button"
                style={{ cursor: "pointer" }}
              >
                Delink Bank Acct
              </button>
            </Box>
          </div>
        ) : null}
      </form>
    </div>
  );
  const renderPendingAddFund = () => (
    <div style={{ marginTop: "40px" }}>
      You can always add funds{" "}
      <Link href="/wiretransfer" underline="always" color="secondary">
        Via wire transfer or check
      </Link>
      {values.account_details.id !== undefined && values.test_acct === true ? (
        <div>
          <br />
          <br />
          <br />
          <br />
          <Box sx={{ marginBottom: 3 }} className="addfund-links">
            <button
              type="button"
              className="delete_link_account"
              onClick={handleDeleteAcc}
              variant="contained"
              id="delink_button"
              style={{ marginRight: "5px", cursor: "pointer" }}
            >
              Delink Bank Acct
            </button>
            <button
              type="button"
              className="delete_link_account"
              onClick={handleReleasePending}
              variant="contained"
              id="delink_button"
              style={{ cursor: "pointer" }}
            >
              Release Pending
            </button>
          </Box>
        </div>
      ) : null}
    </div>
  );
  const renderComponent = () => {
    if (values.loading_completed) {
      return (
        <>
          {values.select_account === "Link a bank account" ? (
            <div>
              <Box fontSize="h7.fontSize" pb={1}>
                If you would like to transfer money directly from a bank
                account, you must first setup a link to the bank account.
              </Box>
              <br />
            </div>
          ) : null}
          {values.pending_fund_transfers.length > 0 && (
            <>
              <div className="accountstatus-body accountstatus-text add-funds-table">
                <div>
                  <div>
                    <p>
                      Only one pending fund transfer is supported at a time.
                      <br /> Please wait for the one below to complete.
                    </p>
                    <p>The following deposits are in process:</p>
                    <p>
                      A deposit from your bank account{" "}
                      {values.pending_fund_transfers[0].account_num} to 99rises
                      is in process:
                    </p>
                    <table>
                      <thead>
                        <tr>
                          <th>DATE</th>
                          <th>TYPE</th>
                          <th>AMOUNT</th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.pending_fund_transfers.map((result, j) => (
                          <tr key={j.toString()}>
                            <td align="left">
                              {moment(result.requested).format("MM/DD/YYYY")}
                            </td>
                            <td align="left">Deposit</td>
                            <td align="left">{result.amount}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {renderPendingAddFund()}
            </>
          )}
          {values.pending_fund_transfers.length === 0 && renderAddFund()}
          <br />
          <br />
          <DialogComponent
            dailogOpen={dailogOpen}
            setDailogOpen={setDailogOpen}
          >
            <p>
              Only one fund transfer, whether into or out of your 99rises
              account, is supported at one time. Please wait for the pending
              transfer to complete.
            </p>
          </DialogComponent>
        </>
      );
    }
    return <CircularProgress />;
  };
  return (
    <div className="add-funds AddFundsPageContentHeight">
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {renderComponent()}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Addfund;
