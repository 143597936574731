import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate, createSearchParams } from "react-router-dom";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Service from "../services/hservice";
import AppBackService from "../services/app-back";
import { numberFormat } from "../utils/numbers";
import "./BlockHeader.scss";

const settings = {
  className: "slider variable-width avl_blocks",
  dots: false,
  infinite: false,
  slidesToShow: 7,
  slidesToScroll: 7,
  speed: 500,
  initialSlide: 0,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 1920,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 1366,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 1360,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 680,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 0,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 512,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        infinite: false,
        dots: false,
      },
    },
  ],
};

const useStyles = makeStyles(() => ({
  activeCardItem: {
    color: "#364150 !important",
    backgroundColor: "#efefef !important",
    border: "1px solid",
    boxShadow: "none",
  },
}));

const BlockHeader = ({
  blockName,
  handleBlockChange,
  setIsLoading,
  AddedBlock,
  showTray,
  handleShowTray,
  setBlockData,
  handleDeleteBlock,
  tabValue,
  testAcct,
}) => {
  const slider = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const Appback = AppBackService();
  const [blocklist, setBlocklist] = React.useState([]);
  const [avlBlocklist, setAvlBlocklist] = React.useState([]);
  const [load, setLoad] = React.useState(false);
  const [selectedBlock, setSelectedBlock] = React.useState("");
  const handleSubmit = async (blockId, portfolioName, cName) => {
    setIsLoading(true);
    setSelectedBlock(blockId);
    handleBlockChange(blockId, false, portfolioName, cName);
  };
  const getAcctBlocks = () => {
    Service.getAccountAndAccountHoldings().then(async (res) => {
      const ablock = await Appback.getAcctBlocks(res.data.Account[0].id);
      setBlocklist(ablock.blocks);
      const selectedBlocks = ablock.blocks.map((x) => x.cname);
      const blocks = await Service.getPortfolios();
      const excludeBlocks = blocks.data.Portfolio.filter(
        (val) => !selectedBlocks.includes(val.cname)
      );
      setAvlBlocklist(excludeBlocks);

      setLoad(false);
    });
  };
  const handleDelete = async (block) => {
    setLoad(true);
    Appback.deleteAcctBlocks(block.block_id).then(async () => {
      getAcctBlocks();
      const blocks = blocklist.map((x) => x.name);
      if (blocks.includes(blockName)) {
        const array = [...blocklist];
        const index = blocks.indexOf(blockName);
        if (index !== -1) {
          array.splice(index, 1);
          setBlocklist(array);
        }
        if (block.name === blockName) {
          setBlockData(array[0].block_id);
        }
        handleDeleteBlock(block.name);
      }
    });
  };
  const handleNavigation = async (cname) => {
    let id = selectedBlock;
    let { hash } = location;
    if (!id) {
      const block = blocklist.filter((val) => val.name === blockName);
      id = block[0].block_id;
    }
    if (!hash) {
      if (tabValue === 0) hash = "#holdings";
      else if (tabValue === 1) hash = "#byindustry";
      else if (tabValue === 2) hash = "#performance";
    }
    navigate(`/blocks?${createSearchParams({ cname }).toString()}`, {
      state: {
        cname,
        from: "port",
        locationHash: hash,
        blockId: id,
      },
    });
  };
  useEffect(() => {
    const portlist = async () => {
      if (blocklist.length === 0 || AddedBlock) {
        getAcctBlocks();
      }
    };
    portlist();
  }, [AddedBlock]);

  return (
    <>
      {load && <div id="cover-spin" />}
      {showTray && (
        <div
          style={{
            display: "flex",
            borderBottom: "1px solid #989898",
            justifyContent: "center",
          }}
        >
          {blocklist.length > 0 && (
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Grid sx={{ textAlign: "start" }}>
                <h4 className="block_tray_header">Your blocks</h4>
              </Grid>
              <Grid
                item
                className="block_tray"
                sx={{
                  paddingBottom: "10px",
                  paddingLeft: "20px",
                }}
              >
                {blocklist.length > 0 &&
                  blocklist.map((portfolio) => (
                    <Grid key={portfolio.cname} item>
                      <Card
                        className={`${
                          portfolio.name === blockName && classes.activeCardItem
                        } cardItem`}
                        key={portfolio.cname}
                        sx={{
                          width: 157,
                          height: 160,
                        }}
                      >
                        {blocklist.length > 1 && testAcct && (
                          <IconButton
                            aria-label="Close"
                            size="small"
                            className="block-close"
                            onClick={() => handleDelete(portfolio)}
                          >
                            <CloseIcon sx={{ width: 15, height: 15 }} />
                          </IconButton>
                        )}
                        <CardContent>
                          <CardActionArea
                            onClick={() =>
                              handleSubmit(
                                portfolio.block_id,
                                portfolio.name,
                                portfolio.cname
                              )
                            }
                          >
                            <Typography
                              sx={{ fontSize: 20, mb: 1.5, mt: 3 }}
                              color="text.secondary"
                              gutterBottom
                            >
                              {portfolio.name}
                            </Typography>

                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                              {numberFormat(portfolio.balances.total_mv)}
                            </Typography>
                          </CardActionArea>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          )}
          {avlBlocklist.length > 0 && (
            <div className="vl" style={{ width: 50 }} />
          )}
          {avlBlocklist.length > 0 && (
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
              }}
            >
              <Grid sx={{ textAlign: "start", paddingLeft: "15px" }}>
                <h4 className="block_tray_header">Available blocks</h4>
              </Grid>
              <Slider
                {...settings}
                ref={slider}
                beforeChange={(_currentSlide, nextSlide) => {
                  if (slider.current) {
                    const slideElement =
                      slider.current.innerSlider.list.querySelector(
                        `[data-index="${nextSlide}"]`
                      );
                    const prevSlideElement =
                      slider.current.innerSlider.list.querySelector(
                        `[data-index="${_currentSlide}"]`
                      );
                    if (prevSlideElement) {
                      prevSlideElement.classList.remove("show-header");
                      prevSlideElement.firstElementChild.classList.remove(
                        "show-header"
                      );
                    }
                    if (slideElement) {
                      slideElement.classList.add("show-header");
                      slideElement.firstElementChild.classList.add(
                        "show-header"
                      );
                    }
                  }
                }}
              >
                {avlBlocklist.map((block) => (
                  <div style={{ width: 170 }} key={block.cname}>
                    <Card
                      className={`${
                        block.name === blockName && classes.activeCardItem
                      } cardItem`}
                      key={block.cname}
                      sx={{
                        backgroundImage: `url(${block.cname}.png) !important`,
                      }}
                    >
                      <CardContent>
                        <CardActionArea
                          onClick={() => handleNavigation(block.cname)}
                        >
                          <Typography
                            sx={{ fontSize: 20, mb: 1.5, mt: 3 }}
                            color="info.contrastText"
                            gutterBottom
                          >
                            {block.name}
                          </Typography>

                          <Typography
                            sx={{ mb: 1.5 }}
                            color="info.contrastText"
                          >
                            {numberFormat(block.cash_amt)}
                          </Typography>
                        </CardActionArea>
                      </CardContent>
                    </Card>
                  </div>
                ))}
              </Slider>
            </Grid>
          )}
          <ExpandLessIcon
            sx={{
              cursor: "pointer",
              position: "absolute",
              top: "65px",
              right: "15px",
            }}
            onClick={() => handleShowTray(false)}
          />
        </div>
      )}
    </>
  );
};
BlockHeader.propTypes = {
  blockName: PropTypes.string.isRequired,
  handleBlockChange: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  AddedBlock: PropTypes.bool.isRequired,
  showTray: PropTypes.bool.isRequired,
  handleShowTray: PropTypes.func.isRequired,
  setBlockData: PropTypes.func.isRequired,
  handleDeleteBlock: PropTypes.func.isRequired,
};
export default BlockHeader;
