import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { Button, TextField, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Formik, Form } from "formik";
import * as yup from "yup";
import AppBackService from "../services/app-back";

const ChangeEmail = ({ userData }) => {
  const Appback = AppBackService();
  const [confirmEmail, setConfirmEmail] = useState(false);
  const [oldEmail, setOldEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [showCEPassword, setShowCEPassword] = useState(false);

  useEffect(() => {
    if (userData) {
      setConfirmEmail(!userData?.attributes?.email_verified);
      setNewEmail(userData?.attributes?.email);
      setOldEmail(userData?.attributes?.email);
    }
  }, [userData]);

  const handleChangeEmail = (model, { setErrors, setStatus }) => {
    let password = model.password;
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, password, password);
      })
      .then(async () => {
        if (
          model.new_email?.toLocaleLowerCase() === oldEmail?.toLocaleLowerCase()
        ) {
          await Auth.verifyCurrentUserAttribute("email");
        }
        return Auth.updateUserAttributes(userData, {
          email: model.new_email?.toLocaleLowerCase(),
        })
          .then(async () => {
            setConfirmEmail(true);
            setNewEmail(model.new_email.toLocaleLowerCase());
            await Appback.updateUserEmail(
              userData.username,
              model.new_email.toLocaleLowerCase()
            );
          })
          .catch((err) => {
            setErrors({ api: err?.message });
            setStatus({});
          });
      })
      .catch((err) => {
        setErrors({ api: err?.message });
        setStatus({});
      });
  };
  const handleReverify = () => {
    setConfirmEmail(false);
  };
  const handleAuthCodeSubmit = (
    values,
    { setErrors, resetForm, setStatus }
  ) => {
    Auth.verifyCurrentUserAttributeSubmit("email", values.confirm_auth_code)
      .then(() => {
        setOldEmail(newEmail);
        setConfirmEmail(false);
        const status = { message: "successfully changed email" };
        setStatus(status);
        resetForm({ status });
      })
      .catch((err) => {
        setErrors({ api: err?.message });
        setStatus({});
      });
  };

  const stopMouseEvent = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <h3 data-testid="change-email-title">Change Email</h3>
      <>
        <Formik
          initialValues={{
            new_email: "",
            password: "",
            confirm_auth_code: "",
          }}
          validationSchema={yup.object().shape({
            new_email: yup
              .string("Enter your email")
              .email("Enter a valid email")
              .required("Email is required"),
            password: yup
              .string("Enter your password")
              .required("Password is required"),
          })}
          onSubmit={(values, formikBag) => {
            handleChangeEmail(values, formikBag);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            setErrors,
            resetForm,
            setStatus,
            status,
          }) => (
            <>
              <p className="error">{errors?.api}</p>
              <p className="success">{status?.message}</p>
              {!confirmEmail && (
                <Form>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="oldemail"
                    name="old_email"
                    label="Current Email Address"
                    value={oldEmail || ""}
                    onChange={handleChange}
                    error={touched.old_email && Boolean(errors.old_email)}
                    helperText={touched.old_email && errors.old_email}
                    InputProps={{ readOnly: true }}
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="newemail"
                    name="new_email"
                    autoComplete="email"
                    label="New Email Address"
                    value={values.new_email}
                    onChange={handleChange}
                    error={touched.new_email && Boolean(errors.new_email)}
                    helperText={touched.new_email && errors.new_email}
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="password"
                    name="password"
                    label="Password"
                    autoComplete="current-password"
                    type={showCEPassword ? "text" : "password"}
                    value={values.password}
                    onChange={handleChange}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowCEPassword(!showCEPassword)}
                            onMouseDown={stopMouseEvent}
                          >
                            {showCEPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    id="change_email_submit_button"
                  >
                    Submit
                  </Button>
                </Form>
              )}

              {confirmEmail && (
                <Form>
                  <p className="" style={{ fontSize: 18 }}>
                    Please check your {newEmail.toLocaleLowerCase()} email for a
                    verification code.
                  </p>
                  <p style={{ fontSize: 15 }}>
                    Please enter the verification code from that email here
                  </p>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="confirm_auth_code"
                    name="confirm_auth_code"
                    label="Auth code"
                    autoComplete="one-time-code"
                    value={values.confirm_auth_code || ""}
                    onChange={handleChange}
                    error={
                      touched.confirm_auth_code &&
                      Boolean(errors.confirm_auth_code)
                    }
                    helperText={
                      touched.confirm_auth_code && errors.confirm_auth_code
                    }
                  />
                  <Button
                    variant="contained"
                    onClick={handleReverify}
                    style={{ width: "49%", marginRight: "2%" }}
                    id="change_email_send_button"
                  >
                    Send Again
                  </Button>
                  <Button
                    variant="contained"
                    style={{ width: "49%" }}
                    onClick={() => {
                      handleAuthCodeSubmit(values, {
                        setErrors,
                        resetForm,
                        setStatus,
                      });
                    }}
                    id="change_email_verify_submit_button"
                  >
                    Submit
                  </Button>
                </Form>
              )}
            </>
          )}
        </Formik>
      </>
    </>
  );
};
export default ChangeEmail;
