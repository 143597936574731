import React from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import MaterialTable from "@material-table/core";
import PropTypes from "prop-types";

function HoldingsList(props) {
  const { holdings } = props;
  const columns = [
    {
      field: "name",
      title: "Name",
    },
    {
      field: "symbol",
      title: "Symbol",
    },
    {
      field: "nshares",
      title: "Nshares",
    },
    {
      field: "cost",
      title: "Cost",
    },
    {
      field: "current",
      title: "Current",
    },
    {
      field: "change%",
      title: "Change%",
    },
    {
      field: "our_stance",
      title: "Our Stance",
    },
  ];
  return (
    <Grid
      item
      xs={12}
      sm={12}
      lg={12}
      container
      direction="row"
      className="border-top"
    >
      <Card className="holdings-table">
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            className="head"
          >
            Holdings
          </Typography>
          <Typography variant="body2" component="div" className="holdinglist">
            <MaterialTable
              title="Holdings"
              columns={columns}
              data={holdings}
              options={{
                search: false,
                filtering: false,
                showTitle: false,
                sorting: true,
                paging: false,
                pageSizeOptions: [5, 10, 20, 50, 100],
                pageSize: 6,
                emptyRowsWhenPaging: false,
                toolbar: false,
                headerStyle: {
                  backgroundColor: "#fff",
                  color: "#039be5",
                  fontWeight: "800",
                  textAlign: "center",
                },
              }}
            />
            <table className="MuiTable-root">
              <tfoot className="MuiTableFooter-root">
                <tr className="MuiTableRow-root MuiTableRow-footer">
                  <td
                    className="MuiTableCell-root MuiTableCell-footer MuiTableCell-sizeMedium MuiTablePagination-root holdinglist-footer"
                    colSpan="7"
                  >
                    Not all holdings are shown
                  </td>
                </tr>
              </tfoot>
            </table>
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}
HoldingsList.propTypes = {
  holdings: PropTypes.instanceOf(Array).isRequired,
};

export default HoldingsList;
