import React, { useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import Modal from "./modal";

const ERROR = "error";

function ErrorHandler(errorLog) {
  const handleFallbackErrors = useErrorHandler();
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const errorModal = () => <Modal open={open} setOpen={setOpen} msg={msg} />;

  return {
    handleError: async (httpStatus, errorObj) => {
      if (httpStatus === 400) {
        await errorLog({
          level: ERROR,
          msg: errorObj.msg || errorObj.toString(),
        });
        setOpen(true);
        setMsg(errorObj.msg);
      } else if (httpStatus >= 500) {
        await errorLog({
          level: ERROR,
          msg: `Received 500 error: ${httpStatus}`,
        });
        handleFallbackErrors(errorObj);
      }
    },
    errorModal,
  };
}

export default ErrorHandler;
