import React from "react";
import { Container, Grid } from "@mui/material";
import "./funding.scss";
import Addfund from "./Addfund";
import Addinvests from "./Addinvests";
import ConfirmAddfunds from "./ConfirmAddfunds";
import AddfundsStatus from "./AddfundsStatus";

const Cashmanager = () => {
  const [showAdd, setShowAdd] = React.useState(true);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [showStatus, setShowStatus] = React.useState(false);
  const [acctId, setAcctId] = React.useState(0);
  const [amount, setAmount] = React.useState(0);
  const [updatedReleases, setUpdatedReleases] = React.useState(false);
  const handleSubmit = (id, amountValue) => {
    setShowConfirm(true);
    setShowAdd(false);
    setShowStatus(false);
    setAcctId(id);
    setAmount(amountValue);
  };
  const handleConfirm = (id, amountValue) => {
    setShowConfirm(false);
    setShowAdd(false);
    setShowStatus(true);
    setAcctId(id);
    setAmount(amountValue);
  };
  const handleStatus = (_id) => {
    setShowConfirm(false);
    setShowAdd(true);
    setShowStatus(false);
    setAcctId(0);
  };
  const handleRelease = () => {
    setUpdatedReleases(true);
    setTimeout(() => {
      setUpdatedReleases(false);
    }, 100);
  };

  const renderComponent = () => (
    <div className="cashmanager">
      <Grid item xs={6}>
        <div
          className="fundingbasic size accountstatus"
          style={{ paddingTop: "15px" }}
        >
          {showAdd && (
            <>
              <h3 id="addfunds_title">Add funds to 99rises</h3>
              <Addfund
                handleSubmit={handleSubmit}
                handleRelease={handleRelease}
                isCashmanager
              />
            </>
          )}
          {showConfirm && (
            <ConfirmAddfunds
              id={acctId}
              amount={amount}
              isCashmanager
              handleConfirm={handleConfirm}
            />
          )}
          {showStatus && (
            <AddfundsStatus
              id={acctId}
              isCashmanager
              amount={amount}
              handleStatus={handleStatus}
            />
          )}
        </div>
      </Grid>
      <Grid item xs={6} className="cash-mobile">
        <div className="fundingbasic size accountstatus">
          <Addinvests isCashmanager updatedReleases={updatedReleases} />
        </div>
      </Grid>
    </div>
  );

  return (
    <div className="add-funds AddFundsPageContentHeight">
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {renderComponent()}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Cashmanager;
