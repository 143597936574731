const getCountriesData = () => {
  let countryData = [];
  return async (appBack) => {
    if (!countryData.length) {
      countryData = await appBack.getCountryData();
      return countryData;
    }
    return countryData;
  };
};
export default getCountriesData();
