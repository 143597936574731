import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Container } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import PropTypes from "prop-types";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AppBackService from "../services/app-back";

function BlockBanner(props) {
  const navigate = useNavigate();
  const Appback = AppBackService();
  const { Portfoliolist, handleClick, selIndex, locationState } = props;
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState(0);
  const [starData, setStarData] = useState([]);

  const getBlockStarData = async () => {
    Appback.getBlockStarData("blocks").then((res) => {
      if (res && res.blocks) setStarData(res.blocks);
    });
  };

  const handleBackNavigation = async (path) => {
    navigate(path, {
      replace: true,
      state: locationState,
    });
  };

  useEffect(() => {
    getBlockStarData();
    setCurrentImageIndex(selIndex);
    setLastIndex(Portfoliolist.length);
  }, [selIndex]);

  const prev = () => {
    if (currentImageIndex !== 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };
  const next = () => {
    if (currentImageIndex !== lastIndex - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };
  const currentIdex = (index) => {
    setCurrentImageIndex(index);
  };
  const handleOnClick = (index, item) => {
    setCurrentImageIndex(index);
    handleClick(index, item);
  };

  const setBlockStarData = (cName) => {
    if (starData.includes(cName)) {
      const array = [...starData];
      const index = array.indexOf(cName);
      if (index !== -1) {
        array.splice(index, 1);
        setStarData(array);
      }
    } else {
      const array = [...starData];
      array.push(cName);
      setStarData(array);
    }
  };

  const handleBlockStarData = (cName) => {
    setBlockStarData(cName);
    const data = {
      key: cName,
      value: !starData.includes(cName),
    };
    Appback.saveStarData(data, "blocks")
      .then(() => {})
      .catch(() => {
        setBlockStarData(cName);
      });
  };

  return (
    <Grid item xs={12} sm={12} md={12} lg={12} container direction="row">
      {Portfoliolist.length > 0 && (
        <>
          <Container>
            {locationState && locationState.from === "port" && (
              <div className="backtolist">
                <ArrowBackIcon />
                <a onClick={() => handleBackNavigation("/portfolio")}>
                  Back to Portfolio
                </a>
              </div>
            )}
            {locationState && locationState.from === "list" && (
              <div className="backtolist">
                <ArrowBackIcon />
                <a onClick={() => handleBackNavigation("/blocklist")}>
                  Back to all Blocks
                </a>
              </div>
            )}
          </Container>
          <Carousel
            showThumbs={false}
            showStatus={false}
            showArrows={false}
            showIndicators={false}
            onChange={handleOnClick}
            selectedItem={currentImageIndex}
          >
            {Portfoliolist.map((item) => (
              <div block={item.name} key={item.name}>
                <img src={`${item.cname}.png`} alt={item.name} />
                <p className="legend">
                  <div
                    className="img-star"
                    onClick={() => handleBlockStarData(item.cname)}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={`${
                        starData.includes(item.cname)
                          ? "Vector.png"
                          : "Vector_non.png"
                      }`}
                      alt={item.name}
                      style={{ width: "20px", height: "20px", margin: "10px" }}
                    />
                  </div>
                  <span>{item.name}</span>
                </p>
              </div>
            ))}
          </Carousel>
          <Container>
            <div className="carousel-navigators">
              <ArrowBackIosNewIcon
                className="control-arrow control-prev"
                onClick={prev}
                style={{
                  marginLeft: "30px",
                  color: "#fff",
                  cursor: "pointer",
                  marginTop: "5px",
                  fontSize: "0.8rem",
                }}
                fontSize="small"
              />
              <ul
                className="control-dots"
                style={{ marginTop: "0", paddingLeft: "0" }}
              >
                {Portfoliolist.map((_item, index) => (
                  <li
                    className={`dot ${
                      index === currentImageIndex ? "selected" : ""
                    }`}
                    value="0"
                    aria-label="slide item 1"
                    onClick={() => currentIdex(index)}
                  />
                ))}
              </ul>
              <ArrowForwardIosIcon
                onClick={next}
                className="control-arrow control-next"
                style={{
                  color: "#fff",
                  cursor: "pointer",
                  marginTop: "5px",
                  fontSize: "0.8rem",
                }}
                fontSize="small"
              />
            </div>
          </Container>
        </>
      )}
    </Grid>
  );
}
BlockBanner.propTypes = {
  Portfoliolist: PropTypes.instanceOf(Array).isRequired,
  handleClick: PropTypes.func.isRequired,
  selIndex: PropTypes.number.isRequired,
};
export default BlockBanner;
