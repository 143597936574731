import React, { useState } from "react";
import { Button, Container, Grid, TextField, Link } from "@mui/material";
import { Auth } from "aws-amplify";
import { useFormik } from "formik";
import * as yup from "yup";
import PhoneNumberInput from "../components/PhoneNumberInput";
import PasswordComponent, {
  passwordValidation,
} from "../components/PasswordComponent";
import SignUpConfirmation from "./SignUpConfirmation";

const PHONE_CODE = "+1";
const validationSchema = yup.object({
  first_name: yup
    .string("Enter your first name")
    .required("First name is required"),
  last_name: yup
    .string("Enter your last name")
    .required("Last name is required"),
  email_address: yup
    .string("Enter your email address")
    .email("Please enter valid email")
    .required("Email address is required"),
  phone_number: yup
    .string("Enter your phone number")
    .required("Phone number is required")
    .test("test-name", "Enter a valid phone number.", (value) => {
      const phoneRegex = /\d/g;
      const usPhoneRegex = /^\(?(\d{3})\)?[-. ]?(\d{3})[-. ]?(\d{4})$/;

      if (value?.includes(PHONE_CODE)) {
        if (!usPhoneRegex.test(value.replace("+1 ", ""))) return false;
      } else if (!phoneRegex.test(value)) return false;
      return true;
    }),
  password: passwordValidation(),
});
const maskValue = "+1 (___) ___-____";

const SignUp = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [values, setValues] = React.useState({
    othercountry: false,
    first_name: "",
    last_name: "",
    email_address: "",
    password: "",
    confirm_password: "",
    phone_number: "",
    showPassword: false,
    showConfirmPassword: false,
    showConfirmation: false,
    authCode: "",
    error: "",
    error1: "",
    auth_code_error: "",
    first_name_required_error: "",
    last_name_required_error: "",
    email_required_error: "",
    email_valid_error: "",
    password_required_error: "",
    confirm_password_required_error: "",
    phone_number_required_error: "",
  });
  const handleSubmit = (model) => {
    if (!isSubmitted) {
      setValues({ ...values });
      const { email_address, password } = model;
      const username = email_address.toLowerCase();
      let pnumber = model.phone_number.replace(/[^0-9]/g, "");
      pnumber = `+${pnumber}`;
      try {
        Auth.signUp({
          username,
          password,
          attributes: {
            email: email_address.toLowerCase(),
            given_name: model.first_name,
            family_name: model.last_name,
            phone_number: pnumber,
          },
        })
          .then(() =>
            setValues({
              showConfirmation: true,
              email_address: username,
              password,
              first_name: values.first_name,
              last_name: values.last_name,
            })
          )
          .catch((error) => {
            if (
              error.message ===
              "An account with the given email already exists."
            ) {
              setValues({ ...values, error: error.message });
            } else setValues({ ...values, error: error.message });
          });
      } catch (error) {
        setValues({ ...values, error: error.message });
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email_address: "",
      phone_number: "",
      password: "",
    },
    validationSchema,
    onSubmit: (model) => {
      handleSubmit(model);
      setIsSubmitted(true);
    },
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePhoneChange = (e) => {
    if (e.target.value !== maskValue) {
      formik.values.phone_number = e.target.value;
    }
  };
  const handleChangePassword = (prop) => (event) => {
    formik.values.password = event.target.value;
    setValues({
      ...values,
      [prop]: event.target.value,
    });
  };

  return (
    <div className="sign-up SignUpPageContentHeight">
      {!values.showConfirmation && (
        <Container>
          <Grid container spacing={1}>
            <Grid item xs={1} sm={2} md={3}>
              {" "}
            </Grid>
            <Grid
              item
              xs={10}
              sm={8}
              md={6}
              style={{
                borderRadius: "10px",
                paddingLeft: "0px",
                paddingRight: "0px",
              }}
            >
              <br />
              <br />
              <p>Let’s get started by creating your online account.</p>
              <br />
              <p style={{ color: "red" }}>{values.error}</p>
              <div>
                <form onSubmit={formik.handleSubmit} style={{ width: "90%" }}>
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      style={{ paddingRight: "0px", paddingLeft: "5%" }}
                    >
                      <TextField
                        variant="outlined"
                        style={{ width: "96%" }}
                        id="first_name"
                        name="first_name"
                        label="First Name"
                        value={formik.values.first_name}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.first_name &&
                          Boolean(formik.errors.first_name)
                        }
                        helperText={
                          formik.touched.first_name && formik.errors.first_name
                        }
                      />{" "}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      style={{ paddingLeft: "0px", paddingRight: "5%" }}
                    >
                      <TextField
                        variant="outlined"
                        style={{ width: "96%" }}
                        id="last_name"
                        name="last_name"
                        label="Last Name"
                        value={formik.values.last_name}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.last_name &&
                          Boolean(formik.errors.last_name)
                        }
                        helperText={
                          formik.touched.last_name && formik.errors.last_name
                        }
                      />
                    </Grid>
                  </Grid>
                  <TextField
                    variant="outlined"
                    style={{ width: "90%" }}
                    id="email_address"
                    name="email_address"
                    label="Email Address"
                    value={formik.values.email_address}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.email_address &&
                      Boolean(formik.errors.email_address)
                    }
                    helperText={
                      formik.touched.email_address &&
                      formik.errors.email_address
                    }
                  />
                  <PhoneNumberInput
                    inputId="phone_number"
                    handlePhoneChange={handlePhoneChange}
                    error={
                      formik.touched.phone_number &&
                      Boolean(formik?.errors?.phone_number)
                    }
                    helperText={
                      formik.touched.phone_number &&
                      formik?.errors?.phone_number
                    }
                    width="90%"
                    phoneNumber=""
                  />
                  <PasswordComponent
                    values={values}
                    label="Password"
                    name="password"
                    type={values.showPassword ? "text" : "password"}
                    value={formik.values.password}
                    onChange={handleChangePassword("password")}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    width="90%"
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      width: "90%",
                      height: "50px",
                      color: "white !important",
                    }}
                    id="continue_button"
                  >
                    CONTINUE
                  </Button>
                  <br />
                </form>
              </div>
              <Grid
                container
                spacing={1}
                justifyContent="center"
                style={{
                  textAlign: "center",
                  marginBottom: "10px",
                  marginTop: "15px",
                }}
              >
                <div className="belowsignup">
                  <Grid container spacing={1}>
                    <p>
                      Already have an account? &nbsp;
                      <Link
                        className="login_button"
                        style={{ color: "#5bbaf9" }}
                        href="/"
                      >
                        Login
                      </Link>
                    </p>
                  </Grid>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={1} sm={2} md={3} id="signup_phone_grid">
              {" "}
            </Grid>
          </Grid>
        </Container>
      )}
      {values.showConfirmation && (
        <SignUpConfirmation email={values.email_address} />
      )}
    </div>
  );
};

export default SignUp;
