import React from "react";
import { Auth } from "aws-amplify";
import axios from "axios";
import ErrorHandler from "../app/error-handling";
import { reloadData } from "../utils/reload-data";

const API_URL = `${process.env.REACT_APP_APPBACK_URL}`;

const AppBackService = () => {
  const axiosAll = async (type, url, params, callErrorLog = true) => {
    let sess = null;
    try {
      sess = await Auth.currentSession();
    } catch (e) {
      // console.log("IOS user no session");
    }

    try {
      const baseUrl = API_URL;

      const headers = { "Request-Client": "WebApp" };
      if (sess) {
        headers.Authorization = `Bearer ${sess.accessToken.jwtToken}`;
      }

      const resp = await axios({
        method: `${type}`,
        url: baseUrl + url,
        data: params,
        headers,
        params,
      });
      return resp.data;
    } catch (error) {
      const { status, data } = error.response;
      if (callErrorLog && status === 500) {
        await handleError(status, { msg: data.message });
      }
      return { error, status, data, msg: data.message };
    }
  };

  const quotes = async (symbols) => {
    const res = await axiosAll("Get", "/quotes", {
      symbols,
    });
    return res.quotes.quote;
  };

  const initUserAccount = async (firstName, lastName) => {
    const userData = await reloadData();
    await axiosAll("Post", "/users", {
      username: userData.cognitoUserName,
      first_name: firstName,
      last_name: lastName,
      email: userData.cognitoUserEmail,
      phonenum: userData.cognitoUserPhoneNumber,
    });
  };

  const deliverDocument = async (bacctId, date, type) => {
    const res = await axiosAll("Get", "/docs/deliver", {
      bacct_id: bacctId,
      date,
      type,
    });
    return res;
  };

  const acctBalances = async (acctId) => {
    const res = await axiosAll("Get", `/accounts/${acctId}/pfdata`, {});
    if (res !== "ERROR") {
      return res;
    }
    const balances = {
      cash: 4000,
      hedge: 500,
      long_mv: 8000,
      net_cash: 1000,
      net_exposure: 0,
      reserve: 0,
      holdings: {
        longs: [],
        shorts: [],
        hedges: [],
      },
      sections: {
        cash_pct: 0.116,
        hedge_pct: 0.1,
        long_pct: 0.645,
        short_pct: 0.139,
      },
      short_mv: -2500,
      total_mv: 0,
    };
    return balances;
  };

  const verifyDeposits = async (extacctId, depOne, depTwo) => {
    const res = await axiosAll("Post", "/extaccts/verify", {
      extacct_id: extacctId,
      dep_one: depOne,
      dep_two: depTwo,
    });
    return res;
  };

  const sectorData = async (acctId) => {
    const res = await axiosAll("Get", `/accounts/${acctId}/industries`, {});
    return res;
  };

  const downloadDocument = async (bacctId, date, type) => {
    const res = await axiosAll("Get", `/accounts/${bacctId}/docs`, {
      type,
      date,
    });
    return res;
  };

  const getAssignBlock = async (acctId, factors) => {
    const res = await axiosAll("Post", `/accounts/${acctId}/blockassign`, {
      // const res = await axiosAll("Post", "/acct/give500", {
      factors,
    });
    return res;
  };

  const getBlockData = async (cname) => {
    const res = await axiosAll("Get", "/blockdata", {
      cname,
    });
    return res;
  };

  const logError = async (data) => {
    const res = await axiosAll("Post", "/app/log", data, false);
    return res;
  };

  const getCountryData = async () => {
    const { countries } = await axiosAll("Get", "/data/countries");
    const countryData = [];
    countries.forEach((item) => {
      const country = {
        value: item,
        key: item,
      };
      countryData.push(country);
    });
    return countryData;
  };

  const getPerformanceData = async (acctId) => {
    const res = await axiosAll("Get", `/accounts/${acctId}/returns`, {});
    return res;
  };

  const updateUserEmail = async (username, email) => {
    const res = await axiosAll("Put", `/users/${username}`, {
      email,
    });
    return res;
  };

  const populateBlockDetails = async (acctId, cname) => {
    return axiosAll("Get", `/accounts/${acctId}/populate/${cname}`, {});
  };

  const updateUserPhone = async (username, phoneNumber) => {
    const res = await axiosAll("Put", `/users/${username}`, {
      phone_number: phoneNumber,
    });

    return res;
  };

  const getAcctBlocks = async (acctId) => {
    return axiosAll("Get", `/accounts/${acctId}/blocks`, {});
  };

  const getABlockPfData = async (blockId) =>
    axiosAll("Get", `/ablocks/${blockId}/pfdata`, {});

  const sectorBlockData = async (blockId) =>
    axiosAll("Get", `/ablocks/${blockId}/industries`, {});

  const performanceBlockData = async (blockId) =>
    axiosAll("Get", `/ablocks/${blockId}/returns`, {});

  const getBlocks = async () => axiosAll("Get", "/pblocks");

  const getBlockStarData = async (type) => {
    const userData = await reloadData();
    return axiosAll("Get", `/users/${userData.cognitoUserName}/stars/${type}`);
  };

  const saveStarData = async (data, type) => {
    const userData = await reloadData();
    const res = await axiosAll(
      "Post",
      `/users/${userData.cognitoUserName}/stars/${type}`,
      {
        key: data.key,
        value: data.value,
      }
    );
    return res;
  };
  const deleteAcctBlocks = async (blockId) => {
    return axiosAll("Delete", `/ablocks/${blockId}`);
  };
  const getStockMemo = async (ticker) =>
    axiosAll("Get", `/stockmemo`, {
      ticker,
    });
  const getCards = async () => {
    const userData = await reloadData();
    return axiosAll("Get", `/users/${userData.cognitoUserName}/cards`, {});
  };
  const updateReleasePending = async (acctId, transId) => {
    axiosAll("Put", `/accounts/${acctId}/fundtransfers/release`, {
      id: transId,
    });
  };
  const getFundtransfers = async (acctId) => {
    return axiosAll("Get", `/accounts/${acctId}/fundtransfers`, {});
  };
  const insertFundTransfers = async (acctId, data) => {
    return axiosAll("Put", `/accounts/${acctId}/fundtransfers`, {
      type: data.type,
      amount: data.amount,
      cname: data.cname,
    });
  };
  const getVerifCode = async (username) => {
    return axiosAll("Get", `/verifcode/${username}`, {});
  };

  const getAcctBal = async (acct_id) => {
    return axiosAll("Get", `/accounts/${acct_id}/tmv`, {});
  };
  const { handleError } = ErrorHandler(logError);
  return {
    initUserAccount,
    deliverDocument,
    acctBalances,
    verifyDeposits,
    sectorData,
    downloadDocument,
    getBlockData,
    logError,
    getCountryData,
    getAssignBlock,
    getPerformanceData,
    updateUserEmail,
    populateBlockDetails,
    updateUserPhone,
    getAcctBlocks,
    getABlockPfData,
    sectorBlockData,
    performanceBlockData,
    getBlocks,
    getBlockStarData,
    saveStarData,
    deleteAcctBlocks,
    quotes,
    getStockMemo,
    getCards,
    updateReleasePending,
    getFundtransfers,
    insertFundTransfers,
    getVerifCode,
    getAcctBal,
  };
};

export function withAppbackService(Component) {
  return function WrappedComponent(props) {
    const Appback = AppBackService();
    return <Component {...props} Appback={Appback} />;
  };
}

export default AppBackService;
