import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";

const Index = () => {
  const [ewaValue, setEwaValue] = useState([]);

  useEffect(() => {
    debugger;
    if (ewaValue.length == 0) {
      let archive = [],
        keys = Object.keys(localStorage),
        i = 0,
        key;

      for (; (key = keys[i]); i++) {
        archive.push({ Key: key, Value: localStorage.getItem(key) });
      }
      setEwaValue(archive);
    }
  }, []);
  return (
    <Container className="blocks-table">
      <table className="MuiTable-root block_performance">
        <thead>
          <tr className="MuiTableRow-root MuiTableRow-head">
            <th
              className="MuiTableCell-root MuiTableCell-head MuiTableCell-alignCenter"
              scope="col"
            >
              Key
            </th>
            <th
              className="MuiTableCell-root MuiTableCell-head MuiTableCell-alignCenter"
              scope="col"
            >
              Value
            </th>
          </tr>
        </thead>
        <tbody className="MuiTableBody-root">
          {ewaValue.map((item) => (
            <tr className="MuiTableRow-root">
              <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignCenter">
                {item.Key}
              </td>
              <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignCenter">
                {item.Value}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
};
export default Index;
