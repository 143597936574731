import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  FormControl,
  FormHelperText,
  Container,
  Grid,
  Tooltip,
  Typography,
  Box,
  tooltipClasses,
} from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import StepTitle from "../components/StepTitle";
import StepData from "../services/step-data";
import StepComponent from "../components/StepComponent";
import StepButtons from "../components/StepButtons";
import DialogComponent from "../components/DialogComponent";
import { getMaxSteps } from ".";

const CURRENT_STEP = 4;
const theme = createTheme();
const useStyles = makeStyles(() => ({
  form: {
    marginTop: theme.spacing(9),
  },
  error: {
    color: "red !important",
    textAlign: "left !important",
  },
  labelText: {
    lineHeight: "1.2 !important",
  },
}));

const ToolTipContent = React.forwardRef(function ToolTipContent(props, ref) {
  return (
    <span {...props} ref={ref}>
      {" "}
      <u>{props.children}</u>
    </span>
  );
});

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const Step4 = () => {
  const classes = useStyles();
  const { control, handleSubmit, setValue, errors, getValues } = useForm({});
  const navigate = useNavigate();
  const [values, setValues] = useState({
    total_net_worth: "",
    liquid_net_worth: "",
    annual_income: "",
    detailsAvailable: false,
    totalAssetPercent: "10",
    investmentProfile: {},
    accountType: "",
    maxStep: 0,
  });
  const [dailogOpen, setDailogOpen] = useState(false);

  useEffect(() => {
    const handleGetDetails = async () => {
      const res = await StepData.get();
      if (res.data && res.data.UserInformation_by_pk) {
        const profile = res.data.UserInformation_by_pk.investmentProfile;
        setValues({
          detailsAvailable: true,
          investmentProfile: profile,
          total_net_worth: res.data.UserInformation_by_pk.total_net_worth,
          liquid_net_worth: res.data.UserInformation_by_pk.liquid_net_worth,
          annual_income: res.data.UserInformation_by_pk.annual_income,
          accountType: res.data.UserInformation_by_pk.account_type,
          maxStep: getMaxSteps(res.data.UserInformation_by_pk.account_type),
        });

        setValue("annualIncome", res.data.UserInformation_by_pk?.annual_income);
        setValue(
          "totalNetWorth",
          res.data.UserInformation_by_pk?.total_net_worth
        );
        setValue(
          "liquidNetWorth",
          res.data.UserInformation_by_pk?.liquid_net_worth
        );
        setValue(
          "totalAssetPercent",
          res.data.UserInformation_by_pk?.asset_percentage
        );
      }
    };

    handleGetDetails();
  }, []);

  const handleUpdateDetails = async (
    data,
    stepRoute = `step/${CURRENT_STEP + 1}`,
    activeStep = CURRENT_STEP + 1
  ) => {
    if (values.detailsAvailable) {
      let totalNetWorthUSD;
      let liquidNetWorthUSD;
      let annualIncomeUSD;

      if (data.totalNetWorth) {
        totalNetWorthUSD = {
          min: parseInt(data.totalNetWorth.split("-")[0]),
          max: parseInt(data.totalNetWorth.split("-")[1]),
        };
      }
      if (data.liquidNetWorth) {
        liquidNetWorthUSD = {
          min: parseInt(data.liquidNetWorth.split("-")[0]),
          max: parseInt(data.liquidNetWorth.split("-")[1]),
        };
      }
      if (data.annualIncome) {
        annualIncomeUSD = {
          min: parseInt(data.annualIncome.split("-")[0]),
          max: parseInt(data.annualIncome.split("-")[1]),
        };
      }
      const updatingValue = {
        investmentProfile: {
          ...values.investmentProfile,
          totalNetWorthUSD,
          liquidNetWorthUSD,
          annualIncomeUSD,
        },
      };

      await StepData.updateN(
        `
        investmentProfile: $investmentProfile
        active_step: ${activeStep},
        annual_income: "${data.annualIncome}",
        total_net_worth: "${data.totalNetWorth}",
        liquid_net_worth: "${data.liquidNetWorth}",
        asset_percentage: "${data.totalAssetPercent}",
        email: "%(cognitoUserEmail)s"
        `,
        updatingValue
      );
      // FIXME: TimingBug
      await new Promise((r) => setTimeout(r, 500));

      navigate(`/${stepRoute}`);
    }
  };

  const onSubmit = (data) => {
    if (
      data.annualIncome === "0-25000" &&
      data.totalNetWorth === "0 - 50000" &&
      values.accountType === "individual"
    ) {
      setDailogOpen(true);
    } else handleUpdateDetails(data);
  };

  const onBack = () => {
    const data = getValues();
    handleUpdateDetails(data, `step/${CURRENT_STEP - 1}`, CURRENT_STEP - 1);
  };

  const annualIncomeOptions = [
    { value: "0-25000", key: "$0 - $25,000" },
    { value: "25001-50000", key: "$25,001 - $50,000" },
    { value: "50001-100000", key: "$50,001 - $100,000" },
    { value: "100001-200000", key: "$100,001 - $200,000" },
    { value: "200001-300000", key: "$200,001 - $300,000" },
    { value: "300001-500000", key: "$300,000+" },
  ];
  const totalNetWorthTitle =
    "Your total net worth is the value of the assets you own, including your home, automobiles, or interests in businesses, minus the liabilities you owe, like your mortgage or car loans.";
  const totalNetWorthOptions = [
    { value: "0 - 50000", key: "$0 - $50,000" },
    { value: "50001-100000", key: "$50,001 - $100,000" },
    { value: "100001-200000", key: "$100,001 - $200,000" },
    { value: "200001-500000", key: "$200,001 - $500,000" },
    { value: "500001-1000000", key: "$500,001 - $1,000,000" },
    { value: "1000001-5000000", key: "$1,000,000+" },
  ];
  const liquidNetWorthTitle =
    "The most common types of assets that comprise your liquid net worth are cash deposits in checking and savings accounts, and marketable securities like stocks and bonds in standard brokerage as well as retirement accounts.";
  const totalAssetsOptions = [
    { value: "25", key: "0-25%" },
    { value: "50", key: "25-50%" },
    { value: "75", key: "50-75% " },
    { value: "100", key: "> 75%" },
  ];
  const totalAssetsTitle =
    "Publicly traded instruments (inclusive of equities, exchange traded funds and mutual funds) are securities that represent fractional ownership of corporations. These instruments trade on public stock exchanges either as “shares” or “units”.";

  return (
    <div className="step1">
      <br />
      <Container className={classes.form} style={{ marginTop: "0px" }}>
        <Grid container spacing={6}>
          <Grid item xs={10} sm={8} lg={6} className="stepsize">
            <form onSubmit={handleSubmit(onSubmit)}>
              <StepTitle
                currentStep={CURRENT_STEP}
                titleText="Your Finances"
                maxStep={values.maxStep}
              />
              <Typography
                variant="subtitle1"
                component="p"
                align="left"
                paragraph
                className={classes.labelText}
              >
                What is your annual earned income?
              </Typography>
              <Box mt={1} mb={1}>
                <FormControl variant="outlined">
                  <StepComponent
                    inputType="Select"
                    inputLabel="Annual Income"
                    options={annualIncomeOptions}
                    componentProp={{
                      autoFocus: true,
                      label: "Annual Income",
                    }}
                    controllerProp={{
                      control,
                      name: "annualIncome",
                      defaultValue: "",
                      rules: {
                        required: "Annual Income Field is Required",
                      },
                    }}
                  />
                  <FormHelperText className={classes.error}>
                    {errors.annualIncome && errors.annualIncome.message}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Typography
                variant="subtitle1"
                component="p"
                align="left"
                paragraph
                className={classes.labelText}
              >
                What is your{" "}
                <LightTooltip
                  title={<Typography>{totalNetWorthTitle}</Typography>}
                >
                  <ToolTipContent>total net worth</ToolTipContent>
                </LightTooltip>{" "}
                not including your primary residence?
              </Typography>
              <Box mt={1} mb={1}>
                <FormControl variant="outlined">
                  <StepComponent
                    inputType="Select"
                    inputLabel="Total Net Worth"
                    options={totalNetWorthOptions}
                    componentProp={{
                      label: "Total Net Worth",
                    }}
                    controllerProp={{
                      control,
                      name: "totalNetWorth",
                      defaultValue: "",
                      rules: {
                        required: "Total Net Worth Field is Required",
                      },
                    }}
                  />
                  <FormHelperText className={classes.error}>
                    {errors.totalNetWorth && errors.totalNetWorth.message}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Typography
                variant="subtitle1"
                component="p"
                align="left"
                paragraph
                className={classes.labelText}
              >
                What is your{" "}
                <LightTooltip
                  title={<Typography>{liquidNetWorthTitle}</Typography>}
                >
                  <ToolTipContent>liquid net worth</ToolTipContent>
                </LightTooltip>{" "}
                ? Your liquid net worth includes balances in bank accounts and
                investments like stocks and mutual funds.
              </Typography>
              <Box mt={1} mb={1}>
                <FormControl variant="outlined">
                  <StepComponent
                    inputType="Select"
                    inputLabel="Liquid Net Worth"
                    options={totalNetWorthOptions}
                    componentProp={{
                      label: "Liquid Net Worth",
                    }}
                    controllerProp={{
                      control,
                      name: "liquidNetWorth",
                      defaultValue: "",
                      rules: {
                        required: "Liquid Net Worth Field is Required",
                      },
                    }}
                  />
                  <FormHelperText className={classes.error}>
                    {errors.liquidNetWorth && errors.liquidNetWorth.message}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Typography
                variant="subtitle1"
                component="p"
                align="left"
                paragraph
                className={classes.labelText}
              >
                What percentage of your liquid net worth is invested in{" "}
                <LightTooltip
                  title={<Typography>{totalAssetsTitle}</Typography>}
                >
                  <ToolTipContent>
                    publicly traded stocks, mutual funds or ETFs
                  </ToolTipContent>
                </LightTooltip>{" "}
                ?
              </Typography>
              <Box mt={1} mb={1}>
                <FormControl variant="outlined">
                  <StepComponent
                    inputType="Select"
                    options={totalAssetsOptions}
                    controllerProp={{
                      control,
                      name: "totalAssetPercent",
                      defaultValue: "",
                      rules: {
                        required: "This Field is Required",
                      },
                    }}
                  />
                  <FormHelperText className={classes.error}>
                    {errors.totalAssetPercent &&
                      errors.totalAssetPercent.message}
                  </FormHelperText>
                </FormControl>
              </Box>
              <StepButtons onBack={onBack} />
            </form>
          </Grid>
        </Grid>
        <DialogComponent dailogOpen={dailogOpen} setDailogOpen={setDailogOpen}>
          <p>
            Unfortunately, we can not open a Margin account for you as needed by
            our current strategies. This is because of the Income and/or Total
            Assets needed to meet regulatory requirements applied by our
            brokerage partner Tradier. We hope to be able to support a separate
            account type soon. We can let you know when that happens.
          </p>
          <p>
            Please feel free to reach out to us at{" "}
            <a href="mailto:support@99rises.com">support@99rises.com</a> with
            questions you may have. Thank you.
          </p>
        </DialogComponent>
      </Container>
    </div>
  );
};

export default Step4;
