import React from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import PropTypes from "prop-types";

function Holdings(props) {
  const { holdingData } = props;
  const customTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${payload[0].name} : ${payload[0].value}%`}</p>
        </div>
      );
    }

    return null;
  };
  const renderCustomizedLabel = ({ percent, index, name }) => {
    const x = 220;
    const y = 35 * (index + 1);
    return (
      <g>
        <foreignObject
          x={x}
          y={y}
          width={200}
          height={100}
          fill="black"
          dominantBaseline="central"
        >
          <div
            style={{
              background: `${holdingData.colors[index]}`,
              width: "20px",
              borderRadius: "50%",
              display: "inline-block",
              marginRight: "10px",
            }}
          >
            &nbsp;
          </div>
          {`${name}`}&nbsp; &nbsp;
          {`${(percent * 100).toFixed(1)}%`}
        </foreignObject>
      </g>
    );
  };
  return (
    <Grid item xs={12} sm={12} lg={12} container direction="row">
      <Card className="holdings">
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            className="head"
          >
            Holdings Classification
          </Typography>
          <Typography variant="body2" component="span">
            {holdingData.data && (
              <PieChart width={350} height={220}>
                <Pie
                  data={holdingData.data}
                  cx={100}
                  cy={100}
                  innerRadius={50}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  labelLine={false}
                  label={renderCustomizedLabel}
                  dataKey="value"
                  isAnimationActive={false}
                >
                  {holdingData.data.map((entry, index) => (
                    <Cell
                      key={`cell-${entry.name}`}
                      fill={
                        holdingData.colors[index % holdingData.colors.length]
                      }
                    />
                  ))}
                </Pie>
                <Tooltip content={customTooltip} />
              </PieChart>
            )}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}
Holdings.propTypes = {
  holdingData: PropTypes.instanceOf(Array).isRequired,
};
export default Holdings;
