import { Box, IconButton, Container } from "@mui/material";
import React from "react";
import ViewListIcon from "@mui/icons-material/ViewList";
import AppsIcon from "@mui/icons-material/Apps";
import "./searchBar.scss";

export default function SearchBar({ setView, view }) {
  return (
    <Container className="outer-container" maxWidth={false}>
      <Box className="main-outer-box">
        <Box className="list-grid-icons-div">
          <IconButton
            onClick={() => {
              setView(1);
            }}
            className={view === 1 ? "active" : "btn"}
          >
            <AppsIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setView(2);
            }}
            className={view === 2 ? "active" : "btn"}
          >
            <ViewListIcon />
          </IconButton>
        </Box>
      </Box>
    </Container>
  );
}
