import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { Button, TextField, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Formik, Form } from "formik";
import * as yup from "yup";
import AppBackService from "../services/app-back";
import PhoneNumberInput from "../components/PhoneNumberInput";

const PHONE_CODE = "+1";

const ChangePhoneNumber = ({ userData }) => {
  const Appback = AppBackService();
  const [oldPhoneNumber, setOldPhoneNumber] = useState("");
  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (userData) {
      setOldPhoneNumber(userData?.attributes?.phone_number);
    }
  }, [userData]);

  const handleChangePhoneNumber = async (
    model,
    { resetForm, setErrors, setStatus }
  ) => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(
          user,
          model.phone_number_password,
          model.phone_number_password
        );
      })
      .then(() => {
        Auth.currentAuthenticatedUser().then((user) => {
          let pnumber = model.new_phone_number.replace(/\D/g, "");
          pnumber = `+${pnumber}`;

          return Auth.updateUserAttributes(user, {
            phone_number: pnumber,
          })
            .then(async () => {
              await Appback.updateUserPhone(userData.username, pnumber);
              setOldPhoneNumber(model.new_phone_number);
              setNewPhoneNumber(model.new_phone_number);
              const status = { message: "successfully changed phonenumber" };
              setStatus(status);
              resetForm({ status });
            })
            .catch((err) => {
              setErrors({ api: err?.message });
              setStatus({});
            });
        });
      })
      .catch((err) => {
        setErrors({ api: err?.message });
        setStatus({});
      });
  };

  const stopMouseEvent = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <h3 data-testid="change-phone-number">Change Phone number</h3>
      <>
        <Formik
          initialValues={{
            new_phone_number: "",
            phone_number_password: "",
          }}
          validationSchema={yup.object().shape({
            new_phone_number: yup
              .string("Enter a new phone number")
              .required("New phone number is required")
              .test("test-name", "Enter a valid phone number.", (value) => {
                const phoneRegex = /\d/g;
                const usPhoneRegex =
                  /^\(?(\d{3})\)?[-. ]?(\d{3})[-. ]?(\d{4})$/;

                if (value?.includes(PHONE_CODE)) {
                  if (!usPhoneRegex.test(value.replace("+1 ", "")))
                    return false;
                } else if (!phoneRegex.test(value)) return false;
                return true;
              }),
            phone_number_password: yup
              .string("Enter your password")
              .required("Password is required"),
          })}
          onSubmit={(values, formikBag) => {
            handleChangePhoneNumber(values, formikBag);
          }}
        >
          {({ values, errors, touched, status, handleChange }) => (
            <Form>
              {errors?.api && <p className="error">{errors?.api}</p>}
              {status?.message && <p className="success">{status?.message}</p>}
              <PhoneNumberInput
                inputId="old_phone_number"
                handlePhoneChange={handleChange}
                error={
                  touched.old_phone_number && Boolean(errors.old_phone_number)
                }
                helperText={touched.old_phone_number && errors.old_phone_number}
                width="100%"
                phoneNumber={oldPhoneNumber || ""}
                readOnly={true}
              />
              <PhoneNumberInput
                inputId="new_phone_number"
                handlePhoneChange={handleChange}
                error={
                  touched.new_phone_number && Boolean(errors.new_phone_number)
                }
                helperText={touched.new_phone_number && errors.new_phone_number}
                width="100%"
                phoneNumber={newPhoneNumber || ""}
              />
              <TextField
                fullWidth
                variant="outlined"
                id="phone_number_password"
                name="phone_number_password"
                label="Password"
                autoComplete="current-password"
                type={showPassword ? "text" : "password"}
                value={values.phone_number_password}
                onChange={handleChange}
                error={
                  touched.phone_number_password &&
                  Boolean(errors.phone_number_password)
                }
                helperText={
                  touched.phone_number_password && errors.phone_number_password
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={stopMouseEvent}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                type="submit"
                variant="contained"
                id="change_phone_submit_button"
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </>
    </>
  );
};
export default ChangePhoneNumber;
