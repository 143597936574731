import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  FormControl,
  FormHelperText,
  Container,
  Grid,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import StepData from "../services/step-data";
import StepTitle from "../components/StepTitle";
import StepComponent from "../components/StepComponent";
import StepButtons from "../components/StepButtons";
import { getMaxSteps } from ".";

const CURRENT_STEP = 3;
const theme = createTheme();
const useStyles = makeStyles(() => ({
  form: {
    marginTop: theme.spacing(9),
  },
  error: {
    color: "red !important",
    textAlign: "left !important",
  },
  textFieldError: {
    padding: "0 14px 0 14px",
    color: "red !important",
  },
}));

const Step3 = () => {
  const classes = useStyles();
  const { control, handleSubmit, setValue, errors, getValues } = useForm({});
  const navigate = useNavigate();
  const [values, setValues] = useState({
    maxStep: 0,
  });

  useEffect(() => {
    const handleGetDetails = async () => {
      const result = await StepData.get();
      if (result.data && result.data.UserInformation_by_pk) {
        const res = result.data.UserInformation_by_pk;

        setValues({
          maxStep: getMaxSteps(res.account_type),
        });

        if (res.age !== 0) {
          setValue("age", res.age);
          if (res.marital_status && res.marital_status !== "null") {
            setValue("marital_status", res.marital_status);
          }
          setValue("dependents", res.dependents);
        }
      }
    };

    handleGetDetails();
  }, []);

  const handleUpdateDetails = async (
    data,
    stepRoute = `step/${CURRENT_STEP + 1}`,
    activeStep = CURRENT_STEP + 1
  ) => {
    await StepData.updateN(
      `
      active_step: ${activeStep},
      age: ${data.age},
      dependents: ${data.dependents},
      marital_status: "${data.marital_status}"
      `,
      {}
    );
    navigate(`/${stepRoute}`);
  };

  const onSubmit = (data) => {
    handleUpdateDetails(data);
  };

  const onBack = () => {
    const data = getValues();
    handleUpdateDetails(data, `step/${CURRENT_STEP - 1}`, CURRENT_STEP - 1);
  };

  return (
    <div className="step2">
      <br />
      <Container style={{ marginTop: "0px" }}>
        <Grid container spacing={6}>
          <Grid item xs={10} sm={8} lg={6} className="stepsize">
            <form onSubmit={handleSubmit(onSubmit)}>
              <StepTitle
                currentStep={CURRENT_STEP}
                titleText="Let's gather some basic information first"
                maxStep={values.maxStep}
              />
              <Box mt={1} mb={1}>
                <FormControl>
                  <StepComponent
                    inputType="Input"
                    controllerProp={{
                      control,
                      name: "age",
                      id: "age",
                      defaultValue: "",
                      rules: {
                        required: "Age Field is Required",
                      },
                    }}
                    componentProp={{
                      type: "number",
                      variant: "outlined",
                      label: "Age",
                      autoFocus: true,
                      inputProps: {
                        min: "18",
                        max: "120",
                        maxLength: "3",
                      },
                      onInput: (e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 3);
                      },
                    }}
                  />
                  <FormHelperText className={classes.textFieldError}>
                    {errors.age && errors.age.message}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box mt={1} mb={1}>
                <FormControl variant="outlined">
                  <StepComponent
                    inputType="Select"
                    inputLabel="Marital Status"
                    options={[
                      { key: "Single", value: "single" },
                      { key: "Married", value: "married" },
                      { key: "Divorced", value: "divorced" },
                      { key: "Widowed", value: "widowed" },
                    ]}
                    componentProp={{
                      label: "Marital Status",
                    }}
                    controllerProp={{
                      control,
                      name: "marital_status",
                      defaultValue: "",
                      rules: {
                        required: "Marital Status Field is Required",
                      },
                    }}
                  />
                  <FormHelperText className={classes.error}>
                    {errors.marital_status && errors.marital_status.message}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box mt={1} mb={1}>
                <FormControl>
                  <StepComponent
                    inputType="Input"
                    controllerProp={{
                      control,
                      name: "dependents",
                      id: "Dependents",
                      defaultValue: "",
                      rules: {
                        required: "Dependents Field is Required",
                      },
                    }}
                    componentProp={{
                      type: "number",
                      variant: "outlined",
                      label: "Dependents",
                      inputProps: {
                        min: "0",
                        max: "99",
                        maxLength: "2",
                      },
                      onInput: (e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 2);
                      },
                    }}
                  />
                  <FormHelperText className={classes.textFieldError}>
                    {errors.dependents && errors.dependents.message}
                  </FormHelperText>
                </FormControl>
              </Box>
              <StepButtons onBack={onBack} />
            </form>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Step3;
