import { Grid, Box, Container } from "@mui/material";
import React from "react";
import CardComponent from "./cardComponent";

export default function CardGrid({
  view,
  Cards,
  showCardDetails,
  openCardModel,
}) {
  return (
    <Container className="outer-container" maxWidth={false}>
      <Box>
        <Grid
          container
          spacing={{ xs: 2, md: 3, lg: view === 1 ? 6 : 3 }}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          {Cards.length > 0 ? (
            <CardComponent
              Cards={Cards}
              viewType={view === 1 ? "card" : "card list-card"}
              imgheight={view === 1 ? "200px" : "180px"}
              showCardDetails={showCardDetails}
              isFrom="List"
              openCardModel={openCardModel}
            />
          ) : (
            <h2 className="result">No Result Found</h2>
          )}
        </Grid>
      </Box>
    </Container>
  );
}
