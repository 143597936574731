import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Grid, Button, Box } from "@mui/material";
import StepData from "../services/step-data";
import StepTitle from "../components/StepTitle";
import Service from "../services/hservice";
import Appback from "../services/app-back";
import ErrorHandler from "../app/error-handling";
import { getMaxSteps } from ".";
import MapRiskScoreFactors from "../services/map-risk-score-factors";
import "./step6.scss";

const CURRENT_STEP = 6;
const Step6 = () => {
  const [values, setValues] = useState({
    pfid: "",
    risk_score: "",
    portfolioName: "",
    desc_lines: "",
    showLoading: true,
    maxStep: 0,
  });
  const navigate = useNavigate();
  const { getAssignBlock, logError } = Appback();
  const { errorModal, handleError } = ErrorHandler(logError);
  const getDetails = async () => {
    const result = await StepData.get();
    if (result.data && result.data.UserInformation_by_pk) {
      const res = result.data.UserInformation_by_pk;
      const factors = MapRiskScoreFactors({
        age: res.age,
        gender: "M",
        maritalStatus: res.marital_status,
        dependents: res.dependents,
        annualIncome: res.annual_income,
        netWorth: res.total_net_worth,
        liquidAssets: res.liquid_net_worth,
        investedAssets: res.asset_percentage,
        investmentHorizon: res.investment_duration,
        riskTolerance: res.investmentProfile.riskTolerance,
      });
      const {
        data: {
          Account: [{ id }],
        },
      } = await Service.getAccountId();
      const blockResult = await getAssignBlock(id, factors);
      if (blockResult?.error && blockResult?.status) {
        setValues({ ...values, showLoading: false });
        handleError(blockResult?.status, blockResult);
      } else {
        setValues({
          ...values,
          pfid: blockResult.pfid,
          risk_score: parseFloat(blockResult.risk_score).toFixed(1),
          portfolioName: blockResult.name,
          showLoading: false,
          desc_lines: blockResult.desc_lines.join(" "),
          maxStep: getMaxSteps(res.account_type),
        });
      }
    }
  };

  useEffect(() => {
    getDetails();
  }, []);

  const handleSubmit = async () => {
    await StepData.updateN(`active_step: ${CURRENT_STEP + 1}`, {});
    navigate(`/step/${CURRENT_STEP + 1}`);
  };

  const handleBack = async () => {
    await StepData.updateN(`active_step: ${CURRENT_STEP - 1}`, {});
    navigate(`/step/${CURRENT_STEP - 1}`);
  };

  return (
    <div className="step5">
      <br />
      <Container>
        {values.showLoading && <div id="cover-spin" />}
        <Grid container spacing={6}>
          <Grid
            item
            xs={10}
            sm={8}
            md={8}
            lg={6}
            className="block-white stepsize"
          >
            <br />
            <StepTitle
              currentStep={CURRENT_STEP}
              titleText="Your Risk Score"
              maxStep={values.maxStep}
            />
            <div>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  className="block-orange"
                >
                  <Box
                    component="span"
                    className="riskscore"
                    sx={{ color: "common.orange" }}
                  >
                    {values.risk_score}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <div className="riskscore-description">
                    <p>
                      Your risk score was calculated from your goals and the
                      financial information provided earlier. The score ranges
                      from 0 to 10 with a larger number meaning greater risk
                      tolerance.
                    </p>
                    <p>
                      Based on your risk score, we recommend the{" "}
                      <span>{values.portfolioName}</span> block as your first.
                      You will get to pick additional blocks as we increase our
                      block offerings.
                    </p>
                    <span
                      dangerouslySetInnerHTML={{ __html: values.desc_lines }}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={1} style={{ width: "100%" }}>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  style={{ padding: "4px" }}
                >
                  <Button
                    onClick={handleBack}
                    variant="contained"
                    style={{
                      width: "100%",
                      height: "50px",
                      color: "white",
                    }}
                    id="back_button"
                  >
                    Back
                  </Button>
                  {/* <Button onClick={handleBack8} variant="raised">Back</Button> */}
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  style={{ padding: "4px" }}
                >
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    style={{
                      width: "100%",
                      height: "50px",
                      color: "white",
                    }}
                    id="continue_button"
                  >
                    Continue
                  </Button>
                  {/* <Button onClick={handleSubmit8} variant="raised">Continue</Button> */}
                </Grid>
              </Grid>
            </div>
            <br />
            <br />
          </Grid>
        </Grid>
      </Container>
      {errorModal()}
    </div>
  );
};

export default Step6;
