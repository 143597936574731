import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Grid, Button } from "@mui/material";
import Service from "../services/hservice";
import { numberFormat } from "../utils/numbers";

export default function ConfirmAddfunds(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [acctId, setAcctId] = React.useState(0);
  const [amount, setAmount] = React.useState(0);
  const handleSubmit = () => {
    Service.getAccount().then((res1) => {
      Service.getFundTransfersAmount(acctId).then((res) => {
        if (res.data.FundTransfers.length > 0) {
          const amountValue =
            parseFloat(res.data.FundTransfers[0].amount.replace(/\$|,/g, "")) +
            parseFloat(amount.replace(/\$|,/g, ""));
          Service.updateFundTransfers(acctId, amountValue, "deposit").then(
            () => {
              if (!props.isCashmanager) {
                navigate("/deposit_ext_status", {
                  state: { id: res1.data.Account[0].id, amount },
                });
              } else {
                props.handleConfirm(res1.data.Account[0].id, amount);
              }
            }
          );
        } else {
          Service.insertFundTransfers(
            acctId,
            res1.data.Account[0].id,
            parseFloat(amount.replace(/\$|,/g, "")),
            "deposit"
          ).then(() => {
            if (!props.isCashmanager) {
              navigate("/deposit_ext_status", {
                state: {
                  id: res1.data.Account[0].id,
                  amount: amount.replace(/\$|,/g, ""),
                },
              });
            } else {
              props.handleConfirm(res1.data.Account[0].id, amount);
            }
          });
        }
      });
    });
  };
  useEffect(() => {
    if (location && location.state && location.state.id) {
      setAcctId(location.state.id);
    } else if (props.id) {
      setAcctId(props.id);
    }
    if (location && location.state && location.state.amount) {
      setAmount(location.state.amount);
    } else if (props.amount) {
      setAmount(props.amount);
    }
  }, []);

  return (
    <div className="confirm-money-transfer">
      <Container>
        <Grid container spacing={6}>
          <Grid
            item
            xs={props.isCashmanager ? 12 : 10}
            sm={props.isCashmanager ? 12 : 8}
            md={props.isCashmanager ? 12 : 8}
            lg={props.isCashmanager ? 12 : 6}
            className="block-white"
            style={{ marginTop: props.isCashmanager ? "-35px" : "" }}
          >
            <br />
            <h3>Please confirm add funds</h3>
            <div style={{ marginTop: props.isCashmanager ? "30px" : "" }}>
              <table className="bank-details">
                <tbody>
                  <tr>
                    <td>From</td>
                    <td>:</td>
                    <td>
                      {localStorage
                        .getItem("account")
                        ?.replace(/.(?=.{4})/g, "X")}
                    </td>
                  </tr>
                  <tr>
                    <td>To</td>
                    <td>:</td>
                    <td>XXXX-9999 (99rises)</td>
                  </tr>
                  <tr>
                    <td>Amount</td>
                    <td>:</td>
                    <td>{numberFormat(amount)}</td>
                  </tr>
                </tbody>
              </table>
              <Button
                onClick={handleSubmit}
                variant="contained"
                className="confirm"
                id="confirm_button"
              >
                Confirm
              </Button>
            </div>
            <br />
            <br />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
