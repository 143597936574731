import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  FormControl,
  FormHelperText,
  Container,
  Grid,
  Typography,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import StepTitle from "../components/StepTitle";
import StepData from "../services/step-data";
import StepComponent from "../components/StepComponent";
import StepButtons from "../components/StepButtons";
import { getMaxSteps } from ".";

const CURRENT_STEP = 5;
const theme = createTheme();
const useStyles = makeStyles(() => ({
  form: {
    marginTop: theme.spacing(9),
  },
  error: {
    color: "red !important",
    textAlign: "left !important",
  },
  labelText: {
    lineHeight: "1.2 !important",
  },
}));

const Step5 = () => {
  const classes = useStyles();
  const { control, handleSubmit, setValue, errors, getValues } = useForm({});
  const navigate = useNavigate();
  const [values, setValues] = useState({
    investmentProfile: {},
    maxStep: 0,
  });

  useEffect(() => {
    const handleGetDetails = async () => {
      const result = await StepData.get();
      if (result.data && result.data.UserInformation_by_pk) {
        const res = result.data.UserInformation_by_pk;

        setValues({
          ...values,
          investmentProfile: res.investmentProfile,
          maxStep: getMaxSteps(res.account_type),
        });
        setValue("initialFund", res.investment_duration);
        setValue(
          "marketDropped",
          (res.investmentProfile && res.investmentProfile.riskTolerance) || ""
        );

        let amt = "$ ";
        if (res.invest_amount !== null) {
          amt = res.invest_amount.split("$")[1].split(",").join("");
        }
        setValue("investAmount", amt);
      }
    };

    handleGetDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUpdateDetails = async (
    data,
    stepRoute = `step/${CURRENT_STEP + 1}`,
    activeStep = CURRENT_STEP + 1
  ) => {
    // eslint-disable-next-line camelcase
    const updating_values = {
      investmentProfile: {
        ...values.investmentProfile,
        riskTolerance: data.marketDropped,
      },
    };

    await StepData.updateN(
      `
      investmentProfile: $investmentProfile,
      active_step: ${activeStep},
      investment_duration: "${data.initialFund}",
      invest_amount: "${data.investAmount}"
      `,
      updating_values
    );
    navigate(`/${stepRoute}`);
  };

  const onSubmit = (data) => {
    handleUpdateDetails(data);
  };

  const onBack = () => {
    const data = getValues();
    handleUpdateDetails(data, `step/${CURRENT_STEP - 1}`, CURRENT_STEP - 1);
  };

  const initialFundsOptions = [
    { value: "1", key: "< 1 years" },
    { value: "2", key: "1-2 years" },
    { value: "3", key: "2-5 years" },
    { value: "4", key: "5-10 years" },
    { value: "5", key: "10+ years" },
  ];

  const marketDroppedOptions = [
    { value: "HIGH", key: "Buy" },
    { value: "MEDIUM", key: "Hold" },
    { value: "LOW", key: "Sell" },
  ];

  return (
    <div className="step1">
      <br />
      <Container className={classes.form} style={{ marginTop: "0px" }}>
        <Grid container spacing={6}>
          <Grid item xs={10} sm={8} lg={6} className="stepsize">
            <form onSubmit={handleSubmit(onSubmit)}>
              <StepTitle
                currentStep={CURRENT_STEP}
                titleText="Your Investment"
                maxStep={values.maxStep}
              />
              <Typography
                variant="subtitle1"
                align="left"
                paragraph={true}
                className={classes.labelText}
              >
                How long are you intending to invest your initial funds for?
              </Typography>
              <Box mt={1} mb={1}>
                <FormControl variant="outlined">
                  <StepComponent
                    inputType="Select"
                    options={initialFundsOptions}
                    componentProp={{
                      autoFocus: true,
                    }}
                    controllerProp={{
                      control,
                      name: "initialFund",
                      defaultValue: "",
                      rules: {
                        required: "This Field is Required",
                      },
                    }}
                  />
                  <FormHelperText className={classes.error}>
                    {errors.initialFund && errors.initialFund.message}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Typography
                variant="subtitle1"
                align="left"
                paragraph={true}
                className={classes.labelText}
              >
                If the market dropped 10% in a day, would you buy, hold, or
                sell?
              </Typography>
              <Box mt={1} mb={1}>
                <FormControl variant="outlined">
                  <StepComponent
                    inputType="Select"
                    options={marketDroppedOptions}
                    controllerProp={{
                      control,
                      name: "marketDropped",
                      defaultValue: "",
                      rules: {
                        required: "This Field is Required",
                      },
                    }}
                  />
                  <FormHelperText className={classes.error}>
                    {errors.marketDropped && errors.marketDropped.message}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Typography
                variant="subtitle1"
                align="left"
                paragraph={true}
                className={classes.labelText}
              >
                How much do you intend to invest at this time?
              </Typography>
              <Box mt={1} mb={1}>
                <FormControl variant="outlined">
                  <StepComponent
                    inputType="NumberFormat"
                    controllerProp={{
                      name: "investAmount",
                      variant: "outlined",
                      control,
                      rules: {
                        validate: {
                          required: (value) => value !== "$ ",
                          positive: (value) =>
                            parseInt(
                              value
                                ? value.split("$ ").join("").split(",").join("")
                                : "",
                              10
                            ) >= 5000,
                        },
                      },
                    }}
                  />
                  <FormHelperText className={classes.error}>
                    {errors.investAmount?.type === "positive" &&
                      "$5,000 is the minimum initial investment"}
                    {errors.investAmount?.type === "required" &&
                      "This Field is Required"}
                  </FormHelperText>
                </FormControl>
              </Box>
              <StepButtons onBack={onBack} />
            </form>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Step5;
