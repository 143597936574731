import React from "react";
import { Container, Grid, Button, TextField } from "@mui/material";
import { Auth } from "aws-amplify";
import { useFormik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object({
  email_address: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
});

export default function ForgotPassword(props) {
  const formik = useFormik({
    initialValues: {
      email_address: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleForgotPassword(values);
    },
  });

  const [values, setValues] = React.useState({
    error: "",
    ShowVerification: true,
  });

  const handleForgotPassword = (model) => {
    setValues({ ...values });
    Auth.forgotPassword(model.email_address)
      .then((user) => {
        setValues({
          ...values,
          ShowVerification: false,
          email_address: model.email_address,
        });
        // props.history.push('/forgot-password-verification1');
      })
      .catch((error) => {
        setValues({ ...values, error: error.message });
      });
  };
  const onKeyPress = (e) => {
    if (e.which === 13) {
      e.preventDefault();
      formik.handleSubmit();
    }
  };
  return (
    <div className="ForgotPasswordPageContentHeight">
      {values.ShowVerification && (
        <Container>
          <Grid container spacing={1}>
            <Grid item xs={1} sm={2} md={2} lg={3}>
              {" "}
            </Grid>
            <Grid
              item
              xs={10}
              sm={8}
              md={8}
              lg={6}
              style={{
                marginTop: "6%",
                borderRadius: "10px",
                paddingLeft: "0px",
                paddingRight: "0px",
              }}
            >
              <br />
              <p>Forgot your password?</p>
              <p>Please enter the email address associated with your account</p>
              <p className="error">{values.error}</p>
              <div>
                <form onSubmit={formik.handleSubmit}>
                  <TextField
                    style={{ width: "80%" }}
                    variant="outlined"
                    id="email_address"
                    name="email_address"
                    label="Email Address"
                    value={formik.values.email_address}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.email_address &&
                      Boolean(formik.errors.email_address)
                    }
                    helperText={
                      formik.touched.email_address &&
                      formik.errors.email_address
                    }
                    onKeyPress={onKeyPress}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    style={{ width: "80%", height: "50px", color: "white" }}
                    id="submit_button"
                  >
                    Submit
                  </Button>
                </form>
              </div>
              <br />
              <br />
            </Grid>
            <Grid item xs={1} sm={2} md={2} lg={3}>
              {" "}
            </Grid>
          </Grid>
        </Container>
      )}
      {!values.ShowVerification && (
        <Container>
          <Grid container spacing={1}>
            <Grid item xs={1} sm={2} md={2} lg={3}>
              {" "}
            </Grid>
            <Grid
              item
              xs={10}
              sm={8}
              md={8}
              lg={6}
              style={{
                marginTop: "6%",
                borderRadius: "10px",
                paddingLeft: "0px",
                paddingRight: "0px",
              }}
            >
              <div>
                <p align="center">
                  We’ve sent you email at {values.email_address} to reset your
                  password.
                  <br /> <br />
                  Please close this tab and click on the link in the email
                </p>
              </div>
            </Grid>
            <Grid item xs={1} sm={2} md={2} lg={3}>
              {" "}
            </Grid>
          </Grid>
        </Container>
      )}
    </div>
  );
}
