"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setPosition = exports.getPosition = exports.isKeyboardEvent = exports.defaultStatusFormatter = exports.noop = void 0;

var _react = require("react");

var _CSSTranslate = _interopRequireDefault(require("../../CSSTranslate"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var noop = function noop() {};

exports.noop = noop;

var defaultStatusFormatter = function defaultStatusFormatter(current, total) {
  return "".concat(current, " of ").concat(total);
};

exports.defaultStatusFormatter = defaultStatusFormatter;

var isKeyboardEvent = function isKeyboardEvent(e) {
  return e ? e.hasOwnProperty('key') : false;
};
/**
 * Gets the list 'position' relative to a current index
 * @param index
 */


exports.isKeyboardEvent = isKeyboardEvent;

var getPosition = function getPosition(index, props) {
  if (props.infiniteLoop) {
    // index has to be added by 1 because of the first cloned slide
    ++index;
  }

  if (index === 0) {
    return 0;
  }

  var childrenLength = _react.Children.count(props.children);

  if (props.centerMode && props.axis === 'horizontal') {
    var currentPosition = -index * props.centerSlidePercentage;
    var lastPosition = childrenLength - 1;

    if (index && (index !== lastPosition || props.infiniteLoop)) {
      currentPosition += (100 - props.centerSlidePercentage) / 2;
    } else if (index === lastPosition) {
      currentPosition += 100 - props.centerSlidePercentage;
    }

    return currentPosition;
  }

  return -index * 100;
};
/**
 * Sets the 'position' transform for sliding animations
 * @param position
 * @param forceReflow
 */


exports.getPosition = getPosition;

var setPosition = function setPosition(position, axis) {
  var style = {};
  ['WebkitTransform', 'MozTransform', 'MsTransform', 'OTransform', 'transform', 'msTransform'].forEach(function (prop) {
    // @ts-ignore
    style[prop] = (0, _CSSTranslate.default)(position, '%', axis);
  });
  return style;
};

exports.setPosition = setPosition;