import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  FormControl,
  Container,
  Grid,
  Box,
  RadioGroup,
  Radio,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  FormHelperText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import StepTitle from "../components/StepTitle";
import StepData from "../services/step-data";
import StepComponent from "../components/StepComponent";
import StepButtons from "../components/StepButtons";
import { getMaxSteps } from ".";
import AppBackService from "../services/app-back";
import getCountriesData from "../services/get-countries-data";
import "./step1.scss";

const CURRENT_STEP = 1;
const YES = "YES";
const NO = "NO";
const theme = createTheme();
const useStyles = makeStyles(() => ({
  form: {
    marginTop: theme.spacing(9),
  },
  error: {
    color: "red !important",
    textAlign: "left !important",
  },
}));

const Step1 = () => {
  const Appback = AppBackService();
  const classes = useStyles();
  const { control, handleSubmit, setValue, errors } = useForm({});
  const navigate = useNavigate();
  const [values, setValues] = useState({
    conditions: YES,
    accountType: null,
    maxStep: 0,
    countryData: [],
  });
  const [dailogOpen, setDailogOpen] = useState(false);
  const [nonCitizens, setNonCitizens] = useState(false);

  useEffect(() => {
    const handleGetDetails = async () => {
      const countryData = await getCountriesData(Appback);
      const result = await StepData.get();
      if (result.data && result.data.UserInformation_by_pk) {
        const res = result.data.UserInformation_by_pk;
        if (res.account_type) {
          setValues({
            ...values,
            conditions: YES,
            accountType: res.account_type,
            maxStep: getMaxSteps(res.account_type),
            countryData,
          });
          setValue("citizenship", res.citizenship);
          setValue("accountType", res.account_type);
        } else {
          setValues({
            ...values,
            countryData,
          });
        }
        if (res.citizenship && res.citizenship !== "United States") {
          setNonCitizens(true);
        } else {
          setNonCitizens(false);
        }
      }
    };

    handleGetDetails();
  }, []);

  const handleUpdateDetails = async (
    data,
    stepRoute = `step/${CURRENT_STEP + 1}`,
    activeStep = CURRENT_STEP + 1
  ) => {
    setValues({
      ...values,
      accountType: data.accountType,
    });
    await StepData.updateN(
      `account_type: "${data.accountType}", active_step: ${activeStep}, citizenship: "${data.citizenship}"`,
      {}
    );
    setTimeout(() => navigate(`/${stepRoute}`), 100);
  };

  const onSubmit = (data) => {
    if (values.conditions === "YES") handleUpdateDetails(data);
    else setDailogOpen(true);
  };

  const onBack = () => {
    navigate(`/stepsinfo`);
  };
  const handleCitizenship = () => (e) => {
    setValues({
      ...values,
      conditions: "YES",
    });
    if (e.target.value !== "United States" && e.target.value !== "") {
      setNonCitizens(true);
    } else {
      setNonCitizens(false);
    }
  };
  const accountTypeOptions = [
    { value: "individual", key: "Individual" },
    { value: "traditional_ira", key: "Traditional IRA" },
    { value: "roth_ira", key: "Roth IRA" },
    { value: "rollover_ira", key: "Rollover IRA" },
  ];
  const handleRadioChange = (e) => {
    setValues({
      ...values,
      conditions: e.target.value,
    });
    if (e.target.value === "NO") setDailogOpen(true);
  };

  const handleAccountType = () => (e) => {
    setValues({
      ...values,
      accountType: e.target.value,
      maxStep: getMaxSteps(e.target.value),
    });
  };

  return (
    <div className="step1">
      <br />
      <Container className={classes.form} style={{ marginTop: "0px" }}>
        <Grid container spacing={6}>
          <Grid item xs={10} sm={8} lg={6} className="stepsize">
            <form onSubmit={handleSubmit(onSubmit)}>
              <StepTitle
                currentStep={CURRENT_STEP}
                titleText="A few quick qualifying questions"
                maxStep={values.maxStep}
              />
              <Box mt={1} mb={1}>
                {" "}
                <FormControl variant="outlined">
                  <StepComponent
                    inputType="Select"
                    inputLabel="Account Type"
                    options={accountTypeOptions}
                    componentProp={{
                      label: "Account Type",
                      onChange: handleAccountType(),
                    }}
                    controllerProp={{
                      control,
                      name: "accountType",
                      defaultValue: "",
                      rules: {
                        required: "Account Type Field is Required",
                      },
                    }}
                  />
                  <FormHelperText className={classes.error}>
                    {errors?.accountType?.message}
                  </FormHelperText>
                </FormControl>{" "}
              </Box>
              <Box mt={1} mb={1}>
                <FormControl variant="outlined">
                  <StepComponent
                    inputType="Select"
                    inputLabel="Citizenship"
                    options={values.countryData}
                    controllerProp={{
                      control,
                      name: `citizenship`,
                      defaultValue: "",
                      rules: {
                        required: "Citizenship Field is Required",
                      },
                    }}
                    componentProp={{
                      onChange: handleCitizenship(),
                    }}
                  />
                  <FormHelperText className={classes.error}>
                    {errors?.citizenship?.message}
                  </FormHelperText>
                </FormControl>
              </Box>
              {nonCitizens && (
                <Box mt={1} mb={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    container
                    direction="row"
                  >
                    <Grid
                      item
                      xs={7}
                      sm={7}
                      md={7}
                      lg={7}
                      container
                      direction="row"
                      className="non-citizen"
                    >
                      Are you a permanent US Resident?
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      container
                      direction="row"
                    >
                      <FormControl
                        component="fieldset"
                        className={classes.radioControl}
                      >
                        <RadioGroup
                          row
                          aria-label="conditions"
                          name="conditions"
                          value={values.conditions}
                          onChange={(e) => handleRadioChange(e)}
                        >
                          <FormControlLabel
                            value={YES}
                            control={<Radio color="primary" />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value={NO}
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              )}
              <StepButtons onBack={onBack} />
            </form>
          </Grid>
        </Grid>
        <Dialog
          open={dailogOpen}
          onClose={() => setDailogOpen(false)}
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <p>
                We&apos;re sorry but our brokerage partner can not create an
                account for you at this time, if you aren&apos;t a permanent US
                Resident or a US Citizen.
              </p>{" "}
              <p>
                Please send email to us at{" "}
                <a href="mailto:support@99rises.com">support@99rises.com</a>{" "}
                with any questions.
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions id="MuiDialogActions-root">
            <Button
              style={{ backgroundColor: "#1685d3", color: "white" }}
              onClick={() => setDailogOpen(false)}
              id="close_button"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </div>
  );
};

export default Step1;
