import React, { useState, useEffect, createContext } from "react";
import { Auth } from "aws-amplify";

export const RootContext = createContext({
  isLoggedOut: false,
  setIsLoggedOut: () => {},
  authStatus: false,
  setAuthStatus: () => {},
  accountStatus: false,
  setAccountStatus: () => {},
  accountStatusName: "",
  setAccountStatusName: () => {},
  accountLength: 0,
  setAccountLength: () => {},
  userAttributes: null,
  setUserAttributes: () => {},
  hasTimeOut: false,
  setHasTimeOut: () => {},
  redirectUrl: "",
  setRedirectUrl: () => {},
});

const RootProvider = (props) => {
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const [hasTimeOut, setHasTimeOut] = useState(false);
  const [authStatus, setAuthStatus] = useState(null);
  const [accountStatusName, setAccountStatusName] = useState("");
  const [accountLength, setAccountLength] = useState(0);
  const [userAttributes, setUserAttributes] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);

  const getUserDetails = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      setAuthStatus(true);
    } catch (e) {
      setAuthStatus(false);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <RootContext.Provider
      value={{
        isLoggedOut,
        setIsLoggedOut,
        authStatus,
        setAuthStatus,
        accountStatusName,
        setAccountStatusName,
        accountLength,
        setAccountLength,
        userAttributes,
        setUserAttributes,
        hasTimeOut,
        setHasTimeOut,
        redirectUrl,
        setRedirectUrl,
      }}
    >
      {props.children}
    </RootContext.Provider>
  );
};

export default RootProvider;
