import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Box,
  Grid,
  Chip,
  Typography,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import AppBackService from "../services/app-back";

const CardComponent = ({
  Cards,
  viewType,
  imgheight,
  showCardDetails,
  isFrom,
  openCardModel,
}) => {
  const Appback = AppBackService();
  const [starData, setStarData] = useState([]);
  const getCardStartData = async () => {
    Appback.getBlockStarData("cards").then((res) => {
      if (res && res.cards) setStarData(res.cards);
    });
  };
  const setCardStarData = (cardId) => {
    if (starData.includes(cardId)) {
      const array = [...starData];
      const index = array.indexOf(cardId);
      if (index !== -1) {
        array.splice(index, 1);
        setStarData(array);
      }
    } else {
      const array = [...starData];
      array.push(cardId);
      setStarData(array);
    }
  };
  const handleCardStarData = (cardId) => {
    setCardStarData(cardId);
    const data = {
      key: cardId,
      value: !starData.includes(cardId),
    };
    Appback.saveStarData(data, "cards")
      .then(() => {})
      .catch(() => {
        setCardStarData(cardId);
      });
  };
  const handleActions = (e, cardId, actionType) => {
    e.stopPropagation();
    if (actionType === "card") showCardDetails(cardId);
    else if (actionType === "star") {
      handleCardStarData(cardId);
    } else if (actionType === "title" && cardId) window.open(cardId, "_blank");
  };
  useEffect(() => {
    if (starData.length === 0 || !openCardModel) getCardStartData();
  }, [openCardModel]);
  return Cards.map((item) => (
    <Grid
      item
      xs={12}
      md={viewType === "card" ? 4 : 12}
      lg={viewType === "card" ? 4 : 12}
      sm={viewType === "card" ? 6 : 12}
      key={item.card_id}
    >
      <Card
        variant="outlined"
        className={viewType}
        style={{
          border: "none !important",
        }}
      >
        <div className="new">
          <CardMedia
            component="img"
            alt="green iguana"
            height={imgheight}
            className="image"
            image={item.image}
          />
          <p
            className="label"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <StarIcon
              sx={{
                color: starData.includes(item.card_id) ? "#ffb800" : "#fff",
                fontSize: "1.8rem",
                marginLeft: "-5px",
                marginRight: "2px",
                marginTop: "-3px",
                cursor: "pointer",
              }}
              onClick={(e) => handleActions(e, item.card_id, "star")}
            />
            {item.label}
          </p>
        </div>
        <CardContent className="text-section">
          <Box className="header">
            {item.link ? (
              <a
                onClick={(e) => handleActions(e, item.link, "title")}
                className="headlinelink"
              >
                <h2 className="headline">{item.headline}</h2>
              </a>
            ) : (
              <h2 className="headline">{item.headline}</h2>
            )}
          </Box>
          <Box
            className="upper-data-div"
            style={{ margin: "5px 0 10px 0", flexDirection: "column" }}
          >
            <Box className="dates">
              <Box className="lower-data-div">
                {item.source && <p>{item.source} | </p>}
                <p>{item.date}</p>
              </Box>
            </Box>
          </Box>
          {isFrom === "Details" ? (
            <>
              <p className="text" style={{ marginBottom: "20px" }}>
                {item.text}
              </p>
            </>
          ) : (
            <>
              <p className="text">{item.text}</p>
            </>
          )}
          <Typography gutterBottom component="div" sx={{ textAlign: "left" }}>
            {item.blocks.map((block) => (
              <Chip
                sx={{
                  backgroundColor: "#26a64a",
                  color: "#fff",
                  borderRadius: "7%",
                  width: "fit-content",
                  marginRight: "5px",
                }}
                label={block}
              />
            ))}
            {item.tickers.map((ticker) => (
              <Chip
                sx={{
                  backgroundColor: "#26a64a",
                  color: "#fff",
                  borderRadius: "7%",
                  width: "fit-content",
                  marginRight: "5px",
                }}
                label={ticker}
              />
            ))}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  ));
};
export default CardComponent;
