import { Auth } from "aws-amplify";

// eslint-disable-next-line import/prefer-default-export
export const reloadData = async () => {
  const currentSession = await Auth.currentSession();
  const idToken = currentSession.idToken.jwtToken;
  const refreshToken = currentSession.refreshToken.token;

  const cognitoUserData = await Auth.currentAuthenticatedUser();
  const cognitoUserName = cognitoUserData.username;
  const cognitoUserEmail = cognitoUserData.attributes.email;
  const cognitoUserPhoneNumber = cognitoUserData.attributes.phone_number;
  return {
    idToken,
    refreshToken,
    cognitoUserName,
    cognitoUserEmail,
    cognitoUserPhoneNumber,
  };
};
