import React from "react";
import Mousetrap from "mousetrap";

export default function useMagicKeys() {
  const setMagicKeys = (props) => {
    Mousetrap.bind(["command+x", "ctrl+x"], () => {
      navigator.clipboard.writeText(props?.accountId);
      return false;
    });
    Mousetrap.bind(["command+z", "ctrl+z"], () => {
      navigator.clipboard.writeText(props?.username);
      return false;
    });
  };
  const unsetMagicKeys = () => {
    Mousetrap.unbind(["command+x", "ctrl+x"]);
    Mousetrap.unbind(["command+z", "ctrl+z"]);
  };
  return [unsetMagicKeys, setMagicKeys];
}
