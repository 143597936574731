import React, { useEffect } from "react";
import {
  Button,
  Modal,
  Select,
  CircularProgress,
  MenuItem,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Service from "../services/hservice";
import AppBackService from "../services/app-back";

const AddBlock = ({
  open,
  close,
  blockName,
  dialogTitle,
  handleBlockChange,
}) => {
  const Appback = AppBackService();
  const [openmodel, setOpenmodel] = React.useState(false);
  const [selectedPortfolio, setSelectedPortfolio] = React.useState("");
  const [selectedBlock, setSelectedBlock] = React.useState("");
  const [portfoliolist, setPortfoliolist] = React.useState([]);
  const [load, setLoad] = React.useState(false);
  const handleClose = () => {
    close();
    setOpenmodel(false);
  };
  const SetBlockslist = async (selectedBlocks, length) => {
    let portfolios = [...portfoliolist];
    if (length === 0) {
      const res = await Service.getPortfolios();
      portfolios = res.data.Portfolio;
    }
    const excludeBlocks = portfolios.filter(
      (val) => !selectedBlocks.includes(val.cname)
    );
    setPortfoliolist(excludeBlocks);
  };
  const getData = async (cname, block) => {
    Service.getAccountAndAccountHoldings().then(async (res) => {
      Appback.populateBlockDetails(res.data.Account[0].id, cname).then(
        (blockRes) => {
          handleBlockChange(blockRes.block_id, true, block, cname);
          setLoad(false);
          close();
          setOpenmodel(false);
        }
      );
    });
  };
  const handleChange = async (name) => {
    setLoad(true);
    setSelectedPortfolio(name);
    const cname = name.toLowerCase().replace(/ /g, "");
    setSelectedBlock(cname);
    await getData(cname, name);
  };
  const handleSubmit = async () => {
    setLoad(true);
    await getData(selectedBlock, selectedPortfolio);
  };
  useEffect(() => {
    const portlist = async () => {
      let selectedBlocks = [];
      if (open === true) {
        await Service.getAccountAndAccountHoldings().then(async (blockres) => {
          const ablock = await Appback.getAcctBlocks(
            blockres.data.Account[0].id
          );
          selectedBlocks = ablock.blocks.map((x) => x.cname);
        });
      }
      if (open === true && portfoliolist.length === 0) {
        SetBlockslist(selectedBlocks, 0);
      } else if (open === true) {
        const includeBlocks = portfoliolist.filter((val) =>
          selectedBlocks.includes(val.cname)
        );
        if (includeBlocks.length === 0) {
          SetBlockslist(selectedBlocks, 0);
        } else {
          SetBlockslist(selectedBlocks, portfoliolist.length);
        }
        setSelectedPortfolio(blockName);
      }
    };
    portlist();
    setOpenmodel(open);
  }, [open]);

  return (
    <div className="switchmodal">
      {load === true ? (
        <div className="progressbar">
          <Modal
            open
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div>
              <div id="circle-div">
                <CircularProgress className="circle" />
              </div>
            </div>
          </Modal>
        </div>
      ) : (
        <Dialog
          open={openmodel}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
          <DialogContent>
            <Select
              autoFocus
              labelId="portfolio-label"
              id="portfolio"
              value={selectedPortfolio}
              onChange={(e) => handleChange(e.target.value)}
              variant="standard"
              fullWidth
            >
              {portfoliolist.map((portfolio) => {
                return portfolio.risk_band !== 0 ? (
                  <MenuItem key={portfolio.name} value={portfolio.name}>
                    {portfolio.name}
                  </MenuItem>
                ) : null;
              })}
            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" id="cancel_button">
              CANCEL
            </Button>
            <Button
              onClick={handleSubmit}
              disabled={selectedPortfolio === ""}
              color="primary"
              autoFocus
              id="submit_button"
            >
              SUBMIT
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};
export default AddBlock;
