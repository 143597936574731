import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  Container,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { Auth } from "aws-amplify";
import StepTitle from "../components/StepTitle";
import StepData from "../services/step-data";
import StepButtons from "../components/StepButtons";
import { getMaxSteps } from ".";
import Profile from "../settings/Profile";

const CURRENT_STEP = 7;
const Styles = {
  form: {
    marginTop: "0px",
  },
};
const YES = "YES";
const Step7 = () => {
  const childRef = useRef();
  const methods = useForm({
    defaultValues: {
      applicants: [
        {
          details: {},
          contact: {},
          employment: {},
          officeAddress: {},
        },
      ],
    },
  });
  const { reset } = methods;
  const navigate = useNavigate();
  const [values, setValues] = useState({
    applicants: [],
    conditions: YES,
    accountType: "",
    maxStep: 0,
  });
  const [userInfoData, setUserInfoData] = useState(null);
  const [dailogOpen, setDailogOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const PHONE_CODE = "+1";

  const handleGetDetails = async () => {
    let phoneNumber = "";
    const userInfo = await Auth.currentAuthenticatedUser();
    if (userInfo) {
      phoneNumber = userInfo.attributes.phone_number.includes(PHONE_CODE)
        ? userInfo.attributes.phone_number.split(PHONE_CODE)[1]
        : userInfo.attributes.phone_number;
    }
    const result = await StepData.get();
    if (result.data && result.data.UserInformation_by_pk) {
      const res = result.data.UserInformation_by_pk;
      if (!res.phoneNumber) res.phoneNumber = phoneNumber;
      res.isfromSetting = false;
      setUserInfoData(res);
      setValues({
        ...values,
        maxStep: getMaxSteps(res.account_type),
      });
    }
  };

  useEffect(() => {
    handleGetDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUpdateDetails = async (
    data,
    stepRoute = `step/${CURRENT_STEP + 1}`,
    activeStep = CURRENT_STEP + 1
  ) => {
    await StepData.update(
      `active_step: ${activeStep}, citizenship: "${data.citizenship}", ${data.dob}, ${data.ssn}, applicants: $applicants`,
      { applicants: data.applicationData }
    );
    navigate(`/${stepRoute}`);
    reset({ ...data });
  };
  const onBackSubmit = (data) => {
    handleUpdateDetails(data, `step/${CURRENT_STEP - 1}`, CURRENT_STEP - 1);
  };
  const onBackConfirm = (showConfirm) => {
    if (showConfirm) setConfirmOpen(showConfirm);
    else navigate(`/step/${CURRENT_STEP - 1}`);
  };
  const onBack = () => {
    childRef.current.onBack(false);
  };
  return (
    <div className="step1">
      <br />
      <Container sx={{ ...Styles.form }}>
        <StepTitle
          currentStep={CURRENT_STEP}
          titleText="Your Details"
          maxStep={values.maxStep}
        />
        <Typography variant="subtitle1">Personal Details</Typography>
        <Profile
          ref={childRef}
          userInfoData={userInfoData}
          onSubmit={handleUpdateDetails}
          onBackSubmit={onBackSubmit}
          onBackConfirm={onBackConfirm}
          StepButtons={<StepButtons onBack={onBack} />}
        />

        <Dialog
          open={dailogOpen}
          onClose={() => setDailogOpen(false)}
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <p>
                We&apos;re sorry but our brokerage partner can not create an
                account for you at this time, if you aren&apos;t a permanent US
                Resident or a US Citizen.
              </p>{" "}
              <p>
                Please send email to us at{" "}
                <a href="mailto:support@99rises.com">support@99rises.com</a>{" "}
                with any questions.
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions id="MuiDialogActions-root">
            <Button
              style={{ backgroundColor: "#1685d3", color: "white" }}
              onClick={() => setDailogOpen(false)}
              id="close_button"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={confirmOpen}
          onClose={() => setConfirmOpen(false)}
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <p>
                If you PROCEED, you will lose any changes you have made on this
                form. You can instead CANCEL, complete this form and submit, and
                then go back after.
              </p>{" "}
            </DialogContentText>
          </DialogContent>
          <DialogActions id="MuiDialogActions-root">
            <Button
              style={{
                backgroundColor: "#1685d3",
                color: "white",
                width: "120px",
              }}
              onClick={() => navigate(`/step/${CURRENT_STEP - 1}`)}
              id="close_button"
            >
              Proceed
            </Button>
            <Button
              style={{
                backgroundColor: "#1685d3",
                color: "white",
                width: "120px",
              }}
              onClick={() => setConfirmOpen(false)}
              id="close_button"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </div>
  );
};

export default Step7;
