import React, { useState, useEffect } from "react";
import { Container, Grid, Button, CircularProgress } from "@mui/material";
import Service from "../services/hservice";
import AppBackService from "../services/app-back";
import "./documents.scss";

const Documents = () => {
  const [documents, setDocuments] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [taxdocuments, setTaxdocuments] = useState("");
  const [statementDocuments, setStatementDocuments] = useState("");
  const [bacct_id, setBacct_id] = useState(null);
  const Appback = AppBackService();

  const getDocumentData = async () => {
    try {
      const result = await Service.getAccountId();
      setBacct_id(result?.data?.Account[0].bacct_id);
      const documentData = await Service.getDocuments(
        result.data.Account[0].id
      );
      const taxesData = documentData.data.Document.filter(
        (x) => x.type === "TAX"
      );
      const documentsData = documentData.data.Document.filter(
        (x) => x.type !== "TAX"
      );
      if (taxesData.length === 0) {
        setTaxdocuments("No Tax documents currently available");
      }
      if (documentsData.length === 0) {
        setStatementDocuments(
          "No Statements and Trades documents currently available"
        );
      }
      setDocuments(
        documentsData.sort((a, b) => new Date(b.date) - new Date(a.date))
      );
      setTaxes(taxesData.sort((a, b) => new Date(b.date) - new Date(a.date)));
    } catch (error) {
      console.log("Error in fetching document data");
    }
  };
  useEffect(() => {
    getDocumentData();
  }, []);

  const viewDocument = async (_id, date, type) => {
    await Appback.downloadDocument(bacct_id, date, type).then((res) => {
      window.open(res["documents"][0]["url"]);
    });
  };

  const taxdocs = () => {
    if (taxdocuments === "" && taxes.length === 0) {
      return <CircularProgress />;
    } else if (taxes.length === 0) {
      return <p>{taxdocuments}</p>;
    }
    return (
      <table className="table">
        <thead>
          <tr>
            <th align="left">Date</th>
            <th align="left">Type</th>
            <th align="left">Description</th>
            <th align="left">Action</th>
          </tr>
        </thead>
        <tbody>
          {taxes.map((result, i) => (
            <tr key={i}>
              <td align="left">{result.date}</td>
              <td align="left">{result.type}</td>
              <td align="left">{result.date}</td>
              <td align="left">
                <div style={{ display: "flex" }}>
                  <Button
                    id={result["id"]}
                    onClick={() =>
                      viewDocument(result.id, result.date, result.type)
                    }
                    style={{
                      fontWeight: "100",
                      marginTop: "-5px",
                      marginLeft: "-15px",
                    }}
                    id="view_button"
                  >
                    Download
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const statementdocs = () => {
    if (statementDocuments === "" && documents.length === 0) {
      return <CircularProgress />;
    } else if (documents.length === 0) {
      return <p>{statementDocuments}</p>;
    }
    return (
      <table className="table">
        <thead>
          <tr>
            <th align="left">Date</th>
            <th align="left">Type</th>
            <th align="left">Description</th>
            <th align="left">Action</th>
          </tr>
        </thead>
        <tbody>
          {documents.map((result, i) => (
            <tr key={i}>
              <td align="left">{result.date}</td>
              <td align="left">{result.type}</td>
              <td align="left">{result.date}</td>
              <td align="center">
                <div style={{ display: "flex" }}>
                  <Button
                    id={result["id"]}
                    onClick={() =>
                      viewDocument(result.id, result.date, result.type)
                    }
                    style={{
                      fontWeight: "100",
                      marginTop: "-5px",
                      marginLeft: "-15px",
                    }}
                  >
                    Download
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div className="documents">
      <br />
      <Container>
        <br />
        <Grid container spacing={6}>
          <Grid item xs={10} sm={8} md={8} lg={8} className="block-white">
            <h3>Tax Documents</h3>
            {taxdocs()}
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={10} sm={8} md={8} lg={8} className="block-white">
            <h3>Statements and Trades</h3>
            {statementdocs()}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Documents;
