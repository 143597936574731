import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Grid, Button } from "@mui/material";
import { Auth } from "aws-amplify";
import StepData from "../services/step-data";
import Service from "../services/hservice";
import AppBackService from "../services/app-back";
import "./stepsinfo.scss";

const Stepsinfo = () => {
  const navigate = useNavigate();
  const AppBack = AppBackService();

  useEffect(() => {
    const handleGetDetails = async () => {
      const res = await Service.getUserInfo();
      // Create the User and Account DB objects if needed
      if (res.data.UserInformation_by_pk === null) {
        const userInfo = await Auth.currentAuthenticatedUser();
        const firstName = userInfo.signInUserSession.idToken.payload.given_name;
        const lastName = userInfo.signInUserSession.idToken.payload.family_name;
        AppBack.initUserAccount(firstName, lastName);
      }
    };

    handleGetDetails();
  }, []);
  const handleSubmit = async () => {
    await StepData.updateN(`active_step: ${1}`, {});
    navigate("/step/1");
  };

  return (
    <div className="stepsinfo">
      <br />
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={10} sm={8} md={10} lg={10} className="block-white">
            <div className="stepinfo-titles">
              <br />
              <span>A Few Fast & Easy Steps</span>
              {/* <p>Minutes</p>
              <h2>A Few Fast & Easy Steps</h2> */}
              <h4>
                We take security & privacy seriously. We take every measure to
                securely store your data and protect your identity data.
              </h4>
            </div>

            <ul className="interview-process">
              <li>
                <img src="chat.png" alt="message icon" />
                <h2>Submit Application</h2>
                <p>
                  We'll ask you a series of questions to calculate your risk
                  score and set up an account with our brokerage partner
                  Tradier.
                </p>
              </li>
              <li>
                <img
                  src="right-arrow.png"
                  alt="arrow"
                  className="right-arrow"
                />
              </li>
              <li>
                <img src="funding.png" alt="user icon" />
                <h2>Fund Account</h2>
                <p>
                  Link your bank to fund your brokerage account and we'll invest
                  your dollars in the selected blocks.
                </p>
              </li>
              <li>
                <img
                  src="right-arrow.png"
                  alt="arrow"
                  className="right-arrow"
                />
              </li>
              <li>
                <img className="security" src="block.png" alt="security icon" />
                <h2>Pick Blocks</h2>
                <p>
                  You can either select our recommended block or select from a
                  menu of blocks offered based on your goals, values and
                  interests.
                </p>
              </li>
            </ul>
            <Button
              onClick={handleSubmit}
              variant="raised"
              style={{ marginLeft: "0px !important" }}
              id="get_started_button"
            >
              Get Started
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Stepsinfo;
