import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  FormControl,
  FormHelperText,
  Container,
  Grid,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import StepTitle from "../components/StepTitle";
import StepData from "../services/step-data";
import StepComponent from "../components/StepComponent";
import StepButtons from "../components/StepButtons";
import { getMaxSteps } from ".";
import "./step2.scss";

const CURRENT_STEP = 2;
const theme = createTheme();
const useStyles = makeStyles(() => ({
  form: {
    marginTop: theme.spacing(9),
  },
  error: {
    color: "red !important",
    textAlign: "left !important",
  },
}));

const Step2 = () => {
  const classes = useStyles();
  const { control, handleSubmit, setValue, errors, getValues } = useForm({});
  const navigate = useNavigate();
  const [values, setValues] = useState({
    detailsAvailable: false,
    investmentProfile: {},
    accountType: "",
    maxStep: 0,
  });

  useEffect(() => {
    const handleGetDetails = async () => {
      const res = await StepData.get();
      if (res.data && res.data.UserInformation_by_pk) {
        const profile = res.data.UserInformation_by_pk.investmentProfile || {};
        setValues({
          detailsAvailable: true,
          investmentProfile: res.data.UserInformation_by_pk.investmentProfile,
          accountType: res.data.UserInformation_by_pk.account_type,
          maxStep: getMaxSteps(res.data.UserInformation_by_pk.account_type),
        });
        setValue("stockExperience", profile.stockExperience);
        setValue("optionExperience", profile.optionExperience);
      }
    };

    handleGetDetails();
  }, []);

  const handleUpdateDetails = async (
    data,
    stepRoute = `step/${CURRENT_STEP + 1}`,
    activeStep = CURRENT_STEP + 1
  ) => {
    if (values.detailsAvailable) {
      const updatingValue = {
        investmentProfile: {
          ...values.investmentProfile,
          stockExperience: data.stockExperience,
          optionExperience: data.optionExperience,
        },
      };

      await StepData.updateN(
        `investmentProfile: $investmentProfile,
        active_step: ${activeStep},
        email: "%(cognitoUserEmail)s"`,
        updatingValue
      );

      navigate(`/${stepRoute}`);
    }
  };

  const onSubmit = (data) => {
    handleUpdateDetails(data);
  };

  const onBack = () => {
    const data = getValues();
    handleUpdateDetails(data, `step/${CURRENT_STEP - 1}`, CURRENT_STEP - 1);
  };
  const selectOptions = [
    { key: "0 years", value: "0" },
    { key: "1 to 2 Years", value: "1" },
    { key: "3 to 5 Years", value: "2" },
    { key: "More than 5 Years", value: "3" },
  ];

  return (
    <div className="step1">
      <br />
      <Container className={classes.form} style={{ marginTop: "0px" }}>
        <Grid container spacing={6}>
          <Grid item xs={10} sm={8} lg={6} className="stepsize">
            <form onSubmit={handleSubmit(onSubmit)}>
              <StepTitle
                currentStep={CURRENT_STEP}
                titleText="Your Prior Investment Experience"
                accountType={values.accountType}
                maxStep={values.maxStep}
              />
              <Box mt={1} mb={1}>
                <FormControl variant="outlined">
                  <StepComponent
                    inputType="Select"
                    inputLabel="Stock Investing"
                    options={selectOptions}
                    componentProp={{
                      autoFocus: true,
                      label: "Stock Investing",
                    }}
                    controllerProp={{
                      control,
                      name: "stockExperience",
                      defaultValue: "",
                      rules: {
                        required: "Stock Investing Field is Required",
                      },
                    }}
                  />
                  <FormHelperText className={classes.error}>
                    {errors.stockExperience && errors.stockExperience.message}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box mt={1} mb={1}>
                <FormControl variant="outlined">
                  <StepComponent
                    inputType="Select"
                    inputLabel="Option Investing"
                    options={selectOptions}
                    componentProp={{
                      label: "Options Investing",
                    }}
                    displayEmpty
                    controllerProp={{
                      control,
                      name: "optionExperience",
                      defaultValue: "",
                      rules: {
                        required: "Options Investing Field is Required",
                      },
                    }}
                  />
                  <FormHelperText className={classes.error}>
                    {errors.optionExperience && errors.optionExperience.message}
                  </FormHelperText>
                </FormControl>
              </Box>
              <StepButtons onBack={onBack} />
            </form>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Step2;
