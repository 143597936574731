import React from "react";
import { Container, Grid } from "@mui/material";

const LTAAccepted = () => (
  <div className="accountstatus AccountStatusPageContentHeight">
    <br />
    <Container maxWidth="lg">
      <Grid container spacing={6} sx={{ justifyContent: "center" }}>
        <Grid
          item
          xs={10}
          sm={10}
          md={10}
          lg={10}
          className="block-white statussize"
        >
          <div className="accountstatus-body">
            <p>
              You have accepted the agreements that permits us to direct the
              trades in your Tradier account after it is approved and created.
              This should happen in a day or two.
              <br />
              <br />
              Meanwhile, you can see the status of your application in your{" "}
              <a href="/accountstatus">99rises account</a>.
            </p>
          </div>
        </Grid>
      </Grid>
    </Container>
  </div>
);

export default LTAAccepted;
