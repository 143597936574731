import React from "react";
import { Container, Button } from "@mui/material";

const ConfirmMessage = (props) => {
  const handleContinue = () => {
    props.toastMessageupdate();
  };
  return (
    <div>
      <Container>
        <div>
          <p>{props.toast_message}</p>
          <br />
          <Button
            className="toast-message-button"
            onClick={() => handleContinue()}
            variant="contained"
            id="continue_button"
          >
            Continue
          </Button>
        </div>
      </Container>
    </div>
  );
};
export default ConfirmMessage;
