import React, { useEffect, useState } from "react";
import { Container, Grid } from "@mui/material";
import { Auth } from "aws-amplify";
import Services from "../services/hservice";

const Wiretransfer = () => {
  const [accountId, setAccountId] = useState("");
  const [user, setUser] = useState(null);

  const getAccount = async () => {
    const account = await Services.getAccount();
    if (account && account.data.Account[0].bacct_id) {
      setAccountId(account.data.Account[0].bacct_id);
    }
  };
  const getUserInfo = async () => {
    const userInfo = await Auth.currentAuthenticatedUser();
    setUser(userInfo);
  };

  useEffect(() => {
    getAccount();
    getUserInfo();
  }, []);

  function AccountNum() {
    return accountId ? (
      <b>{accountId} </b>
    ) : (
      <p>
        <em>
          Something went wrong! Please request your account number from
          support@99rises.com. Sorry for the inconvenience.
        </em>
      </p>
    );
  }

  return (
    <div className="wire-transfer">
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={1} sm={2} md={2} lg={3}>
            {" "}
          </Grid>
          <Grid
            item
            xs={10}
            sm={8}
            md={8}
            lg={6}
            style={{
              marginTop: "6%",
              paddingLeft: "0px",
              paddingRight: "0px",
            }}
          >
            <br />
            <h2 style={{ marginTop: "0px" }}>To fund your 99rises Account</h2>

            <div
              style={{
                padding: "0px 17px 0 17px",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  padding: "10px",
                  textAlign: "center",
                  display: "inline-block",
                  maxWidth: "500px",
                }}
              >
                You may wire funds or send a check using the instructions below.
                Once the funds have cleared into your account, we will invest
                them into your block(s).
              </div>
            </div>

            <h3 style={{ marginTop: "20px" }}>By sending a wire transfer</h3>

            <div style={{ padding: "10px 10px", textAlign: "center" }}>
              <div
                style={{
                  textAlign: "left",
                  display: "inline-block",
                  width: "400px",
                }}
              >
                Wire instructions to use with your financial institution
                <br />
                <br />
                <table>
                  <tr>
                    <td>Account Name:</td>
                    <td> Apex Clearing Inc.</td>
                  </tr>
                  <tr>
                    <td>ABA Routing :</td>
                    <td> 071000288</td>
                  </tr>
                  <tr>
                    <td>Account:</td>
                    <td> 3713286</td>
                  </tr>
                  <tr>
                    <td>Bank Name:</td>
                    <td> BMO Harris Bank</td>
                  </tr>
                  <tr>
                    <td>Address:</td>
                    <td>
                      {" "}
                      111 W. Monroe St <br /> Chicago, IL 60603
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "48%" }}>Further Credit To:</td>
                    <td>
                      {user &&
                        user.signInUserSession &&
                        user.signInUserSession.idToken.payload.given_name}{" "}
                      {user &&
                        user.signInUserSession &&
                        user.signInUserSession.idToken.payload.family_name}{" "}
                      /<br />
                      <AccountNum />
                    </td>
                  </tr>
                </table>
                <br />
                Must be sent from an account in the exact same name <br />
                as the Tradier Brokerage account holder.
                <br />
              </div>
            </div>

            <h3 style={{ marginTop: "30px" }}>By mailing a check</h3>
            <div
              style={{
                textAlign: "left",
                display: "inline-block",
                width: "400px",
              }}
            >
              Please make the check payable to <b>Apex Clearing, Inc</b> and
              include your account number on the check: <br /> <AccountNum />
              <p>
                Mail your check to the following address:
                <br />
                <br />
                Tradier Brokerage
                <br />
                11016 Rushmore Drive, Suite 350
                <br />
                Charlotte, NC 28277
              </p>
            </div>
            <br />
            <br />
          </Grid>
          <Grid item xs={1} sm={2} md={2} lg={3}>
            {" "}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Wiretransfer;
