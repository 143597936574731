import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from "@mui/material";
import PropTypes from "prop-types";

const DialogComponent = React.forwardRef(
  ({ dailogOpen, setDailogOpen, children }, ref) => (
    <>
      <Dialog
        open={dailogOpen}
        onClose={() => setDailogOpen(false)}
        aria-describedby="alert-dialog-description"
        ref={ref}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {children}
          </DialogContentText>
        </DialogContent>
        <DialogActions id="MuiDialogActions-root">
          <Button
            style={{ backgroundColor: "#1685d3", color: "white" }}
            onClick={() => setDailogOpen(false)}
            id="close_button"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
);

DialogComponent.propTypes = {
  dailogOpen: PropTypes.bool.isRequired,
  setDailogOpen: PropTypes.func.isRequired,
  children: PropTypes.instanceOf(Array).isRequired,
};
export default DialogComponent;
