import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Grid, Container } from "@mui/material";
import Service from "../services/hservice";

const Transferprocessing = (props) => {
  const [amount, setAmount] = useState(0);
  const location = useLocation();

  useEffect(() => {
    if (location && location.state && location.state.id) {
      getFundTransfers(props.location.state.id);
    } else if (props.id) {
      getFundTransfers(props.id);
    }
  }, []);

  const getFundTransfers = (id) => {
    Service.getFundTransfersAmount(id).then((res) => {
      if (res.data.FundTransfers.length > 0) {
        setAmount(
          res.data.FundTransfers[res.data.FundTransfers.length - 1].amount
        );
      }
    });
  };

  return (
    <div className="transfer-processing">
      <Container>
        <Grid container spacing={6}>
          {/* <Grid item xs={1} sm={2} md={2} lg={3}> </Grid> */}
          <Grid item xs={12} className="block-white">
            <br />
            <h3 id="transfer_process_title">Your transfer is in process</h3>
            <div>
              <div>
                <br />
                <br />
                <img src="tick-mark.png" alt="tick-mark" width="180px" />
                <br />
                <br />
              </div>

              <p>
                A transfer of ${amount} from your bank account to your 99rises
                account has been initiated. Once the funds are cleared, we will
                invest your funds in a first block on our next rebalance day.
              </p>
              <p>
                <em>
                  We are in process of making adding the block selector, so that
                  you can do this within the app.
                </em>
              </p>
            </div>
          </Grid>
          {/* <Grid item xs={1} sm={2} md={2} lg={3}> </Grid> */}
        </Grid>
      </Container>
    </div>
  );
};

export default Transferprocessing;
