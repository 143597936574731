import { sprintf } from "sprintf-js";
import { reloadData } from "../utils/reload-data";

export const API_URL = `https://${process.env.REACT_APP_HASURA_HOST}/v1/graphql`;

const graphql = {
  query: async (query) => graphql.queryN(query, {}),
  queryN: async (query, variables) => {
    const userData = await reloadData();
    const body = { query: sprintf(query, userData) };
    if (variables) {
      body.variables = variables;
    }

    const options = {
      method: "POST",
      url: `${API_URL}`,
      headers: {
        "content-type": "application/json",
        "X-Hasura-Role": "admin",
        Authorization: `Bearer ${userData.idToken}`,
      },
      body: JSON.stringify(body),
    };

    const retval = fetch(API_URL, options)
      .then((response) => response.json())
      .then((res) => {
        if (res.errors === null || res.errors === undefined) {
          return res;
        }
        console.log("Hasura error: ");
        console.log(body, variables);
        return res;
      })
      .catch((err) => {
        console.log("Graphql fetch error: ", err);
      });
    return retval;
  },
};

export default graphql;
