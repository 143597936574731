import React, { useEffect } from "react";
import { Button, Select } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

const BlockSwicherModal = ({ open, close, handleChangeTheme }) => {
  const [openmodel, setOpenmodel] = React.useState(false);
  const handleClose = () => {
    close();
    setOpenmodel(false);
  };
  useEffect(() => {
    setOpenmodel(open);
  }, [open]);
  return (
    <div className="switchmodal">
      <Dialog
        open={openmodel}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Switch Theme</DialogTitle>
        <DialogContent>
          <Select
            native
            autoFocus
            onChange={(e) => handleChangeTheme(e.target.value)}
            inputProps={{
              name: "selectedTheme",
              id: "age-native-simple",
            }}
            style={{ width: "100%" }}
            variant="standard"
          >
            <option aria-label="None" value="" />
            <option value="flat">Flat Theme</option>
            <option value="tiles">Tiles Theme</option>
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" id="cancel_button">
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default BlockSwicherModal;
