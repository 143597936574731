import React, { useEffect } from "react";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import { useFormContext } from "react-hook-form";
import MaskedInput from "react-text-mask";
import { FormControl, FormHelperText, Box, Typography } from "@mui/material";
import StepComponent from "../components/StepComponent";

const maskObj = {
  dateofbirth: [/[0-9]/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/],
  socialSecurityNumber: [
    /[0-9]/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  phoneNumber: [
    "(",
    /[0-9]/,
    /\d/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  zipCode: [/\d/, /\d/, /\d/, /\d/, /\d/],
};
const validationConst = {
  socialSecurityNumber: 9,
  phoneNumber: 10,
  zipCode: 5,
  dateFormat: "MM/DD/YYYY",
  maxDob: moment().add(-18, "y"),
  minDob: moment().add(-120, "y"),
};

const SSNMaskComponent = (props) => {
  const mask = maskObj.socialSecurityNumber;
  const onFocus = "socialSecurityNumber";
  const { inputRef, ...other } = props;
  const { setValue } = useFormContext();
  return (
    <MaskedInput
      {...other}
      // ref={(ref) => {
      //   inputRef(ref ? ref.inputElement : null);
      // }}
      mask={mask}
      onFocus={(e) => {
        onFocus && setValue(onFocus, "");
      }}
    />
  );
};
const DOBMaskComponent = (props) => {
  const mask = maskObj.dateofbirth;
  const onFocus = "dateofbirth";
  const { inputRef, ...other } = props;
  const { setValue } = useFormContext();
  return (
    <MaskedInput
      {...other}
      // ref={(ref) => {
      //   inputRef(ref ? ref.inputElement : null);
      // }}
      mask={mask}
      onFocus={(e) => {
        onFocus && setValue(onFocus, "");
      }}
    />
  );
};
const beneficiaryTypeSelect = [
  { value: "PRIMARY", key: "PRIMARY" },
  { value: "CONTINGENT", key: "CONTINGENT" },
];
const theme = createTheme();
const useStyles = makeStyles(() => ({
  form: {
    marginTop: theme.spacing(9),
  },
  error: {
    color: "red !important",
    textAlign: "left !important",
  },
  labelText: {
    lineHeight: "1.2 !important",
  },
}));

const BeneficiaryInfo = ({ data, PrimaryExist }) => {
  const classes = useStyles();
  const { errors, control, setValue, formState, reset, clearErrors } =
    useFormContext();
  const { dirtyFields } = formState;
  useEffect(() => {
    if (data) {
      clearErrors();
      setValue(`givenName`, data?.name.givenName);
      setValue(`familyName`, data?.name.familyName);
      setValue(`beneficiaryType`, data?.beneficiaryType);
      setValue(`relationship`, data?.relationship);
      setValue(`sharePercentage`, data?.sharePercent);
      setValue(`dateofbirth`, data?.dateOfBirth);
      setValue(`socialSecurityNumber`, data?.socialSecurityNumber);
    }
  }, [data]);
  return (
    <>
      <Typography variant="subtitle1">Beneficiary info</Typography>
      <Box mt={1} mb={1}>
        <FormControl variant="outlined">
          <StepComponent
            inputType="Input"
            controllerProp={{
              control,
              name: "givenName",
              id: "givenName",
              defaultValue: "",
              rules: {
                required: "First Name Field is Required",
              },
            }}
            componentProp={{
              variant: "outlined",
              label: "First Name",
            }}
          />
          <FormHelperText className={classes.error}>
            {errors.givenName && errors.givenName.message}
          </FormHelperText>
        </FormControl>
      </Box>
      <Box mt={1} mb={1}>
        <FormControl variant="outlined">
          <StepComponent
            inputType="Input"
            controllerProp={{
              control,
              name: "familyName",
              id: "familyName",
              defaultValue: "",
              rules: {
                required: "Last Name Field is Required",
              },
            }}
            componentProp={{
              variant: "outlined",
              label: "Last Name",
            }}
          />
          <FormHelperText className={classes.error}>
            {errors.familyName && errors.familyName.message}
          </FormHelperText>
        </FormControl>
      </Box>
      <Box mt={1} mb={1}>
        <FormControl variant="outlined">
          <StepComponent
            inputType="Select"
            options={beneficiaryTypeSelect}
            inputLabel="Beneficiary Type"
            controllerProp={{
              control,
              name: "beneficiaryType",
              defaultValue: PrimaryExist ? "CONTINGENT" : "",
              rules: {
                required: "Beneficiary Type Field is Required",
              },
            }}
          />

          <FormHelperText className={classes.error}>
            {errors.beneficiaryType && errors.beneficiaryType.message}
          </FormHelperText>
        </FormControl>
      </Box>
      <Box mt={1} mb={1}>
        <FormControl variant="outlined">
          <StepComponent
            inputType="Input"
            controllerProp={{
              control,
              name: "relationship",
              id: "relationship",
              defaultValue: "",
              rules: {
                required: "Relationship Field is Required",
              },
            }}
            componentProp={{
              variant: "outlined",
              label: "Relationship",
            }}
          />
          <FormHelperText className={classes.error}>
            {errors.relationship && errors.relationship.message}
          </FormHelperText>
        </FormControl>
      </Box>
      <Box mt={1} mb={1}>
        <FormControl variant="outlined">
          <StepComponent
            inputType="Input"
            controllerProp={{
              control,
              name: "sharePercentage",
              id: "sharePercentage",
              defaultValue: "",
              rules: {
                validate: {
                  required: (value) => value !== "",
                  positive: (value) =>
                    parseInt(value || "", 10) >= 1 &&
                    parseInt(value || "", 10) <= 100,
                },
              },
            }}
            componentProp={{
              variant: "outlined",
              label: "Share Percentage",
            }}
          />
          <FormHelperText className={classes.error}>
            {errors.sharePercentage?.type === "positive" &&
              "The percentage must be between 1 and 100"}
            {errors.sharePercentage?.type === "required" &&
              "Share Percentage Field is Required"}
          </FormHelperText>
        </FormControl>
      </Box>
      <Box mt={1} mb={1}>
        <FormControl variant="outlined">
          <StepComponent
            inputType="Masked"
            inputLabel="Date of Birth"
            controllerProp={{
              control,
              name: "dateofbirth",
              defaultValue: "",
              maskPattern: maskObj.dateofbirth,
              rules: {
                validate: {
                  required: (value) => value !== null && value !== "",
                  validDate: (value) =>
                    dirtyFields.dateofbirth === undefined ||
                    moment(new Date(value)).isValid(),
                  range: (value) =>
                    dirtyFields.dateofbirth === undefined ||
                    moment(value, validationConst.dateFormat).isBetween(
                      validationConst.minDob,
                      validationConst.maxDob
                    ),
                },
              },
            }}
            componentProp={{
              inputComponent: DOBMaskComponent,
            }}
          />
          <FormHelperText className={classes.error}>
            {errors.dateofbirth?.type === "required" &&
              "Date of Birth is required."}
            {errors.dateofbirth?.type === "validDate" &&
              "Date of Birth is invalid"}
            {errors.dateofbirth?.type === "range" &&
              `Date of Birth is should be in between ${validationConst.minDob.format(
                validationConst.dateFormat
              )} - ${validationConst.maxDob.format(
                validationConst.dateFormat
              )}. `}
          </FormHelperText>
        </FormControl>
      </Box>
      <Box mt={1} mb={1}>
        <FormControl variant="outlined">
          <StepComponent
            inputType="Masked"
            inputLabel="Social security number"
            controllerProp={{
              control,
              name: "socialSecurityNumber",
              defaultValue: "",
              maskPattern: maskObj.socialSecurityNumber,
              rules: {
                validate: {
                  required: (value) => value !== null && value !== "",
                  maxNumber: (value) =>
                    (dirtyFields.socialSecurityNumber === undefined &&
                      value !== undefined) ||
                    value?.split("_").join("").split("-").join("").length ===
                      validationConst.socialSecurityNumber,
                },
              },
            }}
            componentProp={{
              inputComponent: SSNMaskComponent,
            }}
          />
          <FormHelperText className={classes.error}>
            {errors.socialSecurityNumber?.type === "required" &&
              "Social security number is required."}
            {errors.socialSecurityNumber?.type === "maxNumber" &&
              `Social security number should be of ${validationConst.socialSecurityNumber} digits`}
          </FormHelperText>
        </FormControl>
      </Box>
    </>
  );
};

export default BeneficiaryInfo;
