import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import { SynchronousPromise } from 'synchronous-promise';
import ValidationError from '../ValidationError';

var promise = function promise(sync) {
  return sync ? SynchronousPromise : Promise;
};

var unwrapError = function unwrapError(errors) {
  if (errors === void 0) {
    errors = [];
  }

  return errors.inner && errors.inner.length ? errors.inner : [].concat(errors);
};

function scopeToValue(promises, value, sync) {
  //console.log('scopeToValue', promises, value)
  var p = promise(sync).all(promises); //console.log('scopeToValue B', p)

  var b = p.catch(function (err) {
    if (err.name === 'ValidationError') err.value = value;
    throw err;
  }); //console.log('scopeToValue c', b)

  var c = b.then(function () {
    return value;
  }); //console.log('scopeToValue d', c)

  return c;
}
/**
 * If not failing on the first error, catch the errors
 * and collect them in an array
 */


export function propagateErrors(endEarly, errors) {
  return endEarly ? null : function (err) {
    errors.push(err);
    return err.value;
  };
}
export function settled(promises, sync) {
  var Promise = promise(sync);
  return Promise.all(promises.map(function (p) {
    return Promise.resolve(p).then(function (value) {
      return {
        fulfilled: true,
        value: value
      };
    }, function (value) {
      return {
        fulfilled: false,
        value: value
      };
    });
  }));
}
export function collectErrors(_ref) {
  var validations = _ref.validations,
      value = _ref.value,
      path = _ref.path,
      sync = _ref.sync,
      errors = _ref.errors,
      sort = _ref.sort;
  errors = unwrapError(errors);
  return settled(validations, sync).then(function (results) {
    var nestedErrors = results.filter(function (r) {
      return !r.fulfilled;
    }).reduce(function (arr, _ref2) {
      var error = _ref2.value;

      // we are only collecting validation errors
      if (!ValidationError.isError(error)) {
        throw error;
      }

      return arr.concat(error);
    }, []);
    if (sort) nestedErrors.sort(sort); //show parent errors after the nested ones: name.first, name

    errors = nestedErrors.concat(errors);
    if (errors.length) throw new ValidationError(errors, value, path);
    return value;
  });
}
export default function runValidations(_ref3) {
  var endEarly = _ref3.endEarly,
      options = _objectWithoutPropertiesLoose(_ref3, ["endEarly"]);

  if (endEarly) return scopeToValue(options.validations, options.value, options.sync);
  return collectErrors(options);
}