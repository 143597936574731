import React from "react";

const FallbackComponent = () => {
  return (
    <div className="body-content">
      <h1 style={{ textAlign: "center" }}>We are on it..</h1>
    </div>
  );
};
export default FallbackComponent;
